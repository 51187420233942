import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Changpwd } from '../../vo/changepwd';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class ChangepwdService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  updatePwd(vo: Changpwd): Observable<any> {
    return this.apiRequest.put('api/updatePwd', vo);
  }

}
