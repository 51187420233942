import {Component, OnInit} from '@angular/core';
import {FormcodeService} from '../../services/api/formcode.service';
import {Pie} from '../../vo/pie';
import {Coordinate} from '../../vo/coordinate';
import {Formcode} from '../../vo/formcode';
import {AllocationService} from '../../services/api/allocation.service';
import {Allocation} from '../../vo/allocation';

declare var CanvasJS: any;

@Component({
  selector: 'app-dash-index',
  templateUrl: './dash-index.component.html',
  styleUrls: ['./dash-index.component.css']
})
export class DashIndexComponent implements OnInit {

  fozenFormcode: Formcode = new Formcode();
  frozenFormcodes: Formcode [] = [];
  allocation: Allocation = new Allocation();
  allocations: Allocation[] = [];
  public alerts: any = [];

  dataOpet = [];
  dataSeriousOpet1 = {
    type: 'line',
    showInLegend: 'ture',
    legendText: 'Acc',
    xValueFormatString: 'Year ####',
    yValueFormatString: '####### million',
    connectNullData: true,
    nullDataLineDashType: 'dot',
    dataPoints: []
  };
  dataSeriousOpet2 = {
    type: 'line',
    showInLegend: 'ture',
    legendText: 'All',
    xValueFormatString: 'Year ####',
    yValueFormatString: '####### million',
    connectNullData: true,
    nullDataLineDashType: 'dot',
    dataPoints: []
  };

  pie: Pie = new Pie();

  // var opetArray = new Array();
  // var opemdArray = new Array();
  constructor(private myservice: FormcodeService, private allocateService: AllocationService) {
  }

  ngOnInit() {

    this.pie.emdAll = new Coordinate(0, 0);
    this.pie.opetAll = new Coordinate(0, 0);
    this.pie.tasfAll = new Coordinate(0, 0);

    this.myservice.getPieData().subscribe((data) => {
      if (data.operationStatus === 'ERROR' ) {
        alert('error happens');
        return;
      }
      this.pie = data.item;
      console.log(data);
      this.chart();
      this.chart2();
      this.chart3();
    });

    this.myservice.getLineData().subscribe((data) => {
      if (data.operationStatus === 'ERROR' ) {
        alert('error happens');
        return;
      }

      this.dataSeriousOpet1.dataPoints = data.opetAcc;
      this.dataSeriousOpet2.dataPoints = data.opetAll;

      this.dataOpet.push(this.dataSeriousOpet1);
      this.dataOpet.push(this.dataSeriousOpet2);

      this.linechart();
    });

    this.getPageData(null, 10, null, '4');
    this.getAllocationPageData(null, 10, null, '4');

  }

  linechart() {
    const chart = new CanvasJS.Chart('chartContainer_opet',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPET'
        },
        legend: {
          horizontalAlign: 'left',
          verticalAlign: 'bottom',
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        axisX: {
          valueFormatString: '####',
        },
        axisY: {
          labelAngle: 30,
          interlacedColor: '#cfe3f7',
          gridThickness: 0,
          valueFormatString: '#####0. mil.'
        },

        data: this.dataOpet
      });

    chart.render();
  }

  chart() {
    const chart = new CanvasJS.Chart('chart_Opet',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPET Usage'
        },
        legend: {
          horizontalAlign: 'right', // "center" , "right"
          verticalAlign: 'bottom',  // "top" , "bottom"
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        theme: 'theme2',
        data: [
          {
            type: 'pie',
            radius: '95%',
            yValueFormatString: '#0.00',
            indexLabelFontFamily: 'Garamond',
            indexLabelFontSize: 14,
            indexLabel: '{label} {y}%',
            startAngle: -30,
            showInLegend: true,
            toolTipContent: ' {legendText} {y}%',
            dataPoints: [
              {y: this.pie.opetAll.x, legendText: 'Used', label: 'Used', color: '#E25740'},
              {y: this.pie.opetAll.y, legendText: 'Available', label: 'Ava', color: '#4876FF'}
            ]
          }
        ]
      });
    chart.render();
  }


  chart2() {
    const chart = new CanvasJS.Chart('chart_Opemd',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPEMD Usage'
        },
        legend: {
          horizontalAlign: 'right', // "center" , "right"
          verticalAlign: 'bottom',  // "top" , "bottom"
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        theme: 'theme2',
        data: [
          {
            type: 'pie',
            radius: '95%',
            yValueFormatString: '#0.00',
            indexLabelFontFamily: 'Garamond',
            indexLabelFontSize: 14,
            indexLabel: '{label} {y}%',
            startAngle: -30,
            showInLegend: true,
            toolTipContent: ' {legendText} {y}%',
            dataPoints: [
              {y: this.pie.emdAll.x, legendText: 'Used', label: 'Used', color: '#E25740'},
              {y: this.pie.emdAll.y, legendText: 'Available', label: 'Ava', color: '#4876FF'}
            ]
          }
        ]
      });
    chart.render();
  }

  chart3() {
    const chart = new CanvasJS.Chart('chart_Tasf',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'TASF Usage'
        },
        legend: {
          horizontalAlign: 'right', // "center" , "right"
          verticalAlign: 'bottom',  // "top" , "bottom"
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        theme: 'theme2',
        data: [
          {
            type: 'pie',
            radius: '95%',
            yValueFormatString: '#0.00',
            indexLabelFontFamily: 'Garamond',
            indexLabelFontSize: 14,
            indexLabel: '{label} {y}%',
            startAngle: -30,
            showInLegend: true,
            toolTipContent: ' {legendText} {y}%',
            dataPoints: [
              {y: this.pie.tasfAll.x, legendText: 'Used', label: 'Used', color: '#E25740'},
              {y: this.pie.tasfAll.y, legendText: 'Available', label: 'Ava', color: '#4876FF'}
            ]
          }
        ]
      });
    chart.render();
  }


  getPageData(page: number, size: number, frozen: Formcode, num: string) {
    this.myservice.getFrozenFormcode(page, size, frozen, num).subscribe((data) => {
        this.frozenFormcodes = data.items;
      }
    );
  }

  getAllocationPageData(page: number, size: number, allocation: Allocation, num: string) {
    this.allocateService.getInnerAllocates(page, size, allocation, num, null).subscribe((data) => {
        this.allocations = data.items;
      }
    );
  }


}
