import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Dpc } from '../../vo/dpc';
import { DpcService } from '../../services/api/dpc.service';

@Component({
  selector: 's-newdpc-pg',
  templateUrl: './newdpc.component.html'
})
export class NewdpcComponent implements OnInit {
  vo: Dpc = new Dpc();
  public alerts: any = [];
  constructor(private service: DpcService, private router: Router) { }

  ngOnInit() {
  }


  addDpc() {
    // this.dpcDataService.addDpc(this.newTodo);
    // this.todo = new Dpc();
    console.log(this.vo);
    this.service.addDpc(this.vo)
      .subscribe(
        (newTodo) => {
          console.log(newTodo);
          this.addMessage(newTodo.operationMessage);
        }
      );
  }

  backToDpc() {
  this.router.navigate(['/home/dpc']);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

}
