import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Document } from '../../vo/document';
import { DocumentTypeService } from '../../services/api/documentType.service';

@Component({
  selector: 'app-document-type',
  templateUrl: './document-type.component.html',
  styleUrls: ['./document-type.component.css']
})
export class DocumentTypeComponent implements OnInit {

  qry: Document = new Document();
  docs: Document[] = [];
  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;

  // constructor(){}
  //
  // ngOnInit(){}

  // @ViewChild('orderStatusCellTpl') statusCellTpl: TemplateRef<any>;
  // @ViewChild('orderIdTpl') orderIdTpl: TemplateRef<any>;
  // columns:any[];
  // rows:any[];
  constructor(private router: Router, private docService: DocumentTypeService) {}

  gotoNew() {
    this.router.navigate(['/home/newdoc']);
  }
  gotoEdit(todo) {
    this.router.navigate(['/home/editdoc'], {queryParams: {'id': todo.id, 'action' : 'U'}});
  }

  removeTodo(todo) {
    this.router.navigate(['/home/editdoc'], {queryParams: {'id': todo.id, 'action' : 'D'}});
  }

  ngOnInit() {
    const me = this;
    me.getPageData(0, 10, null);
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    const me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, this.qry);
  }

  getPageData(page: number, size: number, doc: Document) {
    // alert(this.qry.dtType);
    const me = this;
    this.docService.getDocs(page, size, doc).subscribe((data) => {
        console.log(data);
        this.docs = data.items;
        this.bigTotalItems = data.totalItems;
        this.bigCurrentPage = data.currentPageNumber + 1;
      }
    );
  }

  filter() {
    const me = this;
    me.getPageData(0, 10, this.qry);
  }

}
