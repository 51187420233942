<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css"
      xmlns:bsValue="http://www.w3.org/1999/xhtml">
<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>BSP Ti Calendar</H1>
  </div>
</div>
<!-- title -->

<div class="col-10 offset-1 table-bordered">
  <!-- Query form Start-->
  <div class="row table-bordered">
    <div class="col-12">
      <div class="row ml-1">
        <div class="form-group col-3 ml-5 mt-4">
          <label>Time:</label>
          <input type="text"
                 name="time"
                 [(ngModel)]="time"
                 [minDate]="minDate"
                 [maxDate]="maxDate"
                 #dp="bsDatepicker"
                 bsDatepicker
                 [bsValue]="today"
                 [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue', showWeekNumbers: false }">
        </div>

        <div class="form-group col-3 mt-4 ml">
          <input type="button" name="snaptable_filter" value="Filter" (click)="filter()">
        </div>
      </div>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-hover table-condensed" style="table-layout: fixed">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E;">
          <td style="width: 10%">Job</td><td style="width: 38%">Job Execution Time</td><td style="width: 10%">Status</td><td>Comment</td>
        </tr>
        </thead>
        <tbody>
        <tr class="Waving1" *ngFor="let todo of bspTiCalendars">
          <td><a>{{todo.calId.timezone}}</a></td>
          <td>{{todo.jobExecutionTime | date:'yyyy-MM-dd HH:mm:ss Z'}}</td>
          <td>{{todo.tiFlag | tiCalendarStatus}}</td>
          <td style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis" title="{{todo.comment}}">{{todo.comment}}</td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>
  <!-- Content End-->
</div>
