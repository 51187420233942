import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tiCalendarStatus'
})
export class TiCalendarStatusPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === 0) {
      return 'Todo';
    } else if (value === 1) {
      return 'Processed';
    } else {
      return 'Skipped';
    }
  }

}
