import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DocumentSubType } from '../../vo/documentSubType';
import { DocumentSubTypeService } from '../../services/api/documentSubType.service';

@Component({
  selector: 'app-document-sub-type',
  templateUrl: './document-sub-type.component.html',
  styleUrls: ['./document-sub-type.component.css']
})
export class DocumentSubTypeComponent implements OnInit {

  qry: DocumentSubType = new DocumentSubType();
  docSubTypes: DocumentSubType[] = [];

  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;

  constructor(private router: Router, private myservice: DocumentSubTypeService) { }

  ngOnInit() {
    const me = this;
    me.getPageData(0, 10, null);
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    const me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, this.qry);
  }

  getPageData(page: number, size: number, vo: DocumentSubType) {
    const me = this;
    this.myservice.getPages(page, size, vo).subscribe((data) => {
      console.log(data);
      this.docSubTypes = data.items;
      this.bigTotalItems = data.totalItems;
    }
    );
  }

  gotoNew() {
    // alert('1');
    this.router.navigate(['/home/newdocsubtype']);
  }

  gotoEdit(todo) {
    this.router.navigate
      (['/home/editdocsubtype'], { queryParams: { 'id': todo.id, 'action': 'U' } });
  }
  //
  gotoDelete(todo) {
    this.router.navigate
      (['/home/editdocsubtype'], { queryParams: { 'id': todo.id, 'action': 'D' } });
  }
  filter() {
    const me = this;
    console.log(this.qry);
    me.getPageData(0, 10, this.qry);
  }

}
