import { Component, OnInit } from '@angular/core';
import { Document } from '../../../vo/document';
import { Router } from '@angular/router';
import { DocumentTypeService } from '../../../services/api/documentType.service';

@Component({
  selector: 'app-create-doc',
  templateUrl: './create-doc.component.html',
  styleUrls: ['./create-doc.component.css']
})
export class CreateDocComponent implements OnInit {

  vo: Document = new Document();
  public alerts: any = [];
  constructor(private service: DocumentTypeService, private router: Router) { }

  ngOnInit() {
  }
  addDoc() {
    console.log(this.vo);
    this.service.addDoc(this.vo)
      .subscribe(
        (newTodo) => {
          console.log(newTodo);
          this.addMessage(newTodo.operationMessage);
        }
      );
  }

  backToList() {
    this.router.navigate(['/home/doc']);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

  statusChange(event: any) {
    if (this.vo.dtStatus === 'active') {
      this.vo.dtStatus = 'inactive';
    } else {
      this.vo.dtStatus = 'active';
    }

  }
  excludeChange(event: any) {
    if (this.vo.excluded === 'Y') {
      this.vo.excluded = 'N';
    } else {
      this.vo.excluded = 'Y';
    }
  }

}
