export class Gds {
  id: number;
  gdsCode = '';
  gdsEmail = '';
  gdsSendtifile: number;
  gdsIinetAccount: string;
  gdsEmail1 = '';
  gdsEmail2 = '';
  gdsEmail3 = '';
  gdsEmail4 = '';
  gdsUser: string;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
