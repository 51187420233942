import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ForgotpwdService } from '../../services/api/forgotpwd.service';

@Component({
  selector: 'app-forgotpwd',
  templateUrl: './forgotpwd.component.html'

})
export class ForgotpwdComponent implements OnInit {
  userName: string;
  capcha: string;
  public alerts: any = [];
  public resolved(captchaResponse: string) {
    console.log('Resolved captcha with response ${captchaResponse}:');
    // alert(captchaResponse);

  }
  constructor(  private router: Router, private forgotpwdService: ForgotpwdService) { }

  ngOnInit() {

  }

  submit() {
    this.forgotpwdService.validate(this.userName, this.capcha).subscribe((response) => {

        console.log(response);
        // alert(response.operationMessage);
        this.addMessage(response.operationMessage);

    }
    );
  }
  back() {
    this.router.navigate(['/login']);
  }
  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 5000
    });
  }
}
