import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Calendar } from '../../vo/calendar';
import { CalendarService } from '../../services/api/calendar.service';

@Component({
  selector: 'app-bsp-cal',
  templateUrl: './bsp-cal.component.html',
  styleUrls: ['./bsp-cal.component.css']
})
export class BspCalComponent implements OnInit {

  constructor(private router: Router, private myservice: CalendarService) {
  }

  calendars: Calendar[] = [];
  vo: Calendar = new Calendar();
  action: string;

  public title = 'Please Confirm';
  public purgeMessage = 'Are you sure you wish to delete this calendar?';
  public cancelClicked = false;

  public alerts: any = [];

  ngOnInit() {
    this.getData();
  }

  getData() {
    this.myservice.getAll().subscribe((data) => {
      console.log(data);
      this.calendars = data.items;
    }
    );
  }

  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: 30000
    });
  }
}
