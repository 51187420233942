import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'snformat'
})
export class SnformatPipe implements PipeTransform {

  transform(value: number): string {

    let result = '';
    // alert(value);
    if (value == null) {
      return '0';
    }

    const temp = ('0000000000' + value).slice(-10);

    result = temp.substring(0, 1) + ',' + temp.substring(1, 4) + ',' + temp.substring(4, 7) + ',' + temp.substring(7, 10);

    return result;

  }

}
