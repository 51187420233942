import { Injectable, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Dpc } from '../../vo/dpc';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class DpcService {
  constructor(private apiRequest: ApiRequestEXService) {
  }

  getDpcs(page?: number, size?: number, dpc?: Dpc): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (dpc && dpc.dpcCode) {
      params = params.append('dpcCode', dpc.dpcCode);
    }
    if (dpc && dpc.dpcEmail) {
      params = params.append('dpcEmail', dpc.dpcEmail);
    }
    if (dpc && dpc.dpcIinetAccount) {
      params = params.append('dpcIinetAccount', dpc.dpcIinetAccount);
    }

    console.log(params);
    return this.apiRequest.get('api/dpcs', params);

  }

  getDpcByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/dpc/' + id);
  }

  addDpc(vo: Dpc): Observable<any> {
    return this.apiRequest.post('api/dpc', vo);
  }

  updateDpc(vo: Dpc): Observable<any> {
    return this.apiRequest.put('api/dpc', vo);
  }

  deleteDpc(vo: Dpc): Observable<any> {
    return this.apiRequest.delete('api/dpc/' + vo.id);
  }

}
