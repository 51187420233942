<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Predefined Rule Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" >

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>Create Predefined Rule</h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form role="form" name="predefinedRuleDetailDynaForm" method="post" action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2FpredefinedRuleDetails.do&_sorig=%2FpredefinedRule.do">
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label style="color:#1B548E;">BSP</label></div>
          <div class="col-sm-9">
            <select name="bspId" [(ngModel)]="vo.bsp.id">
              <option *ngFor="let item of bsps" [value]='item.id'>{{item.bspCode}}</option>
            </select>
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label style="color:#1B548E;">Document Type</label></div>
          <div class="col-sm-9">
            <select    name="documentType" [(ngModel)]="vo.documenttypes.id">
              <option *ngFor="let item of  docs " [value]='item.id'>{{item.dtName}}</option>
            </select>
            <font color="#ff0000" size="4">*</font>

          </div>
        </div>
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="" style="color:#1B548E;">GDS code</label></div>
          <div class="col-sm-9">
            <input type="text" name="code" maxlength="100" size="20" value="" [(ngModel)]="vo.gdscode">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="" style="color:#1B548E;">Volume Per Month</label></div>
          <div class="col-sm-9">
            <input type="text" name="volumePerMonth" maxlength="100" size="20" value="" [(ngModel)]="vo.volumepermonth" >
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->
  <!-- alert -->
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="offset-1">
      <input type="submit" name="update" value="Create" (click)="addNew()">
    </div>
    <div class="offset-3">
      <input type="submit" name="back" value="Cancel" (click)="backToListPage()">
    </div>
  </div>
  <!-- button end -->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>

</div>
<!-- body mt-3 -->
