import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {AllocationRequestService} from '../../services/api/allocationRequest.service';
import {AllocationRequest} from '../../vo/allocationRequest';
import {Document} from '../../vo/document';
import {Bsp} from '../../vo/bsp';

@Component({
  selector: 'app-allocation-request-cancel',
  templateUrl: './allocation-request-cancel.component.html',
  styleUrls: ['./allocation-request-cancel.component.css']
})

export class AllocationRequestCancelComponent implements OnInit {

  constructor(private service: AllocationRequestService, private location: Location) {
  }

  allocationRequest: AllocationRequest;

  alerts: any = [];

  ngOnInit() {
    this.allocationRequest = this.service.selectRequest;
  }

  cancel() {
    this.service.submitCancel(this.allocationRequest).subscribe((response) => {
      console.log(response);
      if (response.operationStatus == 'SUCCESS') {
        this.back();
      } else {
        this.addMessage(response.operationMessage);
      }
    });
  }

  back() {
    this.location.back();
  }

  addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
}
