import {Injectable} from '@angular/core';
import {Observable} from '../../../node_modules/rxjs';
import {ApiRequestEXService} from '../services/api/api-request-ex.service';
import {ServerConfig} from '../vo/serverConfig';

@Injectable()
export class SwitchConfigService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  getConfig(): Observable<any> {
    return this.apiRequest.get('api/autoUploadTiConfig');
  }
  turnOn(): Observable<any> {
    return this.apiRequest.get('api/turnOnAutoUploadTi');
  }
  turnOff(): Observable<any> {
    return this.apiRequest.get('api/turnOffAutoUploadTi');
  }

  getMsaterNode(): Observable<any> {
    return this.apiRequest.get('api/masterNodeConfig');
  }

  switchMasterNode(serverConfig: ServerConfig): Observable<any> {
    return this.apiRequest.put('api/switchMasterNode', serverConfig);
  }
}
