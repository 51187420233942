export class Printer {

  id: number;
  email: string;
  fullname: string;
  printerActive: string;
  printercode: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
