<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Document Types Maintenance</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered" >
    <div class="col-12">
      <form class="" role="form">
        <div class="row">
          <div class="form-group col-3">
            <label for="snaptable_dtName" id="lableInput">Document Name:</label>
            <input type="text" name="snaptable_dtName"  id="snaptable_dtName" maxlength="100" value="" [(ngModel)]="qry.dtName" >
          </div>
          <div class="form-group col-2 ml-5">
            <label for="snaptable_dtType">Document type:</label>
            <select name="snaptable_dtType" id="snaptable_dtType" [(ngModel)]="qry.dtType">
              <option value="">-</option>
              <option value="1" >CGN</option>
              <option value="0" >Paper</option>
            </select>
          </div>
          <div class="form-group col-2 mt-4 ml-5">
            <input type="button" name="snaptable_filter" (click)="filter()" value="Filter">
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-hover">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E;"><td title="Edit record" > </td><td title="Delete record" > </td><td>Document Name</td><td>Document type</td><td>Description</td><td>status</td></tr></thead>

        <tbody *ngIf="docs.length > 0">
        <tr class="Waving1" *ngFor="let todo of docs">
          <td><img border="0" src="assets/SNAPDocroot//images/tableview/edit.gif" alt="Edit" (click)="gotoEdit(todo)" title="Modify this record"></td>
          <td><img border="0" src="assets/SNAPDocroot//images/tableview/delete.gif" alt="Delete" (click)="removeTodo(todo)" title="Delete this record"></td>
          <td>{{todo.dtName}}</td>
          <td>{{todo.dtType | cgn}}</td>
          <td>{{todo.dtDescription}}</td>
          <td>{{todo.dtStatus}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="docs.length == 0">
        <tr class="Waving1" >
          <td colspan="5">No Data...</td>

        </tr>
        </tbody>
      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <div class="row form-group table-bordered">
    <div class="offset-3">
      <input type="button" name="create" value="Create" (click)="gotoNew()">
    </div>
    <div class="offset-3">
      <input type="submit" name="refresh" value="Refresh" (click)="filter()">
    </div>
  </div>
  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->


