import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IssuParty } from '../../vo/issu-party';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class IssuePartyService {

  constructor(private apiRequest: ApiRequestEXService) { }

  getItemByID(id: number): Observable<any> {
    return this.apiRequest.get('api/IssueParty/' + id);
  }

  getIssuingPartys(): Observable<any> {
    return this.apiRequest.get('api/IssuePartys');
  }

  AddIssu(issu: IssuParty): Observable<any> {
    return this.apiRequest.post('api/IssueParty', issu);
  }

  getPages(page?: number, size?: number, vo?: IssuParty): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    return this.apiRequest.get('api/IssuePartys', params);
  }

  update(issu: IssuParty) {
    return this.apiRequest.put('api/UpdateIssueParty', issu);
  }

  canEdit(id: number): Observable<any> {
    return this.apiRequest.get('api/IssueParty/' + id);
  }

  delete(id: number): Observable<any> {
    return this.apiRequest.delete('api/IssueParty/' + id);
  }
}
