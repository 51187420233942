import { Component, OnInit } from '@angular/core';
import { IssuParty } from '../../../vo/issu-party';
import { IssuePartyService } from '../../../services/api/issue-party.service';
import { ActivatedRoute, Params, Router } from '@angular/router';

@Component({
  selector: 'app-edit-issue-party',
  templateUrl: './edit-issue-party.component.html',
  styleUrls: ['./edit-issue-party.component.css']
})
export class EditIssuePartyComponent implements OnInit {

  public alerts: any = [];
  issu: IssuParty = new IssuParty();
  private id: number;

  constructor(private issuService: IssuePartyService,
    protected router: Router,
    protected route: ActivatedRoute) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.canEdit(this.id);
    });

  }

  canEdit(id: number) {
    this.issuService.getItemByID(id).subscribe((data) => {
      console.log(data);
      this.issu = data.item;
    }
    );
  }

  update() {
    this.issuService.update(this.issu)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }

  delete() {
    this.issuService.delete(this.issu.id)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }

  backToList() {
    this.router.navigate(['/home/issueParty']);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 180000
    });
  }
}
