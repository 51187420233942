import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-gdsmenu',
  templateUrl: './gdsmenu.component.html',
  styleUrls: ['./gdsmenu.component.css']
})
export class GdsmenuComponent implements OnInit {

  constructor(private route: Router) {
  }

  ngOnInit() {
  }

  goto(dest: string): void {
    this.route.navigateByUrl(dest);
  }

  gotodpc(dest: string): void {
    this.route.navigateByUrl('dpc');
  }
}
