<!-- title -->
<div class="row" *ngIf="stage == 0">
  <div class="col-10 offset-1">
    <H1>SN Allocation Page</H1>
  </div>
</div>
<!-- title -->
<div>
  <form>
    <input type="hidden" name="selStart" id="selStart" maxlength="9"/>
    <input type="hidden" name="selEnd" id="selEnd" maxlength="9"/>
    <input type="hidden" name="selStatus" id="selStatus" maxlength="9"/>
    <input type="submit" id="testbtn" name="test" value="test" style="display: none;" (click)="goToDetails()">
  </form>
</div>
<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" *ngIf="stage == 0">

  <div class="row">
    <div *ngFor="let alert of alerts">
      <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg}}
      </alert>
    </div>
  </div>

  <!-- table -->

  <!-- Query form Start-->

  <!-- Automatic search -->
  <div class="row mt-1">
    <div class="col-12">
      <form>
        <div class="row form-group mb-2 table-bordered border-top-0 border-left-0 border-right-0">
          <div class="col-2">

            <label for="snaptable_fcNumber" class="mr-5">
              Formcode
              <font color="#ff0000" size="4">*</font>
            </label>

            <select [(ngModel)]="allocation.fromcode.id" name="doctype">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of formcodes" [value]='item.id'>{{item.fcNumber}} /
                {{item.documentType.dtName}}
              </option>
            </select>

          </div>

          <div class="col-2 ml-3">

            <label for="snaptable_documenttypes">
              Quantity
              <font color="#ff0000" size="4">*</font>
            </label>

            <input type="text" name="quantity"
                   [ngModel]="allocation.quantity"
                   (ngModelChange)="allocation.quantity=$event"
            />

          </div>

          <div class="col-2 ml-3">

            <label for="snaptable_documenttypes">
              BL percentage
            </label>

            <input type="text" name="percentage" [(ngModel)]="allocation.documentTypes.dtDefaultpercentage"
                   maxlength="9"/>

          </div>

          <div class="col-2 ml-3">

            <label for="snaptable_documenttypes">
              BL interruptions
            </label>

            <input type="text" name="interrptions" [(ngModel)]="allocation.documentTypes.dtDefaultinterruptions"
                   maxlength="9"/>

          </div>

          <div class="form-group col-2 ml-3 mt-4 pt-1">

            <input type="submit" name="btnAutomaticSearch" value="Automatic search"
                   (click)="autoSearch('auto')">

          </div>

        </div>

      </form>

      <!-- alert -->
      <div class="row pb-2 table-bordered border-top-0 border-left-0 border-right-0">
        <div class="ml-3">
          (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
        </div>
      </div>
      <!-- alert -->

    </div>
  </div>
  <!-- Automatic search -->

  <!-- Manual search -->
  <div class="row">
    <div class="col-12">
      <form class="" role="form">
        <div class="row table-bordered border-top-0 border-left-0 border-right-0">

          <div class="col-3 mt-3 mb-2">

            <label for="snaptable_documenttypes">
              Start number
              <font color="#ff0000" size="4">*</font>
            </label>
            &nbsp;&nbsp;&nbsp;
            <input type="text" name="startNumber" pattern="^[0-9]*$" #startNumber="ngModel"
                   [ngModel]="startNumberDispaly | snapnum"
                   (ngModelChange)="startNumberChange($event)"
                   maxlength="10" size="10"/>

          </div>
          <div class="md-errors-spacer" *ngIf="startNumber.invalid && (startNumber.dirty || startNumber.touched)">
            <div class="ui error message">
              Start Number must have 10 digits.
            </div>
          </div>

          <div class="col-2 ml-3 mt-3 mb-2">

            <input type="submit" name="btnManualSearch" value="Manual search"
                   (click)="autoSearch('manu')">

          </div>

          <div class="col-2 ml-3 mt-3 mb-2">

            <input type="submit" name="btnBackToRequests" value="Back to Requests Page"
                   (click)="backToList()">

          </div>

        </div>


      </form>
    </div>
  </div>
  <!-- Manual search -->

  <!-- Document type -->
  <div *ngIf="showChart" class="row table-bordered border-right-0 border-left-0 border-top-0 mt-1">
    <div class="col-12">

      <div class="row">

        <div class="col-3 mt-2">

          <label for="snaptable_documenttypes">
            Document Type: &nbsp; {{formcode.documentType.dtName}}
          </label>
          <!--Document Type: <input [ngModel]="formcode.documentType.dtName" readonly>-->
        </div>

      </div>

    </div>
  </div>
  <!-- Document type -->

  <!-- Blacklist -->
  <div *ngIf="showChart" class="row table-bordered border-right-0 border-left-0 border-top-0 mt-1">
    <div class="col-12">

      <div class="row">

        <div class="col-3 mt-2 mb-2">

          <input type="checkbox" name="IsBlacklistShown" value="on">IsBlacklistShown

        </div>

      </div>

    </div>
  </div>
  <!-- Blacklist -->

  <!-- Allocate -->
  <div *ngIf="showChart" class="row mt-1">
    <div class="col-12">
      <form>
        <div class="row form-group mb-2 table-bordered border-top-0 border-left-0 border-right-0">
          <div class="col-6">
            <div class="row">
              <div class="col-12">

                <label for="snaptable_fcNumber">
                  BSP
                  <font color="#ff0000" size="4">*</font>
                </label>

                <select [(ngModel)]="allocation.bsp.id" name="bsp" *ngIf="action === 'accept'" disabled>
                  <option value="0" selected="selected">-</option>
                  <option *ngFor="let item of bsps" [value]='item.id'>{{item.bspFullname}}({{item.bspCode}})</option>
                </select>

                <select [(ngModel)]="allocation.bsp.id" name="bsp" *ngIf="action === 'new'" (change)="showGds()">
                  <option value="0" selected="selected">-</option>
                  <option *ngFor="let item of bsps" [value]='item.id'>{{item.bspFullname}}({{item.bspCode}})</option>
                </select>

              </div>
            </div>
            <div class="row">
              <div class="col-12">

                <label for="snaptable_fcNumber">
                  GDS
                  <font color="#ff0000" size="4">*</font>
                </label>

                <select [(ngModel)]="allocation.gds.gdsCode" name="gds" *ngIf="action === 'accept'" disabled>

                  <option *ngFor="let item of gdss" [value]='item.gdsCode'>{{item.gdsUser}} / {{item.gdsEmail}}</option>
                </select>

                <select [(ngModel)]="allocation.gds.gdsCode" name="gds" *ngIf="action === 'new' && showGdslist === 1">
                  <!--<option value="" selected="selected">-</option>-->
                  <option *ngFor="let item of gdss" [value]='item.gdsCode'>{{item.gdsUser}} / {{item.gdsEmail}}</option>
                </select>

              </div>
            </div>
          </div>
          <div class="col-2">
            <div class="form-group col-2 ml-3 mt-4 pt-1">
              <input type="submit" name="btnAllocation" value="Allocate this range..."
                     (click)="allocateRange()">
            </div>
          </div>

        </div>

      </form>

    </div>
  </div>
  <!-- Allocate -->


  <!-- Query form End-->

  <!-- Show -->
  <div *ngIf="showChart" class="row mt-3 table-bordered">
    <div class="col-5 ml-0 pl-0">

      <div class="table-bordered mt-3 ml-3">
        <div class="row mt-2 ml-1">
          <div class="col-8" style="color: #1B548E;">
            <b>Start Number</b>
          </div>
          <div class="col-4">
            {{formcodeUsageDetail.startNumber | snformat}}
          </div>
        </div>

        <div class="row mt-2 ml-1">
          <div class="col-8" style="color: #1B548E;">
            <b>End Number</b>
          </div>
          <div class="col-4">
            {{formcodeUsageDetail.endNumber | snformat}}
          </div>
        </div>
      </div>

      <div class="table-bordered mt-3 ml-3 mb-3">
        <div class="row mt-2 ml-1">
          <div class="col-8" style="color: #1B548E;">
            <b>Number of Blacklisted Numbers</b>
          </div>
          <div class="col-4">
            {{formcodeUsageDetail.blacklistedNumber}} &nbsp;&nbsp; ( {{formcodeUsageDetail.blacklistedPercent}} )
          </div>
        </div>


        <div class="row mt-2 ml-1">
          <div class="col-8" style="color: #1B548E;">
            <b>Interruptions by Blacklisted Numbers</b>
          </div>
          <div class="col-4">
            {{formcodeUsageDetail.blacklistedInteruption}}
          </div>
        </div>
      </div>

    </div>

    <div class="col-7">

      <div class="row mt-3 ml-1 mr-1"><b>legend:</b></div>
      <div class="row pt-1 pb-1 ml-1 mr-1 table-bordered">{{formcodeUsageDetail.freeSpacePercent}}% free</div>
      <div class="row pt-1 pb-1 ml-1 mr-1 table-bordered" style="background-color: #00FF00">
        {{formcodeUsageDetail.retrieveAvailabePercent}}% retrieved/available
      </div>
      <div class="row pt-1 pb-1 ml-1 mr-1 table-bordered" style="background-color: #FF9900">
        {{formcodeUsageDetail.retrieveQuarantinedPercent}}% retrieved/quarantined
      </div>
      <div class="row pt-1 pb-1 ml-1 mr-1 table-bordered" style="background-color: #ccaaaa">
        {{formcodeUsageDetail.allocatedPercent}}% allocated/used
      </div>
      <div class="row pt-1 pb-1 ml-1 mr-1 table-bordered" style="background-color: black;color:white;">
        {{formcodeUsageDetail.blacklistedPercent}}% blacklisted
        ({{formcodeUsageDetail.blacklistedInteruption}}
        interruptions)
      </div>


    </div>
  </div>
  <!-- Show -->

  <!-- chart -->
  <div *ngIf="showChart" class="row mt-4 ml-1 mr-1 table-bordered ">
    <div id="content1" class="content" [innerHtml]="htmlstring | safeHtml">


    </div>


  </div>
  <!-- chart -->


</div>


<!-- title -->
<div class="row" *ngIf="stage == 1">
  <div class="col-10 offset-1">
    <H1>SN Range Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" *ngIf="stage == 1">

  <!-- table -->

  <!-- Query form Start-->

  <div class="row mt-3 ml-1" style="font-size: 15px"><b>Statistics for Selected Range</b></div>
  <div class="row">

    <div class="col-8 ml-0 pl-0">

      <div class="table-bordered mt-3 ml-3">
        <div class="row mt-2 ml-1">
          <div class="col-8" style="color: #1B548E;">
            <b>Start Number</b>
          </div>
          <div class="col-4" id="start">
            {{selStart | snformat}}
          </div>
        </div>

        <div class="row mt-2 ml-1">
          <div class="col-8" style="color: #1B548E;">
            <b>End Number</b>
          </div>
          <div class="col-4" id="end">
            {{selEnd | snformat}}
          </div>
        </div>
      </div>

      <div class="table-bordered mt-3 ml-3 mb-3">
        <div class="row mt-2 ml-1">
          <div class="col-8" style="color: #1B548E;">
            <b>Number of Blacklisted Numbers</b>
          </div>
          <div class="col-4">
            {{formcodeUsageDetail.blacklistedNumber}}
            &nbsp;&nbsp;(
            {{formcodeUsageDetail.blacklistedPercent}}%)
          </div>
        </div>


        <div class="row mt-2 ml-1">
          <div class="col-8" style="color: #1B548E;">
            <b>Interruptions by Blacklisted Numbers</b>
          </div>
          <div class="col-4">
            {{formcodeUsageDetail.blacklistedNumber}}
          </div>
        </div>
      </div>

    </div>

    <div class="col-4 ml-0 pl-0 mt-5">

      <input type="button" name="btnBack" (click)="backToStage0()" value="Back to Allocation page">

    </div>

  </div>


  <div class="row mt-2 ml-1" style="font-size: 15px"><b>Blacklisted Fragmentation</b></div>

  <div class="row mt-4 ml-1" style="font-size: 15px"><b>Legend</b></div>

  <!-- Show -->
  <div class="row">
    <div class="col-7 mt-3">
      <div class="row pt-1 pb-1 ml-1 mr-1 table-bordered">{{formcodeUsageRangeDetail.freeSpacePercent}}% free</div>
      <div class="row pt-1 pb-1 ml-1 mr-1 table-bordered" style="background-color: #00FF00">
        {{formcodeUsageRangeDetail.retrieveAvailabePercent}}% retrieved/available
      </div>
      <div class="row pt-1 pb-1 ml-1 mr-1 table-bordered" style="background-color: #FF9900">
        {{formcodeUsageRangeDetail.retrieveQuarantinedPercent}}% retrieved/quarantined
      </div>
      <div class="row pt-1 pb-1 ml-1 mr-1 table-bordered" style="background-color: #ccaaaa">
        {{formcodeUsageRangeDetail.allocatedPercent}}% allocated/used
      </div>
      <div class="row pt-1 pb-1 ml-1 mr-1 table-bordered" style="background-color: black;color:white;">
        {{formcodeUsageRangeDetail.blacklistedPercent}}% blacklisted
        ({{formcodeUsageRangeDetail.blacklistedNumber}}
        interruptions)
      </div>
    </div>

    <div class="row mt-4 ml-1 mr-1">

      <div id="content2" class="content" [innerHtml]="htmlstring2 | safeHtml"></div>

    </div>
  </div>
  <!-- body mt-3 -->
</div>
<div>
  <div *ngIf="stage == 3">
    <div *ngFor="let alert of alerts">
      <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
        }}
      </alert>
    </div>
  </div>
  <app-newmail (cancel)="cancelSending()" (sendMail)="sendOutMail()" [mailMessage]="mailMessage"
               *ngIf="stage == 2"></app-newmail>
</div>
