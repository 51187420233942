import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { DocumentTypeService } from '../../services/api/documentType.service';
import { IssuePartyService } from '../../services/api/issue-party.service';
import { RecycleService } from '../../services/api/recycle.service';
import { Document } from '../../vo/document';
import { Formcode } from '../../vo/formcode';
import { IssuParty } from '../../vo/issu-party';

@Component({
  selector: 'app-recycle',
  templateUrl: './recycle.component.html',
  styleUrls: ['./recycle.component.css']
})
export class RecycleComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
    private myservice: RecycleService,
    private docService: DocumentTypeService,
    private issuService: IssuePartyService) { }

  stage = 0;
  status = 'all';

  buttonStatus = '';
  qry: Formcode = new Formcode();
  doc: Document = new Document;
  issu: IssuParty = new IssuParty();
  recycles: Formcode[] = [];
  docs: Document[] = [];
  issus: IssuParty[] = [];

  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;

  public title = 'Please Confirm';
  public message = 'Are you sure you wish to recycle this incompleted formcode?';

  public nonBspMsg = 'Form codes with issuing party different to "BSP" are not allowed to be recycled.';

  public cancelClicked = false;

  public alerts: any = [];

  ngOnInit() {
    this.qry.documentType = this.doc;
    const me = this;
    this.route.queryParams.subscribe((p: Params) => {
      if (p.hasOwnProperty('id')) {
        this.qry.id = p['id'];
      }
    });
    me.getPageData(0, 10, this.status, this.qry.id);

    this.docService.getActiveDocs().subscribe((data) => {
      this.docs = data.items;
    });
    this.qry.issueParty = this.issu;
    this.issuService.getIssuingPartys().subscribe((data) => {
      this.issus = data.items;
    });
  }

  getPageData(page: number, size: number, status: string, id: number) {
    this.myservice.getPages(page, size, status, id).subscribe((data) => {
      this.recycles = data.items;
      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
    }
    );
  }

  public pageChanged(event: any): void {
    this.getPageData(event.page - 1, event.itemsPerPage, this.status, null);
  }

  gotoEdit(todo) {
    if (!todo.documentType) {
      todo.documentType = this.doc;
    }
    if (!todo.issueParty) {
      todo.issueParty = this.issu;
    }

    this.qry = todo;
    this.myservice.preCheck(todo.id).subscribe((data) => {
      if (data.operationStatus === 'ERROR') {
        this.addMessage(data.operationMessage, 'danger');
        return;
      }
      this.buttonStatus = data.recycleStatus;
      this.qry = data.item;
      this.stage = 1;
    });
  }

  gotoRecycle() {
    if (this.qry.issueParty.id !== 1) {
      this.addMessage(this.nonBspMsg, 'danger');
      return;
    } else {
      this.stage = 2;
    }
  }

  redefine() {
    this.myservice.recylce(this.qry).subscribe(
      (data) => {
        this.addMessage(data.operationMessage, 'success');
        this.qry = new Formcode();
        this.stage = 0;
      }
    );
  }

  overrideRecycle() {
    if (this.qry.issueParty.id !== 1) {
      this.addMessage(this.nonBspMsg, 'danger');
      return;
    } else {
      this.myservice.overrideRecycle(this.qry).subscribe(
        (data) => {
          this.addMessage(data.operationMessage, 'success');
          this.qry = new Formcode();
          this.stage = 0;
        }
      );
    }
  }

  autoRetrieve() {
    this.myservice.autoRetrieve(this.qry).subscribe(
      (data) => {
        this.addMessage(data.operationMessage, 'success');
        this.qry = new Formcode();
        this.stage = 0;
      }
    );
  }

  cancel() {
    this.qry = new Formcode();
    this.stage = 0;
  }

  filter() {
    this.getPageData(0, 10, this.status, null);
  }

  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: 10000
    });
  }
}
