export class ServerConfig{

  id: number;
  hostName: string;
  isMasterNode: string;
  updateTime: Date;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
