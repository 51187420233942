<!-- title -->
<div class="row" *ngIf="stage != 2">
  <div class="col-10 offset-1">
    <H1>Retrieve Page</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" *ngIf="stage == 0">
  <!-- Form -->
  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0  h-25">
    <div class="col-12 mt-3">
      <form role="form" #myForm="ngForm">
        <div class="row">
          <div class="form-group col-3">
            <label for="startNum">Start Number:</label>
            <font color="#ff0000" size="4">*</font>
            <input type="text" id="startNum" name="startNum" [(ngModel)]="qry.startNumber" minlength="10" maxlength="10" size="13" required pattern="^[0-9]*$" #startNum="ngModel">
          </div>
          <div class="md-errors-spacer" *ngIf="startNum.invalid && (startNum.dirty || startNum.touched)">
            <div class="ui error message">
              Start Number must have 10 digits.
            </div>
          </div>
          <div class="form-group col-3">
            <label for="endNum">End Number:</label>
            <font color="#ff0000" size="4">*</font>
            <input type="text" id="endNum" name="endNum" [(ngModel)]="qry.endNumber" minlength="10" maxlength="10" size="13" required pattern="^[0-9]*$" #endNum="ngModel">
          </div>
          <div class="md-errors-spacer" *ngIf="endNum.invalid && (endNum.dirty || endNum.touched)">
            <div class="ui error message">
              End Number must have 10 digits.
            </div>
          </div>
        </div>

      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->
  <div class="row form-group">
    <div class="ml-3 mt-3">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>
  </div>
  <!-- alert -->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}
    </alert>
  </div>
  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="offset-2">
      <input type="submit" name="submit" value="Search" (click)="preRetrieve()">
    </div>
  </div>
  <!-- button end -->

  <!-- Form -->

</div>
<!-- body mt-3 -->


<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" *ngIf="stage == 1">

  <!-- title2 -->

  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row  table-bordered mt-3 border-right-0 border-left-0  h-25">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <div class="row form-group table-bordered border-right-0 border-left-0 border-top-0">
          <div class="form-group col-3">
            <label>Start Number:</label>
            <font color="#ff0000" size="4">*</font>
            {{qry.startNumber | number}}
          </div>
          <div class="form-group col-3 ml-5 ">
            <label>End Number:</label>
            <font color="#ff0000" size="4">*</font>
            {{qry.endNumber | number}}
          </div>
        </div>
        <div class="row  table-bordered border-right-0 border-left-0 border-top-0">
          <div class="col-3" style="padding-bottom:12px">
            Quantity: {{qry.quantity | number}}
          </div>
        </div>
        <div class="row">
          <div class="col-3 mt-2">
            Bsp: {{qry.fromBspName}}
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->
  <div class="row  mt-2  table-bordered border-top-0 border-right-0 border-left-0">
      <div class="col-9">
            Specify the status that will be assigned to the retrieved range:
      </div>
      <div class="col-3">
        <select name="quarantined" id="quarantined" [(ngModel)]="qry.quarantined">
          <option value="false">Retrieved/Available</option>
          <option value="true">Retrieved/Quarantined</option>
        </select>
      </div>
  </div>


  <div class="row  mt-2  table-bordered border-top-0 border-right-0 border-left-0">
      <div class="col-9">
        Specify the reason for retrieval :
      </div>
      <div class="col-3">
        <select name="reason" id="reason" [(ngModel)]="qry.reasonCode">
          <option value="EA">Erroneous Allocation</option>
          <option value="BS">BSP Stopped using form code</option>
          <option value="PFR">Preparing for recycling of a formcode</option>
          <option value="US">To be specified by the user</option>
        </select>
      </div>
  </div>
  <!-- alert -->
  <!-- Form -->

  <!-- button start -->
  <div class="row form-group table-bordered border-top-0 border-left-0 border-right-0">
    <div class="offset-1">
      <input type="submit" name="retrieve" (click)="retrieve()" value="Confirm">
    </div>
    <div class="offset-3">
      <input type="submit" name="cancel" (click)="cancel()" value="Cancel">
    </div>
  </div>
  <!-- button end -->

</div>
<!-- body mt-3 -->


<div class="col-8 offset-1  mt-3" *ngIf="!isValideRangeForRetrieve">
  <div class="row ">
    <div class="col-12">
      <div class="row">
        <div class="col-10 offset-4" style="font-size:16px">
          <font color=red> Can not retrieve selected range.</font>
        </div>
        <div class="col-10 offset-4" style="font-size:16px">
          Current status for selected filter:
        </div>
      </div>

      <table class="table table-bordered table-hover">
        <thead>
        <tr style="background-color:#F2F5FC">
          <td>Start Number</td>
          <td>End number</td>
          <td>Status</td>
          <td>Bsp</td>
        </tr>
        </thead>
        <tbody *ngIf="stats.length > 0">
        <tr class="Waving1" *ngFor="let todo of stats">
          <td>{{todo.startNum | number}}</td>
          <td>{{todo.endNum | number}}</td>
          <td>{{todo.status}}</td>
          <td>{{todo.bsp}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="stats.length == 0">
        <tr class="Waving1">
          <td colspan="5"><font color="red">There are no allocations for this search parameters.</font></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="col-8 offset-1  mt-3" *ngIf="stage == 1">
  <div class="row ">
    <div class="col-12">
      <div class="row">
        <div class="col-10 offset-4" style="font-size:16px">
          Original Allocation
        </div>
      </div>

      <table class="table table-bordered table-hover">
        <thead>
        <tr style="background-color:#F2F5FC">
          <td>Start Number</td>
          <td>End number</td>
          <td>Quantity</td>
          <td>BSP</td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td>{{qry.originalStartNumber | number}}</td>
          <td>{{qry.originalEndNumber | number}}</td>
          <td>{{qry.originalQuantity | number}}</td>
          <td>{{qry.fromBspName}}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div>
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}
    </alert>
  </div>
  <app-newmail (cancel)="cancelSending()" (sendMail)="sendOutMail()" [mailMessage]="mailMessage" *ngIf="stage == 2"></app-newmail>
</div>
