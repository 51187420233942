<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>BSP Merge</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" >

  <!-- title2 -->

  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <form role="form" name="baseDynaForm" method="post" action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2FmergeBsp.do&_sorig=%2FmergeBsp.do" onsubmit="return confirmSubmit(this)">
    <div class="row form-group table-bordered" >
      <div class="col-sm-6  "><label for="code" >BSPs to be merged</label></div>
      <div class="col-sm-6 "><label for="code" >BSP to merge to</label></div>
    </div>

    <div class="row form-group table-bordered " >
      <div class="col-sm-6 ">
        <select name="bspInitial" multiple="true"  [(ngModel)]="vo1.id" class="form-control" style="font-size:12px">
          <option *ngFor="let item of bsps" [value]="item.id">{{item.bspFullname}}</option>
        </select>
      </div>

      <div class="col-sm-6 ">
        <select name="bspFinal" multiple="true" [(ngModel)]="vo2.id" class="form-control" style="font-size:12px">
          <option *ngFor="let item of bsps" [value]="item.id">{{item.bspFullname}}</option>
        </select>
      </div>
    </div>
    <div class="row form-group table-bordered " >
      <div class="col-sm-6 ">
        <input type="text" size="20" name="mergeDate" [value]='date' readonly="true">
        <!--<a href="javascript:show_calendar('forms[0].mergeDate',null, null, 'dd/MM/yy');">
                           <img src="../SNAPDocroot//images/show-calendar.gif" width=24 height=22 border=0 /> </a> -->
      </div>
    </div>
  </form>
  <!-- Query form End-->
  <!-- alert -->
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">

    <div class="offset-1">
      <input type="submit" name="btnMerge" value="Merge" (click)="merge()">
    </div>
  </div>
  <!-- button end -->

  <!-- Form -->
  <div  *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>
</div>
