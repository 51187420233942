import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Gds } from '../../vo/gds';
import { GdsService } from '../../services/api/gds.service';

@Component({
  selector: 'app-gds',
  templateUrl: './gds.component.html',
  styleUrls: ['./gds.component.css']
})
export class GdsComponent implements OnInit {
  qry: Gds = new Gds();
  gdss: Gds[] = [];
  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;

  // @ViewChild('orderStatusCellTpl') statusCellTpl: TemplateRef<any>;
  // @ViewChild('orderIdTpl') orderIdTpl: TemplateRef<any>;
  // columns:any[];
  // rows:any[];
  constructor(private router: Router, private gdsService: GdsService) { }

  gotoNew() {
    this.router.navigate(['/home/newgds']);
  }
  gotoEdit(todo) {
    this.router.navigate(['/home/editgds/'], { queryParams: { 'id': todo.id, 'action': 'U' } });
  }
  removeTodo(todo) {
    this.router.navigate(['/home/editgds/'], { queryParams: { 'id': todo.id, 'action': 'D' } });
  }

  ngOnInit() {
    const me = this;
    me.getPageData(0, 10, null);
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    const me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, this.qry);
  }

  getPageData(page: number, size: number, gds: Gds) {
    const me = this;
    this.gdsService.getItems(page, size, gds).subscribe((data) => {
      console.log(data);
      this.gdss = data.items;
      this.bigTotalItems = data.totalItems;
    }
    );
  }

  filter() {
    const me = this;
    me.getPageData(0, 10, this.qry);
  }

}
