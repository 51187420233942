import { Component, OnInit } from '@angular/core';
import { Bsp } from '../../vo/bsp';
import { Gds } from '../../vo/gds';
import { DocumentSubType } from '../../vo/documentSubType';
import { Document } from '../../vo/document';
import { Printer } from '../../vo/printer';
import { Snstatus } from '../../vo/snstatus';
import { Opstatus } from '../../vo/opstatus';
import { FormHistory } from '../../vo/formHistory';
import { MonitorUsage } from '../../vo/monitorUsage';
import { BspService } from '../../services/api/bsp.service';
import { GdsService } from '../../services/api/gds.service';
import { PrinterService } from '../../services/api/printer.service';
import { MonitorUsageService } from '../../services/api/monitorUsage.service';
import { DocumentTypeService } from '../../services/api/documentType.service';
import { DocumentSubTypeService } from '../../services/api/documentSubType.service';
import { FormHistoryService } from '../../services/api/formHistory.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { CurrentStatus } from '../../vo/currentStatus';
import { PrintCurrentStatusVo } from '../../vo/printCurrentStatusVo';
import { PrintActionHistoryVo } from '../../vo/printActionHistoryVo';
import { PrintFormHistoryVo } from '../../vo/printFormHistoryVo';
import { AngularCsv } from 'src/app/services/angular-csv';

@Component({
  selector: 'app-monitor-usage',
  templateUrl: './monitor-usage.component.html',
  styleUrls: ['./monitor-usage.component.css']
})
export class MonitorUsageComponent implements OnInit {
  get bsValue(): Date {
    return this._bsValue;
  }

  set bsValue(v: Date) {
    console.log(v);
    this._bsValue = v;
  }
  get bsRangeValue(): any {
    return this._bsRangeValue;
  }

  set bsRangeValue(v: any) {
    this._bsRangeValue = v;
  }

  constructor(private router: Router, private gdsService: GdsService,
    private bspService: BspService, private printerService: PrinterService,
    private docTypeService: DocumentTypeService, private docSubTypeService: DocumentSubTypeService,
    protected route: ActivatedRoute, private myService: MonitorUsageService,
    private formService: FormHistoryService) {
  }

  vo: MonitorUsage = new MonitorUsage();
  activeBsps: Bsp[] = [];
  inactiveBsps: Bsp[] = [];
  activeBsp: Bsp = new Bsp();
  inactiveBsp: Bsp = new Bsp();
  docTypes: Document[] = [];
  docType: Document = new Document();
  docSubTypes: DocumentSubType[] = [];
  docSubType: DocumentSubType = new DocumentSubType();
  printers: Printer[] = [];
  printer: Printer = new Printer();
  gdss: Gds[] = [];
  gds: Gds = new Gds();
  snstatuss: Snstatus[] = [];
  snstatus: Snstatus = new Snstatus();
  opstatuss: Opstatus[] = [];
  opstatus: Opstatus = new Opstatus();
  formHistories: FormHistory[] = [];
  formHistory: FormHistory = new FormHistory();

  stage = 0; // 0 New Search 1 Current status 2 action history status 3 fromcode history status

  voCurrentStatus: CurrentStatus = new CurrentStatus();
  curentStatuss: CurrentStatus[] = [];
  curentStatussCSV: CurrentStatus[] = [];
  printCurentStatuss: PrintCurrentStatusVo[] = [];

  currentStatuss: CurrentStatus[] = [];
  currentStatussCSV: CurrentStatus[] = [];

  printActionHistories: PrintActionHistoryVo[] = [];

  fm: FormHistory = new FormHistory();
  printFormHistories: PrintFormHistoryVo[] = [];

  public action: string;
  public inputHtml: boolean;

  public bigTotalItems: number;
  public maxSize = 5;
  public bigCurrentPage = 1;

  minDate = new Date(1990, 5, 10);
  maxDate = new Date(2018, 9, 15);
  _bsValue: Date;

  _bsRangeValue: any = [new Date(2017, 7, 4), new Date(2017, 7, 20)];

  public alerts: any = [];

  log(v: any) {
    console.log(v);
  }

  ngOnInit() {

    this.route.queryParams.subscribe((p: Params) => {
      // this.id = p['id'];
      this.action = p['action'];
      if (this.action == 'L') {
        // this.inputHtml = false;
        this.vo.formHistory = this.formHistory;
        this.formService.getOderByDate(this.vo.formcodeId).subscribe((data) => {
          console.log(data.items);
          // alert(data.items[0] );
          if (data.items[0] == undefined) {
            this.router.navigate(['/home/monitorusage']);
            this.addMessage('No data');
          } else {
            this.inputHtml = false;
          }
          this.formHistories = data.items;
        });
      } else {
        this.inputHtml = true;
      }
    });

    this.vo.gds = this.gds;
    this.gdsService.getItems(0, 100, null).subscribe((data) => {
      this.gdss = data.items;
      // console.log(this.gdss);
    }
    );

    this.vo.printer = this.printer;
    this.printerService.getAll().subscribe((data) => {
      this.printers = data.items;
      // console.log(data.items);
    });

    this.vo.docSubType = this.docSubType;
    this.docSubTypeService.getPages(0, 100, null).subscribe((data) => {
      this.docSubTypes = data.items;
      // console.log( data.items);
    }
    );

    this.vo.docType = this.docType;
    this.docTypeService.getDocs(0, 100, null).subscribe((data) => {
      this.docTypes = data.items;
      // console.log( data.items);
    }
    );

    this.vo.activeBsp = this.activeBsp;
    this.vo.inactiveBsp = this.inactiveBsp;
    this.bspService.getAllBsps().subscribe((data) => {
      // console.log( data.items);
      for (const i of data.items) {
        if (i.bspActive == '1') {
          this.activeBsps.push(i);
        }
        if (i.bspActive == '0') {
          this.inactiveBsps.push(i);
        }

      }

      this.vo.snstatus = this.snstatus;
      this.myService.getAllSnstatus().subscribe((data) => {
        // console.log(data.items);
        this.snstatuss = data.items;
      });

      this.vo.opstatus = this.opstatus;
      this.myService.getAllOpstatus().subscribe((data) => {
        // console.log(data.items);
        this.opstatuss = data.items;
      });

    });

  }

  public lifeCycles() {
    this.router.navigate
      (['/home/monitorusage'], { queryParams: { 'action': 'L' } });
  }

  clear() {
    // this.vo = new MonitorUsage();
  }

  ckFormcode() {
    if (this.vo.formcodeId == null || this.vo.formcodeId.length === 0) {
      return false;
    }
    return true;
  }

  ckSerialNumber() {
    if (this.vo.serialNumber == null || this.vo.serialNumber.length === 0) {
      return false;
    }
    return true;
  }

  ckBsp() {
    if (this.vo.activeBsp.id == null || this.vo.activeBsp.id < 1) {
      return false;
    }
    return true;
  }

  ckInactiveBsp() {
    if (this.vo.inactiveBsp.id == null || this.vo.inactiveBsp.id < 1) {
      return false;
    }
    return true;
  }

  ckDocType() {
    if (this.vo.docType.id == null || this.vo.docType.id < 1) {
      return false;
    }
    return true;
  }

  ckDocSubType() {
    if (this.vo.docSubType.id == null || this.vo.docSubType.id < 1) {
      return false;
    }
    return true;
  }

  ckGds() {
    if (this.vo.gds.id == null || this.vo.gds.id < 1) {
      return false;
    }
    return true;
  }

  ckPrinter() {
    if (this.vo.printer.id == null || this.vo.printer.id === 0) {
      return false;
    }
    return true;
  }

  ckValidityDate() {
    if (this.vo.validityDate == null) {
      return false;
    }
    return true;
  }

  ckPurchaseOrderNumber() {
    if (this.vo.purchaseOrderNumber == null) {
      return false;
    }
    return true;
  }

  ckAllocationDate() {
    if (this.vo.allocationDate == null) {
      return false;
    }
    return true;
  }

  ckSnCode() {
    if (this.vo.snCode == null || this.vo.snCode.length === 0) {
      return false;
    }
    return true;
  }

  ckstatusChangeDate() {
    if (this.vo.statusChangeDate == null) {
      return false;
    }
    return true;
  }

  ckOpCode() {
    if (this.vo.opCode == null || this.vo.opCode.length === 0) {
      return false;
    }
    return true;
  }

  ckFormcodeFrozen() {
    if (this.vo.formcodeFrozen == null) {
      return false;
    }
    return true;
  }

  newSearch() {
    this.stage = 0;
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    // this.getPageDate(event.page - 1 , event.itemsPerPage, this.vo);
    if (this.stage === 1) {

      this.myService.getCurrentstatusPages(event.page - 1, event.itemsPerPage, this.vo).subscribe((data) => {
        this.curentStatuss = data.items;
        // alert(data.items);
        console.log(data.items);
        this.bigTotalItems = data.totalItems;
        this.bigCurrentPage = data.currentPageNumber + 1;
      });
    } else if (this.stage === 2) {

      this.myService.getActionHistoryPages(event.page - 1, event.itemsPerPage, this.vo).subscribe((data) => {
        console.log(data.items);
        this.currentStatuss = data.items;
        this.bigTotalItems = data.totalItems;
        this.bigCurrentPage = data.currentPageNumber + 1;
      });
    } else if (this.stage === 3) {

      this.formService.getFormHistory(event.page - 1, event.itemsPerPage, this.vo.formcodeId).subscribe((data) => {
        console.log(data.items);
        this.formHistories = data.items;
        this.bigTotalItems = data.totalItems;
        this.bigCurrentPage = data.currentPageNumber + 1;

      });
    }
  }

  currentStatus() {
    this.stage = 1;
    if (!(this.ckFormcode() || this.ckAllocationDate() || this.ckBsp() || this.ckDocSubType() || this.ckDocType() || this.ckFormcodeFrozen() || this.ckGds()
      || this.ckstatusChangeDate() || this.ckInactiveBsp() || this.ckOpCode() || this.ckPrinter() || this.ckPurchaseOrderNumber() || this.ckSerialNumber()
      || this.ckSnCode() || this.ckValidityDate())) {
      alert('Please specify any of the search criteria or select the status code.');
      return;
    }

    this.bigCurrentPage = 1;
    this.myService.getCurrentstatusPages(0, 10, this.vo).subscribe((data) => {
      this.curentStatuss = data.items;
      // alert(data.items);
      console.log(data.items);
      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
    });

    this.vo.snstatus = this.snstatus;
    this.myService.getAllSnstatus().subscribe((data) => {
      // console.log(data.items);
      this.snstatuss = data.items;
    });

  }

  formcodeHistory() {
    if (!this.ckFormcode()) {
      alert('Please input a formcode.');
      this.formHistories = [];
      this.stage = 0;
    } else {
      this.stage = 3;
      if (!(this.ckFormcode() || this.ckAllocationDate() || this.ckBsp() || this.ckDocSubType() || this.ckDocType() || this.ckFormcodeFrozen() || this.ckGds()
        || this.ckstatusChangeDate() || this.ckInactiveBsp() || this.ckOpCode() || this.ckPrinter() || this.ckPurchaseOrderNumber() || this.ckSerialNumber()
        || this.ckSnCode() || this.ckValidityDate())) {
        alert('Please specify any of the search criteria or select the status code.');
        return;
      }
      // alert(this.vo.formcodeId);

      this.bigCurrentPage = 1;
      this.formService.getFormHistory(0, 10, this.vo.formcodeId).subscribe((data) => {
        console.log(data.items);

        this.formHistories = data.items;
        this.bigTotalItems = data.totalItems;
        this.bigCurrentPage = data.currentPageNumber + 1;
      });

    }

  }

  // actionHistory
  actionHistory() {
    this.stage = 2;
    if (!(this.ckFormcode() || this.ckAllocationDate() || this.ckBsp() || this.ckDocSubType() || this.ckDocType() || this.ckFormcodeFrozen() || this.ckGds()
      || this.ckstatusChangeDate() || this.ckInactiveBsp() || this.ckOpCode() || this.ckPrinter() || this.ckPurchaseOrderNumber() || this.ckSerialNumber()
      || this.ckSnCode() || this.ckValidityDate())) {
      alert('Please specify any of the search criteria or select the status code.');
      return;
    }

    this.bigCurrentPage = 1;
    this.myService.getActionHistoryPages(0, 10, this.vo).subscribe((data) => {
      console.log(data.items);
      this.currentStatuss = data.items;
      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
    });
  }

  exportCurrentCsv() {
    this.myService.getCurrentstatusPages(0, 100000, this.vo).subscribe((data) => {
      this.curentStatussCSV = data.items;

      for (const item of this.curentStatussCSV) {
        const printCurentStatusVo: PrintCurrentStatusVo = new PrintCurrentStatusVo();
        printCurentStatusVo.formcode = item.formcode;
        printCurentStatusVo.doc = item.doc;
        printCurentStatusVo.subDoc = item.subDoc;
        printCurentStatusVo.StartEndNumber = item.rangeStart.concat('-').concat(item.rangeEnd);
        printCurentStatusVo.quantity = item.quantity;
        printCurentStatusVo.statusCode = item.statusCode;
        if (item.bsp != null) {
          printCurentStatusVo.bspCode = item.bsp.concat('(').concat(item.bspCode).concat(')');
        } else {
          printCurentStatusVo.bspCode = null;
        }
        printCurentStatusVo.gdsCode = item.gdsCode;
        printCurentStatusVo.print = item.printer;
        printCurentStatusVo.validate = item.validityDate;
        printCurentStatusVo.purOderNum = item.purchaseOrderNumber;
        printCurentStatusVo.schDate = item.schDate;
        printCurentStatusVo.snapNo = item.snapNo;

        this.printCurentStatuss.push(printCurentStatusVo);

      }

      const options = {
        headers: ['Formcode', 'Doc.type', 'Doc.subtype', 'StartEndnumber',
          'Quantity', 'Statuscode', 'BSP', 'GDS', 'Printer', 'Validity date', 'PurchaseOrderNo',
          'Statusdate', 'SNAPNo.']
      };
      new AngularCsv(this.printCurentStatuss, 'CurrentStatus Report', options);

    });
  }

  exportActionCsv() {

    this.myService.getActionHistoryPages(0, 100000, this.vo).subscribe((data) => {
      this.currentStatussCSV = data.items;

      for (const item of this.currentStatussCSV) {
        const printVo: PrintActionHistoryVo = new PrintActionHistoryVo();
        printVo.formcode = item.formcode;
        printVo.doc = item.doc;
        printVo.subDoc = item.subDoc;
        printVo.StartEndNumber = item.rangeStart.concat(' ').concat(item.rangeEnd);
        printVo.quantity = item.quantity;
        printVo.opCode = item.opCode;
        printVo.snCode = item.statusCode;
        if (item.bspCode != null) {
          printVo.bspCode = item.bsp.concat('(').concat(item.bspCode).concat(')');
        } else {
          printVo.bspCode = null;
        }
        printVo.gdsCode = item.gdsCode;
        printVo.print = item.print;
        printVo.validate = item.valDate;
        printVo.purOderNum = item.purchaseOrderNumber;
        printVo.statusDate = item.statusDate;
        printVo.snapNo = item.snapNo;

        this.printActionHistories.push(printVo);
      }
      console.log(this.printActionHistories);
      const options = {
        headers: ['Form code', 'Doc. type', 'Doc. subtype', 'Start/End number', 'Quantity',
          'Opn code', 'Status code', 'BSP (from/to)', 'GDS', 'Printer', 'Validity date', 'Purchase Order No.', 'Status date', 'SNAP No.']
      };
      new AngularCsv(this.printActionHistories, 'ActionHistory Report', options);
    });

  }

  exportFormCsv() {

    this.formService.getFormHistory(0, 100000, this.vo.formcodeId).subscribe((data) => {
      this.formHistories = data.items;

      for (const item of this.formHistories) {
        const printFormVo: PrintFormHistoryVo = new PrintFormHistoryVo();
        printFormVo.dtName = item.documentType.dtName;
        printFormVo.fcReso = item.fcReso;
        printFormVo.fcComment = item.fcComment;
        printFormVo.fcRcyReady = item.fcRcyReady;
        printFormVo.fcFrozenFor = item.fcFrozenFor;
        printFormVo.fcFrozen = item.fcFrozen;
        printFormVo.isOverridden = item.isOverridden;

        this.printFormHistories.push(printFormVo);
      }
      const options = {
        headers: ['Document type', 'Resolution', 'Comment', 'Frozen for recycling date', 'Frozen for (years)',
          'Start date of the life cycle', 'IS_OVERRIDEN']
      };
      new AngularCsv(this.printFormHistories, 'FormHistory Report', options);
    });

  }

  gotoEdit(todo) {
    console.log(todo);
    this.voCurrentStatus = todo;
    this.stage = 4;
  }

  backToListPage() {
    this.stage = 1;
  }

  saveStatus() {
    console.log(this.voCurrentStatus);
    this.myService.updateItem(this.voCurrentStatus).subscribe((response) => {
      console.log(response);
      this.addMessage(response.operationMessage);
    }
    );
  }

  format(date) {
    let v = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    if (date.getDate() < 10) {
      v = '0' + date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
    }
    if ((date.getMonth() + 1) < 10) {
      v = '0' + date.getDate() + '/0' + (date.getMonth() + 1) + '/' + date.getFullYear();
    }

    return v;
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'danger',
      msg: message,
      timeout: 1000
    });
  }

}
