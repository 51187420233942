import { Component, OnInit } from '@angular/core';
import {MailVo} from '../../../vo/mailVo';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SendMailService} from '../../../services/api/send-mail.service';
import {MailResendService} from '../../../services/api/mail-resend.service';

@Component({
  selector: 'app-mail-resend-detail',
  templateUrl: './mail-resend-detail.component.html',
  styleUrls: ['./mail-resend-detail.component.css']
})
export class MailResendDetailComponent implements OnInit {

  id: number;
  stage: number;
  mailMessage: MailVo = new MailVo();
  public alerts: any = [];

  constructor( protected route: ActivatedRoute,  private router: Router,  private mailService: SendMailService , private myService: MailResendService ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.stage = p['stage'];
      const me = this;
      me.getMessage(this.id);
    });
  }

  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: 30000
    });
  }

  public getMessage(id: number) {
    this.myService.getDetail(id).subscribe((data) => {
      // console.log(data.items);
      this.mailMessage = data.items[0];
      // console.log(this.mailMessage);
    }
  ); }

  cancelSending() {
    this.addMessage('Email sending is canceled', 'success');
    this.stage = 3;
    this.router.navigate
    (['/home/mailResend'], { queryParams: {'msg': 'Email sending is canceled', 'type': 'success'}});
  }

  sendOutMail() {
    this.myService.sendOutMail(this.id).subscribe(
      (response) => {
        // console.log(response);
        this.addMessage(response.operationMessage, 'success');
        this.stage = 3;
        this.router.navigate(['/home/mailResend'], { queryParams: {'msg': response.operationMessage, 'type': 'success'}});
      }
    );
  }
}
