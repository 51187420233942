import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Formcode } from '../../vo/formcode';
import { FormcodeService } from '../../services/api/formcode.service';
import { RecycleService } from '../../services/api/recycle.service';
import { PrintRecyclingVo } from '../../vo/printRecyclingVo';
import { DatePipe } from '@angular/common';
import { Document } from '../../vo/document';
import { AngularCsv } from 'src/app/services/angular-csv';

@Component({
  selector: 'app-dash-recycle',
  templateUrl: './dash-recycle.component.html',
  styleUrls: ['./dash-recycle.component.css']
})
export class DashRecycleComponent implements OnInit {
  fozenFormcode: Formcode = new Formcode();
  frozenFormcodes: Formcode[] = [];
  doc: Document = new Document();
  public maxSize: number = 5;
  public bigTotalItems: number = 0;
  public bigCurrentPage: number = 1;
  public numPages: number = 0;
  public alerts: any = [];
  qry: Formcode = new Formcode();
  pcsv: PrintRecyclingVo[] = [];
  formcodesCSV: Formcode[] = [];

  constructor(private router: Router, private formcodeService: FormcodeService, private recycleService: RecycleService, private datePipe: DatePipe) {
  }

  ngOnInit() {
    const me = this;
    this.qry.documentType = this.doc;
    me.getPageData(null, 10, null, null);
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.getPageData(event.page - 1, event.itemsPerPage, null, null);
  }

  getPageData(page: number, size: number, frozen: Formcode, num: string) {
    this.formcodeService.getFrozenFormcode(page, size, frozen, num).subscribe((data) => {
      this.frozenFormcodes = data.items;
      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
    }
    );
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

  recycle(id: number) {
    // var me = this;
    this.router.navigate(['/home/recycle'], { queryParams: { 'id': id.toString() } });
    // this.recycleService.preCheck(id);

  }

  filter() {
    this.getPageData(null, 10, this.qry, null);
  }

  exportRecyclingCSV() {
    var me = this;
    me.pcsv.splice(0, me.pcsv.length);
    console.log(this.qry);
    me.formcodeService.getFrozenFormcode(0, 2000, this.qry, null).subscribe((data) => {
      this.formcodesCSV = data.items;
      for (const item of this.formcodesCSV) {
        const printRecyclingVo: PrintRecyclingVo = new PrintRecyclingVo();
        printRecyclingVo.formcode = item.fcNumber;
        printRecyclingVo.dtName = item.documentType.dtName;
        // printRecyclingVo.resolution = item.fcReso;
        if (item.fcFrozen == null) {
          printRecyclingVo.frozenDate = null;
        } else {
          printRecyclingVo.frozenDate = this.datePipe.transform(item.fcFrozen, 'yyyy-MM-dd');
        }

        if (item.fcFrozenEnd == null) {
          printRecyclingVo.recyclingDate = null;
        } else {
          printRecyclingVo.recyclingDate = this.datePipe.transform(item.fcFrozenEnd, 'yyyy-MM-dd');
        }

        if (item.proceeding !== 'Active') {
          printRecyclingVo.status = item.proceeding;
        } else {
          printRecyclingVo.status = 'Ready';
        }

        this.pcsv.push(printRecyclingVo);
        console.log(printRecyclingVo);
      }
      const options = {
        headers: ['Form Code', 'Document Type', 'Frozen Data', 'Recycling Date', 'Proceed With Recycling']
      };
      new AngularCsv(this.pcsv, 'Formcodes', options);
    });
  }
}
