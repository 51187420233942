<!-- continer -->
<div class="container-fluid">

  <!-- logo -->
  <div class="row">
    <div id="header">
      <div class="logo"><a href="http://www.iata.org/">
        <img src="assets/SNAPDocroot//portal/images/logo_app.gif" border="0"
             title="International Air Transport Association"></a>
      </div>
      <div class="logoprint"><img src="assets/SNAPDocroot/portal/images/logo_print.gif"></div>
    </div>
  </div>
  <!-- logo -->

  <!-- middle -->
  <div class="row" style="height: 42rem">
    <!-- left menu -->
    <nav class="col-md-2 rounded navbar-toggleable-sm">
      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#menu">
        <span class="navbar-toggler-icon">Col</span>
      </button>

      <h1 class="navbar-brand">SNAP</h1>

      <div class="collapse navbar-collapse" id="menu">
        <ul class="navbar-nav flex-column">
          <li class="nav-item">
            <a class="nav-link" style="text-decoration: none" href=""><b>Login</b></a>
          </li>
        </ul>
      </div>
    </nav>
    <!-- left menu -->

    <!-- main -->
    <main class="col-md-10 mt-3">
      <div class="row mt-3">
        <div class="col-md-6 offset-md-3">
          <div class="table-responsive">
            <table class="table">
              <thead>
              <tr>
                <th valign="middle" align="center"><h1>Login Page-login</h1></th>
              </tr>
              </thead>
              <tbody style="border: none">
              <tr style="border: none">
                <td style="border: none"><h1>Welcome to S.N.A.P application. Please login:</h1></td>
              </tr>
              <tr class="pb-0 mb-0" style="border-bottom: 0;border-top:0" *ngIf="msg === 'failed'">
                <td>
                  <span style="color: red; font-size: 13px">Invalid username or password. Please contact SNAP admin at snap&#64;iata.org for access to the SNAP portal.</span>
                </td>
              </tr>
              <tr>
                <td style="border: none">
                  <!-- table body -->
                  <form #f="ngForm" (ngSubmit)="login()">
                    <table>
                      <tr>
                        <td colspan="2">Username: &nbsp;&nbsp;&nbsp;
                          <input type="text" name="username" size="20" placeholder="Username"
                                 [(ngModel)]="model.username" #username="ngModel" required/>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2">Password: &nbsp;&nbsp;&nbsp;&nbsp;
                          <input type="password" name="password" size="20" placeholder="Password"
                                 [(ngModel)]="model.password" #password="ngModel" required/>
                        </td>
                      </tr>
                      <tr>
                        <td align="left">
                          <input type="image" (click)="login()" name="loginAction"
                                 src="assets/SNAPDocroot/images/login.gif">
                        </td>
                        <td align="right">
                          <img src="assets/SNAPDocroot/portal/images-css/ul_bg.gif" class="pr-2">
                          <a (click)='forgotPassword()'>Forgot your password</a>
                        </td>
                      </tr>
                    </table>
                  </form>

                </td>
              </tr>
              <tr>
                <td>
                  <div *ngFor="let alert of alerts">
                    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg}}</alert>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>

      </div>
    </main>
    <!-- main -->

  </div>
  <!-- middle -->

  <!-- foot -->
  <div class="row">
    <div id="footer">
      <h6>Bin v5.2 of 04.01.2007 &copy;2005 International Air Transport Association. All rights reserved.</h6>
    </div>
  </div>
  <!-- foot -->

</div>
<!-- continer -->
