<!-- Collapse -->
<div class="" id="menu">
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('login')" href="javascript:void(0);"><b>Logout</b></a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/gdshome/dashboard')" href="javascript:void(0);">Home</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/gdshome/changepwd')" href="javascript:void(0);">Change Password</a>
    </li>

    <H1 class="mt-2 mb-1">Serial Numbers</H1>

    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/gdshome/allocationreq')" href="javascript:void(0);">AllocationRequest</a>
    </li>

    <h1 class="mt-2 mb-1">Table Maintenance</h1>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/gdshome/formcode-external')" href="javascript:void(0);">Form Code</a>
    </li>

    <H1 class="mt-2 mb-1">Traceability</H1>

    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/gdshome/monitorusageexternal')" href="javascript:void(0);">Monitor Usage</a>
    </li>
  </ul>
</div>
<!-- Collapse -->
