import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Bsp } from '../../vo/bsp';
import { BspService } from '../../services/api/bsp.service';

@Component({
  selector: 'app-bsp',
  templateUrl: './bsp.component.html',
  styleUrls: ['./bsp.component.css']
})
export class BspComponent implements OnInit {

  qry: Bsp = new Bsp();
  bsps: Bsp[] = [];

  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;

  constructor(private router: Router, private myservice: BspService) { }

  ngOnInit() {
    const me = this;
    me.getPageData(0, 10, null);
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    const me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, this.qry);
  }

  getPageData(page: number, size: number, vo: Bsp) {
    const me = this;
    this.myservice.getPages(page, size, vo).subscribe((data) => {
      console.log(data);
      this.bsps = data.items;
      if (isNaN(data.totalItems)) {
        this.bigTotalItems = 1000;
      } else {
        this.bigTotalItems = data.totalItems;
      }
    }
    );
  }

  gotoNew() {
    // alert('1');
    this.router.navigate(['/home/newbsp']);
  }

  gotoEdit(todo) {
    this.router.navigate
      (['/home/editbsp'], { queryParams: { 'id': todo.id, 'action': 'U' } });
  }

  //
  gotoDelete(todo) {
    this.router.navigate
      (['/home/editbsp'], { queryParams: { 'id': todo.id, 'action': 'D' } });
  }
  filter() {
    const me = this;
    console.log(this.qry);
    me.getPageData(0, 10, this.qry);
  }

}
