import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentSubType } from '../../vo/documentSubType';
import { HttpParams } from '@angular/common/http';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class DocumentSubTypeService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  getPages(page?: number, size?: number, vo?: DocumentSubType): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (vo && vo.dstName) {
      params = params.append('dstName', vo.dstName);
    }

    // if(dpc && dpc.dpcCode){
    //   params = params.append('dpcCode', dpc.dpcCode);
    // }
    // if(dpc && dpc.email){
    //   params = params.append('email', dpc.email);
    // }
    // if(dpc && dpc.iinetaccount){
    //   params = params.append('iinetaccount', dpc.iinetaccount);
    // }

    console.log(params);
    return this.apiRequest.get('api/DocumentSubTypes', params);

  }

  getItemByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/DocumentSubType/' + id);
  }

  addItem(vo: DocumentSubType): Observable<any> {
    return this.apiRequest.post('api/DocumentSubType', vo);
  }

  updateItem(vo: DocumentSubType): Observable<any> {
    return this.apiRequest.put('api/DocumentSubType', vo);
  }

  deleteItem(vo: DocumentSubType): Observable<any> {
    return this.apiRequest.delete('api/DocumentSubType/' + vo.id);
  }
}
