import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cgn'
})
export class CgnPipe implements PipeTransform {

  transform(value: number): String {
    if (value === 1) {
      return 'CGN';
    } else {
      return 'Paper';
    }
  }

}
