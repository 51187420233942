import {Document} from './document';

export class PrintFormcodeVo {

  formcode: string;
  dtName: string;
  resolution: string;
  frozenDate: string;
  status: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
