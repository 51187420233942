import { Component, OnInit } from '@angular/core';
import { AllocationRequest } from '../../../vo/allocationRequest';
import { Document } from '../../../vo/document';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AllocationService } from '../../../services/api/allocation.service';
import { Bsp } from '../../../vo/bsp';
import { MailVo } from '../../../vo/mailVo';
import { SendMailService } from '../../../services/api/send-mail.service';

@Component({
  selector: 'app-request-reject',
  templateUrl: './request-reject.component.html',
  styleUrls: ['./request-reject.component.css']
})
export class RequestRejectComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
    protected router: Router,
    protected allocationService: AllocationService,
    private mailService: SendMailService
  ) { }

  public id: number;
  public action: String;
  public rejectStatus: number;

  stage = 1;
  mailMessage: MailVo = new MailVo();
  vo: AllocationRequest = new AllocationRequest();
  docType: Document = new Document();
  bsp: Bsp = new Bsp();
  public alerts: any = [];
  ngOnInit() {
    this.rejectStatus = 0;
    this.vo.bsp = this.bsp;
    this.vo.doctype = this.docType;
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.vo.id = this.id;
      this.getPageData(this.id);
    });
  }

  reject() {
    this.rejectStatus = 1;
    this.vo.status = 'REJECTED';
    this.allocationService.reject(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage, 'success');
          this.mailMessage = response.mailMessage;
        }
      );
    this.stage = 2;
  }

  backToList() {
    this.router.navigate(['/home/allocation']);
  }

  getPageData(id: number) {
    this.allocationService.getItemByID(id).subscribe((data) => {
      console.log(data);
      this.vo = data.item;
    }
    );
  }
  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

  cancelSending() {
    this.addMessage('Email sending is canceled', 'success');
    this.stage = 3;
  }

  sendOutMail() {
    this.mailService.sendEmail(this.mailMessage).subscribe(
      (response) => {
        console.log(response);
        // alert(response.operationMessage);
        this.addMessage(response.operationMessage, 'success');
        this.stage = 3;
      }
    );
  }

}
