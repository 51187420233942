import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Changpwd } from '../../vo/changepwd';
import { ChangepwdService } from '../../services/api/changepwd.service';
import { UserInfoService } from '../../services/user-info.service';

@Component({
  selector: 'app-changepwd',
  templateUrl: './changepwd.component.html',
  styleUrls: ['./changepwd.component.css']
})
export class ChangepwdComponent implements OnInit {

  constructor(private router: Router, private myservice: ChangepwdService, private userInfoService: UserInfoService) { }

  vo: Changpwd = new Changpwd();

  public alerts: any = [];

  ngOnInit() {
  }

  changePwd() {
    if (this.vo.oldPwd == null || this.vo.oldPwd == '') {
      this.addMessage('Please input the old password', 'danger');
      return;
    }

    if (this.vo.newPwd == null || this.vo.newPwd == '') {
      this.addMessage('Please input the new password', 'danger');
      return;
    }

    if (this.vo.comfirmPwd == null || this.vo.comfirmPwd == '') {
      this.addMessage('Please input the confirm password', 'danger');
      return;
    }

    if (this.vo.newPwd != this.vo.comfirmPwd) {
      this.addMessage('The confirm password does not match the new password', 'danger');
      return;
    }

    if (this.vo.newPwd == this.vo.oldPwd) {
      this.addMessage('The new password should not be the same with old password', 'danger');
      return;
    }
    // this.vo.userId = this.userInfoService.getGdsCode();
    this.myservice.updatePwd(this.vo).subscribe((response) => {
      console.log(response);
      if (response.operationStatus == 'SUCCESS') {
        this.addMessage(response.operationMessage, 'success');
      } else {
        this.addMessage(response.operationMessage, 'danger');
      }
    }
    );
  }
  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: 10000
    });
  }

}
