

export class PrintActionHistoryVo {

  formcode: string;
  doc: string;
  subDoc: string;
  StartEndNumber: string;
  quantity: string;
  statusCode: string;
  bspCode: string;
  gdsCode: string;
  print: string;
  validate: string;
  purOderNum: string;
  statusDate: string;
  snapNo: string;
  opCode: string;
  snCode: string;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
