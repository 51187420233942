import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PredefinedRule } from '../../vo/predefinedRule';
import { PredefinedRuleService } from '../../services/api/predefinedRule.service';


@Component({
  selector: 'app-predefined-rule',
  templateUrl: './predefined-rule.component.html',
  styleUrls: ['./predefined-rule.component.css']
})
export class PredefinedRuleComponent implements OnInit {

  qry: PredefinedRule = new PredefinedRule();
  predefinedRules: PredefinedRule[] = [];
  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;
  constructor(private router: Router, private myservice: PredefinedRuleService) { }

  ngOnInit() {
    const me = this;
    me.getPageData(0, 10, null);
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    const me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, this.qry);
  }

  getPageData(page: number, size: number, vo: PredefinedRule) {
    const me = this;
    this.myservice.getPages(page, size, vo).subscribe((data) => {
      console.log(data);
      this.predefinedRules = data.items;
      this.bigTotalItems = data.totalItems;

    }
    );
  }

  gotoNew() {
    this.router.navigate(['/home/newPredefinedRule']);
  }

  gotoEdit(todo) {
    this.router.navigate
      (['/home/editPredefinedRule'], { queryParams: { 'id': todo.id, 'action': 'U' } });
  }

  gotoDelete(todo) {
    this.router.navigate
      (['/home/editPredefinedRule'], { queryParams: { 'id': todo.id, 'action': 'D' } });
  }
  filter() {
    const me = this;
    console.log(this.qry);
    me.getPageData(0, 10, this.qry);
  }

}
