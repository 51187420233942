import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BspService } from '../../../services/api/bsp.service';
import { Calendar } from '../../../vo/calendar';
import { Bsp } from '../../../vo/bsp';
import { CalendarService } from '../../../services/api/calendar.service';

@Component({
  selector: 'app-bsp-cal-batch-edit',
  templateUrl: './bsp-cal-batch-edit.component.html',
  styleUrls: ['./bsp-cal-batch-edit.component.css']
})
export class BspCalBatchEditComponent implements OnInit {

  checkedBsps: Bsp[] = [];
  bspCal: Calendar = new Calendar();
  cals: Calendar[] = [];

  constructor(protected route: ActivatedRoute,
    protected router: Router,
    protected bspService: BspService,
    private calService: CalendarService) {
  }

  ngOnInit() {
    this.bspCal.id = 0;
    this.route.queryParams.subscribe((p: Params) => {
      p['id'].forEach((id: number) => this.getItem(id));
    });
    this.calService.getAll().subscribe((data) => {
      this.cals = data.items;
    });
  }

  getItem(id: number) {
    this.bspService.getItemByID(id).subscribe((data) => {
      this.checkedBsps.push(data.item);
    });
  }

  update() {
    if (!this.bspCal || this.bspCal.id == 0) {
      this.addMessage('Timezone is required.', 'danger');
      return;
    }
    this.checkedBsps.forEach((item: Bsp) => {
      if (item.bspCal.id != this.bspCal.id) {
        item.bspCal.id = this.bspCal.id;
        this.bspService.updateItem(item).subscribe((response) => {
          this.addMessage(response.operationMessage + ': ' + item.bspCode, 'success');
        });
      }
    });
  }

  backToListPage() {
    this.router.navigate(['/home/bspWithCal']);
  }

  public alerts: any = [];

  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: 3000
    });
  }
}
