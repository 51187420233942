import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Allocation } from '../../vo/allocation';
import { AllocationService } from '../../services/api/allocation.service';
import { UserInfoService } from '../../services/user-info.service';

@Component({
  selector: 'app-dashindex',
  templateUrl: './dashindex.component.html',
  styleUrls: ['./dashindex.component.css']
})
export class DashindexComponent implements OnInit {
  allocation: Allocation = new Allocation();
  allocations: Allocation[] = [];
  public bigTotalItems: number;
  public maxSize = 5;
  public bigCurrentPage = 1;
  public alerts: any = [];

  public title = 'Please Confirm';
  public purgeMessage = 'Are you sure you wish to purge this formcode?';
  public cancelClicked = false;

  constructor(private router: Router, private allocateService: AllocationService,
    private userInfoService: UserInfoService) { }

  ngOnInit() {
    const me = this;
    me.getPageData(null, 10, null, this.userInfoService.getGdsCode());

  }
  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    const me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, null, this.userInfoService.getGdsCode());
  }
  getPageData(page: number, size: number, allocation: Allocation, gdscode: string) {
    const me = this;
    me.allocateService.getExternalAllocates(page, size, allocation, gdscode).subscribe((data) => {
      this.allocations = data.items;
      this.bigTotalItems = data.totalItems;
    }
    );
  }
  changeStatus(id: number, status: number) {
    const me = this;
    me.allocateService.changeStatus(id, status).subscribe((repsonse) => {
      console.log(repsonse);
      me.addMessage(repsonse.operationMessage);
      me.getPageData(null, 10, null, this.userInfoService.getGdsCode());
    }
    );
  }
  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
}
