import {Coordinate} from './coordinate';
import {Formcode} from './formcode';

export class Pie {
  emdAll: Coordinate;
  opemdFcs?: (Formcode)[] | null;
  operationMessage: string;
  operationStatus: string;
  opetAll: Coordinate;
  opetFcs?: (Formcode)[] | null;
  tasfAll: Coordinate;
  tasfFcs?: (Formcode)[] | null;
  fcOne: Coordinate;

}
