<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Master Node Switch</H1>
  </div>
</div>
<!-- title -->

<div class="col-10 offset-1 table-bordered">
  <!-- Query form Start-->
  <div class="row table-bordered">
    <div class="col-12">
      <div class="row ml-1">
        <div class="form-group col-3 ml-2 mt-4">
          <label>Master Node:</label>

          <label style="color: #0000FF">
            <select  name="hostName" [(ngModel)]="serverConfig.hostName">
              <option value="aws-lpt2app1">aws-lpt2app1</option>
              <option value="aws-lpt2app2">aws-lpt2app2</option>
            </select>
          </label>
        </div>

        <div class="form-group col-5 ml-2 mt-4">
          <label>Update Time:</label>
          <span style="font-size: 16px">{{serverConfig.updateTime | date:'yyyy-MM-dd HH:mm:ss'}}</span>
        </div>

        <div class="form-group ml-2 col-3 mt-4">
          <button mwlConfirmationPopover
                 [popoverTitle]="title"
                 [popoverMessage]="gneerateMessage"
                 placement="bottom"
                 (confirm)="switch()"
                 (cancel)="cancelClicked = true">Switch</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Query form End-->
</div>
