import {Injectable, Inject} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Bsp} from '../../vo/bsp';
import { HttpParams } from '@angular/common/http';
import {ApiRequestEXService} from './api-request-ex.service';

@Injectable()
export class MergingService {

  constructor(
    private apiRequest: ApiRequestEXService) {
  }

  getItems(bsp1?: Bsp, bsp2?: Bsp): Observable<any> {

    const me = this;

    return me.apiRequest.get('api/merge');

  }

  gotoMerge(from?: string, to?: string): Observable<any> {
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('from', from);
    params = params.append('to', to);
    return me.apiRequest.get('api/mergeTo', params);
  }
}
