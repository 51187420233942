<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Issuing Party Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered">

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>
        View Issuing Party edit
      </h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">

        <div class="row form-group ">
          <div class="col-sm-3 "><label for="name">Name</label></div>
          <div class="col-sm-9">
            <input type="text" name="name" [(ngModel)]="issu.name">
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-sm-3 "><label for="description">Description</label></div>
          <div class="col-sm-9">
            <input type="text" name="id" [(ngModel)]="issu.description">
          </div>
        </div>

      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->
  <div class="row form-group">
    <div class="ml-3 mt-3">
      <input type="button" name="new" value="Update" (click)="update()">
    </div>

    <div class="ml-3 mt-3">
      <input type="button" name="new" value="Delete" (click)="delete()">
    </div>

    <div class="ml-3 mt-3">
      <input type="button" name="back" value="Cancel" (click)="backToList()">
    </div>

  </div>

  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg}}
    </alert>
  </div>
</div>
<!-- body mt-3 -->
