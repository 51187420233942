import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserInfoService} from '../../services/user-info.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  constructor(private route: Router, private userInfoService: UserInfoService) {
  }
  public userName = '';
  previousDest: string;

  ngOnInit() {
    this.userName = this.userInfoService.getUserId();
  }
  goto(dest: string): void {
    if (dest === this.previousDest) {
      this.route.navigate
      (['/home/goRedirect'], {queryParams: {'uri': dest}});
    } else {
      this.previousDest = dest;
      this.route.navigateByUrl(dest);
    }
    // alert(dest);
  }

  gotodpc(dest: string): void {
    this.route.navigateByUrl('dpc');
  }
}
