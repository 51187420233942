<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Calendar</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >

  <!-- table -->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-hover table-condensed">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E;">
          <td>Job</td><td>Ti Generation Time</td><td>Comment</td>
        </tr>
        </thead>
        <tbody>
        <tr class="Waving1" *ngFor="let todo of calendars">
          <td>{{todo.timezone}}</td>
          <td>{{todo.tiGenerateTime}}</td>
          <td>{{todo.comment}}</td>
        </tr>

        <tr class="Waving1" *ngIf="calendars.length == 0">
          <td colspan="7">No Data...</td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>
  <!-- Content End-->

  <!-- table -->


  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}
    </alert>
  </div>

</div>
<!-- body mt-3 -->
