<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Document Types Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" >

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>
        Edit Document Type
      </h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form role="form" name="bspDetailDynaForm" method="post" action="">
        <div class="row form-group"  >
          <div class="col-sm-3 "><label for="name" >Type Name :</label></div>
          <div class="col-sm-9">
            <input type="text" name="name" id="name"  maxlength="100" size="40" value="" disabled [(ngModel)]="vo.dtName"><font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-3 "><label for="description" >Description :</label></div>
          <div class="col-sm-9">
            <input type="text" name="description" id="description" maxlength="512" size="40" value="" [(ngModel)]="vo.dtDescription">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-3 "><label for="dtType" >Document Type :</label></div>
          <div class="col-sm-9">
            <input type="checkbox" name="dtType" id="dtType" disabled [checked]="vo.dtType==0"> Paper
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-3 "><label for="defPer" >Default Percentage :</label></div>
          <div class="col-sm-9">
            <input type="text" name="defPerc" id="defPer" maxlength="3" size="40" value="" [(ngModel)]="vo.dtDefaultpercentage">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-3"><label for="defInterrupt" >Default Interruptions :</label></div>
          <div class="col-sm-9">
            <input type="text" name="defInterrupt" id="defInterrupt" maxlength="100" size="40" value="10" [(ngModel)]="vo.dtDefaultinterruptions">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-3 "><label for="notifLimit" >Notification Limit :</label></div>
          <div class="col-sm-9">
            <input type="text" name="notifLimit" id="notifLimit" maxlength="3" size="40" value="20" [(ngModel)]="vo.dtNotificationlimit">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>

        <div class="row form-group">
          <div class="col-3 pr-2"><label for="dtStatus" >Is Document Type Active :</label></div>
          <div class="col-9">
            <input type="checkbox" name="dtStatus" id="dtStatus" [checked]="vo.dtStatus=='active'" (change)="statusChange($event)">
          </div>
        </div>
        <div class="row form-group" >
          <div class="col-3 pr-1"><label for="excluded" >Exclude to generate TI file :</label></div>
          <div class="col-9">
            <input type="checkbox" name="excluded" id="excluded" [checked]="vo.excluded=='N'" (change)="excludeChange($event)">
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->
  <div class="row form-group">
    <div class="ml-3 mt-3">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>
  </div>
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <!--<div class="offset-1">-->
      <!--<input type="submit" name="update" value="Create" (click)="addDoc()">-->
    <!--</div>-->

    <div class="col-sm-3" *ngIf="!isupdate">
      <input type="submit" name="new" value="Delete" (click)="delete()" >
    </div>
    <div class="col-sm-3" *ngIf="isupdate">
      <input type="submit" name="new" value="Update" (click)="update()" >
    </div>

    <div class="offset-3">
      <input type="submit" name="refresh" value="Cancel" (click)="backToList()">
    </div>
  </div>
  <!-- button end -->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>

  <!-- Form -->

</div>
<!-- body mt-3 -->
