import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from '../services/auth_guard.service';

import {GdshomeComponent} from '../pages/gdshome/gdshome.component';
import {MonitorUsageExternalComponent} from '../pages/monitor-usage-external/monitor-usage-external.component';
import {ChangepwdComponent} from './changepwd/changepwd.component';
import {AllocationRequestComponent} from './allocationrequest/allocation-request.component';
import {DashindexComponent} from './dashindex/dashindex.component';
import {AllocationRequestConfirmComponent} from './allocationrequest/allocation-request-confirm.component';
import {AllocationRequestCancelComponent} from './allocationrequest/allocation-request-cancel.component';
import {FormcodeExternalComponent} from '../pages/formcode-external/formcode-external.component';
import {ViewformcodeExternalComponent} from '../pages/formcode-external/viewformcode-external/viewformcode-external.component';

const routes: Routes = [
  {
    path: 'gdshome',
    component: GdshomeComponent,
    canActivate: [AuthGuard],
    children: [
      {path: '', redirectTo: '/gdshome/dashboard', pathMatch: 'full'},
      {
        path: 'dashboard',
        component: DashindexComponent,
        data: [{selectedHeaderItemIndex: 0, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'changepwd',
        component: ChangepwdComponent,
        data: [{selectedHeaderItemIndex: 0, selectedSubNavItemIndex: 1}]
      },
      {
        path: 'allocationreq',
        component: AllocationRequestComponent,
        data: [{selectedHeaderItemIndex: 0, selectedSubNavItemIndex: 2}]
      },
      {
        path: 'monitorusageexternal',
        component: MonitorUsageExternalComponent,
        data: [{selectedHeaderItemIndex: 0, selectedSubNavItemIndex: 3}]
      },
      {
        path: 'allocationreqconfirm',
        component: AllocationRequestConfirmComponent,
        data: [{selectedHeaderItemIndex: 0, selectedSubNavItemIndex: 7}]
      },
      {
        path: 'allocationreqcancel',
        component: AllocationRequestCancelComponent,
        data: [{selectedHeaderItemIndex: 0, selectedSubNavItemIndex: 8}]
      },
      {
        path: 'formcode-external',
        component: FormcodeExternalComponent,
        data: [{selectedHeaderItemIndex: 0, selectedSubNavItemIndex: 9}]
      },
      {
        path: 'viewformcode-external',
        component: ViewformcodeExternalComponent,
        data: [{selectedHeaderItemIndex: 0, selectedSubNavItemIndex: -1}]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class ExtRoutingModule {
}
