import { Pipe, PipeTransform } from '@angular/core';
import { Gds } from '../vo/gds';

@Pipe({
  name: 'trans'
})
export class TransPipe implements PipeTransform {

  transform(value: any): any {

    let result = '';
    // alert(value);
    if (value != null) {

      const d = new Date(value);

      if (d.getDate() < 10 && (d.getMonth() + 1) >= 10) {
        result = '0' + d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
      } else if ((d.getMonth() + 1) < 10 && d.getDate() < 10) {
        result = '0' + d.getDate() + '/0' + (d.getMonth() + 1) + '/' + d.getFullYear();
      } else if (d.getDate() >= 10 && (d.getMonth() + 1) < 10 ) {
        result = d.getDate() + '/0' + (d.getMonth() + 1) + '/' + d.getFullYear();
      } else {
        result = d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear();
      }

    }
    // alert(result);
    return result;

  }

}
