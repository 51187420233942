import { Pipe, PipeTransform } from '@angular/core';
import { Gds } from '../vo/gds';

@Pipe({
  name: 'arrytostr'
})
export class ArrytostrPipe implements PipeTransform {
  transform(value: Gds[]): string {
    const va = [];
    if ( value != null && value.length > 0 ) {
      for (const item of value) {
        va.push(item.gdsCode);
      }
      return va.toString();
    }

  }


}
