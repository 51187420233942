import {Injectable} from '@angular/core';
import {Observable} from '../../../../node_modules/rxjs';
import { HttpParams } from '@angular/common/http';
import {ApiRequestEXService} from './api-request-ex.service';

@Injectable()
export class BspTiCalendarService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  getCalendars(time: string): Observable<any> {
    const me = this;
    let params: HttpParams = new HttpParams();
    if (time) {
      params = params.append('time', time);
    }
    return this.apiRequest.get('api/getCalendarsForOneDay', params);
  }

}
