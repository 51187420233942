import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Calendar } from '../../vo/calendar';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class CalendarService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  // getItemByID(id: number): Observable<any> {
  //   let me = this;
  //   return me.apiRequest.get('api/calendar/' + id);
  // }
  getAll() {
    return this.apiRequest.get('api/calendars/');
  }

  getItemByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/calendar/' + id);
  }

  updateItem(vo: Calendar): Observable<any> {
    return this.apiRequest.put('api/calendar', vo);
  }

  newItem(vo: Calendar): Observable<any> {
    return this.apiRequest.post('api/newCalendar', vo);
  }

  deleteItem(id: number): Observable<any> {
    return this.apiRequest.delete('api/calendar/' + id);
  }

}
