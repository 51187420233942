<!-- title -->
<div class="row" *ngIf="stage == 1">
  <div class="col-10 offset-1">
    <H1>Allocation Request Reject</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" *ngIf="stage == 1">

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered border-right-0 border-left-0">
    <div class="col-12 mt-3">
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="email" >	Document Type :</label></div>
          <div class="col-sm-9">
            <input readonly type="text" id="email" maxlength="100" size="20"
                   [(ngModel)]="vo.doctype.dtName">
          </div>
        </div>
      <div class="row form-group table-bordered" >
        <div class="col-sm-3 "><label for="email" >	BSP Name :</label></div>
        <div class="col-sm-9">
          <input readonly type="text" id="email" maxlength="100" size="20"
                 [(ngModel)]="vo.bsp.bspFullname">
        </div>
      </div>
      <div class="row form-group table-bordered" >
        <div class="col-sm-3 "><label for="email" >	Create Date :</label></div>
        <div class="col-sm-9">
          <input readonly type="text" id="email" maxlength="100" size="20"
                 [value]='vo.createdate | date'>
        </div>
      </div>
      <div class="row form-group table-bordered" >
        <div class="col-sm-3 "><label for="email" >	Quantity :</label></div>
        <div class="col-sm-9">
          <input readonly type="text" id="email" maxlength="100" size="20"
                 [(ngModel)]="vo.quantity">
        </div>
      </div>
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="description" >Note :</label></div>
          <div class="col-sm-9">
            <textarea id="description" cols="20" rows="5"
                       [(ngModel)]="vo.note">
            </textarea>
          </div>
        </div>
    </div>
  </div>
  <!-- Query form End-->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">

    <div class="offset-1" *ngIf="rejectStatus == 0">
      <input type="button" name="update" value="Submit" (click)="reject()" >
    </div>

    <div class="offset-1" *ngIf="rejectStatus == 1">
      <input type="button" name="update" value="Submit" disabled (click)="reject()" >
    </div>

    <div class="offset-3">
      <input type="button" name="back" value="Cancel" (click)="backToList()" >
    </div>
  </div>
  <!-- button end -->

  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>

  <!-- Form -->

</div>
<!-- body mt-3 -->

<div>
  <div *ngIf="stage == 3">
    <div *ngFor="let alert of alerts" >
      <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
        }}</alert>
    </div>
  </div>
  <app-newmail (cancel)="cancelSending()" (sendMail)="sendOutMail()" [mailMessage]="mailMessage" *ngIf="stage == 2"></app-newmail>
</div>
