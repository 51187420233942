<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Predefined Rule Maintenance</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered" >
    <div class="col-12">
      <form role="form" name="baseDynaForm" method="post" action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2FpredefinedRule.do&_sorig=%2FpredefinedRule.do">
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered" style="font-size:12px">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E">
          <td title="Edit record" width="20px" > </td>
          <td title="Delete record" width="20" > </td>
          <td>BSP</td>
          <!--<td>GDS</td>-->
          <td>DocumentType</td>
          <td>Volume/Month</td>
        </tr>
        </thead>
        <tr class="Waving1" *ngFor="let todo of predefinedRules">
          <td><IMG border="0" src="../../../assets/SNAPDocroot/images/tableview/edit.gif"  alt="Edit" (click)="gotoEdit(todo)" title="Modify this record" /></td>
          <td><IMG border="0" src="../../../assets/SNAPDocroot/images/tableview/delete.gif" alt="Delete" (click)="gotoDelete(todo)" title="Delete this record" /></td>
          <td>{{todo.bsp.bspFullname}}</td>
          <!--<td></td>-->
          <td>{{todo.documenttypes.dtName}}</td>
          <td>{{todo.volumepermonth}}</td>
        </tr>
      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <div class="row form-group table-bordered">
    <div class="offset-1">
      <input type="submit" name="create" value="Create" (click)="gotoNew()">
    </div>
    <div class="offset-3">
      <input type="submit" name="refresh" value="Refresh" (click)="filter()">
    </div>
  </div>
  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->

