
<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Document Subtypes Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" >

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>Edit Document Subtype</h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form role="form" name="documentSubtypesDetailDynaForm" method="post" action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2FdocumentSubtypesDetail.do&_sorig=%2FdocumentSubtypes.do">
        <input type="hidden" name="pkID" value="1">
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="name" >Subtype Name</label></div>
          <div class="col-sm-9">
            <input type="text" name="name" maxlength="100" size="40" value="EBT 2 CPN" [(ngModel)]="vo.dstName">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->
  <!-- alert -->
  <div class="row form-group h-25">
    <div class="ml-3 mt-3">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>
  </div>
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="offset-1">
      <input type="submit" name="update" value="Update" (click)="addNew()">
    </div>
    <div class="offset-3">
      <input type="submit" name="back" value="Cancel" (click)="backToListPage()">
    </div>
  </div>
  <!-- button end -->

  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>
  <!-- Form -->

</div>
<!-- body mt-3 -->

