<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>BSP Calendar</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered">

  <!-- title2 -->
  <div class="col-8 mt-1 ">
    <div class="row">
      <h1>
        BSP Calendar Edit
      </h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <div class="row form-group ">
          <div class="col-sm-3 "><label>BSP Code</label></div>
          <div class="col-sm-9">
            <input type="text" name="bspCode" maxlength="3" size="20" [(ngModel)]="vo.bspCode" readonly>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label>BSP Fullname</label></div>
          <div class="col-sm-9">
            <input type="text" name="bspFullname" maxlength="3" size="20" [(ngModel)]="vo.bspFullname" readonly>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label>BSP Ti Job</label></div>
          <div class="col-sm-9">
            <select [(ngModel)]="vo.bspCal.id" name="bspCal">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of cals" [value]='item.id'>{{item.timezone}}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->


  <!-- alert -->

  <!-- button start -->
    <div class="row">
      <div class="offset-1 col-1">
        <input type="button" name="update" value="Update" (click)="update()">
      </div>
      <div class="offset-1 col-1">
        <input type="button" name="back" value="Cancel" (click)="backToListPage()">
      </div>
    </div>
  </div>
  <!-- button end -->

  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}
    </alert>
  </div>

  <!-- Form -->

<!-- body mt-3 -->


