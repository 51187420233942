<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<!-- title -->
<div class="row" *ngIf="stage == 0">
  <div class="col-10 offset-1">
    <H1>Monitor Usage Filter Page</H1>
  </div>
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>
</div>

<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" *ngIf="stage == 0">

  <!-- title2 -->

  <!-- title2 -->

  <!-- Form -->
  <!-- button start -->
  <div class="row form-group mt-2 table-bordered ">
    <div class="ml-1">
      <input type="submit" name="btnMonitorRangeStatus" value="Current Status" (click)="currentStatus()">
    </div>
    <div class="offset-1">
      <input type="submit" name="btnMonitorActionHistory" value="Action History" (click)="actionHistory()">
    </div>
    <div class="offset-1">
      <input type="submit" name="btnMonitorFormHistory" value="Formcode History" (click)="formcodeHistory()">
    </div>
    <div class="offset-1">
      <input type="submit" name="btnMonitorClear" value="Clear Filters" (click)="clear()">
    </div>
  </div>
  <!-- button end -->
  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">

        <div class="row form-group " >
          <div class="col-sm-4 "><label for="formCode" >Formcode</label></div>
          <div class="col-sm-3" *ngIf="inputHtml" >
            <input type="text" name="formCode" [(ngModel)]="vo.formcodeId" maxlength="3" value="" title="Only Numeric value with 2 or 3 digits">
          </div>
          <div class="col-sm-3" *ngIf="!inputHtml">
            <select name="formCode" [(ngModel)]="vo.formHistory">
              <option *ngFor="let item of formHistories" [value]='item.id' >{{item.fcNumber+'|'+item.updateDate_}}</option>
            </select>
          </div>
          <div class="">
            <input type="submit" name="btnLifeCycles" value="Life cycles..." (click)="lifeCycles()">
          </div>

        </div>

        <div class="row form-group ">
          <div class="col-sm-4 "><label for="rangeStart" >Serial Number</label></div>
          <div class="col-sm-8">
            <input type="text" name="rangeStart" id="rangeStart" value="" maxlength="13" size="13" [(ngModel)]="vo.serialNumber" title="Only Numeric value with 10 digits">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-4 "><label for="activeBsp" >Active BSP</label></div>
          <div class="col-sm-8">
            <select name="activeBsp" [(ngModel)]="vo.activeBsp.id"><option value="" selected="selected">-</option>
              <option *ngFor="let item of activeBsps"  [value]="item.id">{{item.bspFullname+'('+item.bspCode+')' }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-4 "><label for="inactiveBsp" >Inactive BSP</label></div>
          <div class="col-sm-8">
            <select name="inactiveBsp" [(ngModel)]="vo.inactiveBsp.id"><option value="" selected="selected">-</option>
              <option *ngFor="let item of inactiveBsps" [value]="item.id">{{item.bspFullname+'('+item.bspCode+')' }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-4"><label for="docType" >Document type</label></div>
          <div class="col-sm-8">
            <select name="docType" [(ngModel)]="vo.docType.id"><option value="" selected="selected">-</option>
              <option *ngFor="let item of docTypes" [value]="item.id">{{item.dtName}}</option>
            </select>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-4"><label for="docSubType" >Document subtype</label></div>
          <div class="col-sm-8">
            <select name="docSubType" [(ngModel)]="vo.docSubType.id"><option value="" selected="selected">-</option>
              <option *ngFor="let item of docSubTypes" [value]='item.id' >{{item.dstName}}</option>
            </select>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-4"><label for="gds" >GDS code</label></div>
          <div class="col-sm-8">
            <select [(ngModel)]="vo.gds.id"  name="gds">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of gdss" [value]='item.id' >{{item.gdsCode+' / '+item.gdsEmail}}</option>
            </select>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-4"><label for="printer" >Printer</label></div>
          <div class="col-sm-8">
            <select name="printer" [(ngModel)]="vo.printer.id" >
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of printers" [value]='item.id' >{{item.printercode}}</option>
            </select>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-4"><label for="validityDate" >Validity date</label></div>
          <div class="col-sm-8">
            <input type="text" name="validityDate" [(ngModel)]="vo.validityDate" [minDate]="minDate" [maxDate]="maxDate" #dp="bsDatepicker" bsDatepicker [(bsValue)]="bsValue"/>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-4"><label for="purchaseNo" >Purchase order number</label></div>
          <div class="col-sm-8">
            <input type="text" name="purchaseNo" maxlength="100" [(ngModel)]="vo.purchaseOrderNumber">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-4"><label for="allocDate" >Allocation date</label></div>
          <div class="col-sm-8">
            <input type="text" name="allocDate" [minDate]="minDate" [(ngModel)]="vo.allocationDate" [maxDate]="maxDate" #dp="bsDatepicker" bsDatepicker />
            <br>
            <!--<label >{{bsValue}}</label>-->
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-4"><label for="statusCode" >Status code</label></div>
          <div class="col-sm-8">
            <select name="statusCode" [(ngModel)]="vo.snCode" >
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of snstatuss" [value]='item.code' >{{item.description}}</option>
            </select>
          </div>
        </div>


        <div class="row form-group ">
          <div class="col-4"><label for="statusChangeDate" >Status change date</label></div>
          <div class="col-sm-8">
            <input type="text" name="statusChangeDate" [minDate]="minDate" [(ngModel)]="vo.statusChangeDate" [maxDate]="maxDate" #dp="bsDatepicker" bsDatepicker />
            <br>
            <!--<label >{{bsValue}}</label>-->
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-4"><label for="operationCode" >Operation code</label></div>
          <div class="col-sm-8">
            <select name="operationCode" [(ngModel)]="vo.opCode" >
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of opstatuss" [value]='item.code' >{{item.description}}</option>
            </select>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-4"><label for="frozenDate" >Formcode frozen for recycling date</label></div>
          <div class="col-sm-8">
            <input type="text" name="frozenDate" [minDate]="minDate" [(ngModel)]="vo.formcodeFrozen" [maxDate]="maxDate" #dp="bsDatepicker" bsDatepicker />
            <br>
            <!--<label >{{bsValue}}</label>-->
          </div>
        </div>

      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->

  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="ml-1">
      <input type="submit" name="btnMonitorRangeStatus" value="Current Status" (click)="currentStatus()">
    </div>
    <div class="offset-1">
      <input type="submit" name="btnMonitorActionHistory" value="Action History" (click)="actionHistory()">
    </div>
    <div class="offset-1">
      <input type="submit" name="btnMonitorFormHistory" value="Formcode History" (click)="formcodeHistory()">
    </div>
    <div class="offset-1">
      <input type="submit" name="btnMonitorClear" value="Clear Filters" (click)="clear()">
    </div>
  </div>
  <!-- button end -->

  <!-- Form -->

</div>
<!-- body mt-3 -->


<!-- title -->
<div class="row" *ngIf="stage == 1">
  <div class="col-10 offset-1">
    <H1>Monitor Current Status</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" *ngIf="stage == 1">
  <div class="row">
    <div class="col-8 mt-1 ">
      <h2>
        Monitor Current Status Results
      </h2>
    </div>
  </div>

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered " >
    <div class="col-12">
      <form class="" role="form">
        <div class="row">
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorRangeStatus" value="Current Status" disabled="disabled">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorActionHistory" value="Action History" (click)="actionHistory()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorFormHistory" value="Formcode History" (click)="formcodeHistory()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorFilter1" value="New Search" (click)="newSearch()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="submit" name="export" value="Export CSV" (click)="exportCurrentCsv()">
          </div>

        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-hover table-condensed">
        <thead style="background-color: #F2F5FC;color:#1B548E">
        <tr style="text-align:center">
          <td>Form code</td>
          <td>Doc. type</td>
          <td>Doc. subtype</td>
          <td>Start/End number</td>
          <td>Quantity</td>
          <td>Status code</td>
          <td>BSP</td>
          <td>GDS</td>
          <td>Printer</td>
          <td>Validity date</td>
          <td>Purchase Order No.</td>
          <td>Status date</td>
          <td>SNAP No.</td>
        </tr>
        </thead>
        <tbody *ngIf="curentStatuss !== null && curentStatuss.length > 0">
        <tr class="Waving1" *ngFor="let todo of curentStatuss">
          <td hidden="true">{{ todo.formcodeId}}</td>
          <td hidden="true">{{ todo.docType}}</td>

          <!--<td hidden="true">{{ vo.validityDate}}</td>-->

          <td hidden="true">{{ todo.serialNumber}}</td>
          <td hidden="true">{{ todo.docSubType}}</td>
          <td hidden="true">{{ todo.gds}}</td>
          <td hidden="true">{{ todo.activeBsp}}</td>
          <td hidden="true">{{ todo.printer}}</td>
          <!--<td hidden="true">{{ vo.purchaseOrderNumber}}</td>-->
          <td hidden="true">{{ todo.allocationDate}}</td>
          <td hidden="true">{{ todo.validityDate}}</td>
          <!--<td hidden="true">{{ vo.statusChangeDate}}</td>-->
          <td hidden="true">{{ todo.formcodeFrozen}}</td>
          <td hidden="true">{{ todo.snCode}}</td>
          <td hidden="true">{{ todo.opCode}}</td>

          <td>{{todo.formcode}}</td>
          <td>{{todo.doc}}</td>
          <td>{{todo.subDoc}}</td>
          <td>{{todo.rangeStart +"\n"+ todo.rangeEnd}}</td>
          <td>{{todo.quantity}}</td>
          <td *ngIf="todo.statusCode === 'AB'"><a (click)="gotoEdit(todo)" href="javascript:void(0)">{{todo.statusCode}}</a></td>
          <td *ngIf="todo.statusCode !== 'AB'">{{todo.statusCode}}</td>
          <td *ngIf="todo.bsp != null">{{ todo.bsp+"("+todo.bspCode+")" }}</td>
          <td *ngIf="todo.bsp == null"></td>
          <td>{{todo.gdsCode}}</td>
          <td>{{todo.print}}</td>
          <td>{{todo.valDate}}</td>
          <td>{{todo.purOderNum}}</td>
          <td>{{todo.schDate | trans}}</td>
          <td>{{todo.snapNo}}</td>
        </tr>
        </tbody>
        <!--<tbody *ngIf="curentStatuss.length == 0">-->
        <!--<tr class="Waving1" >-->
        <!--<td colspan="5">No Data...</td>-->
        <!--</tr>-->
        <!--</tbody>-->
      </table>

      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>

    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <!--<thead>Legend for Status Codes-->
      <!--</thead>-->
      <table class="table table-bordered" >
        <thead>Legend for Status Codes</thead>
        <tbody *ngIf="snstatuss !== null && snstatuss.length > 0">
        <tr *ngFor="let todo of snstatuss">
          <td>{{todo.code}}</td>
          <td>{{todo.description}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="snstatuss.length == 0">
        <tr class="Waving1" >
          <td colspan="5">No Data...</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>


<!-- title -->
<div class="row" *ngIf="stage == 2">
  <div class="col-11 ml-5">
    <H1>Monitor Action History</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-11 ml-5 table-bordered " *ngIf="stage == 2">
  <div class="row">
    <div class="col-8 mt-1 ">
      <h2>
        Monitor Action History Results
      </h2>
    </div>
  </div>

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered border-left-0 border-right-0" >
    <div class="col-12">
      <form class="" role="form">
        <div class="row">
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorRangeStatus" value="Current Status" (click)="currentStatus()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorActionHistory" value="Action History" disabled="disabled">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorFormHistory" value="Formcode History" (click)="formcodeHistory()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorFilter2" value="New Search" (click)="newSearch()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="export" value="Export CSV" (click)="exportActionCsv()">
          </div>

        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!--<div class="row table-bordered border-top-0 border-left-0 border-right-0" >-->
  <!--<div class="col-12">-->
  <!--<form role="form" name="baseDynaForm" method="post" action="">-->
  <!--<div class="row table-bordered border-top-0 border-left-0 border-right-0" >-->
  <!--<div class="mt-1 col-sm-2">-->
  <!--<label for="formcodeId">Form code</label>-->
  <!--</div>-->
  <!--<div class=" mt-1 col-sm-3">-->
  <!--{{ vo.formcodeId }}-->
  <!--</div>-->
  <!--</div>-->
  <!--<div class="row">-->
  <!--<div class="mt-1 col-sm-2">-->
  <!--<label for="docType">Document type</label>-->
  <!--</div>-->
  <!--<div class=" mt-1 col-sm-3">-->
  <!--{{ dtName }}-->
  <!--</div>-->
  <!--</div>-->

  <!--</form>-->
  <!--</div>-->
  <!--</div>-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-hover table-condensed">
        <thead style="background-color: #F2F5FC;color:#1B548E">
        <tr style="text-align:center">
          <td>Form code</td>
          <td>Doc. type</td>
          <td>Doc. subtype</td>
          <td>Start/End number</td>
          <td>Quantity</td>
          <td>Opn code</td>
          <td>Status code</td>
          <td>BSP (from/to)</td>
          <td>GDS</td>
          <td>Printer</td>
          <td>Validity date</td>
          <td>Purchase Order No.</td>
          <td>Status date</td>
          <td>SNAP No.</td>
        </tr>
        </thead>
        <tbody *ngIf="currentStatuss !== null && currentStatuss.length > 0">
        <tr class="Waving1" *ngFor="let todo of currentStatuss">
          <td>{{todo.formcode}}</td>
          <td>{{todo.doc}}</td>
          <td>{{todo.subDoc}}</td>
          <td>{{todo.rangeStart +"\n"+ todo.rangeEnd}}</td>
          <td>{{todo.quantity}}</td>
          <td>{{todo.opCode}}</td>
          <td>{{todo.statusCode}}</td>
          <td *ngIf="todo.bsp != null">{{ todo.bsp+"("+todo.bspCode+")" }}</td>
          <td *ngIf="todo.bsp == null"></td>
          <td>{{todo.gdsCode}}</td>
          <td>{{todo.print}}</td>
          <td>{{todo.valDate | trans}}</td>
          <td>{{todo.purOderNum}}</td>
          <td>{{todo.statusDate | trans}}</td>
          <td>{{todo.snapNo}}</td>
        </tr>
        </tbody>
      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-6">
      <thead>Legend for Status Codes
      </thead>
      <tbody *ngIf="snstatuss.length > 0">
      <tr *ngFor="let todo of snstatuss">
        <td>{{todo.code}}</td>
        <td>{{todo.description}}</td>
      </tr>
      </tbody>
    </div>
    <div class="col-6">
      <thead>Legend for Operation Codes
      </thead>
      <tbody *ngIf="opstatuss.length > 0">
      <tr *ngFor="let todo of opstatuss">
        <td>{{todo.code}}</td>
        <td>{{todo.description}}</td>
      </tr>
      </tbody>
    </div>
  </div>

  <!-- Content End-->

  <!-- button start -->

  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->

<!-- title -->
<div class="row" *ngIf="stage == 3">
  <div class="col-10 offset-1">
    <H1>Monitor Formcode History</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" *ngIf="stage == 3">
  <div class="row">
    <div class="col-8 mt-1 ">
      <h2>
        Monitor Formcode History Results
      </h2>
    </div>
  </div>

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered " >
    <div class="col-12">
      <form class="" role="form">
        <div class="row">
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorRangeStatus" value="Current Status"  (click)="currentStatus()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorActionHistory" value="Action History" (click)="actionHistory()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorFormHistory" value="Formcode History" disabled="disabled">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="btnMonitorFilter3" value="New Search" (click)="newSearch()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="button" name="export" value="Export CSV" (click)="exportFormCsv()">
          </div>

        </div>
      </form>
    </div>
  </div>

  <!--<div class="row table-bordered" >-->
  <!--<div class="col-12">-->
  <!--<form role="form" name="baseDynaForm" method="post" action="">-->
  <!--<div class="row">-->
  <!--<div class="mt-4 col-sm-2">-->
  <!--<label for="formcodeId">Form code</label>-->
  <!--</div>-->
  <!--<div class=" mt-4 col-sm-3">-->
  <!--{{ vo.formcodeId }}-->
  <!--</div>-->
  <!--</div>-->

  <!--</form>-->
  <!--</div>-->
  <!--</div>-->

  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-hover table-condensed">
        <thead style="background-color: #F2F5FC;color:#1B548E"><tr><td>Formcode</td><td>Document type</td><td>Resolution</td><td>Comment</td><td>Frozen for recycling date</td><td style="text-align:right">Frozen for (years)</td><td>Start date of the life cycle</td><td>IS_OVERRIDEN</td></tr></thead>
        <tbody *ngIf="formHistories !== null && formHistories.length > 0">
        <tr class="Waving1" *ngFor="let todo of formHistories">
          <td>{{vo.formcodeId}}</td>
          <td>{{todo.documentType.dtName}}</td>
          <td>{{todo.fcReso}}</td>
          <td>{{todo.fcComment}}</td>
          <!--<td><a (click)="gotoEdit(todo)" href="javascript:void(0)">{{todo.statusCode}}</a></td>-->
          <!--<td *ngIf="todo.bsp != null">{{ todo.bsp+"("+todo.bspCode+")" }}</td>-->
          <!--<td *ngIf="todo.bsp == null"></td>-->
          <td>{{todo.fcRcyReady | trans }}</td>
          <td>{{todo.fcFrozenFor}}</td>
          <td>{{todo.fcFrozen | trans}}</td>
          <td>{{todo.isOverridden}}</td>

        </tr>
        </tbody>
      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>

  <!-- Content End-->

  <!-- button start -->

  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->



<!-- title -->
<div class="row" *ngIf="stage == 4">
  <div class="col-10 offset-1">

  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" *ngIf="stage == 4">

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>
        Edit Status Code
      </h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="formCode" >Form Code</label></div>
          <div class="col-sm-9">
            <input type="text" name="formCode" [(ngModel)]="voCurrentStatus.formcode" maxlength="10" value="00" disabled="disabled">
          </div>
        </div>

        <div class="row form-group " >
          <div class="col-sm-3 "><label for="docSubType" >Document type</label></div>
          <div class="col-sm-9">
            <input type="text" name="docSubType" maxlength="10" [(ngModel)]="voCurrentStatus.doc"  value="" disabled="disabled">
          </div>
        </div>
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="startNo" >Start Number</label></div>
          <div class="col-sm-9">
            <input type="text" name="startNo" maxlength="10" [(ngModel)]="voCurrentStatus.rangeStart"  value="0" disabled="disabled">
          </div>
        </div>

        <div class="row form-group " >
          <div class="col-sm-3 "><label for="endNo" >End Number</label></div>
          <div class="col-sm-9">
            <input type="text" name="endNo" maxlength="10" value="299999" [(ngModel)]="voCurrentStatus.rangeEnd"  disabled="disabled">
          </div>
        </div>
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="quantity" >Quantity</label></div>
          <div class="col-sm-9">
            <input type="text" name="quantity" maxlength="10" value="300000" [(ngModel)]="voCurrentStatus.quantity"  disabled="disabled">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="statusCode" >Status code</label></div>
          <div class="col-sm-9">
            <select name="statusCode" [(ngModel)]="voCurrentStatus.statusCode" >
              <option *ngFor="let item of snstatuss" [value]='item.code' >{{ item.code +' / '+item.description }}</option>
            </select>
          </div>
        </div>
        <div>
          <input type="hidden" name="oldStartNo" [(ngModel)]="voCurrentStatus.oldStatusCode"  value="0" disabled="disabled">
        </div>
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="bsp" >Bsp</label></div>
          <div class="col-sm-9">
            <input type="text" name="bsp" maxlength="10" value="AUSTRIA" [(ngModel)]="voCurrentStatus.bsp" disabled="disabled">
          </div>
        </div>
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="gds" >Gds</label></div>
          <div class="col-sm-9">
            <input type="text" name="gds" maxlength="10" value="AGTD" [(ngModel)]="voCurrentStatus.gdsCode" disabled="disabled">
          </div>
        </div>
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="printer" >Printer</label></div>
          <div class="col-sm-9">
            <input type="text" name="printer" maxlength="10" value="" [(ngModel)]="voCurrentStatus.print" disabled="disabled">
          </div>
        </div>
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="valDate" >Validity date</label></div>
          <div class="col-sm-9">
            <input type="text" name="valDate" maxlength="10" value="" [(ngModel)]="voCurrentStatus.valDate" disabled="disabled">
          </div>
        </div>
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="purOrderNo" >Purchase order number</label></div>
          <div class="col-sm-9">
            <input type="text" name="purOrderNo" maxlength="100" value="" [(ngModel)]="voCurrentStatus.purOderNum" disabled="disabled">&nbsp;
          </div>
        </div>
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="sinceDate" >Status change date</label></div>
          <div class="col-sm-9">
            <input type="text" name="sinceDate" value="06/10/11" disabled="disabled" [(ngModel)]="voCurrentStatus.schDate" readonly="readonly">
          </div>
        </div>

      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->

  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="ml-3">
      <input type="submit" name="btnSaveStatusCode" value="Save Status" (click)="saveStatus()">
    </div>
    <div class="offset-3">
      <input type="submit" name="btnCancel" value="Cancel" (click)="backToListPage()">
    </div>
  </div>
  <!-- button end -->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>
  <!-- Form -->

</div>
<!-- body mt-3 -->

