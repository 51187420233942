
export class PrintFormHistoryVo {

  dtName: string;
  fcComment: string;
  fcReso: string;
  fcRcyReady: string;
  fcFrozenFor: number;
  fcFrozen: string;
  isOverridden: string;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
