import {Gds} from './gds';
import {Printer} from './printer';
import {DocumentSubType} from './documentSubType';
import {Document} from './document';
import {Bsp} from './bsp';
import {Snstatus} from './snstatus';
import {Opstatus} from './opstatus';
import {FormHistory} from './formHistory';

export class MonitorUsage {

  formCode: string;
  serialNumber: string;
  docType: Document;
  docSubType: DocumentSubType;
  gdsCode: string;
  // printer: string;
  // validityDate: Date;
  validityDate: string;
  purchaseOrderNumber: string;
  // allocationDate: Date;
  allocationDate: string;
  snstatus: Snstatus;
  snCode: string;
  // statusChangeDate: Date;
  statusChangeDate: string;
  opstatus: Opstatus;
  opCode: string;
  // formcodeFrozen: Date;
  formcodeFrozen: string;
  gds: Gds;
  activeBsp: Bsp;
  inactiveBsp: Bsp;
  printer: Printer;
  formHistory: FormHistory;
  formcodeId: string;
  valDate: Date;

  // gdsz: Gds;
  // relGds: Array<Gds>;
  // gdsId:number;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
