<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Bi-Monthly Bulletin</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-7 offset-1 table-bordered" style="background-color:#D9E6F4">
  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="formtable">
        <tr>
          <td>Previous bulletin has no {{previousBulletin.bulletinNo}} and was sent on {{previousBulletin.bulletinDate | date}}.</td>
        </tr>
      </table>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <div class="row form-group table-bordered">
    <div class="col-9">
      <input type="submit" name="generate" value="Generate new Bulletin" (click)="generateBulletin()">
    </div>
  </div>
  <!-- button end

  <!-- Content Start-->
  <div *ngIf="newBulletin.bulletinContent" class="row mt-3">
    <div class="col-9" style="padding: 0">
      <table>
        <tr>
          <td>
            <textarea name="SNAP_BODY" [(ngModel)]="newBulletin.bulletinContent" cols="87" rows="30" style="font-family: courier, monospace;"></textarea>
          </td>
      </table>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <div *ngIf="newBulletin.bulletinContent" class="row form-group table-bordered">
    <div class="col-9">
      <input type="submit" name="send" value="Send bulletin" (click)="sendBulletin()">
    </div>
  </div>
  <!-- button end -->

  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
  </div>
</div>
<!-- body mt-3 -->
