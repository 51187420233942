

  <!-- title -->
  <div class="row" >
    <div class="col-10 offset-1">
      <H1>Dpc Maintenance</H1>
    </div>
  </div>
  <!-- title -->

  <!-- body mt-3 -->
  <div class="col-10 offset-1 table-bordered" >

    <!-- table -->

    <!-- Query form Start-->
    <div class="row table-bordered" >
      <div class="col-12">
        <form class="" role="form"  name="qryForm" method="post" action="">
          <div class="row ml-1" >
            <div class="form-group col-3">
              <label for="snaptable_dpcCode">DPC Code:</label>
              <input type="text" id="snaptable_dpcCode" name="code" maxlength="10" value="" [(ngModel)]="qry.dpcCode">
            </div>
            <div class="form-group col-3">
              <label for="snaptable_dpcEmail" >DPC Email Address:</label>
              <input type="text" id="snaptable_dpcEmail" name="mail" maxlength="100" value="" [(ngModel)]="qry.dpcEmail">
            </div>
            <div class="form-group col-3">
              <label for="snaptable_dpcIinetAccount">DPC IINET Account:</label>
              <input type="text" id="snaptable_dpcIinetAccount" name="iinet" maxlength="50" value="" [(ngModel)]="qry.dpcIinetAccount" >
            </div>
            <div class="form-group col-3 mt-3 ">
              <input type="button" name="snaptable_filter" (click)="filter()" value="Filter">
            </div>
          </div>
        </form>

      </div>
    </div>
    <!-- Query form End-->

    <!-- Content Start-->
    <div class="row mt-3">
      <div class="col-12" >
        <table class="table table-hover table-condensed" >
          <thead>
          <tr style="background-color: #F2F5FC;color:#1B548E;">
            <td title="Edit record" width="20px" > </td>
            <td title="Delete record" width="20" > </td><td>DPC Code</td>
            <td>DPC Email Address</td>
            <td>DPC IINET Account</td>
          </tr>
          </thead>
          <tbody *ngIf="dpcs.length > 0">
          <tr class="Waving1" *ngFor="let todo of dpcs">
            <td><img border="0" src="assets/SNAPDocroot//images/tableview/edit.gif" alt="Edit" (click)="gotoEdit(todo)" title="Modify this record"></td>
            <td><img border="0" src="assets/SNAPDocroot//images/tableview/delete.gif" alt="Delete" (click)="removeTodo(todo)" title="Delete this record">
            </td><td>{{todo.dpcCode}}</td>
            <td>{{todo.dpcEmail}}</td>
            <td>{{todo.dpcIinetAccount}}</td>
          </tr>
          </tbody>
          <tbody *ngIf="dpcs.length == 0">
          <tr class="Waving1" >
            <td colspan="5">No Data...</td>

          </tr>
          </tbody>
        </table>
        <div class="col-md-6">
          <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                      [boundaryLinks]="true"></pagination>
        </div>
      </div>
    </div>
    <!-- Content End-->

    <!-- button start -->
    <div class="row form-group table-bordered">
      <div class="offset-1">
        <input type="submit" name="create" value="Create" (click)="gotoNew()">
      </div>
      <div class="offset-3 ">
        <input type="submit" name="refresh" value="Refresh" (click)="filter()">
      </div>
    </div>
    <!-- button end -->

    <!-- table -->

  </div>
  <!-- body mt-3 -->


<!-- main -->
