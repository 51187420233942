<!-- title -->
<div class="row" *ngIf="stage != 2">
  <div class="col-10 offset-1">
    <H1>Transfer Page</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" *ngIf="stage == 0">

  <!-- title2 -->
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row ">
    <div class="col-12 mt-3">
      <form role="form" name="transferDynaForm" method="post" action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2Ftransfer.do&_sorig=%2Ftransfer.do">
        <input type="hidden" name="pkID" value="1">
        <div class="row form-group table-bordered border-top-0" >
          <div class="col-sm-6">
            <label for="startNum" >Start Number:</label>
            <font color="#ff0000" size="4">*</font>
            <input type="text" name="startNum" [(ngModel)]="qry.startNumber" required pattern="^[0-9]*$" #startNum="ngModel"  maxlength="13" size="13">
          </div>
          <div class="md-errors-spacer" *ngIf="startNum.invalid && (startNum.dirty || startNum.touched)">
            <div class="ui error message">
              Start Number must have 10 digits.
            </div>
          </div>
          <div class="col-sm-6">
            <label for="endNum" >End Number:</label>
            <font color="#ff0000" size="4">*</font>
            <input type="text" name="endNum" [(ngModel)]="qry.endNumber" maxlength="13" size="13" required pattern="^[0-9]*$" #endNum="ngModel">
          </div>
          <div class="md-errors-spacer" *ngIf="endNum.invalid && (endNum.dirty || endNum.touched)">
            <div class="ui error message">
              End Number must have 10 digits.
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->
  <!-- alert -->
  <div class="row form-group h-25">
    <div class="ml-3 mt-3">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>
  </div>
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">

    <div class="offset-1">
      <input type="submit" name="submit" value="Transfer" (click)="preTransfer()">
    </div>
  </div>
  <!-- button end -->

  <!-- Form -->
  <!-- Query form End-->


</div>
<div class="col-10 offset-1 table-bordered" *ngIf="!isValideRangeForRetrieve" >
<div class="row " >
  <div class="col-10  table-bordered">
    <label style="font-size:14px">Current status for selected filter: </label>
  </div>
</div>
<!-- Content Start-->

<div class="row ">
  <div class="col-10 table-bordered">
    <table class="table table-bordered" style="font-size:12px">
      <input type="hidden" name="fromBspId" value="78"/>
      <thead>
      <tr style="background-color: #F2F5FC;color:#1B548E">
        <td>Start Number</td>
        <td>End number</td>
        <td>Status</td>
        <td>BSP</td>
      </tr>
      </thead>
      <tbody *ngIf="stats.length > 0">
      <tr class="Waving1" *ngFor="let todo of stats">
        <td>{{todo.startNum}}</td>
        <td>{{todo.endNum}}</td>
        <td>{{todo.status}}</td>
        <td>{{todo.bsp}}</td>
      </tr>
      </tbody>
      <tbody *ngIf="stats.length == 0">
      <tr class="Waving1" >
        <td colspan="4">No range could be found between the specified start and end numbers.</td>

      </tr>
      </tbody>
    </table>
  </div>
</div>
</div>
<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" *ngIf="stage == 1" >

  <!-- table -->

  <!-- Query form Start-->
  <div class="row mt-3">

    <div class="col-10  table-bordered" >
      <form name="baseDynaForm" method="post" action="">
        <div class="row form-group table-bordered" >
          <div class="col-sm-6 ">
            <div class="row ">
              <div class="col-sm-12">
                <label for="startNum" >Start Number:</label>
                <font color="#ff0000" size="4">*</font>
              </div>
            </div>
            <div class="row ">
              <div class="col-sm-12">
                {{qry.startNumber}}
              </div>
            </div>

          </div>
          <div class="col-sm-6">
            <div class="row ">
              <div class="col-sm-12">
                <label for="endNum" >End Number:</label>
                <font color="#ff0000" size="4">*</font>
              </div>
            </div>
            <div class="row ">
              <div class="col-sm-12">
                {{qry.endNumber}}
              </div>
            </div>
          </div>
        </div>

        <div class="row form-group table-bordered" >
          <div class="col-sm-6 ">
            <label>Quantity: {{qry.quantity}}</label>
          </div>
          <div class="col-sm-6 ">
            <label>Document type: {{qry.documenttype}} </label>
          </div>
        </div>
        <div class="row form-group table-bordered" >
          <div class="col-sm-6 ">
            <label>From BSP:<br><Strong>{{qry.fromBspName}}</Strong></label>
          </div>
          <div class="col-sm-6 ">
            <label>To BSP:<font color="#ff0000" size="4">*</font></label><br>
            <select [(ngModel)]="qry.toBspId"  name="toBsp">
              <option value="" selected="selected">---</option>
              <option *ngFor="let item of bsps" [value]='item.id'>{{item.bspFullname}}</option>
            </select>
          </div>
        </div>
        <div class="row form-group table-bordered ">
          <div class="col-sm-8">
            (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
          </div>

        </div>
        <!-- button start -->
        <div class="row form-group table-bordered">
          <div class="offset-1">
            <input type="submit" name="btnFinalizeTransfer" value="Transfer" (click)="transfer()">
          </div>
          <div class="offset-3">
            <input type="submit" name="cancel" (click)="cancel()" value="Cancel">
          </div>
        </div>
        <!-- button end -->
      </form>
    </div>
  </div>

  <!-- Content End-->
  <!-- Query form End-->
  <div class="row ">
    <div class="col-10 offset-4 table-bordered">
      <label style="font-size:14px">Original Allocation </label>
    </div>
  </div>
  <!-- Content Start-->

  <div class="row ">
    <div class="col-10 table-bordered">
      <table class="table table-bordered" style="font-size:12px">
        <input type="hidden" name="fromBspId" value="78"/>
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E">
          <td>Start Number</td>
          <td>End number</td>
          <td>Quantity</td>
          <td>BSP</td>
        </tr>
        </thead>
        <tr>
          <td>{{qry.originalStartNumber}}</td>
          <td>{{qry.originalEndNumber}}</td>
          <td>{{qry.originalQuantity}}</td>
          <td>{{qry.fromBspName}}</td>
        </tr>
      </table>
    </div>
  </div>
  <!-- Content End-->
</div>
<!-- body mt-3 -->

<div>
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}
    </alert>
  </div>
  <app-newmail (cancel)="cancelSending()" (sendMail)="sendOutMail()" [mailMessage]="mailMessage" *ngIf="stage == 2"></app-newmail>
</div>
