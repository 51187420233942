import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Formcode } from '../../vo/formcode';
import { Document } from '../../vo/document';
import { FormcodeService } from '../../services/api/formcode.service';
import { DocumentTypeService } from '../../services/api/documentType.service';
import { PrintFormcodeVo } from '../../vo/printFormcodeVo';
import { DatePipe } from '@angular/common';
import { IssuParty } from '../../vo/issu-party';
import { IssuePartyService } from '../../services/api/issue-party.service';
import { AngularCsv } from 'src/app/services/angular-csv';

@Component({
  selector: 'app-formcode',
  templateUrl: './formcode.component.html',
  styleUrls: ['./formcode.component.css']
})
export class FormcodeComponent implements OnInit {

  qry: Formcode = new Formcode();
  formcodes: Formcode[] = [];
  formcodesCSV: Formcode[] = [];
  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;

  vo: Formcode = new Formcode();
  doc: Document = new Document();
  docs: Document[] = [];
  issu: IssuParty = new IssuParty();
  issus: IssuParty[] = [];
  public alerts: any = [];
  pcsv: PrintFormcodeVo[] = [];

  isDataLoaded = false;

  constructor(private router: Router, private myservice: FormcodeService, private docService: DocumentTypeService,
    private datePipe: DatePipe, private issuService: IssuePartyService) {
  }

  ngOnInit() {
    this.getPageData(this.myservice.bigCurrentPage - 1, 10, null);

    this.qry.documentType = this.doc;
    this.docService.getActiveDocs().subscribe((data) => {
      console.log(data);
      this.docs = data.items;
      console.log(this.docs);
    });
    this.qry.issueParty = this.issu;
    this.issuService.getIssuingPartys().subscribe((data) => {
      console.log(data);
      this.issus = data.items;
      console.log(this.issus);
    });

  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    if (event.page !== this.myservice.bigCurrentPage) {
      const me = this;
      me.getPageData(event.page - 1, event.itemsPerPage, this.qry);
    }
  }

  getPageData(page: number, size: number, vo: Formcode) {
    this.myservice.getPages(page, size, vo).subscribe((data) => {
      console.log('test');
      console.log(data);
      this.formcodes = data.items;
      this.bigTotalItems = data.totalItems;
      if (!this.isDataLoaded) {
        this.isDataLoaded = true;
        setTimeout(() => {
          this.bigCurrentPage = this.myservice.bigCurrentPage;
        }, 0);
      } else {
        this.myservice.bigCurrentPage = data.currentPageNumber + 1;
      }

    }
    );
  }

  gotoNew() {
    this.router.navigate(['/home/newformcode']);
  }

  gotoEdit(todo) {
    this.router.navigate
      (['/home/editformcode'], { queryParams: { 'id': todo.id, 'action': 'U' } });
  }

  gotoFreeze(todo) {
    this.router.navigate
      (['/home/editformcode'], { queryParams: { 'id': todo.id, 'action': 'F' } });
  }

  gotoUnfreeze(todo) {
    this.router.navigate
      (['/home/editformcode'], { queryParams: { 'id': todo.id, 'action': 'UF' } });
  }

  gotoOpen(todo) {
    this.router.navigate
      (['/home/editformcode'], { queryParams: { 'id': todo.id, 'action': 'O' } });
  }

  gotoView(todo) {
    this.router.navigate
      (['/home/viewformcode'], { queryParams: { 'id': todo.id } });
  }

  backToList() {
    this.router.navigate(['/home/formcode']);
  }

  filter() {
    const me = this;
    console.log(this.qry);
    // this.bigCurrentPage = 1;
    me.getPageData(0, 10, this.qry);
  }

  exportFormCodeCSV() {
    const me = this;
    me.pcsv.splice(0, me.pcsv.length);
    console.log(this.qry);
    me.myservice.getPages(0, 2000, this.qry).subscribe((data) => {
      this.formcodesCSV = data.items;
      for (const item of this.formcodesCSV) {
        const printFormcodeVo: PrintFormcodeVo = new PrintFormcodeVo();
        printFormcodeVo.formcode = item.fcNumber;
        printFormcodeVo.dtName = item.documentType.dtName;
        printFormcodeVo.resolution = item.fcReso;
        if (item.fcFrozen == null) {
          printFormcodeVo.frozenDate = null;
        } else {
          printFormcodeVo.frozenDate = this.datePipe.transform(item.fcFrozen, 'yyyy-MM-dd');
        }
        if (item.fcStatus === 'O') {
          printFormcodeVo.status = 'Opened';
        } else if (item.fcStatus === 'F') {
          printFormcodeVo.status = 'Frozen';
        } else {
          printFormcodeVo.status = 'Recycled';
        }
        this.pcsv.push(printFormcodeVo);
        console.log(printFormcodeVo);
      }
      const options = {
        headers: ['Form Code', 'Document Type', 'Resolution', 'Frozen Date', 'Status']
      };
      new AngularCsv(this.pcsv, 'CurrenFormcode Report', options);
    });
  }

}
