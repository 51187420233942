import {Component, OnInit} from '@angular/core';
import {SwitchConfig} from '../../vo/SwitchConfig';
import {SwitchConfigService} from '../../services/switch-config.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-ti-auto-switch',
  templateUrl: './ti-auto-switch.component.html',
  styleUrls: ['./ti-auto-switch.component.css']
})
export class TiAutoSwitchComponent implements OnInit {

  switchConfig: SwitchConfig = new SwitchConfig();

  public title = 'Please Confirm';
  public gneerateMessage = 'Warring!!! The operation will effect the system sending ti file to iiNET, do you want to continue?';
  public cancelClicked = false;

  constructor(private myservice: SwitchConfigService, private router: Router) {
  }

  ngOnInit() {
    this.myservice.getConfig().subscribe((data) => {
      console.log(data);
      this.switchConfig = data;
    });
  }
  switch() {
    if (this.switchConfig.flag === 'false' || this.switchConfig.flag === 'False' || this.switchConfig.flag === 'FALSE') {
      this.myservice.turnOn().subscribe(() =>
        this.refresh()
      );
    } else {
      this.myservice.turnOff().subscribe(() =>
        this.refresh()
      );
    }
  }
  refresh() {
    this.myservice.getConfig().subscribe((data) => {
      console.log(data);
      this.switchConfig = data;
    });
  }

}
