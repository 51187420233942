<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Formcode Maintenance</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered" >
    <div class="col-12">
      <form class="" role="form">
        <div class="row">
          <div class="form-group col-2">
            <label for="snaptable_fcNumber" >Form Code:</label>
            <input type="text" name="snaptable_fcNumber" id="snaptable_fcNumber" maxlength="3" [(ngModel)]="qry.fcNumber">
          </div>

          <div class="form-group col-2 ml-5">
            <label>Document type:</label>

            <select [(ngModel)]="qry.documentType.id"  name="doctype">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of docs" [value]='item.id'>{{item.dtName}} / {{item.dtType | cgn}}</option>
            </select>
          </div>
          <div class="form-group col-2 ml-5">
            <label>Form Code Status:</label>
            <select  class="col-10" name="snaptable_fcStatus" [(ngModel)]="qry.fcStatus">
              <option value="" selected="selected">-</option>
              <option value="F">Frozen</option>
              <option value="O">Opened</option>
              <option value="A">Recycled</option>
            </select>
          </div>
          <div class="form-group col-1 mt-4 ml-5">
            <input type="button" name="snaptable_filter" value="Filter" (click)="filter()">
          </div>
          <div class="form-group mt-4 ml-5">
            <input type="button" name="snaptable_export" value="Export CSV" (click)="exportFormCodeCSV()">
          </div>
        </div>

      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-hover table-condensed">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E;">
          <td title="Edit record" width="20px"> </td><td title="Delete record" width="20"></td><td>Form Code</td><td>Document type</td><td>Issuing Party</td><td>Resolution</td><td>Frozen Date</td><td>Form Code Status</td>
        </tr>
        </thead>
        <tbody>
        <tr class="Waving1" *ngFor="let todo of formcodes">
          <td *ngIf="todo.fcStatus === 'O' || todo.fcStatus === 'A'"><img border="0" src="../../../assets/SNAPDocroot//images/tableview/edit.gif" alt="Edit" title="Modify this record" (click)="gotoEdit(todo)"></td>
          <td *ngIf="todo.fcStatus === 'F'"><img border="0" src="../../../assets/SNAPDocroot//images/tableview/view.gif" alt="view" title="View this record" (click)="gotoView(todo)"></td>
          <td *ngIf="todo.fcStatus === 'O'"><img border="0" src="../../../assets/SNAPDocroot//images/tableview/freeze.gif" alt="Freeze" title="Freeze this record" (click)="gotoFreeze(todo)"></td>
          <td *ngIf="todo.fcStatus === 'F'"><img border="0" src="../../../assets/SNAPDocroot//images/tableview/unfreeze.gif" alt="Unfreeze" title="Unfreeze this record" (click)="gotoUnfreeze(todo)"></td>
          <td *ngIf="todo.fcStatus === 'A'"><img border="0" src="../../../assets/SNAPDocroot//images/tableview/view.gif" alt="Open" title="Open this record" (click)="gotoOpen(todo)"></td>
          <td>{{todo.fcNumber}}</td>
          <td>{{todo.documentType.dtName}}</td>
          <td>{{todo.issueParty==null?'':todo.issueParty.name}}</td>
          <td>{{todo.fcReso}}</td>
          <td>{{todo.fcFrozen | date:'dd/MM/yy'}}</td>
          <td>{{todo.fcStatus | fcStatus}}</td>
        </tr>

        <tr class="Waving1" *ngIf="formcodes.length == 0">
          <td colspan="7">No Data...</td>
        </tr>

        </tbody>
      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <div class="row form-group table-bordered">
    <div class="offset-3">
      <input type="button" name="create" value="Create" (click)="gotoNew()">
    </div>
    <div class="offset-3">
      <input type="button" name="refresh" value="Refresh" (click)="filter()">
    </div>
  </div>
  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->
