<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Overall Stock Availability Dashboard</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered">

  <table>
    <tr>
      <td>
        <table>
          <tr>
            <td>
              <div id="chartContainer_opet" style="height: 300px; width: 400px;"></div>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table>
          <tr>
            <td>
              <div id="chartContainer_opemd" style="height: 300px; width: 400px;"></div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <div style="color: #6E859A; font-size: 12px">
    The solid RED line is the OVERALL STOCK THRESHOLD.<br>
    The solid BLUE line is the STOCK ALLOCATED.<br>
    The dotted BLUE is the STOCK COMSUMPTION FORCASE.<br>
    This dashboard is to have an overview of the overall stock consumption status.
  </div>
</div>

<div class="col-10 offset-1 table-bordered">

  <table>
    Projection(%):<input type="text" [(ngModel)]="createRatio"  name="createRatio" (change)="PercentageIncrease()">
    <tr>
      <td>
        <table>

          <tr>
            <td>
              <div id="opet_rec" style="height: 300px; width: 400px;"></div>
            </td>
          </tr>
        </table>
      </td>
      <td>

        <table>
          <tr>
            <td>
              <div id="opemd_rec" style="height: 300px; width: 400px;"></div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <div style="color: #6E859A; font-size: 12px">
    The solid RED line is the OVERALL STOCK THRESHOLD.<br>
    The solid BLUE line is the STOCK ALLOCATED MINUS RECYCLED.<br>
    The dotted BLUE is the STOCK COMSUMPTION FORCASE AFTER RECYCLING.<br>
    This dashboard is to have an overview of the overall stock consumption status.
  </div>
</div>

<div class="col-10 offset-1 table-bordered">
  <table>
    <tr>
      <td>
        <table>
          <tr>
            <td>
              <div id="opet" style="height: 300px; width: 400px;"></div>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table>
          <tr>
            <td>
              <div id="opemd" style="height: 300px; width: 400px;">
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>

</div>
