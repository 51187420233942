
<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Change Password</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="offset-1">* - indicates mandatory fields.
</div>
<div class="col-8 offset-1 table-bordered" >

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h2>
        Change Your Password
      </h2>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="oldPassword" >Old Password </label></div>
          <div class="col-sm-9">
            <input type="password" name="oldPassword" value="" style="WIDTH:200px" [(ngModel)]="vo.oldPwd">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-sm-3 "><label for="newPassword" >New Password </label></div>
          <div class="col-sm-9">
            <input type="password" name="newPassword" value="" style="WIDTH:200px" [(ngModel)]="vo.newPwd">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="confirmPassword" >Confirm Password </label></div>
          <div class="col-sm-5">
            <input type="password" name="confirmPassword" value="" style="WIDTH:200px" [(ngModel)]="vo.comfirmPwd">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3">
            <input type="submit" value="Save" class="btn3" (click)="changePwd()">
          </div>
        </div>
        <div *ngFor="let alert of alerts">
          <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg}} </alert>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->
  <div class="row form-group">
    <div class="ml-3 mt-3">
      <p class="note"><b>Password Policy:</b>
      </p>
      <ul>
        <li class="note">Password should be at least 8 letters long.</li>
        <li class="note">First 7 letters of the password should be alphabets.</li>
        <li class="note">Password should not exceed 10 letters.</li>
        <li class="note">Password should end with at least one digit.</li>
        <li class="note">Password should contain at least one Capital letter.</li>
        <li class="note">Password should not contain any special characters</li>
      </ul>
    </div>
  </div>
  <!-- alert -->

  <!-- button start -->

  <!-- button end -->

  <!-- Form -->

</div>
<!-- body mt-3 -->
