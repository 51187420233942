import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {LoginInfoInStorage, UserInfoService} from '../user-info.service';
import {ApiRequestEXService} from './api-request-ex.service';

export interface LoginRequestParam {
  username: string;
  password: string;
}

@Injectable()
export class LoginService {

  landingPage = '/home';

  constructor(private router: Router,
              private userInfoService: UserInfoService,
              private apiRequest: ApiRequestEXService) {
  }

  getToken(username: string, password: string): Observable<any> {

    const bodyData: LoginRequestParam = {
      'username': username,
      'password': password,
    };

    const loginDataSubject: Subject<any> = new Subject<any>(); // Will use this subject to emit data that we want after ajax login attempt
    let loginInfoReturn: LoginInfoInStorage; // Object that we want to send back to Login Page

    this.apiRequest.post('session', bodyData).subscribe((jsonResp) => {
      console.log('response:' + jsonResp);
      if (jsonResp !== undefined && jsonResp !== null && jsonResp.operationStatus === 'SUCCESS') {
        // Create a success object that we want to send back to login page
        loginInfoReturn = {
          'success': true,
          'message': jsonResp.operationMessage,
          'errorstatus': 200,
          'landingPage': jsonResp.landingPage,
          'user': {
            'userId': jsonResp.item.userId,
            'email': jsonResp.item.emailAddress,
            'displayName': jsonResp.item.firstName + ' ' + jsonResp.item.lastNameName,
            'token': jsonResp.item.token,
            'role': jsonResp.item.role,
            'gdsCode': jsonResp.item.gdsCode
          }
        };
        // store username and jwt token in session storage to keep user logged in between page refreshes
        this.userInfoService.storeUserInfo(JSON.stringify(loginInfoReturn.user));
      } else {
        // Create a faliure object that we want to send back to login page
        loginInfoReturn = {
          'success': false,
          'message': 'Server error',
          'errorstatus': 200,
          'landingPage': '/serverdown'
        };
      }
      loginDataSubject.next(loginInfoReturn);
    }, error => {
      console.log(error.status);
      loginInfoReturn = {
        'success': false,
        'message': 'Server Down',
        'errorstatus': error.status,
        'landingPage': '/serverdown'
      };
      loginDataSubject.next(loginInfoReturn);
    });
    return loginDataSubject;
  }

  logout(navigatetoLogout = true): void {
    // clear token remove user from local storage to log user out
    this.userInfoService.removeUserInfo();
    if (navigatetoLogout) {
      this.router.navigate(['logout']);
    }
  }
}
