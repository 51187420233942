import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Bsp } from '../../vo/bsp';
import { BspService } from '../../services/api/bsp.service';
import { MailVo } from '../../vo/mailVo';
import { SendMailService } from '../../services/api/send-mail.service';

@Component({
  selector: 'app-generate-ti-file',
  templateUrl: './generate-ti-file.component.html',
  styleUrls: ['./generate-ti-file.component.css']
})
export class GenerateTiFileComponent implements OnInit {

  vo: Bsp = new Bsp();
  bsps: Bsp[] = [];
  start: Date;
  end: Date;
  startTime = '';
  endTime = '';
  stage = 0;
  mailMessage: MailVo = new MailVo();
  public alerts: any = [];

  public title = 'Please Confirm';
  public gneerateMessage = 'Are you sure you wish to generate the TI File?';

  public cancelClicked = false;

  minDate = new Date(1990, 1, 1);
  maxDate = new Date(2200, 1, 1);

  constructor(private router: Router, private myservice: BspService, private mailService: SendMailService) { }

  ngOnInit() {
    const me = this;
    me.getBsps();
  }

  getBsps() {
    this.myservice.getActiveBsps().subscribe((data) => {
      console.log(data);
      this.bsps = data.items;
    }
    );
  }

  generate() {

    if (this.vo.bspCode === undefined || this.vo.bspCode === '' || this.vo.bspCode === null) {
      alert('Please select BSP code');
      return;
    }
    if (!this.start || this.start === null) {
      alert('Please select start time');
      return;
    }
    if (!this.end || this.end === null) {
      alert('Please select end time');
      return;
    }

    this.startTime = this.start.getFullYear() + '-' + (this.start.getMonth() + 1) + '-' + this.start.getDate() + ' 00:00:00';
    this.endTime = this.end.getFullYear() + '-' + (this.end.getMonth() + 1) + '-' + this.end.getDate() + ' 00:00:00';

    this.myservice.generateTIFileByBsp(this.vo.bspCode, this.startTime, this.endTime).subscribe(
      (data) => {
        this.mailMessage = data.mailMessage;
      }
    );
    this.stage = 1;
  }

  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: 30000
    });
  }

  cancelSending() {
    this.addMessage('Email sending is canceled', 'success');
    this.stage = 2;
  }

  sendOutMail() {
    this.mailService.sendEmail(this.mailMessage).subscribe(
      (response) => {
        console.log(response);
        // alert(response.operationMessage);
        this.addMessage(response.operationMessage, 'success');
        this.stage = 2;
      }
    );
  }

}
