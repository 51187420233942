import {Injectable, Inject} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Printer} from '../../vo/printer';
import { HttpParams } from '@angular/common/http';
import {ApiRequestEXService} from './api-request-ex.service';

@Injectable()
export class PrinterService {

  constructor(private apiRequest: ApiRequestEXService) {
  }


  getPages(page?: number, size?: number, vo?: Printer): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    // if(vo && vo.bspCode){
    //   params = params.append('bspCode', vo.bspCode);
    // }
    // if(vo && vo.bspFullname){
    //   params = params.append('bspFullname', vo.bspFullname);
    // }
    // if(vo && vo.bspActive){
    //   params = params.append('bspActive', vo.bspActive);
    // }

    console.log(params);
    return this.apiRequest.get('api/Printers', params);

  }

  getItemByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/Printer/' + id);
  }

  addItem(vo: Printer): Observable<any> {
    return this.apiRequest.post('api/Printer', vo);
  }

  updateItem(vo: Printer): Observable<any> {
    return this.apiRequest.put('api/Printer', vo);
  }

  deleteItem(vo: Printer): Observable<any> {
    return this.apiRequest.delete('api/Printer/' + vo.id);
  }

  getAll(vo?: Printer): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/Printers/all');
  }
}
