import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Router } from '@angular/router';
import { catchError, map, Observable } from 'rxjs';
import { UserInfoService } from '../user-info.service';
import { AppConfig } from '../../app-config';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable()
export class ApiRequestEXService {

  constructor(private appConfig: AppConfig,
    private http: HttpClient,
    private router: Router,
    private userInfoService: UserInfoService) {
  }

  appendAuthHeader(): HttpHeaders {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const token = this.userInfoService.getStoredToken();
    if (token !== null) {
      headers = new HttpHeaders({ 'Content-Type': 'application/json', 'Authorization': token });
    }
    console.log('=======token=======' + token);
    return headers;
  }

  getBody(url: string, urlParams?: HttpParams, body?: Object): Observable<any> {
    const me = this;
    const headers = this.appendAuthHeader();
    return this.http.request('POST', me.appConfig.baseApiPath + url, {
      body: body,
      params: urlParams,
      responseType: 'json',
      headers: headers
    }).pipe(
      map(resp => resp),
      catchError(error => {
        if (error.status === 401 || error.status === 403) {
          me.router.navigate(['/logout']);
        }
        return throwError(error || 'Server error');
      }));
  }

  get(url: string, urlParams?: HttpParams): Observable<any> {
    console.log(urlParams);
    const me = this;
    const headers = this.appendAuthHeader();
    return this.http.request('GET', me.appConfig.baseApiPath + url, {
      params: urlParams === null ? null : urlParams,
      responseType: 'json',
      headers: headers
    }).pipe(
      map(resp => resp),
      catchError(err => {
        if (err.status === 401 || err.status === 403) {
          me.router.navigate(['/logout']);
        }
        return throwError(err || 'Server error');
      }));
  }

  getPlainText(url: string, urlParams?: HttpParams): Observable<any> {
    const me = this;
    const headers = this.appendAuthHeader();
    return this.http.request('GET', me.appConfig.baseApiPath + url, {
      params: urlParams,
      responseType: 'json',
      headers: headers
    }).pipe(
      map(resp => resp),
      catchError(error => {
        if (error.status === 401 || error.status === 403) {
          me.router.navigate(['/logout']);
        }
        return throwError(error || 'Server error');
      }));
  }

  post(url: string, body: Object): Observable<any> {
    const me = this;
    const headers = this.appendAuthHeader();
    return this.http.request('POST', me.appConfig.baseApiPath + url, {
      body: body,
      responseType: 'json',
      headers: headers
    }).pipe(
      map(resp => resp),
      catchError(error => {
        if (error.status === 401) {
          me.router.navigate(['/login'], { queryParams: { 'msg': 'failed' } });
        }
        return throwError(error || 'Server error');
      }));
  }

  put(url: string, body: Object): Observable<any> {
    const me = this;
    const headers = this.appendAuthHeader();
    return this.http.request('PUT', me.appConfig.baseApiPath + url, {
      body: body,
      responseType: 'json',
      headers: headers
    }).pipe(
      map(resp => resp),
      catchError(error => {
        if (error.status === 401) {
          me.router.navigate(['/logout']);
        }
        return throwError(error || 'Server error');
      }));
  }

  delete(url: string): Observable<any> {
    const me = this;
    const headers = this.appendAuthHeader();
    return this.http.request('DELETE', me.appConfig.baseApiPath + url, {
      responseType: 'json',
      headers: headers
    }).pipe(
      map(resp => resp),
      catchError(error => {
        if (error.status === 401) {
          me.router.navigate(['/logout']);
        }
        return throwError(error || 'Server error');
      }));
  }

}
