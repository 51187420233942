import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Dpc} from '../../../vo/dpc';
import {DpcService} from '../../../services/api/dpc.service';

@Component({
  selector: 'app-deletedpc',
  templateUrl: '../editdpc.component.html'
})
export class DeletedpcComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected service: DpcService) {
  }

  public dpcId: number;

  deleteOperation: boolean;
  vo: Dpc = new Dpc();

  public alerts: any = [];

  ngOnInit() {
    this.deleteOperation = true;
    this.route.params.subscribe((p: Params) => {
      this.dpcId = p['id'];
      const me = this;
      this.vo.id = this.dpcId;
      me.getDpc(this.dpcId);

    });
  }

  deleteDpc() {
    this.service.deleteDpc(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }

  updateDpc() {

  }

  backToDpc() {
    this.router.navigate(['/home/dpc']);
  }

  getDpc(id: number) {
    const me = this;
    this.service.getDpcByID(id).subscribe((data) => {
        console.log(data);
        this.vo = data.item;
      }
    );
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
}
