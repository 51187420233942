<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>SN Allocation Page</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered">

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered">
    <div class="col-12">
      <form class="" role="form">
        <div class="row">
          <div class=" col-4 mt-2">
            <label>Select request status</label>
            <select name="filter" class="ml-3" [(ngModel)]="qry.status" (change)="filter()">
              <option value="" selected="selected">All</option>
              <option value="PENDING">Pending</option>
              <option value="PROCESSED">Processed</option>
              <option value="REJECTED">Rejected</option>
            </select>
          </div>
          <div class="col-3 mt-2 ml-5 pl-5">
            <input type="submit" name="newAlloc" value="New Allocation" (click)="gotoNew()">

          </div>
        </div>

      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered table-hover">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E;">
          <th></th>
          <th></th>
          <th>Document type</th>
          <th>Bsp name</th>
          <th>Gds code</th>
          <th>Create Date</th>
          <th>Quantity</th>
          <th>Status</th>
          <th>Comment</th>
        </tr>
        </thead>
        <tbody *ngIf="allocationRequests.length > 0">
        <tr class="Waving1" *ngFor="let todo of allocationRequests">
          <td *ngIf="todo.status === 'PENDING'"><img border="0" src="../../../assets/SNAPDocroot/images/tableview/view.gif" alt="view" title="Accept" (click)="accept(todo)"></td>
          <td *ngIf="todo.status === 'PENDING'"><img border="0" src="../../../assets/SNAPDocroot/images/tableview/unfreeze.gif" alt="Reject" title="Reject this request" (click)="reject(todo)"></td>
          <td *ngIf="todo.status !== 'PENDING'"></td>
          <td *ngIf="todo.status !== 'PENDING'"></td>
          <td>{{todo.doctype.dtName}}</td>
          <td>{{todo.bsp.bspFullname}}</td>
          <td>{{todo.gdsCode}}</td>

          <td>{{todo.createdate | date:'dd-MM-yyyy'}}</td>
          <td>{{todo.quantity}}</td>
          <td>{{todo.status}}</td>
          <td>{{todo.note}}</td>
        </tr>
        </tbody>

        <tbody *ngIf="allocationRequests.length == 0">
        <tr class="Waving1">
          <td colspan="5">No Data...</td>
        </tr>
        </tbody>

      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->

  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->
