<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>BSP Calendar</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered">

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered">
    <div class="col-12">
      <form role="form" name="baseDynaForm" method="post"
        action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2Fbsp.do&_sorig=%2Fbsp.do">
        <input type="hidden" name="snaptable_oldfilterValues" value="" />
        <div class="row ml-1">
          <div class="form-group col-2">
            <label>BSP Code:</label>
            <input type="text" name="snaptable_bspCode" maxlength="10" value="" [(ngModel)]="qry.bspCode">
          </div>

          <div class="form-group col-2 ml-5">
            <label>BSP Name:</label>
            <input type="text" name="snaptable_bspFullname" maxlength="100" value="" [(ngModel)]="qry.bspFullname">
          </div>

          <div class="form-group col-2 ml-5">
            <label>DPC Name:</label>
            <input type="text" name="dpcCode" maxlength="100" value="" [(ngModel)]="qry.dpc.dpcCode">
          </div>

          <div class="form-group col-1 ml-5">
            <label>Ti Job: </label>
            <select [(ngModel)]="qry.bspCal.id" name="timezone">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of cals" [value]='item.id'>{{item.timezone}}</option>
            </select>
          </div>
          <div class="form-group col-3 mt-4 ml-5">
            <input type="submit" name="snaptable_filter" value="Filter" (click)="filter()">
            <input type="submit" name="batch_edit" value="Batch Edit" (click)="batchEdit()" />
          </div>
        </div>
      </form>

    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered">
        <thead>
          <tr style="background-color: #F2F5FC;color:#1B548E">
            <td title="Select all" width="20px">
              <input type="checkbox" name="all" [checked]="isAllChecked()" (change)="checkAll($event)" />
            </td>
            <td title="Edit record" width="20px"> </td>
            <td>BSP Code</td>
            <td>BSP Name</td>
            <td>DPC</td>
            <td>Timezone</td>
          </tr>
        </thead>
        <tbody *ngIf="bsps.length > 0">
          <tr class="Waving1" *ngFor="let todo of bsps">
            <td><input type="checkbox" [(ngModel)]="todo.checked" [value]="todo.id" name="cb_bsp" /></td>
            <td><img border="0" src="assets/SNAPDocroot//images/tableview/edit.gif" alt="Edit" (click)="gotoEdit(todo)"
                title="Modify this record"></td>
            <td>{{todo.bspCode}}</td>
            <td>{{todo.bspFullname}}</td>
            <td>{{todo.dpc.dpcCode}}</td>
            <td *ngIf="todo.bspCal">{{todo.bspCal.timezone}}</td>
            <td *ngIf="!todo.bspCal"></td>
          </tr>
        </tbody>
        <tbody *ngIf="bsps.length == 0">
          <tr class="Waving1">
            <td colspan="6">No Data...</td>
          </tr>
        </tbody>
      </table>
      <div class="col-md-6">
        <pagination [itemsPerPage]="perPages" [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage"
          (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm" [boundaryLinks]="true">
        </pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <div class="row form-group table-bordered">
    <div class="offset-3">
      <input type="submit" name="refresh" value="Refresh" (click)="filter()">
    </div>
  </div>
  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->

