// import {HashMap} from './hashMap’;

export interface HashMap {
  [details: string]: string;
}

export class TableCell {

  private CSS_CLASSES: HashMap = {};
  constructor(tdWidth: number
    , tdToolTip: string, cellStart: number,
    cellEnd: number, status: string) {
    this.CSS_CLASSES['SEL'] = 'selectedRange';
    this.CSS_CLASSES['AA'] = 'used';
    this.CSS_CLASSES['AB'] = 'used';
    this.CSS_CLASSES['I'] = 'used';
    this.CSS_CLASSES['D'] = 'blacklisted';
    this.CSS_CLASSES['F'] = 'free';
    this.CSS_CLASSES['RA'] = 'available';
    this.CSS_CLASSES['RQ'] = 'quarantined';

    this.width = tdWidth;
    this.cssClass = this.CSS_CLASSES[status];
    this.toolTip = tdToolTip + this.cssClass + ')';
    this.cellStart = cellStart;
    this.cellEnd = cellEnd;
    this.status = status;

  }

  private TABLE_CSS_CLASS = 'rangemap';
  // final static private String TR_CSS_CLASS = "rangemap";
  private width = 0;
  private cssClass: string = null;
  private toolTip: string = null;
  private startLine = false;
  private endLine = false;
  private cellStart: number;
  private cellEnd: number;
  private status: string; // values : 0,1,2,....

  /**
   * @return Returns the status.
   */
  public getStatus(): string {
    return this.status;
  }
  /**
   * @param status The status to set.
   */
  public setStatus(status: string) {
    this.status = status;
  }
  /**
   * @return Returns the cellEnd.
   */
  public getCellEnd(): number {
    return this.cellEnd;
  }
  /**
   * @param cellEnd The cellEnd to set.
   */
  public setCellEnd(cellEnd: number) {
    this.cellEnd = cellEnd;
  }
  /**
   * @return Returns the cellStart.
   */
  public getCellStart(): number {
    return this.cellStart;
  }
  /**
   * @param cellStart The cellStart to set.
   */
  public setCellStart(cellStart: number) {
    this.cellStart = cellStart;
  }

  public getCSSclass(): string {
    return this.cssClass;
  }
  public setCSSClass(c: string) {
    this.cssClass = c;
  }

  public getWidth(): number {
    return this.width;
  }

  public setWidth(w: number) {
    this.width = w;
  }

  public getToolTip(): string {
    return this.toolTip;
  }

  public setToolTip(tt: string) {
    this.toolTip = tt;
  }

  /**
   * set this table cell to start (or not) a new line.
   * @param flag
   */
  public startln(flag: boolean) {
    this.startLine = flag;
  }

  /**
   * set this table cell to end (or not) the current line.
   * @param flag
   */
  public endln(flag: boolean) {
    this.endLine = flag;
  }

  public toHTML(): string {
    let ret = '';

    if (this.startLine) {
      ret += '<table class="' + this.TABLE_CSS_CLASS + '" cellpadding="0" cellspacing="0" align="left">\n <tr class="rangemap">\n';
    }

    if (this.cssClass == 'blacklisted') {
      ret += '  <td class="' + this.cssClass + '" width="' + this.width + '" title="' + this.toolTip + '" onClick="detailRange(' + this.getCellStart() + ',' + this.getCellEnd() + ',\'' + this.getStatus() + '\');" onMouseOver="this.style.cursor = \'pointer\'; changeTdClass(\'' + this.toolTip + '\', \'clickedBlack\');" onMouseOut="changeTdClass(\'' + this.toolTip + '\', \'' + this.cssClass + '\');"><a name="' + this.toolTip + '"></a></td>\n';
    } else {
      ret += '  <td class="' + this.cssClass + '" width="' + this.width + '" title="' + this.toolTip + '" onClick="detailRange(' + this.getCellStart() + ',' + this.getCellEnd() + ',\'' + this.getStatus() + '\');" onMouseOver="this.style.cursor = \'pointer\'; changeTdClass(\'' + this.toolTip + '\', \'clicked\');" onMouseOut="changeTdClass(\'' + this.toolTip + '\', \'' + this.cssClass + '\');"><a name="' + this.toolTip + '"></a></td>\n';
    }

    if (this.endLine) {
      ret += ' </tr>\n</table><br class="crlf">\n';
    }

    return ret;
  }
  public toHTMLDetails(): string {
    let ret = '';

    if (this.startLine) {
      ret += '<table class="' + this.TABLE_CSS_CLASS + '" cellpadding="0" cellspacing="0" align="left">\n <tr class="rangemap">\n';
    }

    ret += '  <td class="' + this.cssClass + '" width="' + this.width + '" title="' + this.toolTip + '" onMouseOver="changeTdClass(\'' + this.toolTip + '\', \'clicked\');" onMouseOut="changeTdClass(\'' + this.toolTip + '\', \'' + this.cssClass + '\');"><a name="' + this.toolTip + '"></a></td>\n';

    if (this.endLine) {
      ret += ' </tr>\n</table><br class="crlf">\n';
    }

    return ret;
  }

}
