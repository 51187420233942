import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Bsp } from '../../vo/bsp';
import { UnmergeService } from '../../services/api/unmerge.service';
import { BspService } from '../../services/api/bsp.service';

@Component({
  selector: 'app-unmerge',
  templateUrl: './unmerge.component.html',
  styleUrls: ['./unmerge.component.css']
})
export class UnmergeComponent implements OnInit {

  bspInit: Bsp = new Bsp();
  bspFinal: Bsp = new Bsp();
  bspMerge: Bsp = new Bsp();
  bspInits: Bsp[] = [];
  bspFinals: Bsp[] = [];

  public alerts: any = [];
  id: number;
  code: string;
  constructor(private service: UnmergeService, private router: Router, private bspservice: BspService) { }

  ngOnInit() {
    const me = this;
    this.getBsps();
  }

  getBsps() {
    this.service.getItems().subscribe((data) => {
      console.log(data);
      this.bspInits = data.items;

    });
  }
  showdetails() {
    this.id = this.bspInit.id;
    this.service.getDetails(this.id).subscribe((data) => {
      console.log(data);
      this.bspFinals = data.items;
    });
  }
  entercode() {
    this.id = this.bspFinal.id;
    /*  this.bspservice.getItemByID(this.id).subscribe((data) => {
        console.log(data);
        this.bspMerge = data.item;
      });*/
    this.service.getItemsByID(this.id.toString()).subscribe((data) => {
      this.bspMerge.bspCode = data.date;

    });
  }
  validatecode() {
    this.code = this.bspMerge.bspCode;
    this.id = this.bspFinal.id;
    this.service.validate(this.code, this.id).subscribe((response) => {
      console.log(response);
      this.addMessage(response.operationMessage);
    });
  }
  public addMessage(message: string): void {

    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
  demerge() {
    this.service.mergeBsps(this.bspInit.id, this.bspFinal.id, this.bspMerge.bspCode).subscribe((response) => {
      console.log(response);
      this.addMessage(response.operationMessage);
    });
  }
}
