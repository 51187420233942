import { Component, OnInit } from '@angular/core';
import { Document } from '../../../vo/document';
import { Formcode } from '../../../vo/formcode';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormcodeService } from '../../../services/api/formcode.service';
import { IssuParty } from '../../../vo/issu-party';

@Component({
  selector: 'app-viewformcode-external',
  templateUrl: './viewformcode-external.component.html',
  styleUrls: ['./viewformcode-external.component.css']
})
export class ViewformcodeExternalComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
    protected router: Router,
    protected formcodeService: FormcodeService) { }

  public id: number;
  vo: Formcode = new Formcode();
  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      const me = this;
      this.vo.id = this.id;
      this.vo.documentType = new Document();
      this.vo.issueParty = new IssuParty();
      me.getPageData(0, 10, this.id);
    });
  }

  backToList() {
    this.router.navigate(['/gdshome/formcode-external']);
  }

  getPageData(page: number, size: number, id: number) {
    const me = this;
    this.formcodeService.getItemByID(id).subscribe((data) => {
      console.log(data);
      this.vo = data.item;
    }
    );
  }
}
