import { Pipe, PipeTransform } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Pipe({
  name: 'snapnum'
})
export class SnapnumPipe implements PipeTransform {

  transform(value: any): any {
    if (value == null) { return; }
    let strValue = value.toString();

    let temp = '';
    if (strValue.length < 10) {
      strValue = strValue.trim();
      temp = ('0000000000' + value).slice(-10);
    } else {
      return strValue;
    }


    return temp;
  }

}
