<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Subscribers Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered">

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>Create Subscriber</h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->
  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form #myForm="ngForm">
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="email"> Subscriber email :</label></div>
          <div class="col-sm-9">
            <input type="text" id="email" name="email" maxlength="100" size="20" [(ngModel)]="vo.email" required #email="ngModel">
            <font color="#ff0000" size="4">*</font>
          </div>
          <div class="md-errors-spacer" *ngIf="email.invalid && (email.dirty || email.touched)">
            <div *ngIf="email.errors.required" class="ui error message">
              Email is required.
            </div>
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="description">Subscriber description :</label></div>
          <div class="col-sm-9">
            <textarea id="description" name="description" cols="20" rows="5" [(ngModel)]="vo.description" required #description="ngModel"></textarea>
            <font color="#ff0000" size="4">*</font>
          </div>
          <div class="md-errors-spacer" *ngIf="description.invalid && (description.dirty || description.touched)">
            <div *ngIf="description.errors.required" class="ui error message">
              Subscriber description is required.
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->
  <!-- alert -->
  <div class="row form-group">
    <div class="ml-3 mt-3">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>
  </div>
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">

    <div class="offset-1">
      <input type="submit" name="update" value="Create" [disabled]="!myForm.form.valid" (click)="addNew()">
    </div>

    <div class="offset-3">
      <input type="submit" name="back" value="Cancel" (click)="backToListPage()">
    </div>
  </div>
  <!-- button end -->

  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
  </div>
  <!-- Form -->

</div>
<!-- body mt-3 -->
