import { Component, OnInit } from '@angular/core';
import {ServerConfig} from '../../vo/serverConfig';
import {SwitchConfigService} from '../../services/switch-config.service';

@Component({
  selector: 'app-master-node-switch',
  templateUrl: './master-node-switch.component.html',
  styleUrls: ['./master-node-switch.component.css']
})
export class MasterNodeSwitchComponent implements OnInit {

  serverConfig: ServerConfig = new ServerConfig();

  public title: string = 'Please Confirm';
  public gneerateMessage: string = 'Warring!!! The operation will effect the system job execution node, do you want to continue?';
  public cancelClicked: boolean = false;

  constructor(private myservice: SwitchConfigService) { }

  ngOnInit() {
    this.myservice.getMsaterNode().subscribe( (data) => {
      console.log(data);
      this.serverConfig = data;
    });
  }


  switch() {
    this.myservice.switchMasterNode(this.serverConfig).subscribe((data) => {
      console.log(data);
      this.refresh();
    });
  }

  refresh() {
    this.myservice.getMsaterNode().subscribe( (data) => {
      console.log(data);
      this.serverConfig = data;
    });
  }
}
