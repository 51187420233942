import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {  Gds} from '../../../vo/gds';
import { GdsService } from '../../../services/api/gds.service';

@Component({
  selector: 'app-newgds',
  templateUrl: './newgds.component.html',
  styleUrls: ['./newgds.component.css']
})
export class NewgdsComponent implements OnInit {

  vo: Gds = new Gds();
  public alerts: any = [];
  constructor(private service: GdsService, private router: Router) { }

  ngOnInit() {
  }
  addGds() {
    // this.dpcDataService.addDpc(this.newTodo);
    // this.todo = new Gds();
    console.log(this.vo);
    this.service.addItem(this.vo)
      .subscribe(
        (newTodo) => {
          console.log(newTodo);
          this.addMessage(newTodo.operationMessage);
        }
      );
  }

  backToGds() {
    this.router.navigate(['/home/gds']);
  }
  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
}
