// Module
import {NgModule} from '@angular/core';
import {SnformatPipe} from './Pipes/snformat.pipe';
import {SnapnumPipe} from './Pipes/snapnum.pipe';
import {SpinnerComponent} from './components/spinner/spinner.component';
import {CommonModule} from '@angular/common';


@NgModule({
  imports: [CommonModule],
  declarations: [
    SnformatPipe,
    SnapnumPipe,
    SpinnerComponent
  ]
  ,
  exports: [
    SnformatPipe,
    SnapnumPipe,
    SpinnerComponent
  ]
})



export class SharedModule {
}
