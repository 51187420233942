import {Component, OnInit, TemplateRef} from '@angular/core';
import {LoginService} from '../../services/api/login.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 's-login-pg',
  templateUrl: './login.component.html'
})

export class LoginComponent implements OnInit {
  model: any = {};
  errMsg = '';
  alerts: any = [];
  msg = '';

  constructor(private router: Router, protected route: ActivatedRoute, private loginService: LoginService) {
  }

  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      console.log(p);
      if (p.hasOwnProperty('msg')) {
        this.msg = p['msg'];
      }
    });
    // reset login status
    this.loginService.logout(false);
  }

  login() {
    this.loginService.getToken(this.model.username, this.model.password).subscribe(resp => {
      switch (resp.errorstatus) {
        case 0:
          // this.errMsg = 'Server Down';
          this.addMessage('Server Down');
          break;
        case 403:
          // this.errMsg = 'Username or password is incorrect';
          this.addMessage('Username or password is incorrect');
          break;
        case 401:
          // this.errMsg = 'Username or password is incorrect';
          this.addMessage('Username or password is incorrect');
          break;
        case 404:
          // this.errMsg = 'Service not found';
          this.addMessage('Service not found');
          break;
        case 408:
          // this.errMsg = 'Request Timedout';
          this.addMessage('Request Timedout');
          break;
        case 500:
          // this.errMsg = 'Internal Server Error';
          this.addMessage('Internal Server Error');
          break;
        default:
          // this.errMsg = 'Server Error';
          this.addMessage('Server Error');
      }
      if (resp.user === undefined || resp.user.token === undefined || resp.user.token === 'INVALID') {
        this.errMsg = 'Username or password is incorrect';
        return;
      }
      console.log(resp);
      this.router.navigate([resp.landingPage]);
    }, error => {
      switch (error.status) {
        case 401:
          this.errMsg = 'Username or password is incorrect';
          this.addMessage('Username or password is incorrect');
          break;
        case 404:
          this.errMsg = 'Service not found';
          this.addMessage('Service not found');
        case 408:
          this.errMsg = 'Request Timedout';
          this.addMessage('Request Timedout');
        case 500:
          this.errMsg = 'Internal Server Error';
          this.addMessage('Internal Server Error');
        default:
          this.errMsg = 'Server Error';
          this.addMessage('Server Error');
      }
    });

  }

  onSignUp() {
    this.router.navigate(['signup']);
  }

  public addMessage(message: string): void {
    this.alerts = [];
    this.alerts.push({
      type: 'danger',
      msg: message,
      timeout: 10000
    });
  }

  forgotPassword() {
    this.router.navigate(['forgotpwd']);
  }

}
