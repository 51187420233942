// Module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CKEditorModule } from 'ng2-ckeditor';
import { ExtRoutingModule } from './ext-routing.module';
// Component
import { GdshomeComponent } from '../pages/gdshome/gdshome.component';
import { AllocationRequestComponent } from './allocationrequest/allocation-request.component';
import { AllocationRequestCancelComponent } from './allocationrequest/allocation-request-cancel.component';
import { AllocationRequestConfirmComponent } from './allocationrequest/allocation-request-confirm.component';
import { ChangepwdComponent } from './changepwd/changepwd.component';
import { DashindexComponent } from './dashindex/dashindex.component';
// Service
import { AllocationRequestService } from '../services/api/allocationRequest.service';
import { ChangepwdService } from '../services/api/changepwd.service';
import { GdsmenuComponent } from '../pages/gdsmenu/gdsmenu.component';
import { SharedModule } from '../shared.module';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';

@NgModule({
    declarations: [
        GdshomeComponent,
        GdsmenuComponent,
        AllocationRequestComponent,
        AllocationRequestCancelComponent,
        AllocationRequestConfirmComponent,
        ChangepwdComponent,
        DashindexComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        // Thirdparty Module
        PaginationModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AlertModule.forRoot(),
        CKEditorModule,
        ExtRoutingModule,
        SharedModule,
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger' // set defaults here
        })],
    providers: [
        AllocationRequestService,
        ChangepwdService,
        provideHttpClient(withInterceptorsFromDi())
    ]
})

export class ExtModule {
}
