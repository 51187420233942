import {Component, OnInit} from '@angular/core';
import {TransferService} from '../../services/api/transfer.service';
import {AllocateStats} from '../../vo/allocateStats';
import {Bsp} from '../../vo/bsp';
import {BspService} from '../../services/api/bsp.service';
import {Retrieve} from '../../vo/retrieve';
import {Reason} from '../../vo/reason';
import {SendMailService} from '../../services/api/send-mail.service';
import {MailVo} from '../../vo/mailVo';

@Component({
  selector: 'app-retrieve',
  templateUrl: './retrieve.component.html',
  styleUrls: ['./retrieve.component.css']
})
export class RetrieveComponent implements OnInit {
  public alerts: any = [];
  isValideRangeForRetrieve = true;
  mailMessage: MailVo = new MailVo();

  qry: Retrieve = new Retrieve();
  stats: AllocateStats[] = [];
  stage = 0;
  bsps: Bsp[] = [];
  reasons: Reason[] = [];

  constructor(protected service: TransferService, private bspService: BspService, private mailService: SendMailService) {
  }

  ngOnInit() {
    this.stage = 0;
    this.bspService.getAllBsps().subscribe((data) => {
      console.log(data);
      this.bsps = data.items;
      console.log(this.bsps);
    });
  }

  preRetrieve() {
    // refresh previous check status
    this.stats = [];
    this.stage = 0;
    this.bsps = [];
    this.reasons = [];
    this.isValideRangeForRetrieve = true;

    if (this.qry.endNumber < this.qry.startNumber) {
      this.addMessage('The serial number are not specified correctly.', 'danger');

      this.stats = [];
      this.stage = 0;
      this.bsps = [];
      this.reasons = [];
      return;

    }

    this.service.preRetrieveSNCheck(this.qry.startNumber, this.qry.endNumber).subscribe((data) => {
        if (data.operationStatus != 'ERROR') {
          if (data.snStatus == 'ERROR') {
            this.isValideRangeForRetrieve = false;
            this.stats = data.allocStats;
          } else {
            this.stage = 1;
            this.qry = data.item;
            this.reasons = data.reasons;

            this.qry.quarantined = false;
            this.qry.reasonCode = 'EA';
          }

          // this.bigTotalItems = data.totalItems;
        }
        console.log(data);

      }
    );

  }

  retrieve() {
    this.stage = 2;
    console.log(this.qry);
    this.service.retrieveSn(this.qry.fromAllocationId, this.qry.startNumber, this.qry.endNumber, this.qry.quarantined, this.qry.reasonCode).subscribe((data) => {
        console.log(data);
        if (data.operationStatus != 'ERROR') {
          // this.qry = data.item;
          // this.bigTotalItems = data.totalItems;
          this.mailMessage = data.mailMessage;
          this.addMessage(data.operationMessage, 'success');
        }
      }
    );

  }

  cancel() {
    this.stage = 0;
    const startNumber: string = this.qry.startNumber;
    const endNumber: string = this.qry.endNumber;
    this.qry = new Retrieve();
    this.qry.startNumber = startNumber;
    this.qry.endNumber = endNumber;

  }

  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: 10000
    });
  }


  cancelSending() {
    // alert('cancel sending');
  }

  sendOutMail() {
    this.mailService.sendEmail(this.mailMessage).subscribe(
      (response) => {
        console.log(response);
        // alert(response.operationMessage);
        this.addMessage(response.operationMessage, 'success');
      }
    );
    this.stage = 3;
  }
}
