import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fcStatus'
})
export class FcStatusPipe implements PipeTransform {

  transform(value: string): any {
    if (value === 'O') {
      return 'Opened';
    } else if (value === 'F') {
      return 'Frozen';
    } else {
      return 'Recycled';
    }
  }

}
