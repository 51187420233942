import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BspService } from '../../../services/api/bsp.service';
import { Calendar } from '../../../vo/calendar';
import { Bsp } from '../../../vo/bsp';
import { CalendarService } from '../../../services/api/calendar.service';

@Component({
  selector: 'app-bsp-edit-cal',
  templateUrl: './bsp-edit-cal.component.html',
  styleUrls: ['./bsp-edit-cal.component.css']
})
export class BspEditCalComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
    protected router: Router,
    protected bspService: BspService,
    private calService: CalendarService) {
  }

  public id: number;

  vo: Bsp = new Bsp();
  cal: Calendar = new Calendar();
  cals: Calendar[] = [];

  public alerts: any = [];

  ngOnInit() {
    this.vo.bspCal = this.cal;
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.getItem(this.id);
    });
    this.calService.getAll().subscribe((data) => {
      console.log(data);
      this.cals = data.items;
      console.log(this.cals);
    });
  }

  getItem(id: number) {
    this.bspService.getItemByID(id).subscribe((data) => {
      this.vo = data.item;
      if (!this.vo.bspCal) {
        this.vo.bspCal = this.cal;
      }
    }
    );
  }

  update() {
    if (!this.vo.bspCal || !this.vo.bspCal.id) {
      alert('Timezone is required.');
      return;
    }
    this.bspService.updateItem(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }

  backToListPage() {
    this.router.navigate(['/home/bspWithCal']);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
}
