<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>GDS Maintenance</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered" >
    <div class="col-12">
      <form role="form" name="baseDynaForm" method="post" action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2Fgds.do&_sorig=%2Fgds.do">
        <input type="hidden" name="snaptable_oldfilterValues" value="" />
        <div class="row">
          <div class="form-group col-4 mt-3">
            <label for="snaptable_gdsCode">GDS code:</label>
            <input type="text" name="snaptable_gdsCode" maxlength="10" value="" [(ngModel)]="qry.gdsCode" >
          </div>

          <div class="form-group col-4 mt-3">
            <label for="snaptable_gdsSendtifile">Send TI file:</label>
            <select name="snaptable_gdsSendtifile" [(ngModel)]="qry.gdsSendtifile">
              <option value=""  selected="selected" >-</option>
              <option value="1">yes</option>
              <option value="0"  >no</option>
            </select>
          </div>

          <div class="form-group col-4 mt-3">
            <input type="submit" name="snaptable_filter" value="Filter" (click)="filter()">
          </div>
        </div>
      </form>

    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E">
          <td title="Edit record" width="20px" > </td>
          <td title="Delete record" width="20" > </td>
          <td>GDS code</td>
          <td>Email Address</td>
          <td>GDS IINET Account</td>
          <td>Send TI file</td></tr>
        </thead>
        <tr class="Waving1" *ngFor="let todo of gdss">
          <td><IMG border="0" src="../../../assets/SNAPDocroot/images/tableview/edit.gif" alt="Edit" (click)="gotoEdit(todo)" title="Modify this record" /></td>
          <td><IMG border="0" src="../../../assets/SNAPDocroot/images/tableview/delete.gif" alt="Delete" (click)="removeTodo(todo)" title="Delete this record" /></td>
          <td>{{todo.gdsCode}}</td>
          <td>{{todo.gdsIinetAccount}}</td>
          <td>{{todo.gdsEmail}}</td>
          <td *ngIf="todo.gdsSendtifile==1"><input type='checkbox' checked="checked" disabled />
          <td *ngIf="todo.gdsSendtifile==0"><input type='checkbox'  disabled />
        </tr>

      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <div class="row form-group table-bordered">
    <div class="offset-1">
      <input type="submit" name="create" value="Create" (click)="gotoNew()">
    </div>
    <div class="offset-3">
      <input type="submit" name="refresh" value="Refresh" (click)="filter()" >
    </div>
  </div>
  <!-- button end -->
  <!-- table -->
</div>
