export class Subscriber {

  id: number;
  email: string;
  description: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
