import {Calendar} from './calendar';

export class SwitchConfig {

  id: number;
  switchCode: string;
  switchValue: string;
  createDate: Date;
  flag: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
