import { Component, OnInit } from '@angular/core';
import { Document } from '../../../vo/document';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DocumentTypeService } from '../../../services/api/documentType.service';

@Component({
  selector: 'app-update-doc',
  templateUrl: './update-doc.component.html',
  styleUrls: ['./update-doc.component.css']
})
export class UpdateDocComponent implements OnInit {

  constructor(  protected route: ActivatedRoute,
                protected router: Router,
                protected service: DocumentTypeService) { }

  public id: number;
  public action: String;
  public isupdate: boolean;
  vo: Document = new Document();
  public alerts: any = [];
  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.action = p['action'];
      if (this.action === 'U') {
        this.isupdate = true;
      } else {
        this.isupdate = false;
      }
      this.vo.id = this.id;
      this.getPageData(this.id);

    });
  }

  update() {
    this.service.updateDoc(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }
  delete() {
    this.service.deleteDoc(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }

  backToList() {
    this.router.navigate(['/home/doc']);
  }

  getPageData(id: number) {
    this.service.getDocByID(id).subscribe((data) => {
        console.log(data);
        this.vo = data.item;
      }
    );
  }
  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

  statusChange(event: any) {
   if (this.vo.dtStatus === 'active') {
     this.vo.dtStatus = 'inactive';
   } else {
     this.vo.dtStatus = 'active';
   }

  }
  excludeChange(event: any) {
    if (this.vo.excluded === 'Y') {
      this.vo.excluded = 'N';
    } else {
      this.vo.excluded = 'Y';
    }
  }
}
