import {Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';
import {AllocationRequestService} from '../../services/api/allocationRequest.service';
import {AllocationRequest} from '../../vo/allocationRequest';
import {Bsp} from '../../vo/bsp';
import {Document} from '../../vo/document';

@Component({
  selector: 'app-allocation-request-confirm',
  templateUrl: './allocation-request-confirm.component.html',
  styleUrls: ['./allocation-request-confirm.component.css']
})

export class AllocationRequestConfirmComponent implements OnInit {
  style = '';

  constructor(protected router: Router, private service: AllocationRequestService, private location: Location) {
  }
  allocationRequest: AllocationRequest;
  calResult = 0;
  oneHistoryData = false;
  disableProceed = false;

  alerts: any = [];

  ngOnInit() {
    this.allocationRequest = this.service.newRequest;
    this.calResult = this.service.calResult;
    this.oneHistoryData = this.service.oneHistoryData;
  }

  submitRequest() {
    this.disableProceed = true;
    this.style = 'color: grey';
    this.service.submitRequest(this.allocationRequest).subscribe((response) => {
      console.log(response);
      console.log(this.allocationRequest);
      if (response.operationStatus === 'SUCCESS') {
        this.backToList(response.operationMessage);
      } else {
        this.addMessage(response.operationMessage);
      }
    });
  }

  back(): void {
    this.location.back();
  }

  backToList(message: string) {
    let message1 = message.substring(0, message.indexOf(':') + 1);
    let message2 = message.substring(message.indexOf(':') + 1, message.length);
    this.router.navigate(['/gdshome/allocationreq'], {queryParams: {'msg1': message1, 'msg2': message2}});
  }

  addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 60000
    });
  }
}
