<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<!-- title -->
<div class="row" *ngIf="stage == 0">
  <div class="col-10 offset-1">
    <H1>TI File Generation</H1>
  </div>
</div>
<!-- title -->

<div class="col-10 offset-1 table-bordered" *ngIf="stage == 0">
  <!-- Query form Start-->
  <div class="row table-bordered">
    <div class="col-12">
      <div class="row ml-1">
        <div class="form-group col-10 ml-5 mt-3">
          <label>BSP: </label>
          <select [(ngModel)]="vo.bspCode" name="bsp">
            <option value="" selected="selected">-</option>
            <option *ngFor="let item of bsps" [value]='item.bspCode'>{{item.bspFullname}} / {{item.bspCode}}</option>
          </select>
        </div>
      </div>

      <div class="row ml-1">
        <div class="form-group col-3 ml-5">
          <label>Start Time:</label>
          <input type="text" readonly name="snaptable_bspCode" maxlength="20" value="" [(ngModel)]="start"
                 [minDate]="minDate" [maxDate]="maxDate" #dp="bsDatepicker" bsDatepicker>
        </div>

        <div class="form-group col-3 ml-5">
          <label>End Time:</label>
          <input type="text" readonly name="snaptable_bspCode" maxlength="20" value="" [(ngModel)]="end"
                 [minDate]="minDate" [maxDate]="maxDate" #dp="bsDatepicker" bsDatepicker>
        </div>

        <div class="form-group col-3 mt-2">
          <button mwlConfirmationPopover
                 [popoverTitle]="title"
                 [popoverMessage]="gneerateMessage"
                 placement="bottom"
                 (confirm)="generate()"
                 (cancel)="cancelClicked = true">Generate</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Query form End-->
</div>

<div>
  <div *ngIf="stage == 2">
    <div *ngFor="let alert of alerts">
      <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
        }}
      </alert>
    </div>
  </div>
  <app-newmail (cancel)="cancelSending()" (sendMail)="sendOutMail()" [mailMessage]="mailMessage"
               *ngIf="stage == 1"></app-newmail>
</div>
