<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Recycle Page</H1>
  </div>
</div>
<!-- title -->
<div *ngFor="let alert of alerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
    }}
  </alert>
</div>
<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" *ngIf="stage == 0">

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered">
    <div class="col-8">
      <form class="" role="form">
        <div class="row">
          <div class="col-2 mt-2 form-group ">
            <select name="criteriaCombo" [(ngModel)]="status">
              <option value="all">All frozen formcodes</option>
              <option value="ready">Ready formcodes</option>
            </select>
          </div>
          <div class="mt-2 ml-5  form-group"><input type="submit" (click)="filter()" name="submitCriteria"
                                                    value="Filter formcodes"></div>
        </div>

      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered table-hover">
        <thead>
        <tr style="background-color:#F2F5FC">
          <td title="view record" width="20px"></td>
          <td>Form code</td>
          <td>Create Date</td>
          <td>Frozen Date</td>
          <td>Frozen For</td>
        </tr>
        </thead>
        <tbody *ngIf="recycles.length > 0">
        <tr class="Waving1" *ngFor="let todo of recycles">
          <td><img border="0" src="assets/SNAPDocroot//images/tableview/edit.gif" alt="Edit" (click)="gotoEdit(todo)"
                   title="Modify this record"></td>
          <td>{{todo.fcNumber}}</td>
          <td>{{todo.fcCreatedate | date :'dd-MM-yyyy'}}</td>
          <td>{{todo.fcFrozen | date :'dd-MM-yyyy'}}</td>
          <td>{{todo.fcFrozenFor}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="recycles.length == 0">
        <tr class="Waving1">
          <td colspan="5">No Data...</td>

        </tr>
        </tbody>
      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)"
                    [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <div class="row form-group table-bordered">
    <div class="ml-3">
      <input type="submit" name="refresh" value="Refresh">
    </div>
  </div>
  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->


<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" *ngIf="stage != 0">

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1 *ngIf="stage == 1">
        Form code details
      </h1>

      <h1 *ngIf="stage == 2">
        Redefine the form code
      </h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0" *ngIf="stage != 2">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcCode">Form Code</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCode" id="fcCode" maxlength="3" size="20" [value]="qry.fcNumber"
                   readonly="readonly">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcDocTypeID">Document type</label></div>
          <div class="col-sm-9">
            <select name="fcDocTypeID" id="fcDocTypeID" disabled="disabled" [(ngModel)]="qry.documentType.id">
              <option [value]='qry.documentType.id' selected="selected">{{qry.documentType.dtName}}
                / {{qry.documentType.dtType | cgn}}</option>
            </select>
          </div>
        </div>




        <div class="row form-group ">
          <div class="col-sm-3 "><label for="issueParty">Issuing Party</label></div>
          <div class="col-sm-9">
            <select name="issueParty" id="test" disabled="disabled" [(ngModel)]="qry.issueParty.id">
              <option [value]='qry.issueParty.id' selected="selected">{{qry.issueParty.name}}</option>
            </select>
          </div>
        </div>



        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcCode">Resolution</label></div>
          <div class="col-sm-9">
            <textarea name="fcResolution" cols="30" rows="8" readonly="readonly">{{qry.fcReso}}</textarea>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-3 "><label for="fcCreateDate">Creation date</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCreateDate" id="fcCreateDate1" size="20"
                   [value]='qry.fcCreatedate | date:"dd/MM/yy"' readonly="readonly">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcFrozenDate">Frozen date</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcFrozenDate" size="20" [value]='qry.fcFrozen | date:"dd/MM/yy"'
                   readonly="readonly">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcComment">Comment</label></div>
          <div class="col-sm-9">
            <textarea name="fcComment" cols="30" rows="8" readonly="readonly">{{qry.fcComment}}</textarea>
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-9" *ngIf="buttonStatus != 'R'">
            The formcode is not yet ready for recycle<br>(there are ranges not entirely used).
          </div>
          <div class="col-sm-9" *ngIf="buttonStatus == 'R'">
            The formcode is ready for recycle.
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-9" *ngIf="buttonStatus == 'A'">
            There still are allocated ranges that should be retrieved.
          </div>
          <div class="col-sm-9" *ngIf="buttonStatus != 'A'">
            There are no ranges to be retrieved.
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->


  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0" *ngIf="stage == 2">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcCode">Form Code</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCode" id="fcCode" maxlength="3" size="20" [value]="qry.fcNumber"
                   readonly="readonly">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcDocTypeID">Document type</label></div>
          <div class="col-sm-9">
            <select name="fcDocTypeID" id="fcDocTypeID" [(ngModel)]="qry.documentType.id">
              <option *ngFor="let item of docs" [value]='item.id'>{{item.dtName}} / {{item.dtType | cgn}}</option>
            </select>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcCode">Resolution</label></div>
          <div class="col-sm-9">
            <textarea name="fcResolution" cols="30" rows="8">{{qry.fcReso}}</textarea>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-3 "><label for="fcCreateDate">Creation date</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCreateDate" id="fcCreateDate" size="20"
                   [value]='qry.fcCreatedate | date:"dd/MM/yy"' readonly="readonly">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcFrozenDate">Frozen date</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcFrozenDate" size="20" [value]='qry.fcFrozen | date:"dd/MM/yy"'
                   readonly="readonly">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcComment">Comment</label></div>
          <div class="col-sm-9">
            <textarea name="fcComment" cols="30" rows="8">{{qry.fcComment}}</textarea>
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-9" *ngIf="buttonStatus != 'R'">
            The formcode is not yet ready for recycle<br>(there are ranges not entirely used).
          </div>
          <div class="col-sm-9" *ngIf="buttonStatus == 'R'">
            The formcode is ready for recycle.
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-9" *ngIf="buttonStatus == 'A'">
            There still are allocated ranges that should be retrieved.
          </div>
          <div class="col-sm-9" *ngIf="buttonStatus != 'A'">
            There are no ranges to be retrieved.
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->
  <div class="row form-group">
    <div class="ml-3 mt-3">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>
  </div>
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="offset-1">
      <input type="submit" name="back" (click)="cancel()" value="Cancel">
    </div>

    <div class="offset-1" *ngIf="buttonStatus == 'R' && stage == 1">
      <input type="submit" name="recycle" (click)="gotoRecycle()" value="Recycle">
    </div>
    <div class="offset-1" *ngIf="buttonStatus == 'R' && stage == 2">
      <input type="submit" name="redefine" (click)="redefine()" value="Redefine">
    </div>
    <div class="offset-1" *ngIf="buttonStatus == 'A' || buttonStatus == 'O'">
      <input type="submit" name="retrieve" (click)="autoRetrieve()" value="Auto-retrieve and send reminders">
    </div>
    <div class="offset-1">
      <button *ngIf="buttonStatus == 'A' || buttonStatus == 'O'"
             mwlConfirmationPopover
             [popoverTitle]="title"
             [popoverMessage]="message"
             placement="top"
             (confirm)="overrideRecycle()"
             (cancel)="cancelClicked = true">Override and Recycle</button>
    </div>
  </div>
  <!-- button end -->

  <!-- Form -->

</div>
<!-- body mt-3 -->
