import {Component, OnInit} from '@angular/core';
import {IssuePartyService} from '../../../services/api/issue-party.service';
import {IssuParty} from '../../../vo/issu-party';
import {Router} from '@angular/router';

@Component({
  selector: 'app-create-issue-party',
  templateUrl: './create-issue-party.component.html',
  styleUrls: ['./create-issue-party.component.css']
})
export class CreateIssuePartyComponent implements OnInit {
  issu: IssuParty = new IssuParty();
  public alerts: any = [];
  issus: IssuParty[] = [];

  constructor(private issuService: IssuePartyService, private router: Router) {
  }

  ngOnInit() {

    this.issuService.getIssuingPartys().subscribe((data) => {
      console.log(data);
      this.issus = data.items;
      console.log(this.issus);
    });
  }

  addIssueParty() {
    if (this.issu.name == null || this.issu.name == '') {
      alert('Issue Party name is required');
      return;
    }
    console.log(this.issu);
    this.issuService.AddIssu(this.issu).subscribe(
      (issu) => {
        console.log(issu);
        this.addMessage(issu.operationMessage);
      }
    );
  }

  backToList() {
    this.router.navigate(['/home/issueParty']);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
}
