import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Formcode } from '../../vo/formcode';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class FormcodeService {

  bigCurrentPage = 1;

  constructor(private apiRequest: ApiRequestEXService) { }

  getPages(page?: number, size?: number, vo?: Formcode): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (vo && vo.fcNumber) {
      params = params.append('fcNumber', vo.fcNumber);
    }
    if (vo && vo.documentType.id) {
      params = params.append('docId', vo.documentType.id.toString());
    }
    if (vo && vo.fcStatus) {
      params = params.append('fcStatus', vo.fcStatus);
    }
    return this.apiRequest.get('api/Formcodes', params);
  }

  getPagesOpened(page?: number, size?: number, vo?: Formcode): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    params = params.append('fcStatus', 'O');
    return this.apiRequest.get('api/Formcodes', params);
  }

  getPagesOpenedByDocType(page?: number, size?: number, vo?: Formcode): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    params = params.append('fcStatus', 'O');
    if (vo && vo.documentType.id) {
      params = params.append('docId', vo.documentType.id.toString());
    }
    return this.apiRequest.get('api/Formcodes', params);
  }

  getItemByID(id: number): Observable<any> {
    return this.apiRequest.get('api/Formcode/' + id);
  }

  update(vo: Formcode): Observable<any> {
    return this.apiRequest.put('api/UpdateFormcode', vo);
  }

  canEdit(id: number): Observable<any> {
    return this.apiRequest.get('api/FormcodeEditable/' + id);
  }

  addItem(vo: Formcode): Observable<any> {
    return this.apiRequest.post('api/Formcode', vo);
  }

  openFormcode(vo: Formcode): Observable<any> {
    return this.apiRequest.put('api/OpenFormcode', vo);
  }

  freezeFormcode(vo: Formcode): Observable<any> {
    return this.apiRequest.put('api/FreezeFormcode', vo);
  }

  unfreezeFormcode(vo: Formcode): Observable<any> {
    return this.apiRequest.put('api/UnfreezeFormcode', vo);
  }

  splitFormcode(vo: Formcode): Observable<any> {
    return this.apiRequest.put('api/SplitFormcode', vo);
  }

  getLineData(): Observable<any> {
    return this.apiRequest.get('api/linedata');
  }

  getLineData1(createRatio: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('createRatio', createRatio.toString());
    return this.apiRequest.get('api/linedata1', params);
  }

  getBarData(): Observable<any> {
    return this.apiRequest.get('api/bardata');
  }

  getPieData(): Observable<any> {
    return this.apiRequest.get('api/piedata');
  }

  getPieDataById(id: number, documentTypeName: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (id != null && id !== -1) {
      params = params.append('formcodeId', id.toString());
    }
    if (documentTypeName != null) {
      params = params.append('documentTypeName', documentTypeName);
    }
    return this.apiRequest.get('api/piedataByFormcode', params);
  }

  getFrozenFormcode(page: number, size: number, vo: Formcode, num: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof size === 'number' ? size.toString() : '1000');
    params = params.append('num', num);
    if (vo && vo.fcNumber) {
      params = params.append('fcNumber', vo.fcNumber);
    }
    return this.apiRequest.get('api/Formcodes/frozenRecycle', params);
  }

  recycle(id: number) {
    return this.apiRequest.post('api/frozenFormcode/recycle', id);
  }
}
