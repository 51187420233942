import {Gds} from './gds';
import {Printer} from './printer';
import {DocumentSubType} from './documentSubType';
import {Document} from './document';
import {Bsp} from './bsp';
import {Snstatus} from './snstatus';
import {Opstatus} from './opstatus';
import {FormHistory} from './formHistory';

export class PrintCurrentStatusVo {

  formcode: string;
  doc: string;
  subDoc: string;
  StartEndNumber: string;
  quantity: string;
  statusCode: string;
  bspCode: string;
  gdsCode: string;
  print: string;
  validate: string;
  purOderNum: string;
  schDate: string;
  snapNo: string;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
