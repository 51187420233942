<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Form Code Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered">

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>
        View Record
      </h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form role="form">
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcCode">Form Code</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCode" id="fcCode" maxlength="3" size="20" [(ngModel)]="vo.fcNumber" readonly>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label>Document type</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcDocTypeID" id="fcDocTypeID" maxlength="3" size="20" [value]="vo.documentType.dtName" readonly>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-sm-3 "><label>Issuing Party</label></div>
          <div class="col-sm-9">
            <input type="text" name="issueParty" id="issueParty" maxlength="3" size="20" [value]="vo.issueParty?.name" readonly>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-sm-3 "><label>Resolution</label></div>
          <div class="col-sm-9">
            <textarea name="fcResolution" cols="40" rows="8" readonly="readonly">{{vo.fcReso}}</textarea>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label>Creation date</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCreateDate" size="20" [value]='vo.fcCreatedate | date:"dd/MM/yy"'
                   readonly="readonly">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-3"><label>Comment</label></div>
          <div class="col-sm-9">
            <textarea name="fcComment" cols="40" rows="8" readonly="readonly">{{vo.fcComment}}</textarea>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcFrozenDate">Frozen date</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcFrozenDate" id="fcFrozenDate" size="20" [value]='vo.fcFrozen | date:"dd/MM/yy"'
                   readonly="readonly">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcFrozenFor">Years until recycle</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcFrozenFor" id="fcFrozenFor" maxlength="2" size="5" [(ngModel)]="vo.fcFrozenFor"
                   readonly="readonly">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label>Is Active</label></div>
          <div class="col-sm-9">
            <input type="checkbox" disabled="" checked="">
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="offset-1">

    </div>
    <div class="offset-3">
      <input type="button" name="back" value="Back" (click)="backToList()">
    </div>
  </div>
  <!-- button end -->

  <!-- Form -->

</div>
<!-- body mt-3 -->
