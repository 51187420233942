<div *ngFor="let alert of alerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
</div>

<form *ngIf="allocationRequest">
  <div class="row col-7">
    <div class="col-4 table-bordered pt-2 pb-2 left">
      Document type
    </div>
    <div class="col-7 table-bordered pt-2 pb-2 right">
      {{allocationRequest.doctype.dtName}}
    </div>
  </div>
  <div class="row col-7">
    <div class="col-4 table-bordered pt-2 pb-2 left">
      BSP Name
    </div>
    <div class="col-7 table-bordered pt-2 pb-2 right">
      {{allocationRequest.bsp.bspFullname}}
    </div>
  </div>
  <div class="row col-7">
    <div class="col-4 table-bordered pt-2 pb-2 left">
      Create Date
    </div>
    <div class="col-7 table-bordered pt-2 pb-2 right">
      {{allocationRequest.createdate | date}}
    </div>
  </div>
  <div class="row col-7">
    <div class="col-4 table-bordered pt-2 pb-2 left">
      Quantity
    </div>
    <div class="col-7 table-bordered pt-2 pb-2 right">
      {{allocationRequest.quantity}}
    </div>
  </div>
  <div class="row col-7">
    <div class="col-4 table-bordered pt-2 pb-2 left">
      Note
    </div>
    <div class="col-7 table-bordered pt-2 pb-2 right">
      <textarea [(ngModel)]="allocationRequest.note" name="note" cols="60"></textarea>
    </div>
  </div>
  <div class="row col-7">
    <div class="col-4 mt-1 mb-1 left">
      <input type="submit" name="submitBtn" value="Submit" (click)="cancel()">
    </div>
    <div class="col-7 mt-1 mb-1 right">
      <input type="submit" name="cancelBtn" value="Cancel" (click)="back()">
    </div>
  </div>
</form>
