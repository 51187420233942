import {Component, OnInit} from '@angular/core';
import {FormcodeService} from '../../services/api/formcode.service';
import {Pie} from '../../vo/pie';
import {Coordinate} from '../../vo/coordinate';
import {Formcode} from '../../vo/formcode';

declare var CanvasJS: any;

@Component({
  selector: 'app-dash-fc-usage',
  templateUrl: './dash-fc-usage.component.html',
  styleUrls: ['./dash-fc-usage.component.css']
})
export class DashFcUsageComponent implements OnInit {

  pie: Pie = new Pie();
  formcode: Formcode = new Formcode();
  opetFcs: Formcode[] = [];
  opemdFcs: Formcode[] = [];
  tasfFcs: Formcode[] = [];
  action: string;
  selectedOpetID: number;
  selectedOpemdID: number;
  selectedTasfID: number;

  constructor(private myservice: FormcodeService) {
  }

  ngOnInit() {
    // this.pie = new Pie();
    this.pie.emdAll = new Coordinate(0, 0);
    this.pie.opetAll = new Coordinate(0, 0);
    this.pie.tasfAll = new Coordinate(0, 0);
    this.pie.fcOne = new Coordinate(0, 0);
    this.pie.opetFcs = this.opetFcs;
    this.pie.opemdFcs = this.opemdFcs;
    this.pie.tasfFcs = this.tasfFcs;
    this.selectedOpetID = -1;
    this.selectedOpemdID = -1;
    this.selectedTasfID = -1;
    this.myservice.getPieData().subscribe((data) => {
      if (data.operationStatus === 'ERROR' ) {
        alert('error happens');
        return;
      }
      this.pie = data.item;
      console.log(data);
      this.chart();
      this.chart2();
      this.chart3();
    });


  }

  chart() {
    const chart = new CanvasJS.Chart('chart_Opet',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPET Usage'
        },
        legend: {
          horizontalAlign: 'right', // "center" , "right"
          verticalAlign: 'bottom',  // "top" , "bottom"
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        theme: 'theme2',
        data: [
          {
            type: 'pie',
            radius: '95%',
            yValueFormatString: '#0.00',
            indexLabelFontFamily: 'Garamond',
            indexLabelFontSize: 14,
            indexLabel: '{label} {y}%',
            startAngle: -30,
            showInLegend: true,
            toolTipContent: ' {legendText} {y}%',
            dataPoints: [
              {y: this.pie.opetAll.x, legendText: 'Used', label: 'Used', color: '#E25740'},
              {y: this.pie.opetAll.y, legendText: 'Available', label: 'Ava', color: '#4876FF'}
            ]
          }
        ]
      });
    chart.render();
  }


  chart2() {
    const chart = new CanvasJS.Chart('chart_Opemd',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPEMD Usage'
        },
        legend: {
          horizontalAlign: 'right', // "center" , "right"
          verticalAlign: 'bottom',  // "top" , "bottom"
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        theme: 'theme2',
        data: [
          {
            type: 'pie',
            radius: '95%',
            yValueFormatString: '#0.00',
            indexLabelFontFamily: 'Garamond',
            indexLabelFontSize: 14,
            indexLabel: '{label} {y}%',
            startAngle: -30,
            showInLegend: true,
            toolTipContent: ' {legendText} {y}%',
            dataPoints: [
              {y: this.pie.emdAll.x, legendText: 'Used', label: 'Used', color: '#E25740'},
              {y: this.pie.emdAll.y, legendText: 'Available', label: 'Ava', color: '#4876FF'}
            ]
          }
        ]
      });
    chart.render();
  }

  chart3() {
    const chart = new CanvasJS.Chart('chart_Tasf',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'TASF Usage'
        },
        legend: {
          horizontalAlign: 'right', // "center" , "right"
          verticalAlign: 'bottom',  // "top" , "bottom"
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        theme: 'theme2',
        data: [
          {
            type: 'pie',
            radius: '95%',
            yValueFormatString: '#0.00',
            indexLabelFontFamily: 'Garamond',
            indexLabelFontSize: 14,
            indexLabel: '{label} {y}%',
            startAngle: -30,
            showInLegend: true,
            toolTipContent: ' {legendText} {y}%',
            dataPoints: [
              {y: this.pie.tasfAll.x, legendText: 'Used', label: 'Used', color: '#E25740'},
              {y: this.pie.tasfAll.y, legendText: 'Available', label: 'Ava', color: '#4876FF'}
            ]
          }
        ]
      });
    chart.render();
  }

  rangeChangeOpet() {

    this.myservice.getPieDataById(this.selectedOpetID, 'OPET').subscribe((data) => {
      if (data.operationStatus === 'ERROR' ) {
        alert('error happens');
        return;
      }
      console.log(data.item);
      const fcOne: Coordinate = data.item.fcOne;
      this.pie.opetAll = fcOne;
      this.chart();

      console.log(data);

    });

  }

  rangeChangeOpemd() {


    this.myservice.getPieDataById(this.selectedOpemdID, 'OPEMD').subscribe((data) => {
      if (data.operationStatus === 'ERROR' ) {
        alert('error happens');
        return;
      }
      console.log(data.item);
      const fcOne: Coordinate = data.item.fcOne;
      this.pie.emdAll = fcOne;
      this.chart2();

      console.log(data);

    });

  }

  rangeChangeTasf() {

    this.myservice.getPieDataById(this.selectedTasfID, 'TASF').subscribe((data) => {
      if (data.operationStatus === 'ERROR' ) {
        alert('error happens');
        return;
      }
      console.log(data.item);
      const fcOne: Coordinate = data.item.fcOne;
      this.pie.tasfAll = fcOne;
      this.chart3();

      console.log(data);

    });

  }


}
