import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Allocation } from '../../../vo/allocation';
import { Formcode } from '../../../vo/formcode';
import { Document } from '../../../vo/document';
import { Gds } from '../../../vo/gds';
import { Bsp } from '../../../vo/bsp';
import { AllocationService } from '../../../services/api/allocation.service';
import { AllocationRequestService } from '../../../services/api/allocationRequest.service';
import { FormcodeService } from '../../../services/api/formcode.service';
import { DocumentTypeService } from '../../../services/api/documentType.service';
import { GdsService } from '../../../services/api/gds.service';
import { BspService } from '../../../services/api/bsp.service';
import { AllocationRequest } from '../../../vo/allocationRequest';
import { FormcodeUsageDetail } from '../../../vo/formcodeUsageDetail';
import { TableGenerator } from '../table/tableGenerator';
import { MailVo } from '../../../vo/mailVo';
import { SendMailService } from '../../../services/api/send-mail.service';
import { SNRange } from '../../../vo/snRange';

@Component({
  selector: 'app-newallocation',
  templateUrl: './newallocation.component.html',
  styleUrls: ['./newallocation.component.css']
})
export class NewallocationComponent implements OnInit {

  allocationRequest: AllocationRequest = new AllocationRequest();
  allocation: Allocation = new Allocation();
  formcode: Formcode = new Formcode();
  formcodes: Formcode[] = [];
  doc: Document = new Document();
  docs: Document[] = [];
  id: number;
  bsp: Bsp = new Bsp();
  bsps: Bsp[] = [];
  gds: Gds = new Gds();
  gdss: Gds[] = [];
  mailMessage: MailVo = new MailVo();

  stage = 0;
  action: string;

  showChart: boolean;
  htmlstring: string;
  htmlstring2: string;

  isAuto: number;
  formcodeid: number;
  quantity: number;
  blpercentage: number;
  blinterruptions: number;
  startNumber: number;
  startNumberDispaly: number;

  selStatus: string;
  selStart: string;
  selEnd: string;
  public alerts: any = [];
  formcodeUsageDetail: FormcodeUsageDetail = new FormcodeUsageDetail();
  selectedRange: SNRange = new SNRange();
  formcodeUsageRangeDetail: FormcodeUsageDetail = new FormcodeUsageDetail();
  today: Date;
  showGdslist: number;

  constructor(protected route: ActivatedRoute, protected router: Router, private allocationService: AllocationService, private allocationRequestService: AllocationRequestService, private formcodeService: FormcodeService, private docService: DocumentTypeService
    , private bspService: BspService, private gdsService: GdsService, private mailService: SendMailService) {
  }

  ngOnInit() {

    this.today = new Date();
    // alert(this.today.getFullYear() + '/' + (this.today.getMonth() + 1) + '/' + this.today.getDate());

    this.formcode.documentType = this.doc;
    this.allocation.fromcode = this.formcode;
    this.allocation.documentTypes = this.doc;
    this.allocationRequest.doctype = this.doc;
    this.allocation.bsp = this.bsp;
    this.allocation.gds = this.gds;

    this.route.queryParams.subscribe((p: Params) => {
      this.action = p['action'];
      if (this.action === 'new') {
        this.formcodeService.getPagesOpened(0, 100, null).subscribe((data) => {
          console.log('Opened form codes:' + data);
          this.formcodes = data.items;
          console.log(this.formcodes);
        });
      }
      if (this.action === 'accept') {
        this.id = p['id'];
        this.allocationRequest.id = this.id;

        // TODO:getAllocationRequestByID
        this.allocationRequestService.findAllocationRequestById(this.id).subscribe((data) => {
          this.allocationRequest = data.item;
          console.log(this.allocationRequest);

          this.allocation.documentTypes = this.allocationRequest.doctype;
          this.allocation.fromcode.documentType.id = this.allocationRequest.doctype.id;
          this.allocation.quantity = this.allocationRequest.quantity;

          this.allocation.bsp.id = this.allocationRequest.bsp.id;
          this.allocation.gds.gdsCode = this.allocationRequest.gdsCode;

          this.formcodeService.getPagesOpenedByDocType(0, 100, this.formcode).subscribe((data) => {
            console.log(data);
            this.formcodes = data.items;
            console.log(this.formcodes);
          });

        });

      }
    });

    this.bspService.getActiveBsps().subscribe((data) => {
      console.log(data);
      this.bsps = data.items;
      console.log(this.bsps);
    });
    this.gdsService.getItems().subscribe((data) => {
      console.log(data);
      this.gdss = data.items;
      console.log(this.gdss);
    });

    this.showChart = false;
    this.isAuto = 0;
    this.showGdslist = 0;
  }

  // getPageDataByid(id: number) {
  //   this.allocationService.getItemByID(id).subscribe((data) => {
  //       console.log(data);
  //       this.allocationRequest = data.item;
  //       this.formcode.documentType.id = this.allocationRequest.doctype.id;
  //       this.formcodeService.getPagesOpenedByDocType(0, 100, this.formcode).subscribe((data1) => {
  //         console.log(data1);
  //         this.formcodes = data1.items;
  //         console.log(this.formcodes);
  //       });
  //     }
  //   );
  // }

  goToDetails() {
    if (this.stage === 0) {
      // goto details for  picked range

      this.stage = 1;
      this.selStart = (<HTMLInputElement>document.getElementById('selStart')).value;
      this.selEnd = (<HTMLInputElement>document.getElementById('selEnd')).value;
      this.selStatus = (<HTMLInputElement>document.getElementById('selStatus')).value;

      // TODO: status quantity
      this.selStatus = '0';
      this.allocationService.getFormcodeRangeDetail(this.formcodeid, this.selStart, this.selEnd, this.selStatus).subscribe(data => {
        console.log(data);
        this.formcodeUsageRangeDetail = data;
        const tableGen: TableGenerator = new TableGenerator(this.formcodeUsageRangeDetail.ranges, null, 0.1, 700);
        this.htmlstring2 = tableGen.createHTML();
      });
      this.showChart = true;

    } else if (this.stage === 1) {
      // goto details for  picked range

      this.stage = 0;
      this.startNumber = +this.selStart;
      this.formcodeUsageDetail.startNumber = +this.selStart;
      this.formcodeUsageDetail.endNumber = +this.selStart + +this.quantity - 1;

      this.selectedRange.openNumber = this.formcodeUsageDetail.startNumber;
      this.selectedRange.closeNumber = this.formcodeUsageDetail.endNumber;
      console.log(this.selectedRange);

      this.autoSearch('manu');

      this.selStart = '';
      this.selEnd = '';
      this.selStatus = '';

      // TODO: status quantity
      this.selStatus = '0';

      this.formcodeUsageRangeDetail = new FormcodeUsageDetail();

      this.htmlstring2 = '';
    }
    this.showChart = true;

  }

  backToStage0() {
    this.stage = 0;
  }

  backToList() {
    this.router.navigate(['/home/allocation']);
  }

  showGds() {
    this.showGdslist = 1;
    for (const vo of this.bsps) {
      if (vo.id == this.allocation.bsp.id) {
        this.gdss = vo.relGds;
        return;
      }
    }
  }

  autoSearch(action: string) {

    if (this.allocation.fromcode.id == null || this.allocation.fromcode.id == 0) {
      alert('Formocde is required');
      return;
    } else if (this.allocation.quantity == null || this.allocation.quantity == 0) {
      alert('Quantity is required');
      return;
    }

    this.formcodeService.getItemByID(this.allocation.fromcode.id).subscribe((data) => {
      console.log(data);
      this.formcode = data.item;
      // this.allocationRequest.doctype.dtDefaultpercentage = this.formcode.documentType.dtDefaultpercentage;
      // this.allocationRequest.doctype.dtDefaultinterruptions = this.formcode.documentType.dtDefaultinterruptions;
      this.allocation.documentTypes = this.formcode.documentType;
      console.log(this.formcode);
    });

    if (action === 'auto') {
      this.isAuto = 1;
    } else {
      this.isAuto = 0;
    }
    // TODO: manuSearch

    this.formcodeid = this.allocation.fromcode.id;
    this.quantity = this.allocation.quantity;
    this.blpercentage = this.allocation.documentTypes.dtDefaultpercentage;
    this.blinterruptions = this.allocation.documentTypes.dtDefaultinterruptions;

    this.allocationService.getFormcodeDetail(this.formcodeid, this.isAuto,
      this.quantity, this.blpercentage, this.blinterruptions,
      this.startNumber).subscribe(data => {
        console.log(data);
        // if (data.item.startNumber === -1) {
        //   this.showChart = false;
        //   this.startNumber = null;
        //   this.startNumberDispaly = null;
        //   this.alerts = [];
        //   this.addMessage('do not have any avaliable range to allocate!', 'danger');
        //   return;
        // }
        if (data.item.startNumber === -1) {
          this.startNumberDispaly = null;
          alert('The allocation can not be performed as there is no available free range for this allocation.');
          return;
        }
        if (data.operationStatus === 'ERROR') {
          alert(data.operationMessage);
          return;
        }
        this.formcodeUsageDetail = data.item;
        this.selectedRange = data.selectedRange;
        this.startNumber = this.formcodeUsageDetail.startNumber;
        this.startNumberDispaly = this.startNumber;
        const tableGen: TableGenerator = new TableGenerator(this.formcodeUsageDetail.ranges, this.selectedRange, 0.001, 600);
        this.htmlstring = tableGen.createHTML();
      });
    this.showChart = true;
  }

  allocateRange() {

    this.allocation.allocationdate = this.today;
    this.allocation.startnumber = this.startNumber;
    this.allocation.bsp = this.bsp;
    this.allocation.fromcode = this.formcode;
    this.allocation.status = 0;
    this.allocation.allocationRequestId = this.allocationRequest.id;

    console.log(this.allocation);

    if (this.allocation == null || this.allocation.bsp == null || this.allocation.bsp.id == null || this.allocation.bsp.id == 0) {
      alert('BSP is required');
      return;
    } else if (this.allocation.gds.gdsCode == null || this.allocation.gds.gdsCode == '') {
      alert('GDS is required');
      return;
    } else if (this.allocation.documentTypes.dtDefaultpercentage == null || this.allocation.documentTypes.dtDefaultpercentage == 0) {
      alert('Defaultpercentage is required');
      return;
    } else if (this.allocation.documentTypes.dtDefaultinterruptions == null || this.allocation.documentTypes.dtDefaultinterruptions == 0) {
      alert('Defaultinterruptions is required');
      return;
    } else if (this.startNumber == null || this.startNumber == 0) {
      alert('StartNumber is required');
      return;
    }

    this.allocationService.allocateRange(this.allocation).subscribe((data) => {
      console.log(data);
      if ('SUCCESS' === data.operationStatus) {
        this.addMessage(data.operationMessage, 'success');
        this.mailMessage = data.mailMessage;
        this.stage = 2;
      } else {
        alert(data.operationMessage);
        // this.addMessage(data.operationMessage, 'danger');
      }
    }
    );
  }

  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: 10000
    });
  }

  cancelSending() {
    this.addMessage('Email sending is canceled', 'success');
    this.stage = 3;
  }

  sendOutMail() {
    this.mailService.sendEmail(this.mailMessage).subscribe(
      (response) => {
        console.log(response);
        // alert(response.operationMessage);
        this.addMessage(response.operationMessage, 'success');
        this.stage = 3;
      }
    );
  }

  startNumberChange(event: any) {
    console.log(event);

    if (isNaN(+event)) {
      this.startNumber = 0;
    } else {
      this.startNumber = +event;
    }
    console.log(this.startNumber);
  }

  // hideDetails() {
  //   this.showChart = false;
  // }

}
