import { Component, OnInit } from '@angular/core';
import { Document } from '../../../vo/document';
import { Formcode } from '../../../vo/formcode';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DocumentTypeService } from '../../../services/api/documentType.service';
import { FormcodeService } from '../../../services/api/formcode.service';
import { IssuParty } from '../../../vo/issu-party';
import { IssuePartyService } from '../../../services/api/issue-party.service';

@Component({
  selector: 'app-editformcode',
  templateUrl: './editformcode.component.html',
  styleUrls: ['./editformcode.component.css']
})
export class EditformcodeComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
    protected router: Router,
    protected formcodeService: FormcodeService,
    protected docService: DocumentTypeService,
    protected issuService: IssuePartyService) { }

  public id: number;
  public action: String;
  public case: number;
  public fcLength: number;
  public editOrView = true;

  public title = 'Please Confirm';
  public freezeMessage = 'Are you sure you wish to freeze this formcode?';
  public openMessage = 'Are you sure you wish to open this formcode?';
  public unfreezeMessage = 'Are you sure you wish to unfreeze this formcode?';
  public splitMessage = 'Are you sure you wish to split this formcode?';

  public cancelClicked: boolean = false;

  vo: Formcode = new Formcode();
  doc: Document = new Document();
  issu: IssuParty = new IssuParty();
  docs: Document[] = [];
  issus: IssuParty[] = [];
  public alerts: any = [];

  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.action = p['action'];
      if (this.action === 'U') {
        this.case = 1;
      } else if (this.action === 'F') {
        this.case = 2;
      } else if (this.action === 'UF') {
        this.case = 3;
      } else if (this.action === 'O') {
        this.case = 4;
      }
      this.getPageData(this.id);
      this.canEdit(this.id);
    });

    // alert(this.vo.fcNumber);
    // this.fcLength = this.vo.fcNumber.length;

    this.vo.documentType = this.doc;
    this.docService.getActiveDocs().subscribe((data) => {
      console.log(data);
      this.docs = data.items;
      console.log(this.docs);
    });
    this.vo.issueParty = this.issu;
    this.issuService.getIssuingPartys().subscribe((data) => {
      console.log(data);
      this.issus = data.items;
      console.log(this.issus);
    });
  }

  update() {
    // alert(this.vo.documentType.id + ' ' + this.vo.fcReso);
    this.formcodeService.update(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
          this.router.navigate(['/home/formcode']);
        }
      );
  }

  split() {
    // this.vo.fcStatus = 'F';
    // this.vo.fcFrozen = null;
    this.formcodeService.splitFormcode(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
          this.router.navigate(['/home/formcode']);
        }
      );
  }

  // TODO: split 3digit
  freeze() {
    this.vo.fcStatus = 'F';
    // this.vo.fcFrozen = null;
    this.formcodeService.freezeFormcode(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
          this.router.navigate(['/home/formcode']);
        }
      );
  }

  // TODO: split 3digit
  unfreeze() {
    this.vo.fcStatus = 'O';
    this.vo.fcFrozen = null;
    this.formcodeService.unfreezeFormcode(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          // this.addMessage(response.operationMessage);
          this.router.navigate(['/home/formcode']);
        }
      );
  }

  // TODO: split 3digit
  open() {
    if (this.vo.issueParty.id != 1) {
      alert('IssuingParty is BSP update Form Code Status  Opened ');
      return;
    } else {
      this.vo.fcStatus = 'O';
      this.vo.fcFrozenFor = 2;
      this.formcodeService.openFormcode(this.vo)
        .subscribe(
          (response) => {
            console.log(response);
            this.router.navigate(['/home/formcode']);
          }
        );
    }
  }

  backToList() {
    this.router.navigate(['/home/formcode']);
  }

  getPageData(id: number) {
    this.formcodeService.getItemByID(id).subscribe((data) => {
      console.log(data);
      this.vo = data.item;
      this.fcLength = this.vo.fcNumber.length;
    }
    );
  }

  canEdit(id: number) {
    this.formcodeService.canEdit(id).subscribe((data) => {
      console.log(data);
      this.editOrView = data;
    }
    );
  }
  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 1800000
    });
  }


}
