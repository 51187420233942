<div class="row">
  <div class="col-10 offset-1">
    <H1>Issuing Party Details</H1>
  </div>
</div>
<div class="col-8 offset-1 table-bordered">
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>
        Create new IssuingParty
      </h1>
    </div>
  </div>

  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="name">Issuing Party Name</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCode" id="fcCode"size="20" [(ngModel)]="issu.name">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>


        <div class="row form-group ">
          <div class="col-sm-3 "><label>Description</label></div>
          <div class="col-sm-9">
            <textarea name="description" cols="40" rows="8" [(ngModel)]="issu.description"></textarea>
          </div>
        </div>
      </form>
      <div class="row form-group table-bordered border-right-0 border-left-0 ">
        <div class="offset-1">
          <input type="button" name="new" value="Create" (click)="addIssueParty()">
        </div>
        <div class="offset-2">
          <input type="button" name="back" value="Cancel" (click)="backToList()">
        </div>
      </div>
      <div *ngFor="let alert of alerts">
        <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}
        </alert>
      </div>
    </div>
  </div>
</div>
