<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>BSP UnMerge</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" >

  <!-- title2 -->
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <form role="form" name="demergeDynaForm" method="post" >

    <div class="row form-group table-bordered " >
      <div class="col-sm-5 ">
        <label for="BspWithMergeDetails">BSP which has Merged Details</label>
      </div>
      <div class="col-sm-7 ">
        <select name="BspWithMergeDetails" [(ngModel)]="bspInit.id">
          <option>-</option>
          <option *ngFor="let item of bspInits" [value]="item.id">{{item.bspFullname}}</option>
        </select>
      </div>
    </div>
    <div class="row form-group table-bordered ">
      <div class="col-sm-3 ">
        <input type="button" name="btnShowDetails" value="Show Details" (click)="showdetails()">
      </div>
    </div>
    <div class="row form-group table-bordered " >
      <div class="col-sm-9 ">
        <label>Select The BSP To Demerge.</label>
      </div>
    </div>
    <div class="row form-group table-bordered " >
      <div class="col-sm-5 ">
        <label for="listBspsMergeWith">BSP Which were Merged with the selected BSP.</label>
      </div>
      <div class="col-sm-7 ">
        <select name="listBspsMergeWith" multiple="multiple" [(ngModel)]="bspFinal.id">
          <option *ngFor="let item of bspFinals" [value]="item.id">{{item.bspFullname}}</option>
        </select>
      </div>
    </div>
  </form>
  <!-- Query form End-->
  <!-- alert -->
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">

    <div class="ml-3">
      <input type="button" name="btnEnterBspCode" value="Enter the BSP Code" (click)="entercode()">
    </div>
  </div>
  <!-- button end -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="col-sm-5 ">
      <label for="btnEnterBspCode">{{bspMerge.bspFullname}}</label>
    </div>
    <div class="col-sm-7 ">
       <input type="text" name="btnEnterBspCode" value="" [(ngModel)]="bspMerge.bspCode" >
    </div>
  </div>
  <div class="row form-group table-bordered border-right-0 border-left-0 ">

    <div class="ml-3">
      <input type="button" name="btnEnterBspCode" value="Enter the BSP Code" (click)="validatecode()">
    </div>
  </div>
  <div class="row form-group table-bordered border-right-0 border-left-0 " *ngFor="let alert of alerts">

    <div class="ml-3" *ngIf="!alert.msg">
      <input type="button" name="btnMergeBsp" value="Merge the BSP Code" (click)="demerge()">
    </div>
  </div>
  <!-- Form -->
  <div  *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>
</div>
<!-- body mt-3 -->
