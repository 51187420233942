import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {Subscriber} from '../../../vo/subscriber';
import {SubscriberService} from '../../../services/api/subscriber.service';

@Component({
  selector: 'app-newsubscriber',
  templateUrl: './newsubscriber.component.html',
  styleUrls: ['./newsubscriber.component.css']
})
export class NewsubscriberComponent implements OnInit {
  vo: Subscriber = new Subscriber();
  public alerts: any = [];

  constructor(private service: SubscriberService, private router: Router) {
  }

  ngOnInit() {
  }

  onSubmit(form: any): void {
    console.log('you submitted value:', form);
  }

  addNew() {
    console.log(this.vo);
    this.service.addItem(this.vo)
      .subscribe(
        (newTodo) => {
          console.log(newTodo);
          this.addMessage(newTodo.operationMessage);
        }
      );
  }

  backToListPage() {
    this.router.navigate(['/home/subscriber']);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

}
