export class EmailTemplate {

  id: number;
  name: string;
  subject: string;
  body: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
