import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Allocation } from '../../vo/allocation';
import { AllocationService } from '../../services/api/allocation.service';

@Component({
  selector: 'app-dash-purge',
  templateUrl: './dash-purge.component.html',
  styleUrls: ['./dash-purge.component.css']
})
export class DashPurgeComponent implements OnInit {
  allocation: Allocation = new Allocation();
  allocations: Allocation[] = [];
  fcNumber: string;
  public bigTotalItems: number;
  public maxSize = 5;
  public bigCurrentPage = 1;
  public alerts: any = [];

  public title = 'Please Confirm';
  public purgeMessage = 'Are you sure you wish to purge this formcode?';
  public cancelClicked = false;

  constructor(private router: Router, private allocateService: AllocationService) {
  }

  ngOnInit() {
    const me = this;
    me.getPageData(null, 10, null, null, null);

  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    const me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, null, null, this.fcNumber);
  }

  getPageData(page: number, size: number, allocation: Allocation, num: string, fcNumber: string) {
    this.allocateService.getInnerAllocates(page, size, allocation, num, fcNumber).subscribe((data) => {
      this.allocations = data.items;
      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
    }
    );
  }

  changeStatus(id: number, status: number) {
    const me = this;
    me.allocateService.changeStatus(id, status).subscribe((response) => {
      console.log(response);
      me.addMessage(response.operationMessage);
      me.getPageData(null, 10, null, null, null);
    }
    );
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

  filter() {
    this.getPageData(null, 10, null, null, this.fcNumber);
  }
}
