<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Ti Auto Switch</H1>
  </div>
</div>
<!-- title -->

<div class="col-10 offset-1 table-bordered">
  <!-- Query form Start-->
  <div class="row table-bordered">
    <div class="col-12">
      <div class="row ml-1">
        <div class="form-group col-3 ml-5 mt-4">
          <label>Auto Upload Ti:</label>
              <span style="color: #0000FF; font-size: 16px">{{switchConfig.flag}}</span>
        </div>

        <div class="form-group col-3 mt-4">
          <button mwlConfirmationPopover
                 [popoverTitle]="title"
                 [popoverMessage]="gneerateMessage"
                 placement="bottom"
                 (confirm)="switch()"
                 (cancel)="cancelClicked = true">Switch</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Query form End-->
</div>
