
<table width="1000">
  <tr>
    <td rowspan="2">
      <table>
        <tr>
          <td>
            <div id="chartContainer_opet" style="height: 300px; width: 300px;"></div>
          </td>
        </tr>
      </table>
    </td>
    <td colspan="2">
      <div class="row">
        <div class="col-12 offset-3 mb-2 ">
          <a style="font-size: 17px; font-weight: bold">Formcode Recycling Dashboard</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
      <table>
        <tr bgcolor="#cfe3f7">
          <td width="170">Formcode</td>
          <td width="170">Document Type</td>
          <td width="200">Frozen Date</td>
          <td width="100">Frezen End</td>
        </tr>

        <tr *ngFor="let todo of frozenFormcodes">
          <td>{{todo.fcNumber}}</td>
          <td>{{todo.documentType.dtName}}</td>
          <td>{{todo.fcFrozen | date:'dd-MM-yyyy'}}</td>
          <td>{{todo.fcFrozenEnd | date:'dd-MM-yyyy'}}</td>
        </tr>
      </table>
        </div>
      </div>
    </td>
  </tr>
  <tr>
    <td colspan="2">
      <div class="row">
        <div class="col-12 offset-3 mb-2">
          <a style="font-size: 17px; font-weight: bold">Allocation-Purge Dashboard</a>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
      <table>
        <tr  bgcolor="#cfe3f7">
          <td width="170">Expired Allocations</td>
          <td width="170">GDS</td>
          <td width="200">Allocation Date</td>
          <td width="100">Status</td>
        </tr>

        <tr *ngFor="let todo of allocations">
          <td>{{todo.startnumber}}-{{todo.startnumber+todo.quantity}}</td>
          <td>{{todo.gds.gdsCode}}</td>
          <td>{{todo.allocationdate | date:'dd-MM-yyyy'}}</td>
          <td *ngIf="todo.status==0">not purge</td>
          <td *ngIf="todo.status==1" >
            <label>gds purged at {{todo.purgeDate | date:'dd-MM-yyyy'}}</label>
            <!--<input type="submit" name="confirm" value="confirm"  (click)="changeStatus(todo.id,todo.status)">-->
          </td>
        </tr>

      </table>
        </div>
      </div>
    </td>
  </tr>
  <tr>
    <td>
      <table>
        <tr>
          <td>
            <div id="chart_Opet" style="height: 300px; width: 300px;"></div>
          </td>
        </tr>
      </table>
    </td>
    <td>
      <table>
        <tr>
          <td>
            <div id="chart_Opemd" style="height: 300px; width: 300px;"></div>
          </td>
        </tr>
      </table>
    </td>
    <td>
      <table>
        <tr>
          <td>
            <div id="chart_Tasf" style="height: 300px; width: 300px;"></div>
          </td>
        </tr>
      </table>
    </td>
  </tr>
</table>
