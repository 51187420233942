<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Formcode Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" >

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>
        Create new Formcode
      </h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <div class="row form-group " >
          <div class="col-sm-3 "><label for="fcCode" >Form Code</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCode" id="fcCode" maxlength="3" size="20" [(ngModel)]="vo.fcNumber">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcDocTypeID" >Document type</label></div>
          <div class="col-sm-9">
            <select [(ngModel)]="vo.documentType.id"  name="doctype">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of docs" [value]='item.id'>{{item.dtName}} / {{item.dtType | cgn}}</option>
            </select>
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-sm-3 "><label for="issueParty" >Issuing Party</label></div>
          <div class="col-sm-9">
            <select [(ngModel)]="vo.issueParty.id"  name="issueParty">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of issus" [value]='item.id'>{{item.name}}</option>
            </select>
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-sm-3 "><label>Resolution</label></div>
          <div class="col-sm-9">
            <textarea name="fcResolution" cols="40" rows="8" [(ngModel)]="vo.fcReso"></textarea>
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label>Creation date</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCreateDate" size="20" [(ngModel)]='vo.fcCreatedate'>
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-3"><label>Comment</label></div>
          <div class="col-sm-9">
            <textarea name="fcComment" cols="40" rows="8" [(ngModel)]="vo.fcComment"></textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->
  <div class="row form-group">
    <div class="ml-3 mt-3">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>
  </div>
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="offset-1">
      <input type="button" name="new" value="Create" (click)="addFormcode()">
    </div>
    <div class="offset-3">
      <input type="button" name="back" value="Cancel" (click)="backToList()">
    </div>
  </div>
  <!-- button end -->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>

  <!-- Form -->

</div>
<!-- body mt-3 -->
