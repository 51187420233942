import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRequestEXService } from './api-request-ex.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class AllocationPulsService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  getPages(page?: number, size?: number, bspId?: string, gdsId?: string, startDate?: string, endDate?: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '10');
    if (bspId) {
      params = params.append('bspId', bspId);
    }
    if (gdsId) {
      params = params.append('gdsId', gdsId);
    }
    if (startDate) {
      params = params.append('startDate', startDate);
    }
    if (endDate) {
      params = params.append('endDate', endDate);
    }

    // console.log(params);
    return this.apiRequest.get('api/AllocationTop2Low', params);

  }

}
