import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {BulletinService} from '../../services/api/bulletin.service';
import {Bulletin} from '../../vo/bulletin';

@Component({
  selector: 'app-bulletin',
  templateUrl: './bulletin.component.html',
  styleUrls: ['./bulletin.component.css']
})

export class BulletinComponent implements OnInit {

  constructor(private router: Router, private service: BulletinService) {
  }

  previousBulletin: Bulletin = new Bulletin();
  newBulletin: Bulletin = new Bulletin;

  alerts: any = [];

  ngOnInit() {
    this.getPreviousBulletin();
  }

  getPreviousBulletin() {
    this.service.getPreviousBulletinInfo().subscribe((data) => {
      console.log(data);
      this.previousBulletin.bulletinDate = data.bulletin.bulletinDate;
      this.previousBulletin.bulletinNo = data.bulletin.bulletinNo;
    });
  }

  generateBulletin() {
    this.service.generateBulletin().subscribe((data) => {
        console.log(data);
        this.newBulletin.bulletinNo = data.bulletin.bulletinNo;
        this.newBulletin.bulletinContent = data.bulletin.bulletinContent;
      }
    );
  }

  sendBulletin() {
    this.service.sendBulletin(this.newBulletin).subscribe((response) => {
      console.log(response);
      if (response.operationStatus == 'SUCCESS') {
        this.service.addMessage(response.message);
        this.router.navigateByUrl('/home/bulletinmail');
      } else {
        this.addMessage(response.operationMessage);
      }
    });
  }

  addMessage(message: string): void {
    this.alerts.push({
      type: 'error',
      msg: message,
      timeout: 3000
    });
  }
}
