import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Dpc } from '../../vo/dpc';
import { DpcService } from '../../services/api/dpc.service';

@Component({
  selector: 's-dpc-pg',
  templateUrl: './dpc.component.html'
})

export class DpcComponent implements OnInit {

  qry: Dpc = new Dpc();
  dpc: Dpc = new Dpc();
  dpcs: Dpc[] = [];
  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;

  // @ViewChild('orderStatusCellTpl') statusCellTpl: TemplateRef<any>;
  // @ViewChild('orderIdTpl') orderIdTpl: TemplateRef<any>;
  // columns:any[];
  // rows:any[];
  constructor(private router: Router, private dpcService: DpcService) { }

  gotoNew() {
    this.router.navigate(['/home/newdpc']);
  }
  gotoEdit(todo) {
    this.router.navigate(['/home/editdpc/' + todo.id]);
  }

  removeTodo(todo) {
    this.router.navigate(['/home/deletedpc/' + todo.id]);
  }

  ngOnInit() {
    let me = this;
    me.getPageData(0, 10, null);
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    let me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, this.qry);
  }

  getPageData(page: number, size: number, dpc: Dpc) {
    this.dpcService.getDpcs(page, size, dpc).subscribe((data) => {
      console.log(data);
      this.dpcs = data.items;
      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
    }
    );
  }

  filter() {
    let me = this;
    console.log(this.qry);
    me.getPageData(0, 10, this.qry);
  }

}
