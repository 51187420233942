import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRequestEXService } from './api-request-ex.service';
import { MailVo } from '../../vo/mailVo';

@Injectable()
export class SendMailService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  sendEmail(message: MailVo): Observable<any> {
    return this.apiRequest.put('api/sendoutEmail', message);
  }

}
