import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {AllocationRequestStatistic} from '../../vo/AllocationRequestStatistic';
import {AllocationRequestService} from '../../services/api/allocationRequest.service';
import {AllocationRequest} from '../../vo/allocationRequest';
import {Gds} from '../../vo/gds';
import {Bsp} from '../../vo/bsp';
import {Document} from '../../vo/document';

@Component({
  selector: 'app-allocation-request',
  templateUrl: './allocation-request.component.html',
  styleUrls: ['./allocation-request.component.css']
})

export class AllocationRequestComponent implements OnInit {

  constructor(private router: Router, protected route: ActivatedRoute, private service: AllocationRequestService) {
  }

  statistic: AllocationRequestStatistic = new AllocationRequestStatistic;
  newRequest: AllocationRequest;
  details: AllocationRequest[] = [];
  gdses: Gds[] = [];
  bsps: Bsp[] = [];
  documentTypes: Document[] = [];
  oldDocumentTypes: Document[] = [];
  history: AllocationRequest[] = [];

  showNewRequest = false;
  showDetails = false;
  isBspUser = false;
  hasHistory = false;
  isCalculate = false;
  calType: string;
  calResult = 0;
  disableQuantity = false;
  disableRequest = false;

  maxSize = 5;
  detailsTotalItems = 0;
  detailsCurrentPage = 1;
  historyTotalItems = 0;
  historyCurrentPage = 1;

  msg: string;

  alerts: any = [];

  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      console.log(p);
      if (p.hasOwnProperty('msg1')) {
        this.addMessage(p['msg1'], p['msg2']);
      }
    });
    this.getStatistic();
  }

  getStatistic() {
    this.service.getAllocationRequestStatistic().subscribe((data) => {
      console.log(data);
      this.statistic = data.statistic;
    });
  }

  addRequest() {
    this.msg = '';
    this.showDetails = false;
    this.isCalculate = false;

    this.initAllocationRequest();

    this.service.newAllocationRequest().subscribe((data) => {
      console.log(data);

      if (data.operationStatus == 'ERROR') {
        this.addMessage(data.operationMessage, '');
        return;
      }

      this.bsps = data.bsps;
      this.documentTypes = data.documentTypes;
      this.oldDocumentTypes = data.documentTypes;
      this.showNewRequest = true;
    });
  }

  calculate(isBsp : boolean) {
    this.disableQuantity = true;
    this.disableRequest = true;
    this.isCalculate = false;

    if (isBsp) {
      // JIRASP-84281 TASF will be discontinued in below BSPs on 1st January 2022.
      if (this.newRequest.bsp.id == 2 || this.newRequest.bsp.id == 3
        || this.newRequest.bsp.id == 5 || this.newRequest.bsp.id == 14
        || this.newRequest.bsp.id == 20 || this.newRequest.bsp.id == 22
        || this.newRequest.bsp.id == 44 || this.newRequest.bsp.id == 57
        || this.newRequest.bsp.id == 86 || this.newRequest.bsp.id == 103) {
        if (this.newRequest.doctype.id == 31) {
          this.newRequest.doctype.id = null;
        }
        this.documentTypes = this.documentTypes.filter(x => x.id !== 31);
      } else {
        this.documentTypes = this.oldDocumentTypes;
      }
    }

    this.service.calculation(this.newRequest).subscribe((data) => {
      console.log(data);
      this.hasHistory = data.hasHistory;
      if (this.hasHistory) {
        this.loadHistory();
      }

      if (data.calculationFlag == 1) {
        this.calType = 'trend';
      } else {
        this.calType = 'predefined rule';
      }

      this.calResult = data.calculationResult;

      this.disableQuantity = false;
      this.disableRequest = false;
      this.isCalculate = true;

      // set documet type name and bsp name for newRequest, because so far only have id in newRequest

      for (const item of this.bsps) {
        if (item.id == this.newRequest.bsp.id) {
          this.newRequest.bsp.bspFullname = item.bspFullname;
          break;
        }
      }

      for (const item of this.documentTypes) {
        if (item.id == this.newRequest.doctype.id) {
          this.newRequest.doctype.dtName = item.dtName;
          break;
        }
      }
    });
  }

  sendNewRequest() {
    this.disableRequest = true;
    const selectBsp = this.bsps.find(x => x.id == this.newRequest.bsp.id);
    const selectDocumentType = this.documentTypes.find(x => x.id == this.newRequest.doctype.id);
    this.newRequest.bsp = selectBsp;
    this.newRequest.doctype = selectDocumentType;
    this.service.sendRequest(this.newRequest, this.calResult);
  }

  loadDetails() {
    this.msg = '';
    this.showDetails = !this.showDetails;
    if (this.showDetails) {
      this.getDetails(0, 10);
    }
  }

  detailsPageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.getDetails(event.page - 1, event.itemsPerPage);
  }

  getDetails(page: number, size: number) {
    this.service.findAllocationRequestDetails(page, size).subscribe((data) => {
        console.log(data);
        this.details = data.items;
        this.detailsTotalItems = data.totalItems;
      }
    );
  }

  loadHistory() {

    // this.showDetails = !this.showDetails;
    // if (this.showDetails) {

    this.getHistory(0, 10);
    // }
  }

  historyPageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.getHistory(event.page - 1, event.itemsPerPage);
  }

  getHistory(page: number, size: number) {
    // alert(2);
    this.service.findHistoryAllocationRequest(page, size, this.newRequest.doctype.id, this.newRequest.bsp.id).subscribe((data) => {
        console.log(data);
        this.history = data.items;
        this.historyTotalItems = data.totalItems;
      }
    );
  }

  forceNumeric(event: any) {
    const key = event.keyCode;
    console.log(key);
    if (key < 48 || key > 57) {
      event.preventDefault();
    }
  }

  goCancel(allocationRequest: AllocationRequest) {
    this.service.cancelRequest(allocationRequest);
    this.router.navigateByUrl('/gdshome/allocationreqcancel');
  }

  initAllocationRequest() {
    this.newRequest = new AllocationRequest({'bsp': new Bsp(), 'doctype': new Document()});
  }

  addMessage(message1: string, message2: string): void {
    this.alerts.push({
      type: 'success',
      msg1: message1,
      msg2: message2,
      timeout: 120000
    });
  }
}
