<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Email Template Maintenance</H1>
  </div>
</div>
<!-- title -->

<!-- body -->
<div class="col-10 offset-1 table-bordered">
  <!-- Query form Start-->
  <div class="row table-bordered">
    <div class="col-12">
      <form>
        <div class="row">
          <div class="form-group col-2">
            <label for="snaptable_etName">Email Name:</label>
            <input type="text" name="snaptable_etName" id="snaptable_etName" maxlength="100" [(ngModel)]="qry.name">
          </div>
          <div class="form-group col-2 ml-5">
            <label for="snaptable_etSubject">Email Subject:</label>
            <input type="text" name="snaptable_etSubject" id="snaptable_etSubject" maxlength="100" [(ngModel)]="qry.subject">
          </div>
          <div class="form-group col-3 mt-4 ml-5">
            <input type="submit" name="snaptable_filter" value="Filter" (click)="filter()">
          </div>
        </div>

      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table width="100%" border="0" class="table  table-hover">
        <thead>
        <tr style="background-color:#F2F5FC">
          <td title="Edit record" width="20px"></td>
          <td>Name</td>
          <td>Email Subject</td>
        </tr>
        </thead>

        <tbody *ngIf="emailTemplates.length > 0">
        <tr class="Waving1" *ngFor="let item of emailTemplates">
          <td><img border="0" src="assets/SNAPDocroot/images/tableview/edit.gif" alt="Edit" (click)="gotoEdit(item)" title="Modify this record"></td>
          <td>{{item.name}}</td>
          <td>{{item.subject}}</td>
        </tr>
        </tbody>

        <tbody *ngIf="emailTemplates.length == 0">
        <tr class="Waving1">
          <td colspan="5">No Data...</td>
        </tr>
        </tbody>

      </table>
      <div class="col-md-6">
        <pagination [totalItems]="totalItems" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm" [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <div class="row form-group table-bordered">
    <div class="ml-3">
      <input type="submit" name="refresh" value="Refresh" (click)="filter()">
    </div>
  </div>

</div>
