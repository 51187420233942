import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Dpc} from '../../vo/dpc';
import {DpcService} from '../../services/api/dpc.service';

@Component({
  selector: 'app-editdpc',
  templateUrl: './editdpc.component.html'
})
export class EditdpcComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected service: DpcService) {
  }
  public dpcId: number;
  deleteOperation: boolean;
  vo: Dpc = new Dpc();

  public alerts: any = [];

  ngOnInit() {
    this.deleteOperation = false;
    this.route.params.subscribe((p: Params) => {
      this.dpcId = p['id'];
      // alert("I'm "+this.dpcId);
      const me = this;
      this.vo.id = this.dpcId;
      me.getPageData(0, 10, this.dpcId);

      // employeeid
    });
  }

  updateDpc() {
    this.service.updateDpc(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }


  backToDpc() {
    this.router.navigate(['/home/dpc']);
  }

  getPageData(page: number, size: number, id: number) {
    const me = this;
    this.service.getDpcByID(id).subscribe((data) => {
        console.log(data);
        this.vo = data.item;
        // this.bigTotalItems = data.totalItems;
      }
    );
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
}
