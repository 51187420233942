import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
// recaptcha
import { RecaptchaModule } from 'ng-recaptcha';
import { RecaptchaFormsModule } from 'ng-recaptcha';
// Third Party Modules
import { ModalModule } from 'ngx-bootstrap/modal';
import { CKEditorModule } from 'ng2-ckeditor';
// Local App Modules
import { AppRoutingModule } from './app-routing.module';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AlertModule } from 'ngx-bootstrap/alert';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// Directives
import { TrackScrollDirective } from './directives/track_scroll/track_scroll.directive';
// Components
import { BadgeComponent } from './components/badge/badge.component';
import { LegendComponent } from './components/legend/legend.component';
import { LogoComponent } from './components/logo/logo.component';
// Pages  -- Pages too are components, they contain other components
import { AppComponent } from './app.component';
import { HomeComponent } from './home.component';
import { LoginComponent } from './pages/login/login.component';
import { LogoutComponent } from './pages/logout/logout.component';

import { MenuComponent } from './pages/menu/menu.component';
import { DpcComponent } from './pages/dpc/dpc.component';
import { RecycleComponent } from './pages/recycle/recycle.component';
// Services
import { AppConfig } from './app-config';
import { UserInfoService } from './services/user-info.service';
import { AuthGuard } from './services/auth_guard.service';
import { TranslateService } from './services/api/translate.service';
import { LoginService } from './services/api/login.service';
import { OrderService } from './services/api/order.service';
import { ProductService } from './services/api/product.service';
import { CustomerService } from './services/api/customer.service';
import { DpcService } from './services/api/dpc.service';
import { DocumentTypeService } from './services/api/documentType.service';
import { SubscriberService } from './services/api/subscriber.service';
import { AllocationService } from './services/api/allocation.service';

import { GdsService } from './services/api/gds.service';

import { DocumentSubTypeService } from './services/api/documentSubType.service';
import { BspService } from './services/api/bsp.service';
import { PredefinedRuleService } from './services/api/predefinedRule.service';
import { MergingService } from './services/api/merging.service';
import { UnmergeService } from './services/api/unmerge.service';
import { CalendarService } from './services/api/calendar.service';

import { BspComponent } from './pages/bsp/bsp.component';
import { NewdpcComponent } from './pages/dpc/newdpc.component';
import { EditdpcComponent } from './pages/dpc/editdpc.component';
import { DeletedpcComponent } from './pages/dpc/deletedpc/deletedpc.component';

import { DocumentTypeComponent } from './pages/document-type/document-type.component';
import { DeleteDocComponent } from './pages/document-type/delete-doc/delete-doc.component';
import { UpdateDocComponent } from './pages/document-type/update-doc/update-doc.component';
import { CreateDocComponent } from './pages/document-type/create-doc/create-doc.component';
import { SubscriberComponent } from './pages/subscriber/subscriber.component';
import { NewsubscriberComponent } from './pages/subscriber/newsubscriber/newsubscriber.component';
import { EditsubscriberComponent } from './pages/subscriber/editsubscriber/editsubscriber.component';

import { GdsComponent } from './pages/gds/gds.component';
import { NewgdsComponent } from './pages/gds/newgds/newgds.component';
import { EditgdsComponent } from './pages/gds/editgds/editgds.component';
import { DocumentSubTypeComponent } from './pages/document-sub-type/document-sub-type.component';
import { EditdocsubtypeComponent } from './pages/document-sub-type/editdocsubtype/editdocsubtype.component';
import { NewdocsubtypeComponent } from './pages/document-sub-type/newdocsubtype/newdocsubtype.component';
import { AllocationComponent } from './pages/allocation/allocation.component';

import { NewbspComponent } from './pages/bsp/newbsp/newbsp.component';
import { EditbspComponent } from './pages/bsp/editbsp/editbsp.component';
import { FormcodeComponent } from './pages/formcode/formcode.component';
import { EmailComponent } from './pages/email/email.component';
import { EmailTemplateDetailComponent } from './pages/email/email-template-detail.component';

import { FormcodeService } from './services/api/formcode.service';
import { EmailTemplateService } from './services/api/emailTemplate.service';
import { NewallocationComponent } from './pages/allocation/newallocation/newallocation.component';

import { CgnPipe } from './Pipes/cgn.pipe';
import { ArrytostrPipe } from './Pipes/arrytostr.pipe';
import { DtStatusPipe } from './Pipes/dt-status.pipe';

import { SafeHtmlPipe } from './Pipes/safe-html.pipe';
import { NewformcodeComponent } from './pages/formcode/newformcode/newformcode.component';
import { EditformcodeComponent } from './pages/formcode/editformcode/editformcode.component';
import { ViewformcodeComponent } from './pages/formcode/viewformcode/viewformcode.component';
import { PredefinedRuleComponent } from './pages/predefined-rule/predefined-rule.component';
import { NewPredefinedRuleComponent } from './pages/predefined-rule/new-predefined-rule/new-predefined-rule.component';
import { EditPredefineRuleComponent } from './pages/predefined-rule/edit-predefine-rule/edit-predefine-rule.component';
import { MergingComponent } from './pages/merging/merging.component';
import { UnmergeComponent } from './pages/unmerge/unmerge.component';
import { BulletinComponent } from './pages/bulletin/bulletin.component';
import { BulletinService } from './services/api/bulletin.service';
import { PrinterService } from './services/api/printer.service';
import { MonitorUsageComponent } from './pages/monitor-usage/monitor-usage.component';
import { TransPipe } from './Pipes/trans.pipe';
import { RetrieveComponent } from './pages/retrieve/retrieve.component';
import { TransferComponent } from './pages/transfer/transfer.component';
import { TransferService } from './services/api/transfer.service';
import { MonitorUsageService } from './services/api/monitorUsage.service';
import { FormHistoryService } from './services/api/formHistory.service';
import { BulletinMailComponent } from './pages/bulletin/bulletin-mail.component';
import { RequestRejectComponent } from './pages/allocation/request-reject/request-reject.component';

import { RecycleService } from './services/api/recycle.service';
import { FromcodeUsageComponent } from './pages/fromcode-usage/fromcode-usage.component';
import { DashRecycleComponent } from './pages/dash-recycle/dash-recycle.component';
import { DashFcUsageComponent } from './pages/dash-fc-usage/dash-fc-usage.component';
import { DashOverallComponent } from './pages/dash-overall/dash-overall.component';
import { DashPurgeComponent } from './pages/dash-purge/dash-purge.component';
import { DashIndexComponent } from './pages/dash-index/dash-index.component';
import { MonitorUsageExternalComponent } from './pages/monitor-usage-external/monitor-usage-external.component';
import { NewmailComponent } from './components/newmail/newmail.component';
import { ForgotpwdComponent } from './pages/forgotpwd/forgotpwd.component';
import { ForgotpwdService } from './services/api/forgotpwd.service';
import { SendMailService } from './services/api/send-mail.service';
import { ExtModule } from './pagesext/ext.module';
import { FcStatusPipe } from './Pipes/fc-status.pipe';
import { SharedModule } from './shared.module';
import { ConfirmationPopoverModule } from 'angular-confirmation-popover';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { BspCalComponent } from './pages/bsp-cal/bsp-cal.component';
import { BspWithCalComponent } from './pages/bsp-with-cal/bsp-with-cal.component';
import { BspEditCalComponent } from './pages/bsp-with-cal/bsp-edit-cal/bsp-edit-cal.component';
import { CalEditComponent } from './pages/bsp-cal/cal-edit/cal-edit.component';
import { GenerateTiFileComponent } from './pages/generate-ti-file/generate-ti-file.component';
import { BspTiCalendarComponent } from './pages/bsp-ti-calendar/bsp-ti-calendar.component';
import { BspTiCalendarService } from './services/api/bsp-ti-calendar.service';
import { TiAutoSwitchComponent } from './pages/ti-auto-switch/ti-auto-switch.component';
import { SwitchConfigService } from './services/switch-config.service';
import { MailResendService } from './services/api/mail-resend.service';
import { MailResendComponent } from './pages/mail-resend/mail-resend.component';
import { MailResendDetailComponent } from './pages/mail-resend/mail-resend-detail/mail-resend-detail.component';
import { TiCalendarStatusPipe } from './Pipes/ti-calendar-status.pipe';
import { MailViewerComponent } from './pages/mail-viewer/mail-viewer.component';
import { AllocationPulsComponent } from './pages/allocation-puls/allocation-puls.component';
import { AllocationPulsService } from './services/api/allocation-puls.service';
import { ApiRequestEXService } from './services/api/api-request-ex.service';
import { ServerDownComponent } from './pages/server-down/server-down.component';
import { MasterNodeSwitchComponent } from './pages/master-node-switch/master-node-switch.component';
import { IssuePartyPipe } from './Pipes/issueparty.pipe';
import { IssuePartyComponent } from './pages/issue-party/issue-party.component';
import { CreateIssuePartyComponent } from './pages/issue-party/create-issue-party/create-issue-party.component';
import { IssuePartyService } from './services/api/issue-party.service';
import { EditIssuePartyComponent } from './pages/issue-party/edit-issue-party/edit-issue-party.component';
import { BspCalBatchEditComponent } from './pages/bsp-with-cal/bsp-cal-batch-edit/bsp-cal-batch-edit.component';
import { FormcodeExternalComponent } from './pages/formcode-external/formcode-external.component';
import { ViewformcodeExternalComponent } from './pages/formcode-external/viewformcode-external/viewformcode-external.component';

@NgModule({
    declarations: [
        // Components
        BadgeComponent,
        LegendComponent,
        LogoComponent,
        MenuComponent,
        RedirectComponent,
        // Pages -- Pages too are components, they contain other components
        AppComponent,
        HomeComponent,
        LoginComponent,
        LogoutComponent,
        DpcComponent,
        // Directives
        TrackScrollDirective,
        BspComponent,
        NewdpcComponent,
        EditdpcComponent,
        DeletedpcComponent,
        DocumentTypeComponent,
        DeleteDocComponent,
        UpdateDocComponent,
        CreateDocComponent,
        SubscriberComponent,
        NewsubscriberComponent,
        EditsubscriberComponent,
        GdsComponent,
        NewgdsComponent,
        EditgdsComponent,
        DocumentSubTypeComponent,
        EditdocsubtypeComponent,
        NewdocsubtypeComponent,
        AllocationComponent,
        AllocationPulsComponent,
        RecycleComponent,
        CgnPipe,
        ArrytostrPipe,
        DtStatusPipe,
        FcStatusPipe,
        NewbspComponent,
        EditbspComponent,
        FormcodeComponent,
        EmailComponent,
        EmailTemplateDetailComponent,
        NewallocationComponent,
        SafeHtmlPipe,
        NewformcodeComponent,
        EditformcodeComponent,
        ViewformcodeComponent,
        PredefinedRuleComponent,
        NewPredefinedRuleComponent,
        EditPredefineRuleComponent,
        MergingComponent,
        UnmergeComponent,
        BulletinComponent,
        MonitorUsageComponent,
        TransPipe,
        RequestRejectComponent,
        RetrieveComponent,
        TransferComponent,
        BulletinMailComponent,
        FromcodeUsageComponent,
        DashRecycleComponent,
        DashFcUsageComponent,
        DashOverallComponent,
        DashPurgeComponent,
        DashIndexComponent,
        MonitorUsageExternalComponent,
        NewmailComponent,
        ForgotpwdComponent,
        BspCalComponent,
        BspWithCalComponent,
        BspEditCalComponent,
        CalEditComponent,
        BspCalBatchEditComponent,
        GenerateTiFileComponent,
        BspTiCalendarComponent,
        TiAutoSwitchComponent,
        MailResendComponent,
        MailResendDetailComponent,
        TiCalendarStatusPipe,
        MailViewerComponent,
        ServerDownComponent,
        MasterNodeSwitchComponent,
        IssuePartyPipe,
        IssuePartyComponent,
        CreateIssuePartyComponent,
        EditIssuePartyComponent,
        FormcodeExternalComponent,
        ViewformcodeExternalComponent
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        // Thirdparty Module
        PaginationModule.forRoot(),
        BsDatepickerModule.forRoot(),
        AlertModule.forRoot(),
        CKEditorModule,
        ModalModule.forRoot(),
        RecaptchaModule,
        RecaptchaFormsModule,
        // RecaptchaModule.forRoot(),
        // Local App Modules
        ExtModule,
        AppRoutingModule,
        SharedModule,
        ConfirmationPopoverModule.forRoot({
            confirmButtonType: 'danger' // set defaults here
        })],
    providers: [
        AuthGuard,
        UserInfoService,
        TranslateService,
        LoginService,
        OrderService,
        ProductService,
        CustomerService,
        DpcService,
        GdsService,
        DocumentTypeService,
        BspService,
        DocumentSubTypeService,
        SubscriberService,
        AllocationService,
        AllocationPulsService,
        AppConfig,
        FormcodeService,
        PredefinedRuleService,
        MergingService,
        UnmergeService,
        EmailTemplateService,
        PrinterService,
        DatePipe,
        BulletinService,
        TransferService,
        MonitorUsageService,
        FormHistoryService,
        RecycleService,
        ForgotpwdService,
        SendMailService,
        CalendarService,
        BspTiCalendarService,
        SwitchConfigService,
        MailResendService,
        IssuePartyService,
        MailResendService,
        ApiRequestEXService,
        provideHttpClient(withInterceptorsFromDi())
    ]
})

export class AppModule {
}
