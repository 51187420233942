import {Dpc} from './dpc';
import {Gds} from './gds';
import {Calendar} from './calendar';

export class Bsp {
  // bspActive: boolean;
  bspActive: number;
  bspCgnRequestallowed: number;
  bspCode: string;
  bspDishType: string;
  bspDpcId: number;
  bspEmail: string;
  bspFullname: string;
  bspIsoc: string;
  bspRegionEmail: string;
  // bspTiDate: number;
  tiDate: string;
  bspTiDate: Date;
  bspTiFlag: number;
  id: number;
  dpc: Dpc;
  gds: Gds;
  gdsz: Gds;
  // relGds: Gds[];
  // relGds:
  relGds: Array<Gds>;
  gdsId: number;
  bspCal: Calendar;
  checked: boolean;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
