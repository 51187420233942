import {Document} from './document';
import {Bsp} from './bsp';

export class AllocationRequest {

  id: number;
  userid: number;
  quantity: number;
  bsp: Bsp;
  gdsCode: string;
  status: string;
  note: string;
  createdate: Date;
  modifieddate: Date;
  blpercentage: number;
  blnumber: number;
  doctype: Document;
  autoFlag: string;
  useremail: string;
  userfullname: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
