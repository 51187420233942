import {Gds} from './gds';
import {Printer} from './printer';
import {DocumentSubType} from './documentSubType';
import {Document} from './document';
import {Bsp} from './bsp';
import {Snstatus} from './snstatus';
import {Opstatus} from './opstatus';
import {FormHistory} from './formHistory';

export class CurrentStatus {

  formCode: string;
  serialNumber: string;
  docType: string;
  docSubType: string;
  // gdsCode: string;
  validityDate: string;
  // validityDate: Date;
  purchaseOrderNumber: string;
  allocationDate: Date;
  snstatus: Snstatus;
  snCode: string;
  statusChangeDate: string;
  opstatus: Opstatus;
  opCode: string;
  formcodeFrozen: string;
  gds: string;
  activeBsp: string;
  inactiveBsp: string;
  printer: string;
  formHistory: FormHistory;
  formcodeId: string;
  statusDate: string;


  formcode: string;
  rangeStart: string;
  rangeEnd: string;
  statusCode: string;
  oldStatusCode: string;
  schDate: string;
  bsp: string;
  bspCode: string;
  print: string;
  gdsCode: string;
  doc: string;
  subDoc: string;
  allDate: string;
  valDate: string;
  purOderNum: string;
  quantity: string;
  snapNo: string;

  // gdsz: Gds;
  // relGds: Array<Gds>;
  // gdsId:number;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
