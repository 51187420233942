import { Injectable, Inject } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { PredefinedRule } from '../../vo/predefinedRule';
import {ApiRequestEXService} from './api-request-ex.service';
import { HttpParams } from '@angular/common/http';



@Injectable()
export class PredefinedRuleService {

  constructor(private apiRequest: ApiRequestEXService) { }


  getPages(page?: number, size?: number, vo?: PredefinedRule): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');

    console.log(params);
    return this.apiRequest.get('api/predefinedRules', params);

  }

  getItemByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/predefinedRule/' + id);
  }

  addItem(vo: PredefinedRule): Observable<any> {
    return this.apiRequest.post('api/predefinedRule', vo);
  }

  updateItem(vo: PredefinedRule): Observable<any> {
    return this.apiRequest.put('api/predefinedRule', vo);
  }

  deleteItem(vo: PredefinedRule): Observable<any> {
    return this.apiRequest.delete('api/predefinedRule/' + vo.id);
  }

}
