import { Component, OnInit } from '@angular/core';
import { AllocationRequest } from '../../vo/allocationRequest';
import { AllocationService } from '../../services/api/allocation.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-allocation',
  templateUrl: './allocation.component.html',
  styleUrls: ['./allocation.component.css']
})
export class AllocationComponent implements OnInit {

  qry: AllocationRequest = new AllocationRequest();
  allocationRequests: AllocationRequest[] = [];
  vo: AllocationRequest = new AllocationRequest();
  id: number;

  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;

  log(v: any) {
    console.log(v);
  }

  constructor(protected route: ActivatedRoute,
    protected router: Router, private allocationService: AllocationService) {
  }

  ngOnInit() {

    this.getPageData(0, 10, null);
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    const me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, this.qry);
  }

  getPageData(page: number, size: number, vo: AllocationRequest) {
    const me = this;
    this.allocationService.getPages(page, size, vo).subscribe((data) => {
      // console.log(data);
      this.allocationRequests = data.items;
      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
    }
    );
  }

  getPageDataByid(id: number) {
    this.allocationService.getItemByID(id).subscribe((data) => {
      console.log(data);
      this.vo = data.item;
    }
    );
  }

  gotoNew() {
    this.router.navigate(['/home/newallocate'], { queryParams: { 'action': 'new' } });
  }

  gotoEdit(todo) {
    this.router.navigate
      (['/home/editallocation'], { queryParams: { 'id': todo.id } });
  }

  accept(todo) {
    this.router.navigate
      (['/home/newallocate'], { queryParams: { 'id': todo.id, 'action': 'accept' } });
  }

  reject(todo) {
    this.router.navigate
      (['/home/requestreject'], { queryParams: { 'id': todo.id } });
  }

  filter() {
    const me = this;
    // console.log(this.qry);
    me.getPageData(0, 10, this.qry);
  }

}
