import { Component, OnInit } from '@angular/core';
import {Calendar} from '../../../vo/calendar';
import {CalendarService} from '../../../services/api/calendar.service';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-cal-edit',
  templateUrl: './cal-edit.component.html',
  styleUrls: ['./cal-edit.component.css']
})
export class CalEditComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              private calService: CalendarService) { }

  public id: number;
  vo: Calendar = new Calendar();
  action: string;

  public alerts: any = [];

  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.action = p['action'];
      this.getItem(this.id);
    });
  }

  getItem(id: number) {
    this.calService.getItemByID(id).subscribe((data) => {
        this.vo = data.item;
      }
    );
  }
  backToListPage() {
    this.router.navigate(['/home/bspCal']);
  }

  update() {
    if (!this.vo.tiGenerateTime) {
      alert('Ti generate time is required.');
      return;
    }
    if (!this.vo.timezone) {
      alert('Timezone is required.');
      return;
    }
    this.calService.updateItem(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }
  add() {
    if (!this.vo.timezone) {
      alert('Timezone is required.');
      return;
    }
    if (!this.vo.tiGenerateTime) {
      alert('Ti generate time is required.');
      return;
    }
    this.calService.newItem(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
}
