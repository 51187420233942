import { Injectable } from '@angular/core';
import { UserInfoService } from './user-info.service';
import { LoginService } from './api/login.service';
import { Router, CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private router: Router,
        private loginService: LoginService,
        private userInfoService: UserInfoService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url: string = state.url;
        return this.checkLogin(url);
        // return true;
    }

    canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        return this.canActivate(route, state);
    }

    checkLogin(url: string): boolean {

        if (this.userInfoService.isLoggedIn() && this.checkPermission(url, this.userInfoService.getUserRole())) {
            return true;
        } else {
         // alert('DONT have access permission...');
        }
        // Store the original url in login service and then redirect to login page
        this.loginService.landingPage = url;
        this.router.navigate(['login', ]);
        return false;
    }

    checkPermission(url: string, role: string): boolean {
      console.log(url);
      console.log(role);
      if (url.indexOf('/home') > -1) {
        if (role == 'ADMIN') {
          return true;
        } else {
          return false;
        }

      } else if (url.indexOf('/gdshome') > -1) {

        if (role == 'USER') {
          return true;
        } else {
          return false;
        }

      } else {
        return false;
      }
    }
}
