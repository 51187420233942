
<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Document Subtypes Maintenance</H1>
  </div>
</div>
<!-- title -->


<!-- body mt-3 -->
<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered" >
    <div class="col-12">
      <form role="form" name="baseDynaForm" method="post" action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2FdocumentSubtypes.do&_sorig=%2FdocumentSubtypes.do">
        <input type="hidden" name="snaptable_oldfilterValues" value="" />
        <div class="row">
          <div class="form-group mt-4 col-sm-4">
            <label for="snaptable_dstName">Subtype Name:</label>
            <input type="text" name="snaptable_dstName" maxlength="100" value="" [(ngModel)]="qry.dstName">
          </div>
          <div class="form-group col-sm-3 mt-4 ml-5">
            <input type="submit" name="snaptable_filter" value="Filter" (click)="filter()">
          </div>
        </div>

      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered" style="font-size:12px">
        <thead><tr style="background-color:#F2F5FC;text-align:center"><td title="Edit record" width="20px" > </td><td title="Delete record" width="20" ></td> <td colspan="3" >Subtype Name</td></tr></thead>
        <tbody *ngIf="docSubTypes.length > 0">
          <tr class="Waving1" *ngFor="let todo of docSubTypes" >
            <td><img border="0" src="assets/SNAPDocroot//images/tableview/edit.gif" alt="Edit" (click)="gotoEdit(todo)" title="Modify this record"></td>
            <td><img border="0" src="assets/SNAPDocroot//images/tableview/delete.gif" alt="Delete" (click)="gotoDelete(todo)" title="Delete this record"></td>
            <td>{{todo.dstName}}</td>
          </tr>
        </tbody>
        <tbody *ngIf="docSubTypes.length == 0">
        <tr class="Waving1" >
          <td colspan="5">No Data...</td>

        </tr>
        </tbody>

      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <div class="row form-group table-bordered">
    <div class="offset-1">
      <input type="submit" name="create" value="Create" (click)="gotoNew()">
    </div>
    <div class="offset-3">
      <input type="submit" name="refresh" value="Refresh" (click)="filter()">
    </div>
  </div>
  <!-- button end -->

  <!-- table -->

</div>
