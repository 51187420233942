<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Email Template Details</H1>
  </div>
</div>
<!-- title -->

<!-- body -->
<div class="col-10 offset-1 table-bordered">
  <!-- Email -->
  <div class="row mt-3 ml-1" style="font-size: 15px"><h1>Edit E-mail Template</h1></div>

  <form #templateDetailForm="ngForm">
    <div class="row">
      <div class="col-2 table-bordered pt-2 pb-2" style="background-color: #E8EAED">
        Template Name :
      </div>
      <div class="col-10 table-bordered pt-2 pb-2" style="background-color: #F2F5FC">
        <input type="text" name="name" maxlength="100" size="40" [(ngModel)]="vo.name" required #name="ngModel">
        <font color="#ff0000" size="4">*</font>
        <span *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger">
          <span *ngIf="name.errors.required">
            Template Name is required.
          </span>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-2 table-bordered pt-2 pb-2" style="background-color: #E8EAED">
        Template Subject :
      </div>
      <div class="col-10 table-bordered pt-2 pb-2" style="background-color: #F2F5FC">
        <input type="text" name="subject" maxlength="100" size="40" [(ngModel)]="vo.subject" required #subject="ngModel">
        <font color="#ff0000" size="4">*</font>
        <span *ngIf="subject.invalid && (subject.dirty || subject.touched)" class="alert alert-danger">
          <span *ngIf="subject.errors.required">
            Template Subject is required.
          </span>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-2 table-bordered pt-2 pb-2" style="background-color: #E8EAED">
        Template Body :
      </div>

      <div class="col-10 table-bordered pt-2 pb-2" style="background-color: #F2F5FC">
        <div class="row">
          <div class="col-4 pt-2 pb-2" style="background-color: #FFFFFF">
            GDS
          </div>
          <div class="col-4 pt-2 pb-2" style="background-color: #FFFFFF">
            BSP
          </div>
          <div class="col-4 pt-2 pb-2" style="background-color: #FFFFFF">
            Printer
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <input type="button" class="btn" (click)="insertPattern('%GDS_CODE%');" value="GDS CODE" name="gds_code"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%GDS_NAME%');" value="GDS NAME" name="gds_name"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%GDS_EMAIL%');" value="GDS EMAIL" name="gds_email"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%GDS_IINET_ACCOUNT%');" value="GDS IINET ACCOUNT" name="gds_iinet_account"/><br/>
          </div>

          <div class="col-4">
            <input type="button" class="btn" (click)="insertPattern('%' + bspPrefix + 'BSP_CODE%');" value="BSP CODE" name="bsp_code"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%' + bspPrefix + 'BSP_NAME%');" value="BSP NAME" name="bsp_name"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%' + bspPrefix + 'BSP_EMAIL%');" value="BSP EMAIL" name="bsp_email"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%' + bspPrefix + 'BSP_ISO_CODE%');" value="BSP ISO CODE" name="bsp_iso_code"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%' + bspPrefix + 'SOURCE_BSP_NAME%');" value="SOURCE BSP NAME" name="source_bsp_name"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%' + bspPrefix + 'DEST_BSP_NAME%');" value="DEST BSP NAME" name="dest_bsp_name"/><br/>
            <input type="radio" name="bspPrefix" value="" id="standard" checked="true" [(ngModel)]="bspPrefix"/>
            <label for="standard">Standard</label><br/>
            <input type="radio" name="bspPrefix" value="SOURCE_" id="from" [(ngModel)]="bspPrefix"/>
            <label for="from">Source</label><br/>
            <input type="radio" name="bspPrefix" value="DEST_" id="to" [(ngModel)]="bspPrefix"/>
            <label for="to">Destination</label>
          </div>

          <div class="col-4">
            <input type="button" class="btn" (click)="insertPattern('%PRINTER_CODE%');" value="PRINTER CODE" name="printer_code"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%PRINTER_NAME%');" value="PRINTER NAME" name="printer_name"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%PRINTER_EMAIL%');" value="PRINTER EMAIL" name="printer_email"/><br/>
          </div>
        </div>

        <div class="row">
          <div class="col-4 pt-2 pb-2" style="background-color: #FFFFFF">
            DPC
          </div>
          <div class="col-4 pt-2 pb-2" style="background-color: #FFFFFF">
            Form Code
          </div>
          <div class="col-4 pt-2 pb-2" style="background-color: #FFFFFF">
            Allocation
          </div>
        </div>

        <div class="row">
          <div class="col-4">
            <input type="button" class="btn" (click)="insertPattern('%DPC_CODE%');" value="DPC CODE" name="dpc_code"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%DPC_NAME%');" value="DPC NAME" name="dpc_name"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%DPC_EMAIL%');" value="DPC EMAIL" name="dpc_email"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%DPC_IINET_ACCOUNT%');" value="DPC IINET ACCOUNT" name="dpc_iinet_account"/><br/>
          </div>

          <div class="col-4">
            <input type="button" class="btn" (click)="insertPattern('%FORM_CODE%');" value="FORM CODE" name="form_code"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%FORM_DOCTYPE%');" value="FORM DOCTYPE" name="form_doctype"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%AUTO_RETRIEVED%');" value="AUTO RETRIEVED" name="auto_retrieved"/><br/>
          </div>

          <div class="col-4">
            <input type="button" class="btn" (click)="insertPattern('%ALLOCATION_TABLE%');" value="ALLOCATION TABLE" name="allocation_table"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%BLACKLIST_TABLE%');" value="BLACKLIST TABLE" name="blacklist_table"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%ALLOCATION_DATE%');" value="ALLOCATION DATE" name="allocation_date"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%TODAY%');" value="TODAY" name="today"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%REASON%');" value="REASON" name="reason"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%BSP_ALLOCATIONS%');" value="BSP ALLOCATIONS" name="bsp_allocations"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%PURCHASE_ORDER_NUMBER%');" value="PURCHASE ORDER NUMBER" name="purchase_order_number"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%ORIGINAL_ALLOCATION_TABLE%');" value="ORIGINAL ALLOCATION TABLE" name="original_allocation_table"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%TIFILE%');" value="TIFILE" name="tifile"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%VALIDITY_DATE_TEMPLATE%');" value="VALIDITY DATE TEMPLATE" name="validity_date_template"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%TOTAL_QTY%');" value="TOTAL QTY" name="total_qty"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%USED_ALLOCATION_TABLE%');" value="USED ALLOCATION TABLE" name="used_allocation_table"/><br/>
            <input type="button" class="btn" (click)="insertPattern('%RETRIEVAL_DATE%');" value="RETRIEVAL DATE" name="retrieval_date"/>
          </div>

          <div class="col-12">
            <b>Click on a pattern from the above lists<br/>
              to insert it at the end of the template editing area.</b> <br/>
          </div>

          <div class="col-12">
            <ckeditor [(ngModel)]="vo.body" [attr.height.px]="320" [config]="{extraPlugins: 'divarea'}" name="editor" required #editor="ngModel"></ckeditor>
            <div *ngIf="editor.invalid && (editor.dirty || editor.touched)" class="alert alert-danger">
              <div *ngIf="editor.errors.required">
                Template Body is required.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-2 pb-2 table-bordered">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>

    <div class="row table-bordered">
      <div class="col-3 mt-1 mb-1 offset-3">
        <input type="submit" name="update" value="Update" [disabled]="!templateDetailForm.form.valid" (click)="update()">
      </div>
      <div class="col-3 mt-1 mb-1 offset-1">
        <input type="submit" name="back" value="Cancel" (click)="goBack()">
      </div>
    </div>
  </form>

  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
  </div>
</div>
