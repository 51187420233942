import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRequestEXService } from './api-request-ex.service';
import { Bulletin } from '../../vo/bulletin';
import { MailVo } from '../../vo/mailVo';

@Injectable()
export class BulletinService {
  mailMessage: MailVo;

  constructor(private apiRequest: ApiRequestEXService) {
  }

  getPreviousBulletinInfo(): Observable<any> {
    return this.apiRequest.get('api/previousbulletin');
  }

  generateBulletin(): Observable<any> {
    return this.apiRequest.get('api/generatebulletin');
  }

  sendBulletin(bulletin: Bulletin): Observable<any> {
    return this.apiRequest.put('api/sendbulletin', bulletin);
  }

  sendEmail(message: MailVo): Observable<any> {
    this.mailMessage = message;
    return this.apiRequest.put('api/sendemail', message);
  }

  addMessage(message: MailVo) {
    this.mailMessage = message;
  }

}
