import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { EmailTemplate } from '../../vo/emailTemplate';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class EmailTemplateService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  getPages(page?: number, size?: number, vo?: EmailTemplate): Observable<any> {
    // Create Request URL params
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (vo && vo.name) {
      params = params.append('name', vo.name);
    }
    if (vo && vo.subject) {
      params = params.append('subject', vo.subject);
    }
    console.log(params);
    return this.apiRequest.get('api/emailtemplates', params);

  }

  getItemByID(id: number): Observable<any> {
    return this.apiRequest.get('api/emailtemplate/' + id);
  }

  updateItem(vo: EmailTemplate): Observable<any> {
    return this.apiRequest.put('api/emailtemplate', vo);
  }

}
