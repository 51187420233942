<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Formcode Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered">

  <!-- title2 -->
  <div class="col-8 mt-1 ">
    <div class="row">
      <h1 *ngIf="case === 1">
        Edit Formcode
      </h1>
      <h1 *ngIf="case === 2">
        Freeze Formcode
      </h1>
      <h1 *ngIf="case === 3">
        Unfreeze Formcode
      </h1>
      <h1 *ngIf="case === 4">
        Open Formcode
      </h1>
    </div>
    <div class="row" *ngIf="!editOrView">
      <a style="color: red">This formcode is read-only because its SNs are in use.</a>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <!--
        <div class="row form-group table-bordered border-top-0" >
          <div class="col-sm-6">
            <font color="red">This formcode is read-only because its SNs are in use.</font>
          </div>
        </div>
        -->
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcCode">Form Code</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCode" id="fcCode" maxlength="3" size="20" [(ngModel)]="vo.fcNumber" readonly>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcDocTypeID">Document type</label></div>
          <div class="col-sm-9">
            <select [(ngModel)]="vo.documentType.id" name="doctype">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of docs" [value]='item.id'>{{item.dtName}} / {{item.dtType | cgn}}</option>
            </select>
          </div>
        </div>

        <div class="row form-group ">
          <div class="col-sm-3 "><label for="issueParty" >Issuing Party</label></div>
          <div class="col-sm-9">
            <select [ngModel]="vo.issueParty==null?null:vo.issueParty.id" (ngModelChange)="vo.issueParty == null ? null : vo.issueParty.id = $event"  name="issueParty">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of issus" [value]='item.id'>{{item.name}}</option>
            </select>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcResolution">Resolution</label></div>
          <div class="col-sm-9">
            <textarea name="fcResolution" cols="40" rows="8" [(ngModel)]="vo.fcReso">{{vo.fcReso}}</textarea>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label for="fcCreateDate">Creation date</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcCreateDate" size="20" [value]="vo.fcCreatedate | date:'dd/MM/yy'"
                   readonly="readonly">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-3"><label for="fcComment">Comment</label></div>
          <div class="col-sm-9">
            <textarea name="fcComment" cols="40" rows="8" [(ngModel)]="vo.fcComment">{{vo.fcComment}}</textarea>
          </div>
        </div>
        <div class="row form-group" *ngIf="editOrView">
          <div class="col-sm-3 "><label for="fcFrozenDate">Frozen date</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcFrozenDate" id="fcFrozenDate" size="20" [value]='vo.fcFrozen | date:"dd/MM/yy"'
                   readonly="readonly">
          </div>
        </div>
        <div class="row form-group " *ngIf="editOrView">
          <div class="col-sm-3 "><label for="fcFrozenFor">Years until recycle</label></div>
          <div class="col-sm-9">
            <input type="text" name="fcFrozenFor" id="fcFrozenFor" maxlength="2" size="5" [(ngModel)]="vo.fcFrozenFor">
          </div>
        </div>
        <div class="row form-group " *ngIf="editOrView">
          <div class="col-sm-3 "><label>Is Active</label></div>
          <div class="col-sm-9">
            <input type="checkbox">
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->


  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="offset-1" *ngIf="case === 2">
      <button mwlConfirmationPopover
             [popoverTitle]="title"
             [popoverMessage]="freezeMessage"
             placement="top"
             (confirm)="freeze()"
             (cancel)="cancelClicked = true">Freeze</button>
    </div>
    <div class="offset-1" *ngIf="case === 3">
      <button mwlConfirmationPopover
             [popoverTitle]="title"
             [popoverMessage]="unfreezeMessage"
             placement="top"
             (confirm)="unfreeze()"
             (cancel)="cancelClicked = true">Unfreeze</button>
    </div>
    <div class="offset-1" *ngIf="case === 4">
      <button mwlConfirmationPopover
             [popoverTitle]="title"
             [popoverMessage]="openMessage"
             placement="top"
             (confirm)="open()"
             (cancel)="cancelClicked = true">Open</button>
    </div>
    <div class="offset-1" *ngIf="fcLength == 2">
      <button mwlConfirmationPopover
             [popoverTitle]="title"
             [popoverMessage]="splitMessage"
             placement="top"
             (confirm)="split()"
             (cancel)="cancelClicked = true">Split 3digit</button>
    </div>
    <div class="offset-2" *ngIf="case === 1 && editOrView">
      <input type="button" name="new" value="Update" (click)="update()">
    </div>
    <div class="offset-2">
      <input type="button" name="back" value="Cancel" (click)="backToList()">
    </div>
  </div>
  <!-- button end -->

  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}
    </alert>
  </div>

  <!-- Form -->

</div>
<!-- body mt-3 -->


