<!-- title -->
<div class="row">
  <div class="col-11">
    <H1>Allocation Request</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-12 table-bordered" style="padding-left: 0px; padding-top: 15px;">
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout"><span *ngIf="alert.msg1.length > 0">{{ alert.msg1 }}<br></span>{{ alert.msg2 }}</alert>
  </div>
  <div class="row col-12 ml-3">
    <span style="color: #0000FF; font-size: 18px">{{msg}}</span>
  </div>
  <div class="row">
    <div class="col-4">
      <div class="row">
        <div class="col-6 table-bordered pt-2 pb-2 left">
          Pending requests
        </div>
        <div class="col-6 table-bordered pt-2 pb-2 right">
          {{statistic.pendingCount}}
        </div>
      </div>
      <div class="row">
        <div class="col-6 table-bordered pt-2 pb-2 left">
          Processed requests
        </div>
        <div class="col-6 table-bordered pt-2 pb-2 right">
          {{statistic.processedCount}}
        </div>
      </div>
      <div class="row">
        <div class="col-6 table-bordered pt-2 pb-2 left">
          Rejected requests
        </div>
        <div class="col-6 table-bordered pt-2 pb-2 right">
          {{statistic.rejectedCount}}
        </div>
      </div>

      <form *ngIf="newRequest && showNewRequest" #requestDetailForm="ngForm">
        <div>
          <div>
            <div *ngIf="isBspUser" class="row">
              <div class="col-3 table-bordered pt-2 pb-2">
                GDS
              </div>
              <div class="col-9 table-bordered pt-2 pb-2">
                <select [(ngModel)]="newRequest.gdsCode" name="gdsField" required #gdsField="ngModel">
                  <option *ngFor="let gds of gdses" [value]="gds.gdsCode">{{gds.gdsCode}}</option>
                </select>
                <font color="#ff0000" size="4">*</font>
                <span *ngIf="gdsField.invalid && (gdsField.dirty || gdsField.touched)" class="alert alert-danger">
              <span *ngIf="gdsField.errors.required">
                GDS is required.
              </span>
            </span>
              </div>
            </div>
            <div *ngIf="!isBspUser" class="row">
              <div class="col-3 table-bordered pt-2 pb-2">
                BSP
              </div>
              <div class="col-9 table-bordered pt-2 pb-2">
                <select [(ngModel)]="newRequest.bsp.id" name="bspField" required #bspField="ngModel"
                        (change)="calculate(true)">
                  <option *ngFor="let bsp of bsps" [value]="bsp.id">{{bsp.bspFullname}}</option>
                </select>
                <font color="#ff0000" size="4">*</font>
                <div *ngIf="bspField.invalid && (bspField.dirty || bspField.touched)" class="alert alert-danger">
                  <div *ngIf="bspField.errors.required">
                    BSP is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 table-bordered pt-2 pb-2">
                Document type
              </div>
              <div class="col-9 table-bordered pt-2 pb-2">
                <select [(ngModel)]="newRequest.doctype.id" name="documentType" required #documentType="ngModel"
                        (change)="calculate(false)">
                  <option *ngFor="let type of documentTypes" [value]="type.id">{{type.dtName}}</option>
                </select>
                <font color="#ff0000" size="4">*</font>
                <div *ngIf="documentType.invalid && (documentType.dirty || documentType.touched)"
                     class="alert alert-danger">
                  <div *ngIf="documentType.errors.required">
                    Document type is required.
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-3 table-bordered pt-2 pb-2">
                Quantity
              </div>
              <div class="col-9 table-bordered pt-2 pb-2">
                <input [(ngModel)]="newRequest.quantity" type="text" name="quantity" maxlength="9"
                       [disabled]="disableQuantity" title="Only Numeric value">
              </div>
            </div>
            <div class="row pt-2 pb-2">
              (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
            </div>
            <div class="row pt-2 pb-2">
              <input type="submit" name="btnAllocRequest" id="RequestAllocation" value="Request Allocation"
                     [disabled]="!requestDetailForm.form.valid || disableRequest"
                     (click)="sendNewRequest()">
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="col-8" style="padding-left: 55px;">
      <div *ngIf="isCalculate && hasHistory">
        <table width="100%" border="1" class="table  table-hover">
          <thead>
          <tr style="background-color:#F2F5FC">
            <td>Document Type</td>
            <td>Bsp Code</td>
            <td>Gds Code</td>
            <td>Create Date</td>
            <td>Quantity</td>
            <td>Status</td>
            <td>Comment</td>
          </tr>
          </thead>

          <tbody *ngIf="history.length > 0">
          <tr class="Waving1" *ngFor="let item of history">
            <td>{{item.doctype.dtName}}</td>
            <td>{{item.bsp.bspCode}}</td>
            <td>{{item.gdsCode}}</td>
            <td>{{item.createdate | date}}</td>
            <td>{{item.quantity}}</td>
            <td>{{item.status}}</td>
            <td>{{item.note}}</td>
          </tr>
          </tbody>
        </table>
        <div>
          <pagination name="page" [totalItems]="historyTotalItems" [(ngModel)]="historyCurrentPage"
                      (pageChanged)="historyPageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                      [boundaryLinks]="true"></pagination>
        </div>

        <span>For document type {{newRequest.doctype.dtName}} and BSP {{newRequest.bsp.bspFullname}}, please see the above list for historical request processed.</span>
        <br>
        <span>For the {{calType}} calculation, the allowed quantity is <b>{{calResult}}</b></span>
      </div>

      <div *ngIf="isCalculate && !hasHistory">
        <font color="#0000FF">No historical data exists for this request. A maximum of 10,000 numbers can be requested
          to start with. Should this not be sufficient, please enter the volume required
          and the request will be put on hold and processed within 1 working day by SNAP admin pending your
          justification is sent to snap&#64;iata.org</font>
      </div>
    </div>
  </div>
  <div style="height: 30px;">
    <div *ngIf="!showNewRequest" class="row col-5">
      <div class="col-6 mt-1 mb-1 left">
        <input type="submit" name="new-request" value="New request" (click)="addRequest()">
      </div>
      <div class="col-6 mt-1 mb-1 right">
        <input type="submit" name="request-details" value="{{showDetails ? 'Hide' : 'Request'}} details"
               (click)="loadDetails()">
      </div>
    </div>
  </div>

  <div *ngIf="showDetails" class="row mt-3">
    <div class="col-12">
      <table width="100%" border="0" class="table  table-hover">
        <thead>
        <tr style="background-color:#F2F5FC">
          <td>&nbsp;</td>
          <td>Document Type</td>
          <td>Bsp Name</td>
          <td>Gds Code</td>
          <td>Create Date</td>
          <td>Quantity</td>
          <td>Status</td>
          <td>Comment</td>
        </tr>
        </thead>

        <tbody *ngIf="details.length > 0">
        <tr class="Waving1" *ngFor="let item of details">
          <td *ngIf="item.status === 'PENDING'"><img border="0" src="assets/SNAPDocroot/images/tableview/delete.gif"
                                                     alt="Cancel the Request" title="Cancel the Request"
                                                     (click)="goCancel(item)"></td>
          <td *ngIf="item.status !== 'PENDING'"></td>
          <td>{{item.doctype.dtName}}</td>
          <td>{{item.bsp.bspFullname}}</td>
          <td>{{item.gdsCode}}</td>
          <td>{{item.createdate | date}}</td>
          <td>{{item.quantity}}</td>
          <td>{{item.status}}</td>
          <td>{{item.note}}</td>
        </tr>
        </tbody>

        <tbody *ngIf="details.length == 0">
        <tr class="Waving1">
          <td colspan="5">No Data...</td>
        </tr>
        </tbody>

      </table>
      <div class="col-md-6">
        <pagination [totalItems]="detailsTotalItems" [(ngModel)]="detailsCurrentPage"
                    (pageChanged)="detailsPageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
</div>
