import { Component, OnInit } from '@angular/core';
import {BspTiCalendar} from '../../vo/bspTiCalendar';
import {BspTiCalendarService} from '../../services/api/bsp-ti-calendar.service';
import {Calendar} from '../../vo/calendar';

@Component({
  selector: 'app-bsp-ti-calendar',
  templateUrl: './bsp-ti-calendar.component.html',
  styleUrls: ['./bsp-ti-calendar.component.css']
})
export class BspTiCalendarComponent implements OnInit {

  // BspTiCalendar: BspTiCalendar = new BspTiCalendar();
  bspTiCalendars: BspTiCalendar[] = [];
  // calendar: Calendar = new Calendar();
  time: Date;
  dayTime = '';

  minDate = new Date(1990, 1, 1);
  maxDate = new Date(2200, 1, 1);
  today = new Date();
  constructor(private myservice: BspTiCalendarService) {}

  ngOnInit() {
    // this.BspTiCalendar.calendar = this.calendar;
  }

  filter() {
    if (this.time === undefined) {
      alert('Time is required!');
      return;
    }

    this.dayTime = this.time.getFullYear() + '-' +  (this.time.getMonth() + 1) + '-' + this.time.getDate();
    this.myservice.getCalendars(this.dayTime).subscribe((data) => {
        console.log(data);
        this.bspTiCalendars = data.items;
        console.log(this.bspTiCalendars);
      }
    );
  }
}
