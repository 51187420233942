import {Component, OnInit} from '@angular/core';
import {Bsp} from '../../vo/bsp';
import {Document} from '../../vo/document';
import {MonitorUsage} from '../../vo/monitorUsage';
import {BspService} from '../../services/api/bsp.service';
import {MonitorUsageService} from '../../services/api/monitorUsage.service';
import {DocumentTypeService} from '../../services/api/documentType.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import {UserInfoService} from '../../services/user-info.service';
import {CurrentStatus} from '../../vo/currentStatus';
import {Snstatus} from '../../vo/snstatus';
import {Opstatus} from '../../vo/opstatus';
import {PrintActionHistoryVo} from '../../vo/printActionHistoryVo';
import {PrintCurrentStatusVo} from '../../vo/printCurrentStatusVo';
import {AngularCsv} from 'src/app/services/angular-csv';

@Component({
  selector: 'app-monitor-usage-external',
  templateUrl: './monitor-usage-external.component.html',
  styleUrls: ['./monitor-usage-external.component.css']
})
export class MonitorUsageExternalComponent implements OnInit {

  public bigTotalItems: number;
  public maxSize = 5;
  public bigCurrentPage = 1;
  public alerts: any = [];

  vo: MonitorUsage = new MonitorUsage();
  qryvo: CurrentStatus = new CurrentStatus();
  activeBsps: Bsp[] = [];
  activeBsp: Bsp = new Bsp();
  docTypes: Document[] = [];
  docType: Document = new Document();
  printCurentStatuss: PrintCurrentStatusVo[] = []; // export current status to csv
  curentStatuss: CurrentStatus[] = [];
  curentStatussCSV: CurrentStatus[] = [];
  snstatuss: Snstatus[] = [];
  stage = 0; // 0 New Search 1 Current status 2 Historical status

  // snstatuss: Snstatus[] = [];
  snstatus: Snstatus = new Snstatus();
  opstatuss: Opstatus[] = [];

  // vo: MonitorUsage = new MonitorUsage();
  historicalStatus: CurrentStatus[] = [];
  historicalStatusCSV: CurrentStatus[] = [];

  printActionHistories: PrintActionHistoryVo[] = [];
  param: any;
  dtName: string;
  private isSpinnerActive: boolean;

  constructor(private router: Router, private docTypeService: DocumentTypeService,
              protected route: ActivatedRoute, private myService: MonitorUsageService, private bspService: BspService, private userInfoService: UserInfoService) {
  }

  private onClick() {
    this.isSpinnerActive = !this.isSpinnerActive;
  }

  ngOnInit() {
    this.vo.gdsCode = this.userInfoService.getGdsCode();
    this.vo.docType = this.docType;
    this.vo.docType.dtStatus = 'active';
    this.docTypeService.getActiveDocsForGds().subscribe((data) => {
        this.docTypes = data.items;
        // console.log( data.items);
      }
    );
    this.vo.activeBsp = this.activeBsp;
    this.bspService.getAllBspsByGds(this.vo.gdsCode).subscribe((data) => {
      // console.log( data.items);
      this.activeBsps = data.items;
    });
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);

    if (this.stage === 1) {
      this.myService.getCurrentstatusPagesByGds(event.page - 1, event.itemsPerPage, this.qryvo).subscribe((data) => {
        this.curentStatuss = data.items;

        this.bigTotalItems = data.totalItems;
        this.bigCurrentPage = data.currentPageNumber + 1;
      });
    } else {
      this.myService.getActionHistoryPagesByGds(event.page - 1, event.itemsPerPage, this.qryvo).subscribe((data) => {
        console.log(data.items);
        this.dtName = data.dtName;
        this.historicalStatus = data.items;
        this.isSpinnerActive = !this.isSpinnerActive;
        this.bigTotalItems = data.totalItems;
        this.bigCurrentPage = data.currentPageNumber + 1;
      });
    }
  }

  currentStatusE() {

    if (!(this.ckFormcode() || this.ckBsp() || this.ckDocType() || this.ckSerialNumber())) {
      alert('Please specify any of the search criteria or select the status code.');
      return;
    }


    console.log(this.vo);
    this.stage = 1;
    // this.router.navigate(['/gdshome/currentstatusexternal'],{queryParams: {'formcodeId' : this.vo.formcodeId, 'serialNumber':this.vo.serialNumber,
    //   'docType': this.vo.docType.id, 'gdsCode': this.vo.gdsCode
    //   }} );
    // this.qryvo.formcodeId = this.vo;

    this.qryvo.formcodeId = this.vo.formcodeId;

    if (this.vo.docType.id > 0) {
      this.qryvo.docType = '' + this.vo.docType.id;
    }

    this.qryvo.serialNumber = this.vo.serialNumber;
    if (this.vo.activeBsp.id > 0) {
      this.qryvo.activeBsp = '' + this.vo.activeBsp.id;
    }

    this.qryvo.gds = this.vo.gdsCode;
    this.bigCurrentPage = 1;
    this.myService.getCurrentstatusPagesByGds(0, 10, this.qryvo).subscribe((data) => {
      this.curentStatuss = data.items;

      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
    });

    // this.vo.snstatus = this.snstatus;
    this.myService.getAllSnstatus().subscribe((data) => {
      this.snstatuss = data.items;
    });
  }

  //
  // //actionHistory
  actionHistoryE() {

    if (!(this.ckFormcode() || this.ckBsp() || this.ckDocType() || this.ckSerialNumber())) {
      alert('Please specify any of the search criteria or select the status code.');
      return;
    }

    this.stage = 2;

    this.qryvo.formcodeId = this.vo.formcodeId;

    if (this.vo.docType.id > 0) {
      this.qryvo.docType = '' + this.vo.docType.id;
    }

    this.qryvo.serialNumber = this.vo.serialNumber;
    if (this.vo.activeBsp.id > 0) {
      this.qryvo.activeBsp = '' + this.vo.activeBsp.id;
    }

    this.qryvo.gds = this.vo.gdsCode;
    // this.isSpinnerActive = !this.isSpinnerActive;

    this.bigCurrentPage = 1;
    this.myService.getActionHistoryPagesByGds(0, 10, this.qryvo).subscribe((data) => {
      console.log(data.items);
      this.dtName = data.dtName;
      this.historicalStatus = data.items;
      // this.isSpinnerActive = !this.isSpinnerActive;
      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
    });


    // this.vo.snstatus = this.snstatus;
    this.myService.getAllSnstatus().subscribe((data) => {
      console.log(data.items);
      this.snstatuss = data.items;
    });
    this.myService.getAllOpstatus().subscribe((data) => {
      this.opstatuss = data.items;
    });

  }

  backToSearch() {
    this.stage = 0;
    this.curentStatuss = [];
    this.snstatuss = [];
    this.opstatuss = [];
    this.historicalStatus = [];
    // this.vo = new MonitorUsage();
    this.qryvo = new CurrentStatus();
  }

  ckFormcode() {
    if (this.vo.formcodeId == null || this.vo.formcodeId.length === 0) {
      return false;
    }
    return true;
  }

  ckSerialNumber() {
    if (this.vo.serialNumber == null || this.vo.serialNumber.length === 0) {
      return false;
    }
    return true;
  }

  ckBsp() {
    if (this.vo.activeBsp.id == null || this.vo.activeBsp.id < 1) {
      return false;
    }
    return true;
  }

  ckDocType() {
    if (this.vo.docType.id == null || this.vo.docType.id < 1) {
      return false;
    }
    return true;
  }

  exportCsv() {

    this.myService.getCurrentstatusPagesByGds(0, 1000, this.qryvo).subscribe((data) => {
      this.curentStatussCSV = data.items;

      for (const item of this.curentStatussCSV) {
        const printCurentStatusVo: PrintCurrentStatusVo = new PrintCurrentStatusVo();
        printCurentStatusVo.formcode = item.formcode;
        printCurentStatusVo.doc = item.doc;
        printCurentStatusVo.subDoc = item.subDoc;
        printCurentStatusVo.StartEndNumber = item.rangeStart.concat('-').concat(item.rangeEnd);
        printCurentStatusVo.quantity = item.quantity;
        printCurentStatusVo.statusCode = item.statusCode;
        if (item.bsp != null && item.bspCode != null) {
          printCurentStatusVo.bspCode = item.bsp.concat('(').concat(item.bspCode).concat(')');
        } else {
          printCurentStatusVo.bspCode = null;
        }
        printCurentStatusVo.gdsCode = item.gdsCode;
        printCurentStatusVo.print = item.printer;
        printCurentStatusVo.validate = item.validityDate;
        printCurentStatusVo.purOderNum = item.purchaseOrderNumber;
        printCurentStatusVo.schDate = item.schDate;
        printCurentStatusVo.snapNo = item.snapNo;

        this.printCurentStatuss.push(printCurentStatusVo);

      }
      const options = {
        headers: ['Formcode', 'Doc.type', 'Doc.subtype', 'StartEndnumber',
          'Quantity', 'Statuscode', 'BSP', 'GDS', 'Printer', 'Validity date', 'PurchaseOrderNo',
          'Statusdate', 'SNAPNo.']
      };
      new AngularCsv(this.printCurentStatuss, 'Current Status Report', options);
    });
  }

  exportHitoricalCsv() {
    this.myService.getActionHistoryPagesByGds(0, 1000, this.param).subscribe((data) => {
      this.historicalStatusCSV = data.items;
      // console.log(data.items);
      for (const item of this.historicalStatus) {
        const printVo: PrintActionHistoryVo = new PrintActionHistoryVo();
        printVo.formcode = item.formcode;
        printVo.doc = item.doc;
        printVo.subDoc = item.subDoc;
        printVo.StartEndNumber = item.rangeStart.concat(' ').concat(item.rangeEnd);
        printVo.quantity = item.quantity;
        printVo.opCode = item.opCode;
        printVo.snCode = item.statusCode;
        if (item.bspCode != null) {
          printVo.bspCode = item.bsp.concat('(').concat(item.bspCode).concat(')');
        } else {
          printVo.bspCode = null;
        }
        printVo.gdsCode = item.gdsCode;
        printVo.print = item.print;
        printVo.validate = item.valDate;
        printVo.purOderNum = item.purchaseOrderNumber;
        printVo.statusDate = item.statusDate;
        printVo.snapNo = item.snapNo;

        this.printActionHistories.push(printVo);
      }
      // console.log(this.printActionHistories);
      const options = {
        headers: ['Form code', 'Doc. type', 'Doc. subtype', 'Start/End number', 'Quantity',
          'Opn code', 'Status code', 'BSP (from/to)', 'GDS', 'Printer', 'Validity date', 'Purchase Order No.', 'Status date', 'SNAP No.']
      };
      new AngularCsv(this.printActionHistories, 'Action History Report', options);
    });
  }

}
