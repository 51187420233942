<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>GDS Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" >

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>Edit GDS</h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form name="gdsDetailDynaForm" method="post" action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2FgdsDetail.do&_sorig=%2Fgds.do">
        <input type="hidden" name="pkID" value="">
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="code" >GDS code :</label></div>
          <div class="col-sm-9">
            <input type="text" name="code" maxlength="10" size="40" value="" [(ngModel)]="vo.gdsCode">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="email">GDS email :</label></div>
          <div class="col-sm-9 ">
            <input type="text" name="email" maxlength="100" size="40" value="" [(ngModel)]="vo.gdsEmail">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="email1">GDS additional email :</label></div>
          <div class="col-sm-9 ">
            <input type="text" name="email1" maxlength="100" size="40" value="" [(ngModel)]="vo.gdsEmail1">
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="email2">GDS additional email :</label></div>
          <div class="col-sm-9 ">
            <input type="text" name="email2" maxlength="100" size="40" value="" [(ngModel)]="vo.gdsEmail2">
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="email3">GDS additional email :</label></div>
          <div class="col-sm-9 ">
            <input type="text" name="email3" maxlength="100" size="40" value=""  [(ngModel)]="vo.gdsEmail3">
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="email4">GDS additional email :</label></div>
          <div class="col-sm-9 ">
            <input type="text" name="email4" maxlength="100" size="40" value="" [(ngModel)]="vo.gdsEmail4">
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="user">GDS User:</label></div>
          <div class="col-sm-9 ">
            <input type="text" name="user" maxlength="100" size="40" value=""  [(ngModel)]="vo.gdsUser">
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->
  <!-- alert -->
  <div class="row form-group">
    <div class="ml-3 mt-3">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>
  </div>
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="col-sm-3" *ngIf="!isupdate">
      <input type="submit" name="new" value="Delete" (click)="delete()" >
    </div>
    <div class="col-sm-3" *ngIf="isupdate">
      <input type="submit" name="new" value="Update" (click)="update()" >
    </div>
    <div class="offset-3">
      <input type="submit" name="back" value="Cancel" (click)="backToListPage()">
    </div>
  </div>
  <!-- button end -->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>

  <!-- Form -->

</div>
<!-- body mt-3 -->
