<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>DPC Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" >

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>Create new DPC</h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start -->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form role="form" name="bspDetailDynaForm" method="post" action="">
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="code" >DPC code :</label></div>
          <div class="col-sm-9">
            <input type="text" id="code" name="code" maxlength="20" size="40" value="" [(ngModel)]="vo.dpcCode">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="email" > DPC email :</label></div>
          <div class="col-sm-9">
            <input type="text" id="email" name="email" maxlength="100" size="40" value="" [(ngModel)]="vo.dpcEmail">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="iinet" >  DPC IINET Account:</label></div>
          <div class="col-sm-9">
            <input type="text" id="iinet" name="iinet" maxlength="50" size="40" value="" [(ngModel)]="vo.dpcIinetAccount">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->
  <!-- alert -->
  <div class="row form-group">
    <div class="ml-3 mt-3">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>
  </div>
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group mt-2 table-bordered">

    <div class="col-sm-3" *ngIf="deleteOperation">
      <input type="submit" name="new" value="Delete" (click)="deleteDpc()" >
    </div>
    <div class="col-sm-3" *ngIf="!deleteOperation">
      <input type="submit" name="new" value="Update" (click)="updateDpc()" >
    </div>

    <div class="col-sm-9">
      <input type="submit" name="back" value="Cancel" (click)="backToDpc()">
    </div>
  </div>
  <!-- button end -->

  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>

  <!-- Form -->

</div>
<!-- body mt-3 -->

