import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AllocationRequest } from '../../vo/allocationRequest';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class AllocationRequestService {
  private _newRequest: AllocationRequest;
  private _selectRequest: AllocationRequest;
  private _calResult: number;
  private _oneHistoryData = false;

  constructor(private apiRequest: ApiRequestEXService, private router: Router) {
  }

  findAllocationRequestById(id: number): Observable<any> {
    return this.apiRequest.get('api/AllocationRequest/' + id);
  }

  getAllocationRequestStatistic(): Observable<any> {
    return this.apiRequest.get('api/AllocationRequestStatistic');
  }

  findAllocationRequestDetails(page: number, size: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    console.log(params);
    return this.apiRequest.get('api/AllocationRequestDetails', params);
  }

  findHistoryAllocationRequest(page: number, size: number, docID: number, bspID: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    params = params.append('docID', docID.toString());
    params = params.append('bspID', bspID.toString());
    console.log(params);
    return this.apiRequest.get('api/AllocationRequestHistory', params);
  }

  newAllocationRequest(): Observable<any> {
    return this.apiRequest.get('api/AllocationRequestNew');
  }

  calculation(request: AllocationRequest): Observable<any> {
    return this.apiRequest.put('api/AllocationRequestCalculate', request);
  }

  sendRequest(request: AllocationRequest, calResult: number) {
    this._calResult = calResult;
    this.apiRequest.put('api/AllocationRequestSend', request).subscribe((response) => {
      console.log(response);
      this._newRequest = response.item;

      this._oneHistoryData = response.oneHistoryData;
      if (this._calResult < this._newRequest.quantity) {
        this._newRequest.autoFlag = '0';
      }
      this.router.navigateByUrl('/gdshome/allocationreqconfirm');
    });
  }

  submitRequest(request: AllocationRequest): Observable<any> {
    return this.apiRequest.put('api/AllocationRequestSubmit', request);
  }

  cancelRequest(request: AllocationRequest) {
    this._selectRequest = request;
  }

  submitCancel(request: AllocationRequest): Observable<any> {
    return this.apiRequest.put('api/AllocationRequestCancel', request);
  }

  get newRequest(): AllocationRequest {
    return this._newRequest;
  }

  get selectRequest(): AllocationRequest {
    return this._selectRequest;
  }

  get calResult(): number {
    return this._calResult;
  }

  get oneHistoryData(): boolean {
    return this._oneHistoryData;
  }
}
