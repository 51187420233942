<div class="row">
  <div class="col-10 offset-1">
    <H1>BSP Calendar</H1>
  </div>
</div>

<div class="col-8 offset-1 table-bordered">

  <div class="col-8 mt-1 ">
    <div class="row">
      <h1>
        BSP Calendar Batch Edit
      </h1>
    </div>
  </div>

  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form role="form">
        <div class="row form-group">
          <div class="col-sm-12">
            <label>You have selected {{checkedBsps.length}} BSP(s), please double check then batch edit BSP Ti Job.</label>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-12">
            <table class="table table-bordered">
              <thead>
                <tr style="background-color: #F2F5FC;color:#1B548E">
                  <td>BSP Code</td>
                  <td>BSP Name</td>
                  <td>DPC</td>
                  <td>Timezone</td>
                </tr>
              </thead>
              <tbody>
                <tr class="Waving1" *ngFor="let bsp of checkedBsps">
                  <td>{{bsp.bspCode}}</td>
                  <td>{{bsp.bspFullname}}</td>
                  <td>{{bsp.dpc.dpcCode}}</td>
                  <td *ngIf="bsp.bspCal">{{bsp.bspCal.timezone}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row form-group">
          <div class="col-sm-3"><label>BSP Ti Job</label></div>
          <div class="col-sm-9">
            <select [(ngModel)]="bspCal.id" name="bspCal">
              <option value="0" selected="selected">-</option>
              <option *ngFor="let item of cals" [value]='item.id'>{{item.timezone}}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>

  <div class="row">
    <div class="offset-1 col-1">
      <input type="button" name="update" value="Update" (click)="update()">
    </div>
    <div class="offset-1 col-1">
      <input type="button" name="back" value="Cancel" (click)="backToListPage()">
    </div>
  </div>
</div>

<div *ngFor="let alert of alerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
</div>