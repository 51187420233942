import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AllocationPulsService} from '../../services/api/allocation-puls.service';
import {DatePipe} from '@angular/common';
import {AllocationPuls} from '../../vo/allocationPuls';

@Component({
  selector: 'app-allocation-puls',
  templateUrl: './allocation-puls.component.html',
  styleUrls: ['./allocation-puls.component.css']
})
export class AllocationPulsComponent implements OnInit {

  bspId: string;
  gdsId: string;
  startDate: string;
  endDate: string;

  allocationPulses: AllocationPuls[] = [];

  minDate = new Date(1990, 5, 10);
  maxDate = new Date(2090, 5, 10);

  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;

  log(v: any) {
    console.log(v);
  }

  constructor( protected route: ActivatedRoute,
               protected router: Router, private allocationPulsService: AllocationPulsService, private datePipe: DatePipe) {
    this.endDate =  this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.startDate =  this.datePipe.transform((new Date().getTime() - 3600 * 24 * 7 * 1000) , 'yyyy-MM-dd');
  }
  ngOnInit() {
    this.getPageData(0, 10, null, null ,  this.startDate  ,   this.endDate);
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    const me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, this.bspId, this.gdsId, this.startDate, this.endDate);
  }

  getPageData(page: number, size: number, bspId: string, gdsId: string, startDate: string, endDate: string) {
    const me = this;
    this.allocationPulsService.getPages(page, size, bspId, gdsId, startDate, endDate).subscribe((data) => {
        console.log(data.items);
        this.allocationPulses = data.items;
        console.log(this.allocationPulses);
        this.bigTotalItems = data.totalItems;
        this.bigCurrentPage = data.currentPageNumber + 1;
      }
    );
  }

  filter() {
    const me = this;
    me.getPageData(0, 10, this.bspId, this.gdsId, this.startDate, this.endDate);
  }
}
