import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EmailTemplate} from '../../vo/emailTemplate';
import {EmailTemplateService} from '../../services/api/emailTemplate.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.css']
})

export class EmailComponent implements OnInit {

  qry: EmailTemplate = new EmailTemplate();
  emailTemplates: EmailTemplate[] = [];

  maxSize = 5;
  totalItems = 0;
  currentPage = 1;
  numPages = 0;

  constructor(private router: Router, private service: EmailTemplateService) {
  }

  ngOnInit() {
    this.getPageData(0, 10, null);
  }

  pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.getPageData(event.page - 1, event.itemsPerPage, this.qry);
  }

  getPageData(page: number, size: number, vo: EmailTemplate) {
    this.service.getPages(page, size, vo).subscribe((data) => {
        console.log(data);
        this.emailTemplates = data.items;
        this.totalItems = data.totalItems;
      }
    );
  }

  gotoEdit(item) {
    this.router.navigate(['/home/editemailtemplate'], {queryParams: {'id': item.id, 'action': 'U'}});
  }

  filter() {
    console.log(this.qry);
    this.getPageData(0, 10, this.qry);
  }
}
