import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {Location} from '@angular/common';

import {EmailTemplate} from '../../vo/emailTemplate';
import {EmailTemplateService} from '../../services/api/emailTemplate.service';

@Component({
  selector: 'app-email-template-detail',
  templateUrl: './email-template-detail.component.html',
  styleUrls: ['./email-template-detail.component.css']
})

export class EmailTemplateDetailComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private location: Location,
              private service: EmailTemplateService) {
  }

  id: number;
  action: string;
  isupdate: boolean;
  bspPrefix: string;
  vo: EmailTemplate = new EmailTemplate();

  alerts: any = [];

  ngOnInit(): void {
    this.bspPrefix = '';
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.action = p['action'];
      if (this.action == 'U') {
        this.isupdate = true;
      } else {
        this.isupdate = false;
      }

      this.vo.id = this.id;
      this.getData(this.id);
    });
  }

  update(): void {
    if (!this.checkTemplateName()) {
      return;
    }

    this.service.updateItem(this.vo).subscribe((response) => {
      console.log(response);
      this.addMessage(response.operationMessage);
    });
  }

  goBack(): void {
    this.location.back();
  }

  getData(id: number) {
    this.service.getItemByID(id).subscribe((data) => {
        console.log(data);
        this.vo = data.item;
      }
    );
  }

  insertPattern(pattern: string) {
    if (this.vo.body == null) {
      this.vo.body = '';
    }
    this.vo.body += ' ' + pattern;
  }

  checkTemplateName(): boolean {
    let specialChars = '~`!@#$%^&_+=[]\\\\{}|\\\';:,<>?abcdefghijklmnopqrstuvwxyz';
    let length = this.vo.name.length;
    for (let i = 0; i < length; i++) {
      if (specialChars.indexOf(this.vo.name.charAt(i)) != -1) {
        this.addMessage('Template name can only contain upper case letter with the following characters : * / - . \\" ( )\\n Please enter again.');
        return false;
      }
    }

    return true;
  }

  addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

}
