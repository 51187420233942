export class Retrieve {
  documenttype: string;
  endNumber: string;
  fromAllocationId: number;
  fromBspId: number;
  fromBspName: string;
  originalEndNumber: number;
  originalQuantity: number;
  originalStartNumber: number;
  quantity: number;
  quarantined: boolean;
  reasonCode: string;
  startNumber: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
