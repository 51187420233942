<!-- title -->
<div class="row">
  <div class="col-10">
    <H1>Allocation Request Confirm</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div *ngIf="allocationRequest" class="col-10 table-bordered" style="padding-left: 0px; padding-top: 15px;">
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
  </div>

  <div class="row col-7">
    <div class="col-4 table-bordered pt-2 pb-2 left">
      BSP
    </div>
    <div class="col-7 table-bordered pt-2 pb-2 right">
      {{allocationRequest.bsp.bspFullname}}
    </div>
  </div>
  <div class="row col-7">
    <div class="col-4 table-bordered pt-2 pb-2 left">
      Document type
    </div>
    <div class="col-7 table-bordered pt-2 pb-2 right">
      {{allocationRequest.doctype.dtName}}
    </div>
  </div>
  <div class="row col-7">
    <div class="col-4 table-bordered pt-2 pb-2 left">
      Quantity
    </div>
    <div class="col-7 table-bordered pt-2 pb-2 right">
      {{allocationRequest.quantity}}
    </div>
  </div>

  <div style="height: 20px;">
  </div>

  <div class="row col-7">
    <div class="col-11 table-bordered pt-2 pb-2">
      Are you sure you want to place this Request
    </div>
  </div>

  <div style="height: 20px;">
  </div>

  <div class="row col-10">
      <span *ngIf="calResult < allocationRequest.quantity">
        The serial number volume exceeds historical usage. Based on the historical usage, your request cannot exceed <font><i><b>{{calResult}}</b></i></font> to be processed automatically.
        You can either cancel the request and place a new one with an amended serial number volume or proceed with this request and send a justification email to snap&#64;iata.org .
        Your justification email will be reviewed and the request will be processed manually by SNAP admin within 1 working day.
      </span>
    <span *ngIf="calResult >= allocationRequest.quantity && oneHistoryData">
        Only one request has previously been processed for this document type and country so a trend can still not be determined.  As a result this request has been put on hold and will be processed within 1 working day
      </span>
  </div>

  <div class="row col-7">
    <div class="col-3 mt-1 mb-1 offset-3">
      <input type="submit" name="process" value="Proceed"
             [ngStyle]="{'color':disableProceed === true ? 'grey' : '' }" [disabled]="disableProceed"
             (click)="submitRequest()">
    </div>
    <div class="col-3 mt-1 mb-1 offset-1">
      <input type="submit" name="back" value="Cancel" (click)="back()">
    </div>
  </div>
</div>
