import {Injectable, Inject} from '@angular/core';
import {ApiRequestEXService} from './api-request-ex.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class UnmergeService {
  vo: String[] = [];

  constructor(
    private apiRequest: ApiRequestEXService) {
  }

  getItems() {
    const me = this;

    return me.apiRequest.get('api/unmerge');
  }

  getDetails(id: number) {
    const me = this;
    return me.apiRequest.get('api/unmerge/' + id);
  }

  validate(code: string, ids: number) {
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('code', code);
    params = params.append('ids', ids.toString());
    return me.apiRequest.get('api/unmerge/validate', params);

  }

  mergeBsps(initBspId: number, finalBsp: number, mergedCode: string) {
    const me = this;
    me.vo.push(initBspId.toString());
    me.vo.push(finalBsp.toString());
    me.vo.push(mergedCode);
    return me.apiRequest.post('api/unmerge/demerge', me.vo);
  }

  getItemsByID(ids: string) {
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('ids', ids);
    return me.apiRequest.get('api/unmerge/list', params);
  }
}
