import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Bsp } from '../../vo/bsp';
import { BspService } from '../../services/api/bsp.service';
import { Calendar } from '../../vo/calendar';
import { CalendarService } from '../../services/api/calendar.service';
import { Dpc } from '../../vo/dpc';

@Component({
  selector: 'app-bsp-with-cal',
  templateUrl: './bsp-with-cal.component.html',
  styleUrls: ['./bsp-with-cal.component.css']
})
export class BspWithCalComponent implements OnInit {
  qry: Bsp = new Bsp();
  bsps: Bsp[] = [];
  cal: Calendar = new Calendar();
  cals: Calendar[] = [];
  dpc: Dpc = new Dpc();
  dpcs: Dpc[] = [];

  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;
  public perPages = 100;

  constructor(private router: Router, private myservice: BspService, private calService: CalendarService) { }

  ngOnInit() {
    this.qry.bspCal = this.cal;
    this.qry.dpc = this.dpc;
    this.getPageData(0, 100, null);
    this.calService.getAll().subscribe((data) => {
      console.log(data);
      this.cals = data.items;
      this.dpcs = data.dpcList;
      console.log(this.cals);
    });
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.getPageData(event.page - 1, event.itemsPerPage, this.qry);
  }

  getPageData(page: number, size: number, vo: Bsp) {
    this.myservice.getActivePages(page, size, vo).subscribe((data) => {
      console.log(data);
      this.bsps = data.items;
      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
    });
  }

  gotoEdit(todo) {
    this.router.navigate(['/home/bspEditCal'], { queryParams: { 'id': todo.id } });
  }

  filter() {
    console.log(this.qry);
    this.getPageData(0, 100, this.qry);
  }

  checkAll(e) {
    this.bsps.forEach(x => x.checked = e.target.checked);
  }

  isAllChecked() {
    return this.bsps.every(_ => _.checked);
  }

  batchEdit() {
    const checkedBsps: Bsp[] = this.bsps.filter(i => i.checked);
    if (checkedBsps.length === 0) {
      alert('At least one checkbox must be checked!');
      return;
    }
    const checkedBspIds = checkedBsps.map(i => i.id);
    this.router.navigate(['/home/bspCalBatchEdit'], { queryParams: { 'id': checkedBspIds } });
  }
}
