import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MailResendService} from '../../services/api/mail-resend.service';
import {MailResend} from '../../vo/mailResend';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-mail-resend',
  templateUrl: './mail-resend.component.html',
  styleUrls: ['./mail-resend.component.css']
})
export class MailResendComponent implements OnInit {

  status: number;
  from: string;
  subject: string;
  createDate: string;
  to: string;
  cc: string;

  mailBackUp: MailResend[] = [];

  minDate = new Date(1990, 5, 10);
  maxDate = new Date(2090, 5, 10);

  public maxSize = 5;
  public bigTotalItems = 0;
  public bigCurrentPage = 1;
  public numPages = 0;

  public alerts: any = [];

  log(v: any) {
    console.log(v);
  }

  constructor(protected route: ActivatedRoute, private router: Router, private myservice: MailResendService, private datePipe: DatePipe) {
    this.createDate =  this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }

  ngOnInit() {
    this.getPageData(0, 10, -1, null, null,  this.createDate, null , null);
    this.route.queryParams.subscribe((p: Params) => {
      this.addMessage( p['msg'], p['type']);
    });
  }

  getPageData(page: number, size: number, status: number, from: string, subject: string, createDate: string , to: string, cc: string) {
    this.myservice.getPages(page, size, status, from, subject, createDate, to, cc).subscribe((data) => {
      console.log(data.items);
      this.mailBackUp = data.items;
      console.log(this.mailBackUp);
      this.bigTotalItems = data.totalItems;
      this.bigCurrentPage = data.currentPageNumber + 1;
      }
    );
  }

  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    const me = this;
    me.getPageData(event.page - 1, event.itemsPerPage, this.status, this.from, this.subject, this.createDate, this.to, this.cc);
  }

  filter() {
    const me = this;
    me.getPageData(0, 10, this.status, this.from, this.subject, this.createDate, this.to, this.cc);
  }

  gotoView(todo) {
    this.router.navigate
    (['/home/mailResendDetail'], { queryParams: {'id': todo.id, 'stage': todo.status}});
  }

  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: 30000
    });
  }

}
