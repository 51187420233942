import { Component, OnInit } from '@angular/core';
import { Bsp } from '../../../vo/bsp';
import { Dpc } from '../../../vo/dpc';
import { Gds } from '../../../vo/gds';
import { BspService } from '../../../services/api/bsp.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DpcService } from '../../../services/api/dpc.service';
import { GdsService } from '../../../services/api/gds.service';

@Component({
  selector: 'app-editbsp',
  templateUrl: './editbsp.component.html',
  styleUrls: ['./editbsp.component.css']
})
export class EditbspComponent implements OnInit {


  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected service: BspService,
              private dpcService: DpcService,
              private gdsService: GdsService) { }
  get bsValue(): Date {
    return this._bsValue;
  }

  set bsValue(v: Date) {
    console.log(v);
    this._bsValue = v;
  }
  get bsRangeValue(): any {
    return this._bsRangeValue;
  }

  set bsRangeValue(v: any) {
    this._bsRangeValue = v;
  }

  public id: number;
  public action: string;
  public isupdate: boolean;
  dpc: Dpc = new Dpc();
  dpcs: Dpc[] = [];
  vo: Bsp = new Bsp();
  gds: Gds = new Gds();
  gdss: Gds[] = [];


  minDate = new Date(1990, 1, 1);
  maxDate = new Date(2200, 1, 1);
  _bsValue: Date;

  _bsRangeValue: any = [new Date(2017, 7, 4), new Date(2017, 7, 20)];
  public alerts: any = [];

  log(v: any) {console.log(v); }

  // existGds: Gds[] = [];
  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.action = p['action'];
      if (this.action == 'U') {
        this.isupdate = true;
      } else {
        this.isupdate = false;
      }

      const me = this;
      this.vo.id = this.id;
      this.vo.dpc = this.dpc;
      me.getData(this.id);

      this.dpcService.getDpcs(0, 1000, null).subscribe((data) => {
          // console.log(data);
          this.dpcs = data.items;
          // console.log(this.dpcs);
        }
      );
      this.vo.gds = this.gds;
      this.gdsService.getItems(0, 1000, null).subscribe((data) => {
           this.gdss = data.items;
          // console.log(this.gdss);
        }
      );

      // this.vo.relGds;

    });
  }


  // gdsChecked: Gds[] = [];
  addTo() {
    // console.log(this.vo);
    const v = new Gds();
    // alert(this.vo.gdsId);
    // v.id = this.vo.gdsId;

    for (const item of this.gdss) {
      if (item.id == this.vo.gdsId) {
        v.gdsCode = item.gdsCode;
        v.id = item.id;

        // console.log(v);
        const index = this.gdss.indexOf(item);
        // alert(index);
        this.gdss.splice(index, 1);
      }
    }
    this.vo.relGds.push(v);
    console.log(this.gdss);

  }

  back() {
    const v = new Gds();
    // v.id = this.vo.gdsId;
    // alert(this.vo.gdsId);
    // console.log(this.vo.gdsId);
    // if ( this.vo.gdsId == undefined){
    //   return;
    // }
    for (const itemO of this.vo.relGds) {
      if (itemO.id == this.vo.gdsId) {
        v.gdsCode = itemO.gdsCode;
        v.id = itemO.id;

        const index = this.vo.relGds.indexOf(itemO);
        // alert(index);
        this.vo.relGds.splice(index, 1);
      }
    }
    console.log(this.gdss);
    this.gdss.push(v);
    console.log(this.gdss);

  }

  update() {
    // if (this.vo.bspActive = 'true') {
    //   this.vo.bspActive = '1';
    // } else {
    //   this.vo.bspActive = '0';
    // }
    // if (this.vo.bspCgnRequestallowed = 'true') {
    //   this.vo.bspCgnRequestallowed = '1';
    // } else {
    //   this.vo.bspCgnRequestallowed = '0';
    // }
    // if (this.vo.bspTiFlag = 'true') {
    //   this.vo.bspTiFlag = '1';
    // } else {
    //   this.vo.bspTiFlag = '0';
    // }
    this.service.updateItem(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }
  delete() {
    console.log(this.vo);
    this.service.deleteItem(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }


  backToListPage() {
    this.router.navigate(['/home/bsp']);
  }

  getData(id: number) {
    const me = this;
    this.service.getItemByID(id).subscribe((data) => {
         const dateStr = data.item.bspTiDate;
         // alert(new Date(dateStr));
         data.item.bspTiDate = new Date(dateStr);
         console.log(data);
        this.vo = data.item;
      }
    );
  }
  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }


}
