import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';

import { MailVo } from '../../vo/mailVo';
import {BulletinService} from '../../services/api/bulletin.service';

@Component({
  selector: 'app-bulletin-mail',
  templateUrl: './bulletin-mail.component.html',
  styleUrls: ['./bulletin-mail.component.css']
})

export class BulletinMailComponent implements OnInit {

  constructor(private service: BulletinService, private location: Location) {
  }
  mailMessage: MailVo = new MailVo();

  alerts: any = [];

  ngOnInit(): void {
    if (this.service.mailMessage) {
      this.mailMessage = this.service.mailMessage;
      console.log(this.mailMessage);
    }
  }

  sendEmail(): void {
    console.log(this.mailMessage);

    const to  = '' + this.mailMessage.to;
    const cc = '' + this.mailMessage.cc;

    const toArray = to.split(',');
    const ccArray = cc.split(',');
    this.mailMessage.to = toArray;
    this.mailMessage.cc = ccArray;

    console.log(this.mailMessage);
    this.service.sendEmail(this.mailMessage).subscribe((response) => {
      console.log(response);
      this.addMessage(response.operationMessage);
    });
  }

  goBack(): void {
    this.location.back();
  }

  addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
}
