<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Serial number allocations Dashboard</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered" >
    <div class="col-12">
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E">
          <td>Expired allocations</td>
          <td>volums</td>
          <td>BSP</td>
          <td>GDS</td>
          <td>Allocation Date</td>
          <td>Status</td>
        </tr>
        </thead>
        <tr class="Waving1" *ngFor="let todo of allocations">
          <td>{{todo.startnumber | snformat}}-{{(todo.startnumber+todo.quantity)| snformat}}</td>
          <td>{{todo.quantity | number}}</td>
          <td>{{todo.bsp.bspFullname}}</td>
          <td>{{todo.gds.gdsCode}}</td>
          <td>{{todo.allocationdate | date:'dd-MM-yyyy'}}</td>
          <td>
            <button mwlConfirmationPopover
                   [popoverTitle]="title"
                   [popoverMessage]="purgeMessage"
                   placement="left"
                   (confirm)="changeStatus(todo.id,todo.status)"
                   (cancel)="cancelClicked = true">purge</button>
          </td>
        </tr>

      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
  </div>
  <!-- table -->
</div>
