import {Injectable, Inject} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Recycle} from '../../vo/recycle';
import {Formcode} from '../../vo/formcode';
import {ApiRequestEXService} from './api-request-ex.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class RecycleService {

  constructor(private apiRequest: ApiRequestEXService) {
  }


  getPages(page?: number, size?: number, status?: string, id?: number): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof size === 'number' ? size.toString() : '1000');
    params = params.append('status', status);
    if (id != null) {
      params = params.append('id', id.toString());
    }
    // if(vo && vo.subEmail){
    //   params = params.append('subEmail', vo.subEmail);
    // }
    console.log(params);
    return this.apiRequest.get('api/FrozenFormcodes', params);

  }

  preCheck(id: number): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('id', id.toString());
    // if(vo && vo.subEmail){
    //   params = params.append('subEmail', vo.subEmail);
    // }

    console.log(params);
    return this.apiRequest.get('api/PreRecycleFormcode', params);

  }

  recylce(vo: Formcode): Observable<any> {

    // Create Request URL params
    return this.apiRequest.post('api/recycleFormcode', vo);

  }

  overrideRecycle(vo: Formcode): Observable<any> {

    // Create Request URL params
    return this.apiRequest.post('api/overrideRecycleFormcode', vo);

  }

  autoRetrieve(vo: Formcode): Observable<any> {
    // Create Request URL params
    return this.apiRequest.post('api/autoRetrieveFormcode', vo);
  }

  getItemByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/Recycle/' + id);
  }

  addItem(vo: Recycle): Observable<any> {
    return this.apiRequest.post('api/Recycle', vo);
  }

  updateItem(vo: Recycle): Observable<any> {
    return this.apiRequest.put('api/Recycle', vo);
  }

  deleteItem(vo: Recycle): Observable<any> {
    return this.apiRequest.delete('api/Recycle/' + vo.id);
  }
}
