import {Component, OnInit} from '@angular/core';
import {IssuParty} from '../../vo/issu-party';
import {IssuePartyService} from '../../services/api/issue-party.service';
import {Router} from '@angular/router';
import {Formcode} from '../../vo/formcode';

@Component({
  selector: 'app-issue-party',
  templateUrl: './issue-party.component.html',
  styleUrls: ['./issue-party.component.css']
})
export class IssuePartyComponent implements OnInit {
  issu: IssuParty = new IssuParty();
  issus: IssuParty[] = [];
  public bigTotalItems: number = 0;
  public bigCurrentPage: number = 1;
  public maxSize: number = 5;

  constructor(private issuService: IssuePartyService, private  router: Router) {
  }

  ngOnInit() {
    this.getPageData(0, 10, null);
  }

  gotoNew() {
    this.router.navigate(['/home/addissueParty']);
  }

  filter() {
    console.log(this.issu);
    this.getPageData(0, 10, this.issu);
  }

  getPageData(page: number, size: number, issu: IssuParty) {
    this.issuService.getPages(page, size, issu).subscribe((data) => {
        console.log('test');
        console.log(data);
        this.issus = data.items;
        this.bigTotalItems = data.totalItems;
        this.bigCurrentPage = data.currentPageNumber + 1;
      }
    );
  }
  public pageChanged(event: any): void {
    console.log('Page changed to: ' + event.page);
    console.log('Number items per page: ' + event.itemsPerPage);
    this.getPageData(event.page - 1, event.itemsPerPage, this.issu);
  }
  gotoView(issu) {
    this.router.navigate
    (['/home/viewissueParty'], {queryParams: {'id': issu.id}});
  }
  gotoEdit(issu) {
    this.router.navigate
    (['/home/editissueParty'], {queryParams: {'id': issu.id}});
  }
}
