import { Component, OnInit } from '@angular/core';
import { Formcode } from '../../../vo/formcode';
import { Document } from '../../../vo/document';
import { Router } from '@angular/router';
import { FormcodeService } from '../../../services/api/formcode.service';
import { DocumentTypeService } from '../../../services/api/documentType.service';
import { IssuParty } from '../../../vo/issu-party';
import { IssuePartyService } from '../../../services/api/issue-party.service';

@Component({
  selector: 'app-newformcode',
  templateUrl: './newformcode.component.html',
  styleUrls: ['./newformcode.component.css']
})
export class NewformcodeComponent implements OnInit {

  vo: Formcode = new Formcode();
  doc: Document = new Document();
  issu: IssuParty = new IssuParty();
  docs: Document[] = [];
  issus: IssuParty[] = [];
  today: Date;
  public alerts: any = [];

  constructor(private service: FormcodeService, private router: Router, private docService: DocumentTypeService,
    private issuService: IssuePartyService) {
  }

  ngOnInit() {

    this.vo.documentType = this.doc;
    this.today = new Date();
    // alert(this.today.getFullYear() + '/' +  (this.today.getMonth() + 1) + '/' + this.today.getDate());
    this.vo.fcCreatedate = this.today;
    this.docService.getActiveDocs().subscribe((data) => {
      console.log(data);
      this.docs = data.items;
      console.log(this.docs);
    });
    this.vo.issueParty = this.issu;
    this.issuService.getIssuingPartys().subscribe((data) => {
      console.log(data);
      this.issus = data.items;
      console.log(this.issus);
    });

  }
  addFormcode() {
    if (this.vo.fcNumber == null || this.vo.fcNumber == '') {
      alert('Formcode Number is required!');
      return;
    } else if (this.vo.documentType.id == null || this.vo.documentType.id == 0) {
      alert('DocumentType is required!');
      return;
    } else if (this.vo.issueParty.id == null || this.vo.issueParty.id == 0) {
      alert('IssuingParty is required!');
      return;
    }
    console.log(this.vo);
    this.vo.fcStatus = 'A';
    this.service.addItem(this.vo)
      .subscribe(
        (newTodo) => {
          console.log(newTodo);
          this.addMessage(newTodo.operationMessage);
        }
      );
  }

  backToList() {
    this.router.navigate(['/home/formcode']);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

}
