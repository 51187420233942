import { Component, OnInit } from '@angular/core';

import {LoginInfoInStorage, UserInfoService} from '../../services/user-info.service';

@Component({
  selector: 'app-gdshome',
  templateUrl: './gdshome.component.html',
  styleUrls: ['./gdshome.component.css']
})
export class GdshomeComponent implements OnInit {

  gdsCode = '';

  constructor(private userInfoService: UserInfoService) { }

  ngOnInit() {
    this.gdsCode = this.userInfoService.getGdsCode();
  }

}
