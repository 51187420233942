import { Component, OnInit } from '@angular/core';
import { DocumentSubType } from '../../../vo/documentSubType';
import { DocumentSubTypeService } from '../../../services/api/documentSubType.service';
import { Router, ActivatedRoute, Params } from '@angular/router';


@Component({
  selector: 'app-newdocsubtype',
  templateUrl: './newdocsubtype.component.html',
  styleUrls: ['./newdocsubtype.component.css']
})
export class NewdocsubtypeComponent implements OnInit {

  vo: DocumentSubType = new DocumentSubType();
  public alerts: any = [];

  constructor(private service: DocumentSubTypeService, private router: Router) { }

  ngOnInit() {
  }

  addNew() {
    console.log(this.vo);
    this.service.addItem(this.vo)
      .subscribe(
        (newTodo) => {
          console.log(newTodo);
          this.addMessage(newTodo.operationMessage);
        }
      );
  }

  backToListPage() {
    this.router.navigate(['/home/docsubtype']);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }


}
