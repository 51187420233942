export class Document {

  id: number;
  dtName: string;
  dtType: number;
  dtDescription: string;
  dtDefaultpercentage: number;
  dtDefaultinterruptions: number;
  dtNotificationlimit: number;
  dtLimitexceeded: number;
  dtStatus: string;
  excluded: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
