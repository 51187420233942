export class Bulletin {

  bulletinDate: Date;
  bulletinNo: number;
  bulletinContent: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
