import { TableCell } from './tableCell';
import { SNRange } from '../../../vo/snRange';

export class TableGenerator {
  get range(): SNRange {
    return this._range;
  }

  set range(value: SNRange) {
    this._range = value;
  }
  get selected(): SNRange {
    return this._selected;
  }

  set selected(value: SNRange) {
    this._selected = value;
  }
  maxWidth = 1000;	// initialize the maximum width with a default value
  DEFAULT_SCALE_FACTOR = 1000; // ApplicationConfig.getRangeViewrDefaultScaleFactor();

  /**
   * Vector conaining the ranges to be drawed using HTML tags; this should NOT contain a range of type 0
   * (that means 'selected')
   */
  ranges: Array<SNRange> = null;

  /**
   * The selected range must be of type 0
   */
  private _selected: SNRange = null;

  /**
   * Keeps track of how much of the current row was already drawn on the page, in order to know when
   * to end the current line
   */
  protected currentWidth = 0;

  /**
   * Flag that indicates that we are currently splitting a range that cannot fit into one html 'tr';
   * this makes us postpone the iteration of ranges until we finish the current one.
   */
  protected splitting = false;

  /**
   * Keeps track of how much of the current range we have already used
   */
  protected alreadyUsed = 0;

  // /**
  //  * Ranges iterator
  //  */
  // protected Iterator iterator = null;

  /**
   * Property that keeps the current range
   */
  private _range: SNRange = null;

  private index = 0;

  /**
   * the scale factor to send to ranges; if left 0, the range default scale factor will be used
   */
  protected scaleFactor: number = this.DEFAULT_SCALE_FACTOR;

  constructor(rngs: Array<SNRange>, sel: SNRange, scaleFactor: number, maxWidth: number) {
    if (maxWidth > 0) {
      this.maxWidth = maxWidth;
    }
    if (scaleFactor > 0) {
      this.scaleFactor = scaleFactor;
    }

    this.ranges = rngs;
    this._selected = sel;

  }

  // ******************************************************************************************
  private scaleQuantity(range: SNRange): number {
    return (this._range.quantity * this.scaleFactor < 1) ? 1 :
      Math.round(range.quantity * this.scaleFactor);
  }

  /**
   * generates a string defining the range: startpoint - endpoint, which will be used in a
   * 'td' tag 'title' attribute
   * @return tooltip string
   */
  public toolTip(range: SNRange): string {
    // added the formated quantity to the tool tip
    if (range.quantity > 1) {
      return range.formatedOpenNumber + ' - ' + range.formatedCloseNumber + ' (' + range.formatedQuantity + ' numbers ';
    } else {
      return range.formatedOpenNumber + ' (' + range.formatedQuantity + ' number ';
    }
  }
  public toolTipSelected(range: SNRange): string {
    // added the formated quantity to the tool tip
    if (range.quantity > 1) {
      return 'selected : ' + range.formatedOpenNumber + ' - ' + range.formatedCloseNumber + ' (' + range.formatedQuantity + ' numbers in ';
    } else {
      return range.formatedOpenNumber + ' (' + range.formatedQuantity + ' number in ';
    }
  }

  /**
   * uses the iterator and the private properties currentWidth, splitting, alreadyUsed to define the
   * next table cell.
   * @return the next cell of the table
   */
  public getNextCell(): TableCell {
    let tableCell: TableCell = null;
    // the actual width of the cell to be returned
    let width = 0;
    // the requested width (the remaining width of the current range)
    let requestedWidth = 0;
    // start a new line
    let slFlag = false;
    // end the current line
    let elFlag = false;

    if (this.splitting) {	// if we have a part of the previous range left we don't iterate.
      requestedWidth = this.scaleQuantity(this.range) - this.alreadyUsed;
    } else if (this.index < this.ranges.length) {
      this.range = this.ranges[this.index];
      this.index++;
      // range.setScaleFactor(scaleFactor);
      requestedWidth = this.scaleQuantity(this.range);
    } else {	// if we ran out of ranges
      return null;
    }

    if (this.currentWidth == 0) {
      // if we haven't yet started the current line, we'll start a new line
      slFlag = true;
    }

    if (this.currentWidth + requestedWidth <= this.maxWidth) {
      // if the remaining width of the range fits into the current line
      this.splitting = false;
      this.alreadyUsed = 0;
      width = requestedWidth;
      this.currentWidth += requestedWidth;
      if (this.currentWidth == this.maxWidth) {
        elFlag = true;
        this.currentWidth = 0;
      }
    } else { // if the requested width exceeds the current line
      this.splitting = true;
      // end the current line
      elFlag = true;
      width = this.maxWidth - this.currentWidth;
      this.alreadyUsed += width;
      // in the next iteration we must start a new line
      this.currentWidth = 0;
    }

    if (this.index >= this.ranges.length) { // if this is the last cell, force ending the current line
      elFlag = true;
    }

    // create a table cell of the specified width, type of the current range etc.
    if (this._selected != null && this.range.openNumber != this._selected.openNumber && this.range.closeNumber != this._selected.closeNumber) {
      tableCell = new TableCell(width, this.toolTip(this.range), this.range.openNumber, this.range.closeNumber, this.range.status);
    } else {
      tableCell = new TableCell(width, this.toolTipSelected(this.range), this.range.openNumber, this.range.closeNumber, this.range.status);
    }
    tableCell.startln(slFlag);
    tableCell.endln(elFlag);
    return tableCell;
  }

  /**
   *
   * @return HTML code of the range map
   */
  public createHTML(): string {
    let htmlCode = '';
    let tc: TableCell = null;
    while ((tc = this.getNextCell()) != null) {
      htmlCode += tc.toHTML();
    }
    return htmlCode;
  }

}
