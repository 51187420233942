<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Calendar</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" *ngIf="action === 'edit'">

  <!-- title2 -->
  <div class="col-8 mt-1 ">
    <div class="row">
      <h1>
        Calendar Edit
      </h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <div class="row form-group ">
          <div class="col-sm-3 "><label>Ti Job</label></div>
          <div class="col-sm-9">
            <input type="text" name="Job" size="20" [(ngModel)]="vo.timezone">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label>Job Execution Time</label></div>
          <div class="col-sm-9">
            <input type="text" name="tiGenerateTime" size="20" [(ngModel)]="vo.tiGenerateTime">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label>Comment</label></div>
          <div class="col-sm-9">
            <textarea name="comment" cols="40" rows="8" [(ngModel)]="vo.comment">{{vo.comment}}</textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->


  <!-- alert -->

  <!-- button start -->
  <div class="row">
    <div class="offset-1 col-1">
      <input type="button" name="update" value="Update" (click)="update()">
    </div>
    <div class="offset-1 col-1">
      <input type="button" name="back" value="Cancel" (click)="backToListPage()">
    </div>
  </div>
</div>
<!-- button end -->

<div class="col-8 offset-1 table-bordered" *ngIf="action === 'add'">

  <!-- title2 -->
  <div class="col-8 mt-1 ">
    <div class="row">
      <h1>
        New Calendar
      </h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">
        <div class="row form-group ">
          <div class="col-sm-3 "><label>Timezone</label></div>
          <div class="col-sm-9">
            <input type="text" name="Timezone" size="20" [(ngModel)]="vo.timezone">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label>Ti Generate Time</label></div>
          <div class="col-sm-9">
            <input type="text" name="tiGenerateTime" size="20" [(ngModel)]="vo.tiGenerateTime">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-3 "><label>Comment</label></div>
          <div class="col-sm-9">
            <textarea name="comment" cols="40" rows="8" [(ngModel)]="vo.comment"></textarea>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->


  <!-- alert -->

  <!-- button start -->
  <div class="row">
    <div class="offset-1 col-1">
      <input type="button" name="new" value="New Calendar" (click)="add()">
    </div>
    <div class="offset-1 col-1">
      <input type="button" name="back" value="Cancel" (click)="backToListPage()">
    </div>
  </div>
</div>


<div *ngFor="let alert of alerts">
  <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
    }}
  </alert>
</div>

<!-- Form -->

<!-- body mt-3 -->



