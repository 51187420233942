<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>BSP Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered">

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>Create new BSP</h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form role="form" name="bspDetailDynaForm" method="post"
            action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2FbspDetails.do&_sorig=%2Fbsp.do">
        <input type="hidden" name="pkID" value="">
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="code" style="color:#1B548E">BSP code</label></div>
          <div class="col-sm-9">
            <input type="text" name="code" maxlength="10" size="20" value="" [(ngModel)]="vo.bspCode">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 ">
            <label for="active" style="color:#1B548E">Is BSP active</label>
          </div>
          <div class="col-sm-9">
            <input type="checkbox" name="active" [checked]="vo.bspActive==1" (change)="vo.bspActive=1-vo.bspActive">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="name" style="color:#1B548E">BSP name</label></div>
          <div class="col-sm-9">
            <input type="text" name="name" maxlength="100" size="20" value="" [(ngModel)]="vo.bspFullname">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="email" style="color:#1B548E">BSP e-mail</label></div>
          <div class="col-sm-9">
            <input type="text" name="email" maxlength="100" size="20" value="" [(ngModel)]="vo.bspEmail">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-3"><label for="regionEmail" style="color:#1B548E">BSP Regional e-mail</label></div>
          <div class="col-sm-9">
            <input type="text" name="regionEmail" maxlength="100" size="20" value="" [(ngModel)]="vo.bspRegionEmail">
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="reqallowed" style="color:#1B548E">CGN request allowed</label></div>
          <div class="col-sm-9">
            <input type="checkbox" name="reqallowed" [checked]="vo.bspCgnRequestallowed==1"
                   (change)="vo.bspCgnRequestallowed=1-vo.bspCgnRequestallowed">
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 ">
            <label style="color:#1B548E">DPC</label>
          </div>
          <div class="col-sm-9">
            <select [(ngModel)]="vo.dpc.id" name="dpc">
              <option *ngFor="let itemZ of dpcs" [value]='itemZ.id'>{{itemZ.dpcCode}}</option>
            </select>
          </div>
        </div>

        <div class="row form-group table-bordered">
          <div class="col-sm-5 ">
            <label style="color:#1B548E">List of GDSs</label>
            <select name="gdsName" multiple="multiple" size="4" [(ngModel)]="vo.gdsId" style="width: 100px;"
                    (dblclick)="addTo()">
              <option *ngFor="let itemX of gdss" [value]='itemX.id'>{{itemX.gdsCode}}</option>
            </select>
          </div>
          <div class="col-sm-3">
            <input type="button" name="toAttached" value=">>" (click)="addTo()"><br>
            <input type="button" name="toAvailable" value="<<" (click)="back()">
          </div>
          <div class="col-sm-4">
            <select name="gdsAttached" multiple="multiple" size="4" style="width: 100px;" [(ngModel)]="vo.gdsId"
                    (dblclick)="back()">
              <option *ngFor="let itemY of vo.relGds" [value]='itemY.id'>{{itemY.gdsCode}}</option>
            </select>
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>

        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="bspIsoc" style="color:#1B548E">Isoc</label></div>
          <div class="col-sm-9">
            <input type="text" name="bspIsoc" maxlength="2" size="20" value="" [(ngModel)]="vo.bspIsoc">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="bspSendTifile" style="color:#1B548E">Send TI file:</label></div>
          <div class="col-sm-9">
            <input type="checkbox" name="bspSendTifile" [checked]="vo.bspTiFlag==1"
                   (change)="vo.bspTiFlag=1-vo.bspTiFlag">
          </div>
        </div>

        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="v_bspTiDate" style="color:#1B548E">BSP TI Date</label></div>
          <div class="col-3 mt-2">
            <input type="text" readonly [(ngModel)]="vo.bspTiDate" name="v_bspTiDate"
                   [minDate]="minDate"
                   [maxDate]="maxDate"
                   #dp="bsDatepicker"
                   bsDatepicker>
          </div>
          <!--<div class="col-sm-9">-->
          <!--<input class="col-lg-4" type="text" name="bspTiDate" value="" readonly="readonly" [(ngModel)]="vo.bspTiDate">&nbsp;-->
          <!--<a href="javascript:show_calendar('bspDetailDynaForm.bspTiDate',null, null, '');">-->
          <!--<img src="SNAPDocroot//images/show-calendar.gif"	width=24 height=22 border=0 />-->
          <!--</a>-->
          <!--</div>-->
        </div>

        <div class="row form-group table-bordered">
          <div class="col-sm-3 "><label for="bspDishType" style="color:#1B548E">DISH Type</label></div>
          <div class="col-sm-9">
            <select name="bspDishType" [(ngModel)]="vo.bspDishType">
              <option value="1">DISH 20.3</option>
              <option value="2">DISH 22</option>
              <option value="3">DISH 23</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->
  <div class="row form-group">
    <div class="ml-3 mt-3">
      (<font color="#ff0000" size="4">*</font>marked fields are mandatory)
    </div>
  </div>
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">
    <div class="col-sm-3" *ngIf="!isupdate">
      <input type="submit" name="new" value="Delete" (click)="delete()">
    </div>

    <div class="offset-1" *ngIf="isupdate">
      <input type="submit" name="update" value="Update" (click)="update()">
    </div>
    <div class="offset-3">
      <input type="submit" name="back" value="Cancel" (click)="backToListPage()">
    </div>
  </div>
  <!-- button end -->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}
    </alert>
  </div>
  <!-- Form -->

</div>
<!-- body mt-3 -->

