<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Send Email Page</H1>
  </div>
</div>
<!-- title -->

<!-- body -->
<div class="col-10 offset-1 table-bordered" style="background-color:#D9E6F4">
  <form #bulletinMailForm="ngForm">
    <div class="row">
      <div class="col-2 table-bordered pt-2 pb-2" style="background-color: #E8EAED">
        From :
      </div>
      <div class="col-10 table-bordered pt-2 pb-2" style="background-color: #D9E6F4">
        {{mailMessage.fromName}}&lt;{{mailMessage.from}}&gt;
      </div>
    </div>

    <div class="row">
      <div class="col-2 table-bordered pt-2 pb-2" style="background-color: #E8EAED">
        To:
      </div>
      <div class="col-10 table-bordered pt-2 pb-2" style="background-color: #D9E6F4">
        <input type="text" readonly="readonly" name="to" size="100" [(ngModel)]="mailMessage.to" required #to="ngModel">
        <font color="#ff0000" size="4">*</font>
        <span *ngIf="to.invalid && (to.dirty || to.touched)" class="alert alert-danger">
          <span *ngIf="to.errors.required">
            To is required.
          </span>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-2 table-bordered pt-2 pb-2" style="background-color: #E8EAED">
        CC:
      </div>
      <div class="col-10 table-bordered pt-2 pb-2" style="background-color: #D9E6F4">
        <input type="text" readonly="readonly" name="cc" size="100" [(ngModel)]="mailMessage.cc" required #cc="ngModel">
        <font color="#ff0000" size="4">*</font>
        <span *ngIf="cc.invalid && (cc.dirty || cc.touched)" class="alert alert-danger">
          <span *ngIf="cc.errors.required">
            CC is required.
          </span>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-2 table-bordered pt-2 pb-2" style="background-color: #E8EAED">
        Message Subject:
      </div>
      <div class="col-10 table-bordered pt-2 pb-2" style="background-color: #D9E6F4">
        <input type="text" readonly="readonly" name="subject" size="50" [(ngModel)]="mailMessage.subject" required #subject="ngModel">
        <font color="#ff0000" size="4">*</font>
        <span *ngIf="subject.invalid && (subject.dirty || subject.touched)" class="alert alert-danger">
          <span *ngIf="subject.errors.required">
            Message Subject is required.
          </span>
        </span>
      </div>
    </div>

    <div class="row">
      <div class="col-2 table-bordered pt-2 pb-2" style="background-color: #E8EAED">
        Message Body:
      </div>
      <div class="col-10 table-bordered pt-2 pb-2" style="background-color: #D9E6F4">

        <div id="content1" class="content"  [innerHtml]="mailMessage.body | safeHtml">
      </div>
    </div>
    </div>

    <div class="row">
      <div class="col-2 table-bordered pt-2 pb-2" style="background-color: #E8EAED">
        Attached Files :
      </div>
      <div class="col-10 table-bordered pt-2 pb-2" style="background-color: #D9E6F4">
        {{mailMessage.attachments}}
      </div>
    </div>

    <div class="row table-bordered">
      <div *ngIf="stage == 0">
        <div class="col-3 mt-1 mb-1 offset-3">
          <input type="submit" name="update" value="Send" (click)="goSendMail()">
        </div>
      </div>
      <div class="col-3 mt-1 mb-1 offset-1">
        <input type="submit" name="back" value="Cancel" (click)="goBack()">
      </div>
    </div>
  </form>

  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
  </div>
</div>
