<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Formcode Usage Dashboard</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >


  <table>
    <tr>
      <td>
        <table>
          <tr>
            <td>
              OPET
              <select [(ngModel)]="selectedOpetID" name="opet" (change)="(rangeChangeOpet())">
                <option value=-1>---All---</option>

                <option *ngFor="let item of pie.opetFcs" [value]='item.id'>{{item.fcNumber}}</option>
              </select>

            </td>
           <!-- <td width="65%">
              <div id="opetAlert" style="color:red"></div>
            </td>-->
          </tr>
          <tr>
            <td colspan="2">
              <div id="chart_Opet" style="height: 300px; width: 300px;"></div>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table>
          <tr>
            <td>
              OPEMD
              <select  name="opemd" [(ngModel)]="selectedOpemdID" (change)="(rangeChangeOpemd())">
                <option value=-1>---All---</option>
                <option *ngFor="let item of pie.opemdFcs" [value]='item.id'>{{item.fcNumber}}</option>
              </select>
            </td>
           <!-- <td width="65%">
              <div id="opemdAlert" style="color:red"></div>
            </td>-->
          </tr>
          <tr>
            <td colspan="2">
              <div id="chart_Opemd" style="height: 300px; width: 300px;"></div>
            </td>
          </tr>
        </table>
      </td>
      <td>
        <table>
          <tr>
            <td>
              TASF
              <select  name="tasf" [(ngModel)]="selectedTasfID" (change)="(rangeChangeTasf())">
                <option value=-1>---All---</option>
                <option *ngFor="let item of pie.tasfFcs" [value]='item.id'>{{item.fcNumber}}</option>
              </select>
            </td>
            <!--<td width="65%">
              <div id="tasfAlert" style="color:red"></div>
            </td>-->
          </tr>
          <tr>
            <td colspan="2">
              <div id="chart_Tasf" style="height: 300px; width: 300px;"></div>
            </td>
          </tr>
        </table>
      </td>
    </tr>


  </table>
  <div style="color: #6E859A; font-size: 12px">This dashboard is to have an overview of the opened form codes and their real time availability status</div>



</div>
<!-- body mt-3 -->

