import {Document} from './document';
import {IssuParty} from './issu-party';
// import {Time} from 'ngx-bootstrap/timepicker/timepicker.models';

export class Formcode {
  documentType: Document;
  fcComment: string;
  fcCreatedate: Date;
  fcFrozen: Date;
  fcFrozenFor: number;
  fcNumber: string;
  fcReso: string;
  fcStatus: string;
  id: number;
  fcFrozenEnd: Date;
  proceeding: string;
  issueParty: IssuParty;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
