import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Allocation } from '../../vo/allocation';
import { AllocationRequest } from '../../vo/allocationRequest';
import { ApiRequestEXService } from './api-request-ex.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class AllocationService {
  vo: Allocation = new Allocation();
  voReq: AllocationRequest = new AllocationRequest();

  constructor(private apiRequest: ApiRequestEXService) {
  }


  getPages(page?: number, size?: number, vo?: AllocationRequest): Observable<any> {

    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (vo && vo.status) {
      params = params.append('status', vo.status);
    }

    // console.log(params);
    return this.apiRequest.get('api/ManualAllocationRequests', params);

  }

  getFormcodeDetail(formcodeid: number, isAuto: number, quantity: number, blpercentage: number,
    blinterruptions: number, startNumber: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('formcodeid', formcodeid.toString());
    params = params.append('isAuto', isAuto.toString());
    if (quantity != null) {
      params = params.append('quantity', quantity.toString());
    }
    if (blpercentage != null) {
      params = params.append('blpercentage', blpercentage.toString());
    }
    if (blinterruptions != null) {
      params = params.append('blinterruptions', blinterruptions.toString());
    }
    if (startNumber != null) {
      params = params.append('startNumber', startNumber.toString());
    }
    console.log(params);
    return this.apiRequest.get('api/allocation/formcodeDetail', params);
  }

  //
  // private addUrlParameter(paramName: string, numberVal: number, params: HttpParams): HttpParams {
  //   if (numberVal != null) {
  //     return params.append(paramName, numberVal.toString());
  //   }
  // }

  getFormcodeRangeDetail(formcodeid: number, selStart: string, selEnd: string, selStatus: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('formcodeid', formcodeid.toString());
    params = params.append('selStart', selStart.toString());
    params = params.append('selEnd', selEnd.toString());
    params = params.append('selStatus', selStatus.toString());

    // params.set('isAuto', isAuto.toString());
    // params.set('quantity', quantity.toString());
    // if (blpercentage != null) {
    //   params.set('blPercentage', blpercentage.toString());
    // }
    // if (blinterruptions != null) {
    //   params.set('blInterruptions', blinterruptions.toString());
    // }
    // if (startNumber != null) {
    //   params.set('startNumber', startNumber.toString());
    // }

    return this.apiRequest.get('api/allocation/rangeDetail', params);
  }

  getItemByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/AllocationRequest/' + id);
  }

  reject(vo: AllocationRequest): Observable<any> {
    return this.apiRequest.put('api/AllocationRequestReject', vo);
  }

  getInnerAllocates(page: number, size: number, allocation: Allocation, num: string, fcNumber: string) {
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof size === 'number' ? size.toString() : '1000');
    params = params.append('num', num);
    params = params.append('fcNumber', fcNumber);
    return me.apiRequest.get('api/allocations/inner', params);
  }

  // getInnerAllocate(page: number, size: number, allocation: Allocation, num: string, fcNumber: string) {
  //   let me = this;
  //   let params: URLSearchParams = new URLSearchParams();
  //   params.set('page', typeof page === 'number' ? page.toString() : '0');
  //   params.set('size', typeof size === 'number' ? size.toString() : '1000');
  //   params.set('num', num);
  //   alert(fcNumber);
  //   params.set('fcNumber', fcNumber);
  //   return me.apiRequest.get('api/allocations/inner', params);
  // }
  changeStatus(id: number, status: number) {
    const me = this;
    const params: number[] = [];
    params.push(id);
    params.push(status);
    return me.apiRequest.post('api/allocation/changeStatus', params);
  }

  getExternalAllocates(page: number, size: number, allocation: Allocation, gdscode: string) {
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof size === 'number' ? size.toString() : '1000');
    params = params.append('gdscode', gdscode);
    return me.apiRequest.get('api/allocations/exter', params);
  }

  allocateRange(vo: Allocation): Observable<any> {

    return this.apiRequest.post('api/allocation', vo);
  }
}
