import { Component, OnInit } from '@angular/core';
import {FormcodeService} from '../../services/api/formcode.service';
declare var CanvasJS: any;

@Component({
  selector: 'app-dash-overall',
  templateUrl: './dash-overall.component.html',
  styleUrls: ['./dash-overall.component.css']
})
export class DashOverallComponent implements OnInit {
  createRatio: number;
  dataOpet = [];
  dataPoint_1 = [];
  dataPoint_2 = [];
  dataPoint_3 = [];
  dataPoint_4 = [];
  dataSeriousOpet1 = {
    type: 'line',
    showInLegend: 'ture',
    legendText: 'Acc',
    xValueFormatString: 'Year ####',
    yValueFormatString: '####### million',
    connectNullData: true,
    nullDataLineDashType: 'dot',
    dataPoints: []
  };
  dataSeriousOpet2 = {
    type: 'line',
    showInLegend: 'ture',
    legendText: 'All',
    xValueFormatString: 'Year ####',
    yValueFormatString: '####### million',
    connectNullData: true,
    nullDataLineDashType: 'dot',
    dataPoints: []
  };
  dataOpetRec = [];
  dataSeriousOpetRec4 = {
    type: 'line',
    showInLegend: 'ture',
    legendText: 'Acc',
    xValueFormatString: 'YY MMM',
    yValueFormatString: '####### million',
    connectNullData: true,
    xValueType: 'dateTime',
    nullDataLineDashType: 'dot',
    dataPoints: []
  };
  dataSeriousOpetRec5 = {
    type: 'line',
    showInLegend: 'ture',
    legendText: 'All',
    xValueFormatString: 'YY MMM',
    yValueFormatString: '####### million',
    connectNullData: true,
    xValueType: 'dateTime',
    nullDataLineDashType: 'dot',
    dataPoints: []
  };
  dataSeriousOpet3 = {
    type: 'column',
    showInLegend: 'ture',
    legendText: 'ACC',
    dataPoints: []
  };
  dataOpemd = [];
  dataSeriousOpemd1 = {
    type: 'line',
    showInLegend: 'ture',
    legendText: 'Acc',
    xValueFormatString: 'Year ####',
    yValueFormatString: '####### million',
    connectNullData: true,
    nullDataLineDashType: 'dot',
    dataPoints: []
  };
  dataSeriousOpemd2 = {
    type: 'line',
    showInLegend: 'ture',
    legendText: 'All',
    xValueFormatString: 'Year ####',
    yValueFormatString: '####### million',
    connectNullData: true,
    nullDataLineDashType: 'dot',
    dataPoints: []
  };
  dataSeriousOpemd3 = {
    type: 'line',
    showInLegend: 'ture',
    dataPoints: []
  };
  dataOpemdRec = [];
  dataSeriousOpemdRec4 = {
    type: 'line',
    showInLegend: 'ture',
    legendText: 'Acc',
    xValueFormatString: 'YY MMM',
    yValueFormatString: '####### million',
    connectNullData: true,
    xValueType: 'dateTime',
    nullDataLineDashType: 'dot',
    dataPoints: []
  };
  dataSeriousOpemdRec5 = {
    type: 'line',
    showInLegend: 'ture',
    legendText: 'All',
    xValueFormatString: 'YY MMM',
    yValueFormatString: '####### million',
    connectNullData: true,
    xValueType: 'dateTime',
    nullDataLineDashType: 'dot',
    dataPoints: []
  };


  constructor(private myservice: FormcodeService) {
  }

  ngOnInit() {
    this.createRatio = 5;

    this.myservice.getLineData().subscribe((data) => {

      this.dataSeriousOpet1.dataPoints = data.opetAcc;
      this.dataSeriousOpet2.dataPoints = data.opetAll;

      this.dataOpet.push(this.dataSeriousOpet1);
      this.dataOpet.push(this.dataSeriousOpet2);
      console.log(this.dataOpet);

      this.chart();


      this.dataSeriousOpemd1.dataPoints = data.opemdAcc;
      this.dataSeriousOpemd2.dataPoints = data.opemdAll;

      this.dataOpemd.push(this.dataSeriousOpemd1);
      this.dataOpemd.push(this.dataSeriousOpemd2);
      console.log(this.dataOpet);

      this.chart2();
      // this.docs = data.items;
      // console.log(this.docs);
    });
    this.myservice.getLineData1(this.createRatio).subscribe((data) => {
      this.dataSeriousOpetRec4.dataPoints = data.opetAcc;
      this.dataSeriousOpetRec5.dataPoints = data.opetAll;
      this.dataOpetRec.push(this.dataSeriousOpetRec4);
      this.dataOpetRec.push(this.dataSeriousOpetRec5);
      console.log(this.dataOpetRec);
      this.chart5();
      this.dataSeriousOpemdRec4.dataPoints = data.opemdAcc;
      this.dataSeriousOpemdRec5.dataPoints = data.opemdAll;
      this.dataOpemdRec.push(this.dataSeriousOpemdRec4);
      this.dataOpemdRec.push(this.dataSeriousOpemdRec5);
      this.chart6();
    });

    this.myservice.getBarData().subscribe((data) => {
      this.dataPoint_1 = data.springOPETAcc;
      this.dataPoint_2 = data.summerOPETAcc;
      this.dataPoint_3 = data.autumnOPETAcc;
      this.dataPoint_4 = data.winterOPETAcc;
      this.chart3();
      this.dataPoint_1 = data.springOPEMDAcc;
      this.dataPoint_2 = data.summerOPEMDAcc;
      this.dataPoint_3 = data.autumnOPEMDAcc;
      this.dataPoint_4 = data.winterOPEMDAcc;
      this.chart4();

    });

  }

  chart6() {
    var chart = new CanvasJS.Chart('opemd_rec',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPEMD',
          fontSize: 22
        },
        legend: {
          horizontalAlign: 'left',
          verticalAlign: 'bottom',
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        axisX: {
          valueFormatString: 'YY MMM',
        },
        axisY: {
          labelAngle: 30,
          interlacedColor: '#cfe3f7',
          gridThickness: 0,
          valueFormatString: '#####0. mil.'
        },

        data: this.dataOpemdRec
      });

    chart.render();
  }

  chart5() {
    var chart = new CanvasJS.Chart('opet_rec',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPET',
          fontSize: 22
        },
        legend: {
          horizontalAlign: 'left',
          verticalAlign: 'bottom',
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        axisX: {
          valueFormatString: 'YY MMM',
        },
        axisY: {
          labelAngle: 30,
          interlacedColor: '#cfe3f7',
          gridThickness: 0,
          valueFormatString: '#####0. mil.'
        },

        data: this.dataOpetRec
      });

    chart.render();
  }

  chart3() {
    // @ts-ignore
    var chart = new CanvasJS.Chart('opet',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPET',
          fontSize: 22
        },
        subtitles: [{
          text: 'QUATERLY ALLOCATION',
          fontSize: 18
        }],
        axisX: {
          interval: 1,
          intervalType: 'year'
        },
        axisY: {
          labelAngle: 30,
          interlacedColor: '#cfe3f7',
          gridThickness: 0,
          valueFormatString: '#####0. mil.'
        },
        toolTip: {
          shared: false
        },
        legend: {
          cursor: 'pointer',
          horizontalAlign: 'left',
          itemclick: toggleDataSeries
        },
        // data:  this.dataOped
        data: [
          {
            type: 'column',
            name: 'Q1',
            color: '#8db8fc',
            showInLegend: true,
            yValueFormatString: '#####0. million',
            toolTipContent: '{label}<br/>{name}, <strong>{y}</strong><br/>Total,<strong>{total} million</strong><br/>',
            dataPoints: this.dataPoint_1
          },
          {
            type: 'column',
            name: 'Q2',
            color: '#3594C7',
            showInLegend: true,
            yValueFormatString: '#####0. million',
            toolTipContent: '{label}<br/>{name}, <strong>{y}</strong><br/>Total,<strong>{total} million</strong><br/>',
            dataPoints: this.dataPoint_2
          },
          {
            type: 'column',
            name: 'Q3',
            color: '#254992',
            showInLegend: true,
            yValueFormatString: '#####0. million',
            toolTipContent: '{label}<br/>{name}, <strong>{y}</strong><br/>Total,<strong>{total} million</strong><br/>',
            dataPoints: this.dataPoint_3
          },
          {
            type: 'column',
            name: 'Q4',
            color: '#161F63',
            showInLegend: true,
            yValueFormatString: '#####0. million',
            toolTipContent: '{label}<br/>{name}, <strong>{y}</strong><br/>Total,<strong>{total} million</strong><br/>',
            dataPoints: this.dataPoint_4
          }
        ]
      });
    chart.render();

    function toggleDataSeries(e) {
      if (typeof (e.dataSeries.visible) === 'undefined' || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      e.chart.render();
    }

  }

  chart4() {
    // @ts-ignore
    var chart = new CanvasJS.Chart('opemd',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPEMD',
          fontSize: 22
        },
        subtitles: [{
          text: 'QUATERLY ALLOCATION',
          fontSize: 18
        }],
        axisX: {
          interval: 1,
          intervalType: 'year'
        },
        axisY: {
          labelAngle: 30,
          interlacedColor: '#cfe3f7',
          gridThickness: 0,
          valueFormatString: '#####0. mil.'
        },
        toolTip: {
          shared: false
        },
        legend: {
          horizontalAlign: 'left',
          cursor: 'pointer',
          itemclick: toggleDataSeries
        },
        // data:  this.dataOped
        data: [
          {
            type: 'column',
            name: 'Q1',
            showInLegend: true,
            color: '#769bfc',
            yValueFormatString: '#####0. million',
            toolTipContent: '{label}<br/>{name}, <strong>{y}</strong><br/>Total,<strong>{total} million</strong><br/>',
            dataPoints: this.dataPoint_1
          },
          {
            type: 'column',
            name: 'Q2',
            color: '#3594C7',
            showInLegend: true,
            yValueFormatString: '#####0. million',
            toolTipContent: '{label}<br/>{name}, <strong>{y}</strong><br/>Total,<strong>{total} million</strong><br/>',
            dataPoints: this.dataPoint_2
          },
          {
            type: 'column',
            name: 'Q3',
            color: '#254992',
            showInLegend: true,
            yValueFormatString: '#####0. million',
            toolTipContent: '{label}<br/>{name}, <strong>{y}</strong><br/>Total,<strong>{total} million</strong><br/>',
            dataPoints: this.dataPoint_3
          },
          {
            type: 'column',
            name: 'Q4',
            color: '#161F63',
            showInLegend: true,
            yValueFormatString: '#####0. million',
            toolTipContent: '{label}<br/>{name}, <strong>{y}<c/strong><br/>Total,<strong>{total} million</strong><br/>',
            dataPoints: this.dataPoint_4
          }
        ]
      });
    chart.render();

    function toggleDataSeries(e) {
      if (typeof (e.dataSeries.visible) === 'undefined' || e.dataSeries.visible) {
        e.dataSeries.visible = false;
      } else {
        e.dataSeries.visible = true;
      }
      chart.render();
    }

  }

  chart() {
    var chart = new CanvasJS.Chart('chartContainer_opet',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPET',
          fontSize: 22
        },
        legend: {
          horizontalAlign: 'left',
          verticalAlign: 'bottom',
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        axisX: {
          valueFormatString: '####',
        },
        axisY: {
          labelAngle: 30,
          interlacedColor: '#cfe3f7',
          gridThickness: 0,
          valueFormatString: '#####0. mil.'
        },

        data: this.dataOpet
      });

    chart.render();
  }

  chart2() {
    const chart = new CanvasJS.Chart('chartContainer_opemd',
      {
        animationEnabled: true,
        animationDuration: 2000,
        title: {
          text: 'OPEMD',
          fontSize: 22
        },
        legend: {
          horizontalAlign: 'left',
          verticalAlign: 'bottom',
          fontSize: 10,
          fontFamily: 'Helvetica'
        },
        axisX: {
          valueFormatString: '####',
        },
        axisY: {
          labelAngle: 30,
          interlacedColor: '#cfe3f7',
          gridThickness: 0,
          valueFormatString: '#####0. mil.'
        },

        data: this.dataOpemd
      });

    chart.render();
  }

  PercentageIncrease() {
    this.dataOpetRec = [];
    this.dataOpemdRec = [];
    if (!(/(^-?\d*$)/.test(String((this.createRatio)))) || this.createRatio.valueOf() < -100 || this.createRatio.valueOf() > 100) {
      alert('must be integer');
    } else {
      this.myservice.getLineData1(this.createRatio).subscribe((data) => {
        this.dataSeriousOpetRec4.dataPoints = data.opetAcc;
        this.dataSeriousOpetRec5.dataPoints = data.opetAll;
        this.dataOpetRec.push(this.dataSeriousOpetRec4);
        this.dataOpetRec.push(this.dataSeriousOpetRec5);
        console.log('changed');
        console.log(this.dataOpetRec);
        this.chart5();
        this.dataSeriousOpemdRec4.dataPoints = data.opemdAcc;
        this.dataSeriousOpemdRec5.dataPoints = data.opemdAll;
        this.dataOpemdRec.push(this.dataSeriousOpemdRec4);
        this.dataOpemdRec.push(this.dataSeriousOpemdRec5);
        this.chart6();

      });

    }
  }
}
