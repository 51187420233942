<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Serial Number Allocation-Purge Dashboard</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered" >
    <div class="col-12">
      <div class="row">
        <div class="form-group col-2">
          <label for="snaptable_fcNumber" >Form Code:</label>
          <input type="text" name="snaptable_fcNumber" id="snaptable_fcNumber" maxlength="3" [(ngModel)]="fcNumber">
        </div>
        <div class="form-group col-3 mt-4 ml-5">
          <input type="button" name="snaptable_filter" value="Filter" (click)="filter()">
        </div>
      </div>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E">
          <td>Expired allocations</td>
          <td>volums</td>
          <td>BSP</td>
          <td>GDS</td>
          <td>Allocation Date</td>
          <td>Expiry Date</td>
          <td>Status</td>
        </tr>
        </thead>
        <tr class="Waving1" *ngFor="let todo of allocations">
          <td>{{todo.startnumber | snformat}}-{{(todo.startnumber+todo.quantity) | snformat}}</td>
          <td>{{todo.quantity | number}}</td>
          <td>{{todo.bsp.bspFullname}}</td>
          <td>{{todo.gds.gdsCode}}</td>
          <td>{{todo.allocationdate | date:'dd-MM-yyyy'}}</td>
          <td>{{todo.validitydate | date:'dd-MM-yyyy'}}</td>
          <td *ngIf="todo.status==0">not purge</td>
          <td *ngIf="todo.status==1" >
            <label>gds purged at {{todo.purgeDate | date:'dd-MM-yyyy'}}</label>
            <button mwlConfirmationPopover
                   [popoverTitle]="title"
                   [popoverMessage]="purgeMessage"
                   placement="left"
                   (confirm)="changeStatus(todo.id,todo.status)"
                   (cancel)="cancelClicked = true">confirm</button>
          </td>
        </tr>

      </table>

     <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>

      <div style="color: #6E859A; font-size: 12px">
        This dashboard is to track the expotation of allocated ranges to GDSs.(Expiration data of the range is one year from the date of allocation)
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <!--<div class="row form-group table-bordered">
    <div class="offset-1">
      <input type="submit" name="create" value="Create" >
    </div>
    <div class="offset-3">
      <input type="submit" name="refresh" value="Refresh" >
    </div>
  </div>-->
  <!-- button end -->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>

  <!-- table -->
</div>
