import {Injectable, Inject} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {MonitorUsage} from '../../vo/monitorUsage';
import {CurrentStatus} from '../../vo/currentStatus';
import {Snstatus} from '../../vo/snstatus';
import {Opstatus} from '../../vo/opstatus';
import {ApiRequestEXService} from './api-request-ex.service';
import { HttpParams } from '@angular/common/http';


@Injectable()
export class MonitorUsageService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  updateItem(vo: CurrentStatus): Observable<any> {
    return this.apiRequest.put('api/updateStatus', vo);
  }

  getAllSnstatus(vo?: Snstatus): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/Snstatuss/all');
  }

  getAllOpstatus(vo?: Opstatus): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/Opstatus/all');
  }

  getCurrentstatusPages(page?: number, size?: number, vo?: MonitorUsage): Observable<any> {
    // Create Request URL params
    console.log(vo);
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (vo && vo.formcodeId) {
      params = params.append('formcodeId', vo.formcodeId);
    }

    if (vo && vo.docType.id) {
      params = params.append('docType', vo.docType.id.toString());
    }
    if (vo && vo.validityDate) {
      params = params.append('validityDate', vo.validityDate);
    }
    if (vo && vo.serialNumber) {
      params = params.append('serialNumber', vo.serialNumber);
    }
    if (vo && vo.docSubType.id) {
      params = params.append('docSubType', vo.docSubType.id.toString());
    }
    if (vo && vo.gds.id) {
      params = params.append('gds', vo.gds.id.toString());
    }
    if (vo && vo.activeBsp.id) {
      params = params.append('activeBsp', vo.activeBsp.id.toString());
    }
    if (vo && vo.printer.id) {
      params = params.append('printer', vo.printer.id.toString());
    }
    if (vo && vo.purchaseOrderNumber) {
      params = params.append('purchaseOrderNumber', vo.purchaseOrderNumber);
    }
    if (vo && vo.allocationDate) {
      params = params.append('allocationDate', vo.allocationDate);
    }
    if (vo && vo.formcodeFrozen) {
      params = params.append('formcodeFrozen', vo.formcodeFrozen);
    }
    if (vo && vo.snCode) {
      params = params.append('snCode', vo.snCode);
    }
    if (vo && vo.opCode) {
      params = params.append('opCode', vo.opCode);
    }
    console.log(params);
    return this.apiRequest.get('api/CurrentStatuss', params);

  }

  getCurrentstatusPagesByGds(page?: number, size?: number, vo?: CurrentStatus): Observable<any> {
    // Create Request URL params
    console.log(vo);
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (vo && vo.formcodeId) {
      params = params.append('formcodeId', vo.formcodeId);
    }
    if (vo && vo.docType) {
      params = params.append('docType', vo.docType);
    }
    if (vo && vo.serialNumber) {
      params = params.append('serialNumber', vo.serialNumber);
    }
    if (vo && vo.gds) {
      params = params.append('gds', vo.gds);
    }
    if (vo && vo.activeBsp) {
      params = params.append('activeBsp', vo.activeBsp);
    }
    console.log(params);
    return this.apiRequest.get('api/CurrentStatusGds', params);

  }

  getActionHistoryPagesByGds(page?: number, size?: number, vo?: CurrentStatus): Observable<any> {
    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (vo && vo.formcodeId) {
      params = params.append('formcodeId', vo.formcodeId);
    }
    if (vo && vo.docType) {
      params = params.append('docType', vo.docType);
    }
    if (vo && vo.serialNumber) {
      params = params.append('serialNumber', vo.serialNumber);
    }
    if (vo && vo.gds) {
      params = params.append('gds', vo.gds);
    }
    if (vo && vo.activeBsp) {
      params = params.append('activeBsp', vo.activeBsp);
    }

    console.log(params);
    return this.apiRequest.get('api/ActionHistoriesGds', params);

  }

  getActionHistoryPages(page?: number, size?: number, vo?: MonitorUsage): Observable<any> {
    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');

    if (vo && vo.formcodeId) {
      params = params.append('formcodeId', vo.formcodeId.toString());
    }
    // alert(vo.docType.id);
    // console.log(vo.docType.id);
    if (vo && vo.docType.id) {
      params = params.append('docType', vo.docType.id.toString());
    }
    if (vo && vo.validityDate) {
      params = params.append('validityDate', vo.validityDate);
    }
    if (vo && vo.serialNumber) {
      params = params.append('serialNumber', vo.serialNumber);
    }
    // alert(vo.docSubType);
    if (vo && vo.docSubType.id) {
      params = params.append('docSubType', vo.docSubType.id.toString());
    }
    if (vo && vo.gds.id) {
      params = params.append('gds', vo.gds.id.toString());
    }
    if (vo && vo.activeBsp.id) {
      params = params.append('activeBsp', vo.activeBsp.id.toString());
    }
    if (vo && vo.printer.id) {
      params = params.append('printer', vo.printer.id.toString());
    }
    if (vo && vo.purchaseOrderNumber) {
      params = params.append('purchaseOrderNumber', vo.purchaseOrderNumber);
    }
    if (vo && vo.allocationDate) {
      params = params.append('allocationDate', vo.allocationDate);
    }
    if (vo && vo.formcodeFrozen) {
      params = params.append('formcodeFrozen', vo.formcodeFrozen);
    }
    if (vo && vo.statusChangeDate) {
      params = params.append('statusChangeDate', vo.statusChangeDate);
    }
    if (vo && vo.snCode) {
      params = params.append('snCode', vo.snCode);
    }
    if (vo && vo.opCode) {
      params = params.append('opCode', vo.opCode);
    }

    console.log(params);
    return this.apiRequest.get('api/ActionHistories', params);

  }

}
