export class Dpc {
  dpcCode: string;
  dpcEmail: string;
  dpcIinetAccount: string;
  id: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
