<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Issuing Party Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered">

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>
        View Issuing Party details
      </h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-hover table-condensed">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E;">
          <td title="Edit record" width="20px"></td>
          <td>Name</td>
          <td>Description</td>
        </thead>
        <tbody>
        <tr class="Waving1" *ngFor="let  issue of issus ">
          <td ><img border="0" src="../../../assets/SNAPDocroot//images/tableview/edit.gif" alt="Edit" title="Modify this record" (click)="gotoEdit(issue)"></td>
          <td>{{issue.name}}</td>
          <td>{{issue.description}}</td>
        </tr>

        <tr class="Waving1" *ngIf="issus.length == 0">
          <td colspan="7">No Data...</td>
        </tr>

        </tbody>
      </table>
    </div>
  </div>
  <div class="col-md-6">
    <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)"
                [maxSize]="maxSize" class="pagination-sm"
                [boundaryLinks]="true"></pagination>
  </div>

  <div class="row form-group table-bordered">
    <div class="offset-3">
      <input type="button" name="create" value="Create" (click)="gotoNew()">
    </div>
    <div class="offset-3">
      <input type="button" name="refresh" value="Refresh" (click)="filter()">
    </div>
  </div>
</div>
