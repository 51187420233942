<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Subscribers Maintenance</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered" >
    <div class="col-12">
      <form name="baseDynaForm" method="post" action="">
        <div class="row">
          <div class="form-group col-5 mt-4">
            <label for="snaptable_email"> Email Address:</label>
            <input type="text" name="snaptable_email" maxlength="100" value="" [(ngModel)]="qry.email" >
          </div>
          <div class="form-group col-3 mt-4 ml-5">
            <input type="submit" name="snaptable_filter" value="Filter" (click)="filter()">
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered" style="font-size:12px">
        <thead><tr style="background-color: #F2F5FC;color:#1B548E"><td title="Edit record" width="20px" > </td><td title="Delete record" width="20" > </td><td>Email Address</td><td>Description</td></tr></thead>
        <tbody *ngIf="subscribers.length > 0">
        <tr class="Waving1" *ngFor="let todo of subscribers">
          <td><img border="0" src="assets/SNAPDocroot//images/tableview/edit.gif" alt="Edit" (click)="gotoEdit(todo)" title="Modify this record"></td>
          <td><img border="0" src="assets/SNAPDocroot//images/tableview/delete.gif" alt="Delete" (click)="gotoDelete(todo)" title="Delete this record">
          </td><td>{{todo.email}}</td>
          <td>{{todo.description}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="subscribers.length == 0">
        <tr class="Waving1" >
          <td colspan="5">No Data...</td>

        </tr>
        </tbody>

      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <div class="row form-group table-bordered">
    <div class="offset-1">
      <input type="submit" name="create" value="Create" (click)="gotoNew()">
    </div>
    <div class="offset-3">
      <input type="submit" name="refresh" value="Refresh" (click)="filter()">
    </div>
  </div>
  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->
