import { Component, OnInit } from '@angular/core';
import { PredefinedRule } from '../../../vo/predefinedRule';
import { PredefinedRuleService } from '../../../services/api/predefinedRule.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {Bsp} from '../../../vo/bsp';
import {Gds} from '../../../vo/gds';
import {Document} from '../../../vo/document';

@Component({
  selector: 'app-edit-predefine-rule',
  templateUrl: './edit-predefine-rule.component.html',
  styleUrls: ['./edit-predefine-rule.component.css']
})
export class EditPredefineRuleComponent implements OnInit {

  constructor(  protected route: ActivatedRoute,
                protected router: Router,
                protected service: PredefinedRuleService) { }

  public id: number;
  public action: String;
  public isupdate: boolean;
  vo: PredefinedRule = new PredefinedRule();
  public alerts: any = [];
  ngOnInit() {
    const bsp: Bsp = new Bsp();
    this.vo.bsp = bsp;

    const doc: Document = new Document();
    this.vo.documenttypes = doc;

    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.action = p['action'];
      if (this.action == 'U') {
        this.isupdate = true;
      } else {
        this.isupdate = false;
      }
      const me = this;
      this.vo.id = this.id;
      me.getData(this.id);
    });
  }

  update() {
    this.service.updateItem(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }
  delete() {
    this.service.deleteItem(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }

  backToListPage() {
    this.router.navigate(['/home/predefinedRule']);
  }

  getData(id: number) {
    const me = this;
    this.service.getItemByID(id).subscribe((data) => {
        console.log(data);
        this.vo = data.item;
        // this.bigTotalItems = data.totalItems;
      }
    );
  }
  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

}
