<!-- continer -->
<div class="container-fluid">

  <!-- logo -->
  <div class="row">
    <div id="header">
      <div class="logo"><a href="http://www.iata.org/"><img src="./assets/SNAPDocroot//portal/images/logo_app.gif" border="0"
                                                            title="International Air Transport Association"></a></div>
      <div class="logoprint"><img src="./assets/SNAPDocroot//portal/images/logo_print.gif"></div>
    </div>
  </div>
  <!-- logo -->


  <!-- middle -->
  <div class="row" style="height: 42rem">

    <!-- left menu -->
    <nav class="col-md-2 rounded navbar-toggleable-sm" >

      <button class="navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#menu">
        <span class="navbar-toggler-icon">Col</span>
      </button>

      <h1 class="navbar-brand">SNAP</h1>

    </nav>
    <!-- left menu -->


    <!-- main -->
    <main class="col-md-10 mt-3" >
      <div class="row mt-3">

        <div class="col-md-6 offset-md-3" >

            <div class="row table-bordered mt-3 border-right-0 border-left-0">
              <div class="col-12 mt-3">
                <form role="form"  >
                  <div class="row form-group table-bordered" >
                    <div class="col-sm-3 "><label for="usr">Username</label></div>
                    <div class="col-sm-9">
                      <input type="text" name="usr" [(ngModel)]="userName">
                      <font color="#ff0000" size="4">*</font>
                    </div>
                  </div>
                  <div class="row form-group table-bordered">
                    <re-captcha   resolved="resolved($event)" siteKey="6LdWyzgUAAAAANFO8_yJrQlAxfGj57W3IaCraATU"
                                  [(ngModel)]="capcha" name="captcha"></re-captcha>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row offset-3 ">
          <div >
            <input type="submit" name="submit" value="submit" (click)="submit()" >
          </div>
          <div class="offset-3">
            <input type="submit" name="back" value="back" (click)='back()'>
          </div>
        </div>
        <div class="row offset-3" *ngFor="let alert of alerts">
          <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
            }}</alert>
        </div>
    </main>

  <!-- main -->
  </div>
  <!-- foot -->
  <div class="row">
    <div id="footer">
      <h6>Bin v5.2 of 04.01.2007 &copy;2005 International Air Transport Association. All rights reserved.</h6>
    </div>
  </div>
  <!-- foot -->
</div>


