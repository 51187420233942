export class Changpwd {

  userId: string;
  oldPwd: string;
  newPwd: string;
  comfirmPwd: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
