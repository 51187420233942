import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Gds } from '../../vo/gds';
import { HttpParams } from '@angular/common/http';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class GdsService {
  constructor(
    private apiRequest: ApiRequestEXService
  ) {
  }

  getItems(page?: number, size?: number, gds?: Gds): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (gds && gds.gdsCode) {
      params = params.append('gdsCode', gds.gdsCode);
    }
    if (gds && gds.gdsSendtifile) {
      params = params.append('gdsSendtifile', gds.gdsSendtifile.toString());
    }
    console.log(params);
    return this.apiRequest.get('api/gdss', params);

  }

  getItemByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/gds/' + id);
  }

  addItem(vo: Gds): Observable<any> {
    return this.apiRequest.post('api/gds', vo);
  }

  updateItem(vo: Gds): Observable<any> {
    return this.apiRequest.put('api/gds', vo);
  }

  deleteItem(vo: Gds): Observable<any> {
    return this.apiRequest.delete('api/gds/' + vo.id);
  }

}
