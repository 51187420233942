import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {HomeComponent} from './home.component';

import {LoginComponent} from './pages/login/login.component';
import {LogoutComponent} from './pages/logout/logout.component';
import {DpcComponent} from './pages/dpc/dpc.component';

import {AuthGuard} from './services/auth_guard.service';
import {PageNotFoundComponent} from './pages/404/page-not-found.component';
import {BspComponent} from './pages/bsp/bsp.component';
import {NewdpcComponent} from './pages/dpc/newdpc.component';
import {EditdpcComponent} from './pages/dpc/editdpc.component';
import {DeletedpcComponent} from './pages/dpc/deletedpc/deletedpc.component';
import {DocumentTypeComponent} from './pages/document-type/document-type.component';
import {UpdateDocComponent} from './pages/document-type/update-doc/update-doc.component';
import {CreateDocComponent} from './pages/document-type/create-doc/create-doc.component';
import {SubscriberComponent} from './pages/subscriber/subscriber.component';
import {NewsubscriberComponent} from './pages/subscriber/newsubscriber/newsubscriber.component';
import {EditsubscriberComponent} from './pages/subscriber/editsubscriber/editsubscriber.component';

import {GdsComponent} from './pages/gds/gds.component';
import {NewgdsComponent} from './pages/gds/newgds/newgds.component';
import {EditgdsComponent} from './pages/gds/editgds/editgds.component';

import {DocumentSubTypeComponent} from './pages/document-sub-type/document-sub-type.component';
import {EditdocsubtypeComponent} from './pages/document-sub-type/editdocsubtype/editdocsubtype.component';
import {NewdocsubtypeComponent} from './pages/document-sub-type/newdocsubtype/newdocsubtype.component';
import {AllocationComponent} from './pages/allocation/allocation.component';
import {RecycleComponent} from './pages/recycle/recycle.component';
import {NewbspComponent} from './pages/bsp/newbsp/newbsp.component';
import {EditbspComponent} from './pages/bsp/editbsp/editbsp.component';

import {FormcodeComponent} from './pages/formcode/formcode.component';
import {EmailComponent} from './pages/email/email.component';
import {NewallocationComponent} from './pages/allocation/newallocation/newallocation.component';
import {NewformcodeComponent} from './pages/formcode/newformcode/newformcode.component';
import {EditformcodeComponent} from './pages/formcode/editformcode/editformcode.component';
import {EmailTemplateDetailComponent} from './pages/email/email-template-detail.component';
import {ViewformcodeComponent} from './pages/formcode/viewformcode/viewformcode.component';
import {PredefinedRuleComponent} from './pages/predefined-rule/predefined-rule.component';
import {NewPredefinedRuleComponent} from './pages/predefined-rule/new-predefined-rule/new-predefined-rule.component';
import {EditPredefineRuleComponent} from './pages/predefined-rule/edit-predefine-rule/edit-predefine-rule.component';
import {MergingComponent} from './pages/merging/merging.component';
import {BulletinComponent} from './pages/bulletin/bulletin.component';
import {MonitorUsageComponent} from './pages/monitor-usage/monitor-usage.component';
import {RetrieveComponent} from './pages/retrieve/retrieve.component';
import {TransferComponent} from './pages/transfer/transfer.component';
import {BulletinMailComponent} from './pages/bulletin/bulletin-mail.component';
import {UnmergeComponent} from './pages/unmerge/unmerge.component';
import {RequestRejectComponent} from './pages/allocation/request-reject/request-reject.component';

import {DashRecycleComponent} from './pages/dash-recycle/dash-recycle.component';
import {DashFcUsageComponent} from './pages/dash-fc-usage/dash-fc-usage.component';
import {DashOverallComponent} from './pages/dash-overall/dash-overall.component';
import {DashPurgeComponent} from './pages/dash-purge/dash-purge.component';
import {DashIndexComponent} from './pages/dash-index/dash-index.component';

import {ForgotpwdComponent} from './pages/forgotpwd/forgotpwd.component';
import {RedirectComponent} from './pages/redirect/redirect.component';
import {BspCalComponent} from './pages/bsp-cal/bsp-cal.component';
import {BspWithCalComponent} from './pages/bsp-with-cal/bsp-with-cal.component';
import {BspEditCalComponent} from './pages/bsp-with-cal/bsp-edit-cal/bsp-edit-cal.component';
import {CalEditComponent} from './pages/bsp-cal/cal-edit/cal-edit.component';
import {GenerateTiFileComponent} from './pages/generate-ti-file/generate-ti-file.component';
import {BspTiCalendarComponent} from './pages/bsp-ti-calendar/bsp-ti-calendar.component';
import {TiAutoSwitchComponent} from './pages/ti-auto-switch/ti-auto-switch.component';
import {AllocationPulsComponent} from './pages/allocation-puls/allocation-puls.component';
import {MailResendComponent} from './pages/mail-resend/mail-resend.component';
import {MailResendDetailComponent} from './pages/mail-resend/mail-resend-detail/mail-resend-detail.component';
import {ServerDownComponent} from './pages/server-down/server-down.component';
import {MasterNodeSwitchComponent} from './pages/master-node-switch/master-node-switch.component';
import {IssuePartyComponent} from './pages/issue-party/issue-party.component';
import {CreateIssuePartyComponent} from './pages/issue-party/create-issue-party/create-issue-party.component';
import {EditIssuePartyComponent} from './pages/issue-party/edit-issue-party/edit-issue-party.component';
import { BspCalBatchEditComponent } from './pages/bsp-with-cal/bsp-cal-batch-edit/bsp-cal-batch-edit.component';
const routes: Routes = [
  // Important: The sequence of path is important as the router go over then in sequential manner
  {path: '', redirectTo: '/home', pathMatch: 'full'},
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [  // Children paths are appended to the parent path
      {path: '', redirectTo: '/home/dashboard', pathMatch: 'full'},  // Default path (if no deep path is specified for home component like webui/home then it will by default show ProductsComponent )
      {
        path: 'dashboard',
        component: DashIndexComponent,
        data: [{selectedHeaderItemIndex: 0, selectedSubNavItemIndex: -1}]
      },
      {path: 'dpc', component: DpcComponent, data: [{selectedHeaderItemIndex: 5, selectedSubNavItemIndex: -1}]},
      {path: 'bsp', component: BspComponent, data: [{selectedHeaderItemIndex: 6, selectedSubNavItemIndex: -1}]},

      {path: 'newdpc', component: NewdpcComponent, data: [{selectedHeaderItemIndex: 7, selectedSubNavItemIndex: -1}]},
      {
        path: 'editdpc/:id',
        component: EditdpcComponent,
        data: [{selectedHeaderItemIndex: 8, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'deletedpc/:id',
        component: DeletedpcComponent,
        data: [{selectedHeaderItemIndex: 9, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'doc',
        component: DocumentTypeComponent,
        data: [{selectedHeaderItemIndex: 10, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'newdoc',
        component: CreateDocComponent,
        data: [{selectedHeaderItemIndex: 11, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'editdoc',
        component: UpdateDocComponent,
        data: [{selectedHeaderItemIndex: 12, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'subscriber',
        component: SubscriberComponent,
        data: [{selectedHeaderItemIndex: 13, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'newsubscriber',
        component: NewsubscriberComponent,
        data: [{selectedHeaderItemIndex: 14, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'editsubscriber',
        component: EditsubscriberComponent,
        data: [{selectedHeaderItemIndex: 15, selectedSubNavItemIndex: -1}]
      },

      {path: 'gds', component: GdsComponent, data: [{selectedHeaderItemIndex: 16, selectedSubNavItemIndex: -1}]},
      {path: 'newgds', component: NewgdsComponent, data: [{selectedHeaderItemIndex: 17, selectedSubNavItemIndex: -1}]},
      {
        path: 'editgds',
        component: EditgdsComponent,
        data: [{selectedHeaderItemIndex: 18, selectedSubNavItemIndex: -1}]
      },

      {
        path: 'docsubtype',
        component: DocumentSubTypeComponent,
        data: [{selectedHeaderItemIndex: 16, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'newdocsubtype',
        component: NewdocsubtypeComponent,
        data: [{selectedHeaderItemIndex: 17, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'editdocsubtype',
        component: EditdocsubtypeComponent,
        data: [{selectedHeaderItemIndex: 18, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'allocation',
        component: AllocationComponent,
        data: [{selectedHeaderItemIndex: 19, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'recycle',
        component: RecycleComponent,
        data: [{selectedHeaderItemIndex: 20, selectedSubNavItemIndex: -1}]
      },

      {
        path: 'formcode',
        component: FormcodeComponent,
        data: [{selectedHeaderItemIndex: 22, selectedSubNavItemIndex: -1}]
      },
      {path: 'email', component: EmailComponent, data: [{selectedHeaderItemIndex: 23, selectedSubNavItemIndex: -1}]},
      {
        path: 'newallocate',
        component: NewallocationComponent,
        data: [{selectedHeaderItemIndex: 24, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'newformcode',
        component: NewformcodeComponent,
        data: [{selectedHeaderItemIndex: 25, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'editformcode',
        component: EditformcodeComponent,
        data: [{selectedHeaderItemIndex: 26, selectedSubNavItemIndex: -1}]
      },

      {path: 'newbsp', component: NewbspComponent, data: [{selectedHeaderItemIndex: 27, selectedSubNavItemIndex: -1}]},
      {
        path: 'editbsp',
        component: EditbspComponent,
        data: [{selectedHeaderItemIndex: 28, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'editemailtemplate',
        component: EmailTemplateDetailComponent,
        data: [{selectedHeaderItemIndex: 29, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'viewformcode',
        component: ViewformcodeComponent,
        data: [{selectedHeaderItemIndex: 30, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'predefinedRule',
        component: PredefinedRuleComponent,
        data: [{selectedHeaderItemIndex: 31, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'newPredefinedRule',
        component: NewPredefinedRuleComponent,
        data: [{selectedHeaderItemIndex: 32, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'editPredefinedRule',
        component: EditPredefineRuleComponent,
        data: [{selectedHeaderItemIndex: 33, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'merging',
        component: MergingComponent, data: [{selectedHeaderItemIndex: 34, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'bulletin',
        component: BulletinComponent, data: [{selectedHeaderItemIndex: 35, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'monitorusage',
        component: MonitorUsageComponent, data: [{selectedHeaderItemIndex: 37, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'retrieve',
        component: RetrieveComponent, data: [{selectedHeaderItemIndex: 38, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'transfer',
        component: TransferComponent, data: [{selectedHeaderItemIndex: 38, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'bulletinmail',
        component: BulletinMailComponent, data: [{selectedHeaderItemIndex: 41, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'unmerge',
        component: UnmergeComponent, data: [{selectedHeaderItemIndex: 42, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'requestreject',
        component: RequestRejectComponent, data: [{selectedHeaderItemIndex: 43, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'fcUsage',
        component: DashFcUsageComponent, data: [{selectedHeaderItemIndex: 44, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'dashRecycle',
        component: DashRecycleComponent, data: [{selectedHeaderItemIndex: 45, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'dashOverall',
        component: DashOverallComponent, data: [{selectedHeaderItemIndex: 47, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'dashPurge',
        component: DashPurgeComponent, data: [{selectedHeaderItemIndex: 48, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'dashIndex',
        component: DashIndexComponent, data: [{selectedHeaderItemIndex: 48, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'goRedirect',
        component: RedirectComponent, data: [{selectedHeaderItemIndex: 49, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'bspCal',
        component: BspCalComponent, data: [{selectedHeaderItemIndex: 50, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'bspWithCal',
        component: BspWithCalComponent, data: [{selectedHeaderItemIndex: 51, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'bspEditCal',
        component: BspEditCalComponent, data: [{selectedHeaderItemIndex: 51, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'calEdit',
        component: CalEditComponent, data: [{selectedHeaderItemIndex: 51, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'bspCalBatchEdit',
        component: BspCalBatchEditComponent, data: [{selectedHeaderItemIndex: 51, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'tiFileGenerate',
        component: GenerateTiFileComponent, data: [{selectedHeaderItemIndex: 52, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'bspTiCalendar',
        component: BspTiCalendarComponent, data: [{selectedHeaderItemIndex: 52, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'tiSwitch',
        component: TiAutoSwitchComponent, data: [{selectedHeaderItemIndex: 52, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'allocaTop',
        component: AllocationPulsComponent, data: [{selectedHeaderItemIndex: 53, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'mailResend',
        component: MailResendComponent, data: [{selectedHeaderItemIndex: 54, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'mailResendDetail',
        component: MailResendDetailComponent, data: [{selectedHeaderItemIndex: 55, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'masterNodeSwitch',
        component: MasterNodeSwitchComponent, data: [{selectedHeaderItemIndex: 55, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'issueParty',
        component: IssuePartyComponent, data: [{selectedHeaderItemIndex: 56, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'addissueParty',
        component: CreateIssuePartyComponent, data: [{selectedHeaderItemIndex: 57, selectedSubNavItemIndex: -1}]
      },
      {
        path: 'editissueParty',
        component: EditIssuePartyComponent,
        data: [{selectedHeaderItemIndex: 58, selectedSubNavItemIndex: -1}]
      }
    ]
  },
  {path: 'login', component: LoginComponent},
  {path: 'logout', component: LogoutComponent},
  {path: 'forgotpwd', component: ForgotpwdComponent},
  {path: '**', component: PageNotFoundComponent},
  {path: 'serverdown', component: ServerDownComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule],
  declarations: [PageNotFoundComponent]
})
export class AppRoutingModule {
}
