<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Formcode Recycling Dashboard</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" >

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered" >
    <div class="col-12">
      <div class="row">
        <div class="form-group col-2">
          <label for="snaptable_fcNumber" >Form Code:</label>
          <input type="text" name="snaptable_fcNumber" id="snaptable_fcNumber" maxlength="3" [(ngModel)]="qry.fcNumber">
        </div>
        <div class="form-group col-3 mt-4 ml-5">
          <input type="button" name="snaptable_filter" value="Filter" (click)="filter()">
        </div>

        <div class="form-group col-3 mt-4 ml-5">
          <input type="button" name="export" value="Export CSV" (click)="exportRecyclingCSV()">
        </div>
      </div>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E">
          <td>Inactive form codes</td>
          <td>Document Type</td>
          <td>Frozen Date</td>
          <td>Recycling Date</td>
          <td>Proceed with recycling</td>
        </tr>
        </thead>
        <tr class="Waving1" *ngFor="let todo of frozenFormcodes ">
          <td>{{todo.fcNumber}}</td>
          <td>{{todo.documentType.dtName}}</td>
          <td>{{todo.fcFrozen | date:'dd-MM-yyyy'}}</td>
          <td>{{todo.fcFrozenEnd | date:'dd-MM-yyyy'}}</td>
          <td *ngIf="todo.proceeding !='Active'">{{todo.proceeding}}</td>
          <td *ngIf="todo.proceeding =='Active'">
            <input type="submit" name="recycle" value="RECYCLE"  (click)="recycle(todo.id)">
          </td>
        </tr>
      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
      <div style="color: #6E859A; font-size: 12px">
        This dashboard is to have an overview of the ready to Recycle form codes.
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->
  <!-- button end -->
  <!-- table -->
</div>
