
export class Snstatus {

  code: string;
  description: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
