export class Calendar {

  id: number;
  timezone: string;
  tiGenerateTime: string;
  comment: string;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
