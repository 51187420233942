import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class MailResendService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  getPages(page?: number, size?: number, status?: number, from?: string, subject?: string, createDate?: string, to?: string, cc?: string,): Observable<any> {

    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '10');
    // params = params.append('status', typeof status === 'number' ? status.toString() : '-1');
    if (status != null) {
      params = params.append('status', status.toString());
    } else {
      params = params.append('status', '-1');
    }
    if (from) {
      params = params.append('from', from);
    }
    if (subject) {
      params = params.append('subject', subject);
    }
    if (to) {
      params = params.append('to', to);
    }
    if (cc) {
      params = params.append('cc', cc);
    }
    if (createDate) {
      params = params.append('createDate', createDate);
    }
    // console.log(params);
    return this.apiRequest.get('api/mailResendView', params);

  }

  getDetail(id?: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (id != null) {
      params = params.append('id', id.toString());
    }
    return this.apiRequest.get('api/mailDetail', params);
  }

  sendOutMail(id?: number): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (id != null) {
      params = params.append('id', id.toString());
    }
    return this.apiRequest.get('api/mailResend', params);
  }

}
