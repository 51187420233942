export class SNRange {
  alloc: number;
  allocated: boolean;
  available: boolean;
  blacklisted: boolean;
  bsp: number;
  closeNumber: number;
  formatedCloseNumber: string;
  formatedOpenNumber: string;
  formatedQuantity: string;
  free: boolean;
  openNumber: number;
  quantity: number;
  quarantined: boolean;
  status: string;
}
