import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {Gds} from '../../../vo/gds';
import {GdsService} from '../../../services/api/gds.service';

@Component({
  selector: 'app-editgds',
  templateUrl: './editgds.component.html',
  styleUrls: ['./editgds.component.css']
})
export class EditgdsComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
              protected router: Router,
              protected service: GdsService) {
  }
  public id: number;
  public action: String;
  public isupdate: boolean;

  vo: Gds = new Gds();

  public alerts: any = [];

  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      this.action = p['action'];

      if (this.action == 'U') {
        this.isupdate = true;
      } else {
        this.isupdate = false;
      }

      const me = this;
      this.vo.id = this.id;
      me.getData(this.id);

    });
  }

  update() {
    this.service.updateItem(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }

  delete() {
    this.service.deleteItem(this.vo)
      .subscribe(
        (response) => {
          console.log(response);
          this.addMessage(response.operationMessage);
        }
      );
  }


  backToListPage() {
    this.router.navigate(['/home/gds']);
  }

  getData(id: number) {
    const me = this;
    this.service.getItemByID(id).subscribe((data) => {
        console.log(data);
        this.vo = data.item;
        // this.bigTotalItems = data.totalItems;
      }
    );
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

}
