import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Document } from '../../vo/document';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class DocumentTypeService {
  constructor(
    private apiRequest: ApiRequestEXService
  ) {
  }

  getDocs(page?: number, size?: number, doc?: Document): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (doc && doc.dtName) {
      params = params.append('dtName', doc.dtName);
    }
    if (doc && doc.dtType) {
      params = params.append('dtType', doc.dtType.toString());
    }
    // if(dpc && dpc.iinetaccount){
    //   params = params.append('iinetaccount', dpc.iinetaccount);
    // }

    console.log(params);
    return this.apiRequest.get('api/documentTypes', params);

  }

  getActiveDocs(): Observable<any> {

    return this.apiRequest.get('api/activeDocumentType');

  }

  getActiveDocsForGds(): Observable<any> {

    return this.apiRequest.get('api/activeDocumentTypeForGds');

  }

  getDocsOpened(page?: number, size?: number, doc?: Document): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (doc && doc.dtName) {
      params = params.append('dtName', doc.dtName);
    }
    // if (doc && doc.dtType) {
    //   params = params.append('dtType', doc.dtType.toString());
    // }
    // doc.dtStatus = 'active';
    // if(dpc && dpc.iinetaccount){
    //   params = params.append('iinetaccount', dpc.iinetaccount);
    // }

    console.log(params);
    return this.apiRequest.get('api/documentTypes', params);

  }

  getDocByID(id: number): Observable<any> {
    return this.apiRequest.get('api/documentType/' + id);
  }

  addDoc(vo: Document): Observable<any> {
    return this.apiRequest.post('api/documentType', vo);
  }

  updateDoc(vo: Document): Observable<any> {
    return this.apiRequest.put('api/documentType', vo);
  }

  deleteDoc(vo: Document): Observable<any> {
    return this.apiRequest.delete('api/documentType/' + vo.id);
  }

}
