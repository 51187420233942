<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Allocation Puls Page</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered">

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered">
    <div class="col-12">
      <form class="" role="form">
        <div class="row">
          <!--<div class=" col-2 mt-2">-->
            <!--<label>Select bsp</label>-->
            <!--<select name="bsp" class="ml-3" [(ngModel)]="this.bspId" >-->
              <!--<option value="" selected="selected">All</option>-->
              <!--<option value="PENDING">Pending</option>-->
            <!--</select>-->
          <!--</div>-->
          <!--<div class=" col-2 mt-2">-->
            <!--<label>Select gds</label>-->
            <!--<select name="gds" class="ml-3" [(ngModel)]="this.gdsId" >-->
              <!--<option value="" selected="selected">All</option>-->
              <!--<option value="PENDING">Pending</option>-->
            <!--</select>-->
          <!--</div>-->
          <div class="col-3 mt-4">
            <label>startDate</label>
            <input type="date" name="startDate" [(ngModel)]="this.startDate"/>
          </div>
          <div class="col-3 mt-4">
            <label>endDate</label>
            <input type="date" name="endDate" [(ngModel)]="this.endDate" />
          </div>
          <div class="col-1 mt-4">
            <input type="submit" name="filter" value="Filter" (click)="filter()">
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered table-hover">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E;">
          <th>Document type</th>
          <th>Bsp name</th>
          <th>Gds code</th>
          <th>Create Date</th>
          <th>Quantity</th>
        </tr>
        </thead>
        <tbody *ngIf="allocationPulses.length > 0">
        <tr class="Waving1" *ngFor="let todo of allocationPulses">
          <td>{{todo.dtName}}</td>
          <td>{{todo.bspFullName}}</td>
          <td>{{todo.gdsCode}}</td>
          <td>{{todo.allocationdate | date:'dd-MM-yyyy'}}</td>
          <td>{{todo.quantity}}</td>
        </tr>
        </tbody>

        <tbody *ngIf="allocationPulses.length == 0">
        <tr class="Waving1">
          <td colspan="5">No Data...</td>
        </tr>
        </tbody>

      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->

  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->
