import { Component, OnInit } from '@angular/core';
import {Transfer} from '../../vo/transfer';
import {TransferService} from '../../services/api/transfer.service';
import {AllocateStats} from '../../vo/allocateStats';
import {Bsp} from '../../vo/bsp';
import {BspService} from '../../services/api/bsp.service';
import {MailVo} from '../../vo/mailVo';
import {SendMailService} from '../../services/api/send-mail.service';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.css']
})
export class TransferComponent implements OnInit {
  qry: Transfer = new Transfer();
  stats: AllocateStats[] = [];
  stage = 0;
  bsps: Bsp[] = [];
  isValideRangeForRetrieve = true;
  mailMessage: MailVo = new MailVo();
  public alerts: any = [];
  constructor(protected service: TransferService, private bspService: BspService, private mailService: SendMailService) { }

  ngOnInit() {
    this.stage = 0;
    this.bspService.getAllBsps().subscribe((data) => {
      console.log(data);
      this.bsps = data.items;
      console.log(this.bsps);
    });
  }

  preTransfer() {

    this.stats = [];
    this.stage = 0;
    this.isValideRangeForRetrieve = true;


    this.service.preTransferSNCheck(this.qry.startNumber, this.qry.endNumber).subscribe((data) => {

      if (data.operationStatus == 'ERROR') {
          // alert(data.operationMessage);
          this.isValideRangeForRetrieve = false;
          if (data.allocStats) {
            this.stats = data.allocStats;
          }


        // this.bigTotalItems = data.totalItems;
      } else {
        this.stage = 1;
        this.qry = data.item;
      }
      console.log(data);

    });
  }

  transfer() {
    this.stage = 2;
    console.log(this.qry);
    this.service.transferSn(this.qry.startNumber, this.qry.endNumber, this.qry.fromAllocationId, this.qry.toBspId).subscribe((data) => {
        if (data.operationStatus != 'ERROR') {
          this.qry = data.item;
          // this.bigTotalItems = data.totalItems;
          this.mailMessage = data.mailMessage;
          this.addMessage(data.operationMessage, 'success');
        }
        // alert(data.operationStatus);

      }
    );

  }

  cancel() {
    this.stage = 0;
    const startNumber: number = this.qry.startNumber;
    const endNumber: number = this.qry.endNumber;
    this.qry = new Transfer();
    this.qry.startNumber = startNumber;
    this.qry.endNumber = endNumber;

  }


  public addMessage(message: string, type: string): void {
    this.alerts.push({
      type: type,
      msg: message,
      timeout: 10000
    });
  }


  cancelSending() {
    // alert('cancel sending');
  }

  sendOutMail() {
    this.mailService.sendEmail(this.mailMessage).subscribe(
      (response) => {
        console.log(response);
        // alert(response.operationMessage);
        this.addMessage(response.operationMessage, 'success');
      }
    );
    this.stage = 3;
  }

}
