import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class ForgotpwdService {

  constructor(private apiRequest: ApiRequestEXService) { }

  validate(userName: string, capcha: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    if (userName) {
      params = params.append('userName', userName);
    }
    if (capcha) {
      params = params.append('capcha', capcha);
    }
    return this.apiRequest.get('api/forgotpwd/reset', params);
  }
}
