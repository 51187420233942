<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<!-- title -->
<div class="row">
  <div class="col-10 offset-1">
    <H1>Mail Resend Page</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered">

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered">
    <div class="col-12">
      <form class="" role="form">
        <div class="row">
          <div class="col-3 mt-3">
            <label>Subject</label>
            <input type="text" name="subject" [(ngModel)]="this.subject">
          </div>
          <div class=" col-3 mt-3">
          <label>Select status</label>
          <select name="status" class="ml-3" [(ngModel)]="this.status" >
            <option value=-1>All</option>
            <option value=0>Fail</option>
            <option value=1>Success</option>
            <option value=2>Resent</option>
          </select>
          </div>
          <div class="col-4 mt-3">
            <label>SinceDate</label>
            <input type="date" name="createDate" [(ngModel)]="this.createDate" />
          </div>
          <div class="col-2 mt-3">
            <input type="submit" name="filter" value="Filter" (click)="filter()">
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-bordered table-hover">
        <thead>
        <tr style="background-color: #F2F5FC;color:#1B548E;">
          <th>Subject</th>
          <th>To</th>
          <th>Cc</th>
          <th>Status</th>
          <th>Create Date</th>
        </tr>
        </thead>
        <tbody *ngIf="mailBackUp.length > 0">
        <tr class="Waving1" *ngFor="let todo of mailBackUp">
          <td><a (click)="gotoView(todo)">{{todo.subject}}</a></td>
          <td title="{{todo.to}}">{{todo.to.substring(0,todo.to.indexOf(".")+4)}}</td>
          <td title="{{todo.cc}}">{{todo.cc.substring(0,todo.cc.indexOf(".")+4)}}</td>
          <td *ngIf="todo.status === 0">Fail</td>
          <td *ngIf="todo.status === 1">Success</td>
          <td *ngIf="todo.status === 2">Resent</td>
          <td>{{todo.createDate | date:'dd-MM-yyyy HH:mm:ss'}}</td>
        </tr>
        </tbody>

        <tbody *ngIf="mailBackUp.length == 0">
        <tr class="Waving1">
          <td colspan="5">No Data...</td>
        </tr>
        </tbody>

      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)" [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>
  <!-- Content End-->

  <!-- button start -->

  <!-- button end -->

  <!-- table -->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg }}</alert>
  </div>
</div>
<!-- body mt-3 -->
