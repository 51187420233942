import {Injectable, Inject} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {ApiRequestEXService} from './api-request-ex.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class TransferService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  preTransferSNCheck(startNo: number, endNo: number): Observable<any> {

    // Create Request URL params
    const me = this;
    // let params: HttpParams = new HttpParams();
    let params: HttpParams = new HttpParams();
    // params = params.append('page', typeof page === "number"? page.toString():"0");
    // params = params.append('size', typeof page === "number"? size.toString():"1000");
    params = params.append('startNo', startNo.toString());
    params = params.append('endNo', endNo.toString());

    console.log(params);
    return this.apiRequest.get('api/snPreTransferCheck', params);

  }


  transferSn(startNo: number, endNo: number, allocationId: number, bspId: number): Observable<any> {

    // Create Request URL params
    const me = this;
    // let params: HttpParams = new HttpParams();
    let params: HttpParams = new HttpParams();
    // params = params.append('page', typeof page === "number"? page.toString():"0");
    // params = params.append('size', typeof page === "number"? size.toString():"1000");
    params = params.append('startNo', startNo.toString());
    params = params.append('endNo', endNo.toString());
    params = params.append('allocationId', allocationId.toString());
    params = params.append('bspId', bspId.toString());

    console.log(params);
    return this.apiRequest.get('api/snTransfer', params);

  }

  preRetrieveSNCheck(startNo: string, endNo: string): Observable<any> {

    // Create Request URL params
    const me = this;
    // let params: HttpParams = new HttpParams();
    let params: HttpParams = new HttpParams();
    // params = params.append('page', typeof page === "number"? page.toString():"0");
    // params = params.append('size', typeof page === "number"? size.toString():"1000");
    params = params.append('startNo', startNo.toString());
    params = params.append('endNo', endNo.toString());

    console.log(params);
    return this.apiRequest.get('api/snPreRetrieveCheck', params);

  }

  retrieveSn(allocationId: number, startNo: string, endNo: string, quarantined: boolean, reason: string): Observable<any> {

    // Create Request URL params
    const me = this;
    // let params: HttpParams = new HttpParams();
    let params: HttpParams = new HttpParams();
    params = params.append('allocationId', allocationId.toString());
    params = params.append('startNo', startNo.toString());
    params = params.append('endNo', endNo.toString());
    params = params.append('quarantined', quarantined.toString());
    params = params.append('reason', reason);

    console.log(params);
    return this.apiRequest.get('api/snRetrieve', params);

  }


}
