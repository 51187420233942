export class MailVo {

  attachments?: (string)[] | null;
  bcc?: (string)[] | null;
  body: string;
  cc?: (string)[] | null;
  emailTempPresent: boolean;
  from: string;
  fromName: string;
  subject: string;
  to?: (string)[] | null;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
