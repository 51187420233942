<!-- Collapse -->
<div class="" id="menu">
  <ul class="nav flex-column">
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('login')" href="javascript:void(0);"><b>Logout</b></a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/dashboard')" href="javascript:void(0);">Home</a>
    </li>

    <h1 class="mt-2 mb-1">Dashboard</h1>

    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/fcUsage')" href="javascript:void(0);">FormCode Usage</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/dashRecycle')" href="javascript:void(0);">Recycle Dashboard</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/dashOverall')" href="javascript:void(0);">Overall Dashboard</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/dashPurge')" href="javascript:void(0);">Allocation Purge</a>
    </li>

    <h1 class="mt-2 mb-1">Serial Numbers</h1>

    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/allocation')" href="javascript:void(0);">Allocation</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/transfer')" href="javascript:void(0);">Transfer</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/retrieve')" href="javascript:void(0);">Retrieve</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/recycle')" href="javascript:void(0);">Recycle</a>
    </li>

    <h1 class="mt-2 mb-1">Table Maintenance</h1>

    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/formcode')" href="javascript:void(0);">Form Code</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/doc')" href="javascript:void(0);">Document Type</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/docsubtype')" href="javascript:void(0);">Document Subtype</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/dpc')" href="javascript:void(0);">DPC</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/bsp')" href="javascript:void(0);">BSP</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/gds')" href="javascript:void(0);">GDS</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/subscriber')" href="javascript:void(0);">Subscribers</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/email')" href="javascript:void(0);">Email template</a>
    </li>
    <!--automatic allocation-->
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/predefinedRule')" href="javascript:void(0);">Predefined Rule</a>
    </li>

    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/issueParty')" href="javascript:void(0);">Issue Party</a>
    </li>



    <h1 class="mt-2 mb-1">BSPs</h1>

    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/merging')" href="javascript:void(0);">Merging</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/unmerge')" href="javascript:void(0);">UnMerging</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/bspCal')" href="javascript:void(0);">Calander</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/bspWithCal')" href="javascript:void(0);">BspCalander</a>
    </li>
	  <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/tiFileGenerate')" href="javascript:void(0);">TI File</a>
    </li>

    <h1 class="mt-2 mb-1" *ngIf="userName === 'demo'">TI Monitor</h1>
    <li class="nav-item" *ngIf="userName === 'demo'">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/bspTiCalendar')" href="javascript:void(0);">BspTiCalander</a>
    </li>
    <li class="nav-item" *ngIf="userName === 'demo'">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/tiSwitch')" href="javascript:void(0);">TiAutoSwitch</a>
    </li>
    <li class="nav-item" *ngIf="userName === 'demo'">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/masterNodeSwitch')" href="javascript:void(0);">MasterNodeSwitch</a>
    </li>

    <h1 class="mt-2 mb-1">SNAP Numbers</h1>

    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/bulletin')" href="javascript:void(0);">Bi-Monthly Bulletin</a>
    </li>

    <h1 class="mt-2 mb-1">Traceability</h1>

    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/monitorusage')" href="javascript:void(0);">Monitor Usage</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/allocaTop')" href="javascript:void(0);">Allocation Top</a>
    </li>
    <li class="nav-item">
      <a class="nav-link mt-0 mb-0 pb-0 pt-0" style="text-decoration: none" (click)="goto('/home/mailResend')" href="javascript:void(0);">Mail Resend</a>
    </li>
  </ul>
</div>
<!-- Collapse -->
