<!-- title -->
<div class="row" >
  <div class="col-10 offset-1">
    <H1>Predefined Rule Details</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" >

  <!-- title2 -->
  <div class="row">
    <div class="col-8 mt-1 ">
      <h1>Edit Predefined Rule</h1>
    </div>
  </div>
  <!-- title2 -->

  <!-- Form -->

  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form role="form" name="predefinedRuleDetailDynaForm" method="post" action="/portal/index.html?ctrl:id=window.iata.SNAP&ctrl:type=action&_spage=%2FpredefinedRuleDetails.do&_sorig=%2FpredefinedRule.do">
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label style="color:#1B548E;">BSP</label></div>
          <div class="col-sm-9">
            <select name="bspID" disabled="disabled">
              <option value="">{{vo.bsp.bspCode}}</option>

            </select>
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="" style="color:#1B548E;">Document Type</label></div>
          <div class="col-sm-9">
            <select name="docTypeID" disabled="disabled">
              <option value="">{{vo.documenttypes.dtName}}</option>
            </select>
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="" style="color:#1B548E;">GDS code</label></div>
          <div class="col-sm-9">
            <input type="text" name="code" value="{{vo.gdscode}}" disabled="disabled">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
        <div class="row form-group table-bordered" >
          <div class="col-sm-3 "><label for="" style="color:#1B548E;">Volume Per Month</label></div>
          <div class="col-sm-9">
            <input type="text" name="volumePerMonth" maxlength="100" size="20" value="" [(ngModel)]="vo.volumepermonth">
            <font color="#ff0000" size="4">*</font>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->
  <!-- alert -->
  <!-- alert -->

  <!-- button start -->
  <div class="row form-group table-bordered border-right-0 border-left-0 ">

    <div class="col-sm-3" *ngIf="!isupdate">
     <input type="submit" name="new" value="Delete"  (click)="delete()">
    </div>
    <div class="col-sm-3" *ngIf="isupdate">
      <input type="submit" name="new" value="Update" (click)="update()" >
    </div>
    <div class="offset-3">
      <input type="submit" name="back" value="Cancel" (click)="backToListPage()">
    </div>
  </div>
  <!-- button end -->
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}</alert>
  </div>
  <!-- Form -->

</div>
<!-- body mt-3 -->
