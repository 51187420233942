import { Document } from './document';
import { DocumentSubType } from './documentSubType';
import { Bsp } from './bsp';
import { Gds } from './gds';
import { Formcode } from './formcode';

export class Allocation {
  allocationdate: Date;
  bsp: Bsp;
  documentSubtype: DocumentSubType;
  documentTypes: Document;
  endnumber: number;
  fromcode: Formcode;
  gds: Gds;
  id: number;
  printerId: number;
  purchaseordernumber: string;
  purgeDate: Date;
  quantity: number;
  startnumber: number;
  status: number;
  validitydate: Date;
  allocationRequestId: number;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
