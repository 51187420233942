import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Location} from '@angular/common';
import {MailVo} from '../../vo/mailVo';

@Component({
  selector: 'app-mail-viewer',
  templateUrl: './mail-viewer.component.html'
})
export class MailViewerComponent implements OnInit {

  constructor( private location: Location) {
  }

  @Input()
  mailMessage: MailVo;

  @Input()
  stage: number;

  ccStr: string;
  toStr: string;

  @Output()
  cancel: EventEmitter<any> = new EventEmitter();

  @Output()
  sendMail: EventEmitter<MailVo> = new EventEmitter();

  public alerts: any = [];

  ngOnInit(): void {
  }

  goBack(): void {
    this.cancel.emit(this.mailMessage);
  }
  goSendMail(): void {
    const to = '' + this.mailMessage.to;
    const cc = '' + this.mailMessage.cc;
    console.log(to);
    console.log(cc);
    const toArray = to.split(',');
    const ccArray = cc.split(',');
    this.mailMessage.to = toArray;
    this.mailMessage.cc = ccArray;

    console.log(toArray);
    console.log(ccArray);
    this.sendMail.emit(this.mailMessage);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
}
