<!--Start of query form -->
<!-- title -->
<div class="row" *ngIf="stage == 0">
  <div class="col-10 offset-1">
    <H1>Monitor Usage Filter Page</H1>
  </div>
  <div *ngFor="let alert of alerts">
    <alert [type]="alert.type" [dismissOnTimeout]="alert.timeout">{{ alert.msg
      }}
    </alert>
  </div>
</div>

<!-- title -->

<!-- body mt-3 -->
<div class="col-8 offset-1 table-bordered" *ngIf="stage == 0">

  <!-- title2 -->

  <!-- title2 -->

  <!-- Form -->
  <!-- button start -->
  <div class="row form-group mt-2 table-bordered ">
    <div class="ml-3">
      <input type="submit" name="btnMonitorRangeStatus" value="Current Status" (click)="currentStatusE()">
    </div>
    <div class="offset-3">
      <input type="submit" name="btnMonitorActionHistory" value="Action History" (click)="actionHistoryE()">
    </div>
  </div>
  <!-- button end -->
  <!-- Query form Start-->
  <div class="row table-bordered mt-3 border-right-0 border-left-0">
    <div class="col-12 mt-3">
      <form class="" role="form">

        <div class="row form-group ">
          <div class="col-sm-4 "><label for="formCode">Formcode</label></div>
          <div class="col-sm-3">
            <input type="text" name="formCode" [(ngModel)]="vo.formcodeId" maxlength="3" value=""
                   title="Only Numeric value with 2 or 3 digits">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-8">
            <input type="hidden" id="gdsCode" name="gdsCode" [(ngModel)]="vo.gdsCode" maxlength="3" value="">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-4 "><label for="rangeStart">Serial Number</label></div>
          <div class="col-sm-8">
            <input type="text" name="rangeStart" id="rangeStart" value="" maxlength="13" size="13"
                   [(ngModel)]="vo.serialNumber">
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-sm-4 "><label for="activeBsp">Active BSP</label></div>
          <div class="col-sm-8">
            <select name="activeBsp" [(ngModel)]="vo.activeBsp.id">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of activeBsps" [value]="item.id">{{item.bspFullname+'('+item.bspCode+')' }}
              </option>
            </select>
          </div>
        </div>
        <div class="row form-group ">
          <div class="col-4"><label for="docType">Document type</label></div>
          <div class="col-sm-8">
            <select name="docType" [(ngModel)]="vo.docType.id">
              <option value="" selected="selected">-</option>
              <option *ngFor="let item of docTypes" [value]="item.id">{{item.dtName}}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- alert -->

  <!-- alert -->

  <!-- button start -->
  <!-- button end -->

  <!-- Form -->

</div>
<!-- body mt-3 -->
<!-- End of query form-->

<!--Start of current status form -->
<!-- title -->
<div class="row" *ngIf="stage == 1">
  <div class="col-10 offset-1">
    <H1>Monitor Current Status</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" *ngIf="stage == 1">
  <div class="row">
    <div class="col-8 mt-1 ">
      <h2>
        Monitor Current Status Results
      </h2>
    </div>
  </div>

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered ">
    <div class="col-12">
      <form class="" role="form">
        <div class="row">
          <div class="form-group col-2 mt-2">
            <input type="submit" name="btnMonitorRangeStatus" value="Current Status" disabled="disabled">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="submit" name="btnMonitorActionHistory" value="Action History" (click)="actionHistoryE()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="submit" name="btnMonitorFilter" value="New Search" (click)="backToSearch()">
          </div>
          <div class="form-group col-2 mt-2">
            <input type="submit" name="export" value="Export CSV" (click)="exportCsv()">
          </div>

        </div>
      </form>
    </div>
  </div>
  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">
      <table class="table table-hover table-condensed">
        <thead style="background-color: #F2F5FC;color:#1B548E">
        <tr style="text-align:center">
          <td style="text-align:right">Form code</td>
          <td>Doc. type</td>
          <!-- <td>Doc. subtype</td>-->
          <td>Start/End number</td>
          <td>Quantity</td>
          <td>Status code</td>
          <td>BSP</td>
          <td>GDS</td>
          <!-- <td>Printer</td>-->
          <td>Validity date</td>
          <td>Purchase Order No.</td>
          <td>Status date</td>
          <td>SNAP No.</td>
        </tr>
        </thead>
        <tbody *ngIf="curentStatuss !== null && curentStatuss.length > 0">
        <tr class="Waving1" *ngFor="let todo of curentStatuss">
          <td hidden="true">{{ todo.formcodeId}}</td>
          <td hidden="true">{{ todo.docType}}</td>

          <!--<td hidden="true">{{ vo.validityDate}}</td>-->

          <td hidden="true">{{ todo.serialNumber}}</td>
          <td hidden="true">{{ todo.docSubType}}</td>
          <td hidden="true">{{ todo.gds}}</td>
          <td hidden="true">{{ todo.activeBsp}}</td>
          <td hidden="true">{{ todo.printer}}</td>
          <!--<td hidden="true">{{ vo.purchaseOrderNumber}}</td>-->
          <td hidden="true">{{ todo.allocationDate}}</td>
          <td hidden="true">{{ todo.validityDate}}</td>
          <!--<td hidden="true">{{ vo.statusChangeDate}}</td>-->
          <td hidden="true">{{ todo.formcodeFrozen}}</td>
          <td hidden="true">{{ todo.snCode}}</td>
          <td hidden="true">{{ todo.opCode}}</td>

          <td>{{todo.formcode}}</td>
          <td>{{todo.doc}}</td>
          <!--   <td>{{todo.subDoc}}</td> -->
          <td>{{todo.rangeStart +"\n"+ todo.rangeEnd}}</td>
          <td>{{todo.quantity}}</td>
          <td>{{todo.statusCode}}</td>
          <td *ngIf="todo.bsp != null">{{ todo.bsp+"("+todo.bspCode+")" }}</td>
          <td *ngIf="todo.bsp == null"></td>
          <td>{{todo.gdsCode}}</td>
          <!-- <td>{{todo.print}}</td>-->
          <td>{{todo.valDate}}</td>
          <td>{{todo.purOderNum}}</td>
          <td>{{todo.schDate | trans}}</td>
          <td>{{todo.snapNo}}</td>
        </tr>
        </tbody>
        <!--<tbody *ngIf="curentStatuss.length == 0">-->
        <!--<tr class="Waving1" >-->
        <!--<td colspan="5">No Data...</td>-->
        <!--</tr>-->
        <!--</tbody>-->
      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)"
                    [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-12">
      <!--<thead>Legend for Status Codes-->
      <!--</thead>-->
      <table class="table table-bordered">
        <thead>Legend for Status Codes</thead>
        <tbody *ngIf="snstatuss !== null && snstatuss.length > 0">
        <tr *ngFor="let todo of snstatuss">
          <td>{{todo.code}}</td>
          <td>{{todo.description}}</td>
        </tr>
        </tbody>
        <tbody *ngIf="snstatuss.length == 0">
        <tr class="Waving1">
          <td colspan="5">No Data...</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>

</div>

<!-- End of current status form-->


<!-- Action history start -->

<!-- title -->
<div class="row" *ngIf="stage == 2">
  <div class="col-10 offset-1">
    <H1>Monitor Action History</H1>
  </div>
</div>
<!-- title -->

<!-- body mt-3 -->
<div class="col-10 offset-1 table-bordered" *ngIf="stage == 2">
  <div class="row">
    <div class="col-8 mt-1 ">
      <h2>
        Monitor Action History Results
      </h2>
    </div>
  </div>

  <!-- table -->

  <!-- Query form Start-->
  <div class="row table-bordered border-left-0 border-right-0">
    <div class="col-12">
      <form class="" role="form">
        <div class="row">
          <div class="form-group col-2 mt-2">
            <input type="submit" name="btnMonitorRangeStatus" value="Current Status" (click)="currentStatusE()"/>
          </div>
          <div class="form-group col-2 mt-2">
            <input type="submit" name="btnMonitorActionHistory" value="Action History" disabled="disabled"/>
          </div>
          <div class="form-group col-2 mt-2">
            <input type="submit" name="btnMonitorFilter" value="New Search" (click)="backToSearch()"/>
          </div>
          <div class="form-group col-2 mt-2">
            <input type="submit" name="export" value="Export CSV" (click)="exportHitoricalCsv()">
          </div>

        </div>
      </form>
    </div>
  </div>

  <spinner [busy]="isSpinnerActive" message="loading...">
  </spinner>

  <!-- Query form End-->

  <!-- Content Start-->
  <div class="row mt-3">
    <div class="col-12">

      <table class="table table-hover table-condensed">
        <thead style="background-color: #F2F5FC;color:#1B548E">
        <tr style="text-align:center">
          <td>Form code</td>
          <td>Doc. type</td>
          <td style="text-align:right">Start/End number</td>
          <td style="text-align:right">Quantity</td>
          <td>Opn code</td>
          <td>Status code</td>
          <td>BSP (from/to)</td>
          <td>GDS</td>
          <td>Validity date</td>
          <td style="text-align:right">Purchase Order No.</td>
          <td>Status date</td>
          <td>SNAP No.</td>
        </tr>
        </thead>
        <tbody *ngIf="historicalStatus !== null && historicalStatus.length > 0">
        <tr class="Waving1" *ngFor="let todo of historicalStatus">
          <td>{{todo.formcode}}</td>
          <td>{{todo.doc}}</td>
          <td>{{todo.rangeStart +"\n"+ todo.rangeEnd}}</td>
          <td>{{todo.quantity}}</td>
          <td>{{todo.opCode}}</td>
          <td>{{todo.statusCode}}</td>
          <td *ngIf="todo.bsp != null">{{ todo.bsp+"("+todo.bspCode+")" }}</td>
          <td *ngIf="todo.bsp == null"></td>
          <td>{{todo.gdsCode}}</td>
          <td>{{todo.valDate | trans}}</td>
          <td>{{todo.purOderNum}}</td>
          <td>{{todo.statusDate | trans}}</td>
          <td>{{todo.snapNo}}</td>
        </tr>
        </tbody>
      </table>
      <div class="col-md-6">
        <pagination [totalItems]="bigTotalItems" [(ngModel)]="bigCurrentPage" (pageChanged)="pageChanged($event)"
                    [maxSize]="maxSize" class="pagination-sm"
                    [boundaryLinks]="true"></pagination>
      </div>
    </div>
  </div>

  <div class="row mt-3">
    <div class="col-6">
      <thead>Legend for Status Codes
      </thead>
      <tbody *ngIf="snstatuss.length > 0">
      <tr *ngFor="let todo of snstatuss">
        <td>{{todo.code}}</td>
        <td>{{todo.description}}</td>
      </tr>
      </tbody>
    </div>
    <div class="col-6">
      <thead>Legend for Operation Codes
      </thead>
      <tbody *ngIf="opstatuss.length > 0">
      <tr *ngFor="let todo of opstatuss">
        <td>{{todo.code}}</td>
        <td>{{todo.description}}</td>
      </tr>
      </tbody>
    </div>
  </div>

  <!-- Content End-->

  <!-- button start -->

  <!-- button end -->

  <!-- table -->

</div>
<!-- body mt-3 -->

<!-- end of action history -->
