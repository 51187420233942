import { Component, OnInit } from '@angular/core';
import { Document } from '../../../vo/document';
import { Formcode } from '../../../vo/formcode';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { DocumentTypeService } from '../../../services/api/documentType.service';
import { FormcodeService } from '../../../services/api/formcode.service';
import { IssuParty } from '../../../vo/issu-party';
import { IssuePartyService } from '../../../services/api/issue-party.service';

@Component({
  selector: 'app-viewformcode',
  templateUrl: './viewformcode.component.html',
  styleUrls: ['./viewformcode.component.css']
})
export class ViewformcodeComponent implements OnInit {

  constructor(protected route: ActivatedRoute,
    protected router: Router,
    protected formcodeService: FormcodeService,
    protected docService: DocumentTypeService,
    protected issuService: IssuePartyService) { }

  public id: number;
  public action: String;
  public isupdate: boolean;
  vo: Formcode = new Formcode();
  doc: Document = new Document();
  issu: IssuParty = new IssuParty();
  issus: IssuParty[] = [];
  public fcLength: number;
  public alerts: any = [];
  ngOnInit() {
    this.route.queryParams.subscribe((p: Params) => {
      this.id = p['id'];
      // this.action = p['action'];
      // if (this.action === 'U') {
      //   this.isupdate = true;
      // } else {
      //   this.isupdate = false;
      // }

      const me = this;
      this.vo.id = this.id;
      this.vo.documentType = this.doc;
      me.getPageData(0, 10, this.id);
    });

    this.vo.issueParty = this.issu;
    this.issuService.getIssuingPartys().subscribe((data) => {
      console.log(data);
      this.issus = data.items;
      console.log(this.issus);
    });
  }

  backToList() {
    this.router.navigate(['/home/formcode']);
  }

  // TODO: split
  split() {
  }

  getPageData(page: number, size: number, id: number) {
    const me = this;
    this.formcodeService.getItemByID(id).subscribe((data) => {
      console.log(data);
      this.vo = data.item;
      this.fcLength = this.vo.fcNumber.length;
    }
    );
  }
  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 180000
    });
  }

}
