import {Bsp} from './bsp';
import {Document} from './document';

export class PredefinedRule {
  bsp: Bsp;
  createdate: string;
  documenttypes: Document;
  flag: string;
  gdscode: string;
  id: number;
  modifieddate: string;
  volumepermonth: number;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
