import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Bsp } from '../../vo/bsp';
import { MergingService } from '../../services/api/merging.service';

@Component({
  selector: 'app-merging',
  templateUrl: './merging.component.html',
  styleUrls: ['./merging.component.css']
})
export class MergingComponent implements OnInit {

  vo1: Bsp = new Bsp();
  vo2: Bsp = new Bsp();
  from: string;
  to: string;
  bsps: Bsp[] = [];
  date: string;
  public alerts: any = [];
  constructor(private service: MergingService, private router: Router) { }

  ngOnInit() {
    const me = this;
    this.getBsps();
  }
  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }
  getBsps() {
    this.service.getItems(this.vo1, this.vo2).subscribe((data) => {
      console.log(data);
      this.bsps = data.items;
      this.date = data.date;
    }
    );
  }
  merge() {
    this.from = this.vo1.id.toString();
    this.to = this.vo2.id.toString();
    this.service.gotoMerge(this.from, this.to).subscribe((response) => {
      console.log(response);
      this.addMessage(response.operationMessage);
      this.getBsps();
    }
    );
  }

}
