import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {PredefinedRule } from '../../../vo/predefinedRule';
import {Bsp } from '../../../vo/bsp';
import {Document } from '../../../vo/document';
import { PredefinedRuleService } from '../../../services/api/predefinedRule.service';
import { BspService} from '../../../services/api/bsp.service';
import {DocumentTypeService} from '../../../services/api/documentType.service';


@Component({
  selector: 'app-new-predefined-rule',
  templateUrl: './new-predefined-rule.component.html',
  styleUrls: ['./new-predefined-rule.component.css']
})
export class NewPredefinedRuleComponent implements OnInit {

  vo: PredefinedRule = new PredefinedRule();
  public alerts: any = [];
  bsp: Bsp = new Bsp();
  bsps: Bsp[] = [];
  doc: Document = new Document();
  docs: Document[] = [];
  constructor(private service: PredefinedRuleService, private router: Router, private bspservice: BspService, private dctservice: DocumentTypeService) { }
  ngOnInit() {
    this.vo.bsp = this.bsp;
    this.vo.documenttypes = this.doc;
  /*  this.bspservice.getPages(0,10,null).subscribe((data) => {
          console.log(data);
          this.bsps = data.items;
        }
    );*/
    this.bspservice.getAllBsps().subscribe((data) => {
        console.log(data);
        this.bsps = data.items;
      }
    );
    this.dctservice.getDocs(0, 100, null).subscribe((data) => {
        console.log(data);
        this.docs = data.items;
      }
    );

  }
  addNew() {
    console.log(this.vo);
    this.service.addItem(this.vo)
      .subscribe(
        (newTodo) => {
          console.log(newTodo);
          this.addMessage(newTodo.operationMessage);
        }
      );
  }

  backToListPage() {
    this.router.navigate(['/home/predefinedRule']);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

}
