import {Injectable, Inject} from '@angular/core';
import {Observable, ReplaySubject, Subject} from 'rxjs';
import {Subscriber} from '../../vo/subscriber';
import { HttpParams } from '@angular/common/http';
import {ApiRequestEXService} from './api-request-ex.service';

@Injectable()
export class SubscriberService {

  constructor(private apiRequest: ApiRequestEXService) {
  }


  getPages(page?: number, size?: number, vo?: Subscriber): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (vo && vo.email) {
      params = params.append('email', vo.email);
    }

    // if(dpc && dpc.dpcCode){
    //   params = params.append('dpcCode', dpc.dpcCode);
    // }
    // if(dpc && dpc.email){
    //   params = params.append('email', dpc.email);
    // }
    // if(dpc && dpc.iinetaccount){
    //   params = params.append('iinetaccount', dpc.iinetaccount);
    // }

    console.log(params);
    return this.apiRequest.get('api/subscribers', params);

  }

  getItemByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/subscriber/' + id);
  }

  addItem(vo: Subscriber): Observable<any> {
    return this.apiRequest.post('api/subscriber', vo);
  }

  updateItem(vo: Subscriber): Observable<any> {
    return this.apiRequest.put('api/subscriber', vo);
  }

  deleteItem(vo: Subscriber): Observable<any> {
    return this.apiRequest.delete('api/subscriber/' + vo.id);
  }
}
