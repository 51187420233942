<div class="container-fluid">

  <!-- logo -->
  <div class="row">
    <div id="header">
      <div class="logo">
        <a href="http://www.iata.org/">
          <img src="../assets/SNAPDocroot//portal/images/logo_app.gif" border="0" title="International Air Transport Association">
        </a>
      </div>
      <div class="logoprint">
        <img src="../assets/SNAPDocroot//portal/images/logo_print.gif">
      </div>
    </div>
  </div>
  <!-- logo -->

  <!-- welcome -->
  <div id="HeaderBar" class="row">
    <div class="col-2" id="UserInfo">
      Welcome, <a href="#">{{gdsCode}}</a>
    </div>
    <div class="col-3 offset-7 pt-1 text-right" style="font: bold 8pt/12pt Arial, Helvetica, sans-serif; color: #FFFFFF;">
      Serial Number Allocation Programme
    </div>
  </div>
  <!-- welcome -->

  <!-- Help -->
  <div class="row mt-2">
    <div class="col-1 text-right offset-11">
      <a class="helpSt" style="font: bold 8pt/12pt Arial, Helvetica, sans-serif;" target="_blank" href="../../../assets/SNAP_GDS_UserManual.pdf">HELP</a>
    </div>
  </div>
  <!-- Help -->

  <!-- middle mt-3 -->
  <div class="row">

    <!-- menu -->
    <nav class="col-2 navbar-toggleable-sm">
      <H1>SNAP</H1>
      <app-gdsmenu></app-gdsmenu>
      <!-- Collapse -->
    </nav>
    <!-- menu -->

    <!-- main -->
    <main class="col-10">
      <router-outlet></router-outlet>
    </main>
  </div>
  <!-- middle mt-3 -->

  <!-- foot -->
  <div id="footer" class="row">
    <div class="col-4 "></div>
    <h6>Bin v5.2 of 04.01.2007 &copy;2005 International Air Transport Association. All rights reserved.</h6>
  </div>
  <!-- foot -->
</div>
