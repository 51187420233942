import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FormHistory } from '../../vo/formHistory';
import { ApiRequestEXService } from './api-request-ex.service';
import { HttpParams } from '@angular/common/http';

@Injectable()
export class FormHistoryService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  getPages(page?: number, size?: number, vo?: FormHistory): Observable<any> {

    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    // if(vo && vo.bspCode){
    //   params = params.append('bspCode', vo.bspCode);
    // }
    // if(vo && vo.bspFullname){
    //   params = params.append('bspFullname', vo.bspFullname);
    // }
    // if(vo && vo.bspActive){
    //   params = params.append('bspActive', vo.bspActive);
    // }

    console.log(params);
    return this.apiRequest.get('api/FormHistorys', params);

  }

  getItemByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/FormHistory/' + id);
  }

  addItem(vo: FormHistory): Observable<any> {
    return this.apiRequest.post('api/FormHistory', vo);
  }

  updateItem(vo: FormHistory): Observable<any> {
    return this.apiRequest.put('api/FormHistory', vo);
  }

  deleteItem(vo: FormHistory): Observable<any> {
    return this.apiRequest.delete('api/FormHistory/' + vo.id);
  }

  getAll(vo?: FormHistory): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/FormHistorys/all');
  }

  getOderByDate(fcNumber: string): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/FormHistorysOrderBy/' + fcNumber);
  }

  getFormHistory(page?: number, size?: number, formcode?: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    params = params.append('formcode', formcode);
    console.log(params);
    return this.apiRequest.get('api/getFormHistory', params);
  }

}
