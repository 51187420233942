import { Component, OnInit } from '@angular/core';
import { Bsp } from '../../../vo/bsp';
import { Dpc } from '../../../vo/dpc';
import { Gds } from '../../../vo/gds';
import { BspService } from '../../../services/api/bsp.service';
import { DpcService } from '../../../services/api/dpc.service';
import { GdsService } from '../../../services/api/gds.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-newbsp',
  templateUrl: './newbsp.component.html',
  styleUrls: ['./newbsp.component.css']
})

export class NewbspComponent implements OnInit {

  vo: Bsp = new Bsp();
  dpc: Dpc = new Dpc();
  dpcs: Dpc[] = [];
  gds: Gds = new Gds();
  gdss: Gds[] = [];
  gdsChecked: Gds[] = [];
  public alerts: any = [];

/*  minDate = new Date(2017, 5, 10);
  maxDate = new Date(2018, 9, 15);*/
  _bsValue: Date;
  get bsValue(): Date {
    return this._bsValue;
  }

  set bsValue(v: Date) {
    console.log(v);
    this._bsValue = v;
  }

  _bsRangeValue: any = [new Date(2017, 7, 4), new Date(2017, 7, 20)];
  get bsRangeValue(): any {
    return this._bsRangeValue;
  }

  set bsRangeValue(v: any) {
    this._bsRangeValue = v;
  }

  log(v: any) {console.log(v); }

  constructor(private service: BspService, private router: Router, private dpcService: DpcService, private gdsService: GdsService) { }

  ngOnInit() {
      this.vo.dpc = this.dpc;
      this.dpcService.getDpcs(0, 1000, null).subscribe((data) => {
          console.log(data);
          this.dpcs = data.items;
          console.log(this.dpcs);
        }
      );

    this.vo.gds = this.gds;
    this.gdsService.getItems(0, 1000, null).subscribe((data) => {
        console.log(data);
        this.gdss = data.items;
        console.log('=================GDS===================');
        console.log(this.gdss);
      }
    );

  }


  addTo() {
    const v = new Gds();
    console.log(this.gdss);
    console.log(this.gdsChecked);
    for (const item of this.gdss) {
      if (item.id == this.vo.gds.id) {
        v.gdsCode = item.gdsCode;
        v.id = item.id;
        // console.log(item);
        const index = this.gdss.indexOf(item);
        // alert(index);
        this.gdss.splice(index, 1);
      }
    }
    console.log(v);
    this.gdsChecked.push(v);
    console.log(this.gdsChecked);

  }

  back() {
    const v = new Gds();
    v.id = this.vo.gds.id;
    // alert(this.vo.gds.id);
    // console.log(this.gdsChecked);
    console.log(this.gdss);
    console.log(this.gdsChecked);
    for (const item of this.gdsChecked) {
      if (item.id == this.vo.gds.id) {
        v.gdsCode = item.gdsCode;
        v.id = item.id;
        const index = this.gdsChecked.indexOf(item);
        // alert(index);
        this.gdsChecked.splice(index, 1);
      }
    }
    console.log(v);
    console.log(this.gdsChecked);
    this.gdss.push(v);
    console.log(this.gdss);

  }

  addNew() {
    console.log(this.vo);
    alert(this.vo.bspActive);
    // if (this.vo.bspActive = 'true') {
    //   this.vo.bspActive = '1';
    // } else {
    //   this.vo.bspActive = '0';
    // }
    // if (this.vo.bspCgnRequestallowed = 'true') {
    //   this.vo.bspCgnRequestallowed = '1';
    // } else {
    //   this.vo.bspCgnRequestallowed = '0';
    // }
    // if (this.vo.bspTiFlag = 'true') {
    //   this.vo.bspTiFlag = '1';
    // } else {
    //   this.vo.bspTiFlag = '0';
    // }

    this.vo.relGds = this.gdsChecked;
    // console.log(this.vo);
    this.service.addItem(this.vo)
      .subscribe(
        (newTodo) => {
          // console.log(newTodo);
          this.addMessage(newTodo.operationMessage);
        }
      );
  }

  backToListPage() {
    this.router.navigate(['/home/bsp']);
  }

  public addMessage(message: string): void {
    this.alerts.push({
      type: 'success',
      msg: message,
      timeout: 3000
    });
  }

}
