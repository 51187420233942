export class Transfer {
  documenttype: string;
  endNumber: number;
  fromAllocationId: number;
  fromBspId: number;
  fromBspName: string;
  originalEndNumber: number;
  originalQuantity: number;
  originalStartNumber: number;
  quantity: number;
  startNumber: number;
  toBspId: number;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
