
import {SNRange} from './snRange';

export class FormcodeUsageDetail {

  startNumber: number;
  endNumber: number;
  freeSpacePercent: number;
  retrieveAvailabePercent: number;
  retrieveQuarantinedPercent: number;
  allocatedPercent: number;
  blacklistedNumber: number;
  blacklistedPercent: number;
  blacklistedInteruption: number;
  ranges?: (SNRange)[] | null;

  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
