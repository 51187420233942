import {Document} from './document';
export class FormHistory {

  id: number;
  formcodeId: string;
  documentType: Document;
  fcComment: string;
  fcReso: string;
  updateDate: Date;
  updateDate_: string;
  // fcFrozen: Date;
  // fcRcyReady: Date;

  fcFrozen: string;
  fcRcyReady: string;

  fcFrozenFor: number;
  isOverridden: string;
  fcNumber: string;


  constructor(values: Object = {}) {
    Object.assign(this, values);
  }
}
