import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Bsp } from '../../vo/bsp';
import { ApiRequestEXService } from './api-request-ex.service';

@Injectable()
export class BspService {

  constructor(private apiRequest: ApiRequestEXService) {
  }

  getPages(page?: number, size?: number, vo?: Bsp): Observable<any> {

    // Create Request URL params
    const me = this;
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (vo && vo.bspCode) {
      params = params.append('bspCode', vo.bspCode);
    }
    if (vo && vo.bspFullname) {
      params = params.append('bspFullname', vo.bspFullname);
    }
    if (vo && vo.bspActive) {
      params = params.append('bspActive', vo.bspActive.toString());
    }

    // if(dpc && dpc.dpcCode){
    //   params.append('dpcCode', dpc.dpcCode);
    // }
    // if(dpc && dpc.email){
    //   params.append('email', dpc.email);
    // }
    // if(dpc && dpc.iinetaccount){
    //   params.append('iinetaccount', dpc.iinetaccount);
    // }

    console.log(params);
    return this.apiRequest.get('api/Bsps', params);

  }

  getItemByID(id: number): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/Bsp/' + id);
  }

  addItem(vo: Bsp): Observable<any> {
    // alert(vo.bspActive);
    return this.apiRequest.post('api/Bsp', vo);
  }

  updateItem(vo: Bsp): Observable<any> {
    // alert(vo.bspTiDate);
    return this.apiRequest.put('api/Bsp', vo);
  }

  deleteItem(vo: Bsp): Observable<any> {
    return this.apiRequest.delete('api/Bsp/' + vo.id);
  }

  getAllBsps(vo?: Bsp): Observable<any> {
    const me = this;
    return this.apiRequest.get('api/Bsps/all');
  }

  getAllBspsByGds(gds: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('Gdscode', gds.toString());
    return this.apiRequest.get('api/ActiveBspsByGds', params);
  }

  getActiveBsps(): Observable<any> {
    return this.apiRequest.get('api/ActiveBsps');
  }

  getActivePages(page?: number, size?: number, vo?: Bsp): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.append('page', typeof page === 'number' ? page.toString() : '0');
    params = params.append('size', typeof page === 'number' ? size.toString() : '1000');
    if (vo && vo.bspCode) {
      params = params.append('bspCode', vo.bspCode);
    }
    if (vo && vo.bspFullname) {
      params = params.append('bspFullname', vo.bspFullname);
    }
    if (vo && vo.bspCal.id) {
      params = params.append('bspCal', vo.bspCal.id.toString());
    }
    if (vo && vo.dpc.dpcCode) {
      params = params.append('bspDpcId', vo.dpc.dpcCode);
    }
    console.log(params);
    return this.apiRequest.get('api/BspsWithCal', params);

  }

  generateTIFileByBsp(bspCode: string, startTime: string, endTime: string): Observable<any> {

    let params: HttpParams = new HttpParams();
    if (bspCode) {
      params = params.append('bspCode', bspCode);
    }
    if (startTime) {
      params = params.append('startTime', startTime);
    }
    if (endTime) {
      params = params.append('endTime', endTime);
    }
    return this.apiRequest.get('api/TiFileGeneration', params);
  }
}
